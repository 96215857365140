import type { SelectedValue } from "@editorComponents/Lists";

import * as React from "react";

import ElementNameEditor from "@components/editor/elementEditors/ElementNameEditor";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import Separator from "@editor/components/common/designSystem/Separator";
import { TextTab } from "@editor/components/common/TextTab";
import { ShopifyThemeSettings } from "@editor/components/editor/elementEditors/ShopifyThemeSettings";
import { InfiniteProductSummaryList } from "@editor/components/editor/page/ProductSummaryList";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useInitial } from "@editor/hooks/useInitial";

import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { BsSearch } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import { ElementEditorHeading } from "./ElementEditorHeading";

type ProductTemplateElementEditorTab = "general" | "shopifyTheme";

const PRODUCT_TEMPLATE_EDITOR_TABS = [
  { value: "general", label: "General", isVisible: true },
  { value: "shopifyTheme", label: "Shopify Theme", isVisible: true },
] as const;

const ProductTemplateElementEditor: React.FC<{
  initialName?: string;
}> = ({ initialName }) => {
  const [selectedTab, setSelectedTab] =
    React.useState<ProductTemplateElementEditorTab>("general");

  return (
    <div className="flex h-fit flex-col gap-y-2">
      <TextTab
        options={PRODUCT_TEMPLATE_EDITOR_TABS}
        onChange={setSelectedTab}
        selectedValue={selectedTab}
        containerClassName="flex"
        className="text-xs"
      />
      {exhaustiveSwitch({ type: selectedTab })({
        general: () => (
          <ProductTemplateBasicSettings initialName={initialName} />
        ),
        shopifyTheme: () => <ShopifyThemeSettings className="mt-2" />,
      })}
    </div>
  );
};

const ProductTemplateBasicSettings: React.FC<{
  initialName?: string;
}> = ({ initialName }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const initialElementIsPublished = useInitial(element.isPublished);

  const onChangeProductIds = (item: SelectedValue) => {
    const itemAsString = String(item);
    const newTemplateShopifyProductIds =
      element.templateShopifyProductIds?.includes(itemAsString)
        ? element.templateShopifyProductIds.filter((i) => i !== itemAsString)
        : [...(element.templateShopifyProductIds ?? []), itemAsString];
    onChangeElement({
      ...element,
      // Note (Evan, 2023-10-13): We sort this so we can reliably reset the template editor product to the first assigned product
      templateShopifyProductIds: newTemplateShopifyProductIds.sort(),
    });
  };

  return (
    <div className="flex h-fit flex-col gap-y-2">
      <ElementNameEditor
        type="shopifyProductTemplate"
        inputName="page-name"
        initialName={initialName ?? element.name}
        onChange={(name) => onChangeElement({ ...element, name })}
      />
      <LabeledControl label="Path" isPhonyLabel size="sm" className="mt-2">
        <div className="flex flex-row gap-2 items-center">
          <div className="flex-none">
            <Selectable
              className="bg-light-surface w-fit max-w-[150px] truncate"
              labelClassName="truncate block"
              arrowClassName="text-slate-400"
              options={[{ label: "products/", value: "products/" }]}
              value="products/"
              onSelect={() => {}}
              disableDropdownFixedWidth
              dropdownAlign="start"
              size="xs"
              isDisabled
            />
          </div>
          <Input
            id="page-data-path"
            placeholder="product-slug"
            value="product-slug"
            size="sm"
            isDisabled
          />
        </div>
      </LabeledControl>

      {initialElementIsPublished && (
        <div className="mt-2">
          <SwitchWithDescription
            label="Is Published"
            description="Your products will be displayed using this template."
            isOn={element.isPublished}
            onChange={(isPublished) =>
              onChangeElement({ ...element, isPublished })
            }
            size="sm"
          />
        </div>
      )}

      <Separator className="mt-2" />

      <ProductSelector
        selectedIds={element.templateShopifyProductIds ?? []}
        onChange={onChangeProductIds}
      />
    </div>
  );
};

const ProductSelector: React.FC<{
  selectedIds: SelectedValue[];
  onChange: (item: SelectedValue) => void;
}> = ({ selectedIds, onChange }) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  return (
    <div className="mt-2">
      <ElementEditorHeading className="text-sm">
        Products Assigned to Template
        {selectedIds.length > 0 ? ` (${selectedIds.length})` : ""}
      </ElementEditorHeading>
      <div className="my-2">
        <Input
          size="sm"
          value={searchTerm}
          startEnhancer={<BsSearch />}
          endEnhancer={() =>
            searchTerm?.trim() && (
              <RiCloseFill
                size={12}
                className="cursor-pointer text-slate-400"
                onClick={() => setSearchTerm("")}
              />
            )
          }
          placeholder="Product name or ID"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="min-h-[120px]">
        <InfiniteProductSummaryList
          selectedProductIds={selectedIds.map((id) => Number(id))}
          onSelect={onChange}
          isMultiselect
          searchTerm={searchTerm}
          itemSize={32}
          itemsOnViewCount={9}
        />
      </div>
    </div>
  );
};

export default ProductTemplateElementEditor;
