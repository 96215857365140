import type {
  ElementContent,
  ReploEditableElementWithId,
} from "@editor/utils/element";
import type { ReploElementType } from "schemas/generated/element";
import type { UpdateElementModalProps } from "../AppModalTypes";

import * as React from "react";

import ProductTemplateElementEditor from "@components/editor/elementEditors/ProductTemplateElementEditor";
import { SectionElementEditor } from "@components/editor/elementEditors/SectionElementEditor";
import OldPageElementEditor from "@editor/components/editor/elementEditors/OldPageElementEditor";
import { ElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import {
  useElementEditorErrorContext,
  withElementEditorErrorContext,
} from "@editor/contexts/ElementEditorErrorContext";
import { useEditableElement } from "@editor/hooks/useEditableElement";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { elementFormHasErrors } from "@editor/utils/element";

import Button from "@replo/design-system/components/button";
import { Spinner } from "@replo/design-system/components/spinner";
import classNames from "classnames";
import { isPageOrShopifyArticleType } from "schemas/element";

import { ElementEditorModalForm } from "../editor/elementEditors/ElementEditorModalForm";
import { ElementEditorTitle } from "../editor/elementEditors/ElementEditorTitle";

const UPDATE_ELEMENT_TYPE_CONTENT: Record<ReploElementType, ElementContent> = {
  page: {
    header: "Page Settings",
    submitText: "Save Page Settings",
  },
  shopifyArticle: {
    header: "Edit Blog Post",
    submitText: "Update Post",
  },
  shopifyProductTemplate: {
    header: "Edit Product Template",
    submitText: "Update Template",
  },
  shopifySection: {
    header: "Edit Section",
    submitText: "Update Section",
  },
};

export const UpdateElementModal: React.FC<UpdateElementModalProps> =
  withElementEditorErrorContext(({ element: initialElement }) => {
    const {
      element,
      onChangeElement,
      updateElement,
      updateElementMetadata,
      validateElement,
      isUpdatingElement,
    } = useEditableElement({
      element: initialElement,
    });

    const modal = useModal();
    const { errorMapping, setErrors, clearErrors } =
      useElementEditorErrorContext();

    if (!element) {
      return (
        <div className="p-4 text-center">
          <Spinner variant="primary" />
        </div>
      );
    }

    const content = UPDATE_ELEMENT_TYPE_CONTENT[element.type];
    const isPageOrShopifyArticle = isPageOrShopifyArticleType(element.type);

    function onSubmit() {
      clearErrors("path", "allErrorsFromAllFormKeys");

      const { isValid, errors: validationErrors } = validateElement(element!);
      if (!isValid) {
        if (validationErrors.title.length > 0) {
          setErrors("title", validationErrors.title);
        }
        if (validationErrors.path.length > 0) {
          setErrors("path", validationErrors.path);
        }
        return;
      }

      if (isFeatureEnabled("refactor-element-settings")) {
        void updateElementMetadata(element as ReploEditableElementWithId).then(
          () => {
            modal.closeModal({ type: "updateElementModal" });
          },
        );
      } else {
        updateElement(element as ReploEditableElementWithId);
      }
    }

    return (
      <ElementEditorDataContext.Provider value={{ element, onChangeElement }}>
        <ElementEditorModalForm
          testId="update-element-form"
          onSubmit={(e) => {
            e.preventDefault();
            if (isUpdatingElement) {
              return;
            }
            onSubmit();
          }}
          onCloseModal={() => {
            modal.closeModal({ type: "updateElementModal" });
          }}
        >
          <div className="no-scrollbar max-h-[85vh] overflow-y-auto">
            <div
              className={classNames({
                "h-[47rem]": isPageOrShopifyArticle,
              })}
            >
              <ElementEditorTitle>{content?.header}</ElementEditorTitle>
              {(() => {
                switch (element.type) {
                  case "page":
                  case "shopifyArticle":
                    return <OldPageElementEditor requestType="update" />;
                  case "shopifyProductTemplate":
                    return <ProductTemplateElementEditor />;
                  case "shopifySection":
                    return <SectionElementEditor requestType="update" />;
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
          <div className="mt-8 flex flex-row justify-end gap-x-2">
            <Button
              size="lg"
              variant="primary"
              type="submit"
              isLoading={isUpdatingElement}
              data-testid="page-submit-button"
              disabled={elementFormHasErrors(errorMapping)}
            >
              {content?.submitText}
            </Button>
          </div>
        </ElementEditorModalForm>
      </ElementEditorDataContext.Provider>
    );
  });
