import type { EditorStore } from "@editor/store";
import type { ComponentStyleProps } from "replo-runtime/shared/utils/renderComponents";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";
import type {
  PreviewableProperty,
  PreviewableSubProperty,
} from "schemas/preview";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import * as React from "react";

import { getTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import {
  selectBackgroundSize,
  selectBoxShadow,
  selectDraftComponentIds,
  selectDraftComponents,
  selectDraftElementId,
  selectDraftRepeatedIndex,
  selectHasBorderStyles,
  selectNumberOfColumns,
  selectObjectPosition,
  selectTextOutline,
  selectTextShadow,
  selectTransform,
} from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";

import {
  selectActiveCanvas,
  selectVisibleCanvases,
} from "@/features/canvas/canvas-reducer";
import isArray from "lodash-es/isArray";
import {
  editorCanvasToMediaSize,
  getStylePropsFromComponent,
  mediaSizeToEditorCanvas,
} from "replo-runtime/shared/utils/breakpoints";
import {
  getPreviewPropertyClassName,
  previewablePropertyDataMapping,
} from "replo-runtime/shared/utils/preview";
import { getTransformStyleString } from "replo-runtime/shared/utils/transform";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { filterNulls } from "replo-utils/lib/array";
import { coerceNumberToString, isNotNullish } from "replo-utils/lib/misc";

import { getBoxShadowString, parseBoxShadows } from "./boxShadow";
import { getEditorComponentNodes } from "./component";
import { getTextShadowString, parseTextShadows } from "./textShadow";

/**
 * Hook which provides access to starting/ending/setting in-canvas previews for
 * specific previewable properties, which are useful for providing fast user feedback
 * while values of a certain CSS property are being updated in quick succession.
 *
 * Exposes three methods:
 *
 * - enableCanvasPreviewCSSProperties: Starts the preview. After calling this,
 * the node will take on whatever value was last set with setPreviewCSSPropertyValue
 * - setPreviewCSSPropertyValue: Sets the preview value. Usually called directly
 * before starting previews or when a value is updated. After this, the node will have
 * the given value for the preview property until disableCanvasPreviewCSSProperties is
 * called.
 * - disableCanvasPreviewCSSProperties: Stops the preview. After calling this, any
 * preview values have no effect and the node will use whatever its last painted value
 * was.
 */
export function useInCanvasPreview() {
  const store = useEditorStore();

  const setPreviewCSSPropertyValue = React.useCallback(
    (
      properties: PreviewableProperty[],
      value: string,
      subProperty?: PreviewableSubProperty,
      propertyIndex?: number,
    ) => {
      const activeCanvas = selectActiveCanvas(store.getState());
      const canvasToDraftComponentsNodeMapping =
        getCanvasToDraftComponentsNodeMapping(store);

      if (!canvasToDraftComponentsNodeMapping) {
        return;
      }

      let cssValue = value;
      // Note (Sebas, 2022-09-23): Properties that require border-width to be set
      const borderProperties = new Set([
        "borderWidth",
        "borderBottomWidth",
        "borderLeftWidth",
        "borderRightWidth",
        "borderTopWidth",
      ]);
      const hasBorderStyles = selectHasBorderStyles(store.getState());

      // Note (Sebas, 2022-09-07): We have to check the previous values of the transform
      // property because if not, we reset all the values and only apply the modified one.
      if (properties.includes("transform") && subProperty) {
        const previousTransformValues = selectTransform(store.getState());
        const transformValue = getPreviewTransformValue(
          previousTransformValues,
          subProperty,
          value,
        );
        if (transformValue) {
          cssValue = transformValue;
        }
      }

      if (properties.includes("transformOrigin") && subProperty) {
        cssValue = getPreviewTransformOriginValue(
          Object.values(
            canvasToDraftComponentsNodeMapping[activeCanvas] ?? {},
          )[0] ?? null,
          subProperty,
          value,
        );
      }

      const previousBoxShadow = selectBoxShadow(store.getState());
      if (
        properties.includes("boxShadow") &&
        isNotNullish(propertyIndex) &&
        subProperty &&
        previousBoxShadow &&
        !isMixedStyleValue(previousBoxShadow)
      ) {
        cssValue = getPreviewBoxShadowsValue(
          previousBoxShadow,
          propertyIndex,
          subProperty,
          value,
        );
      }

      const [previousObjectPositionX, previousObjectPositionY] =
        selectObjectPosition(store.getState());

      const previousBackgroundSizeValues = selectBackgroundSize(
        store.getState(),
      );
      if (
        properties.includes("backgroundSize") &&
        subProperty &&
        previousBackgroundSizeValues &&
        !isMixedStyleValue(previousBackgroundSizeValues)
      ) {
        cssValue = getPreviewBackgroundSize(
          coerceNumberToString(previousBackgroundSizeValues),
          subProperty,
          value,
        );
      }

      const previousTextShadow = selectTextShadow(store.getState());
      if (
        properties.includes("textShadow") &&
        isNotNullish(propertyIndex) &&
        subProperty &&
        previousTextShadow &&
        !isMixedStyleValue(previousTextShadow)
      ) {
        cssValue = getPreviewTextShadowValue(
          previousTextShadow,
          propertyIndex,
          subProperty,
          value,
        );
      }

      const previousTextOutline = selectTextOutline(store.getState());
      if (
        properties.includes("__textStroke") &&
        subProperty &&
        isArray(previousTextOutline) &&
        !previousTextOutline.some(isMixedStyleValue)
      ) {
        const isEmptyArray = filterNulls(previousTextOutline).length === 0;
        cssValue = !isEmptyArray
          ? (previousTextOutline[1] && `${value} ${previousTextOutline[1]}`) ??
            `${value} #000000`
          : `${value} #000000`;
      }

      const previousNumberOfColumns = selectNumberOfColumns(store.getState());
      if (
        properties.includes("__numberOfColumns") &&
        previousNumberOfColumns &&
        !isMixedStyleValue(previousNumberOfColumns)
      ) {
        cssValue = `repeat(${value}, minmax(0, 1fr))`;
      }

      for (const property of properties) {
        const nodesToStyle = getCanvasNodesToStyle(
          store,
          canvasToDraftComponentsNodeMapping,
          property,
        );

        for (const node of nodesToStyle) {
          node?.style.setProperty(
            `--${previewablePropertyDataMapping[property].cssVariable}`,
            cssValue,
          );
        }

        if (
          property === "objectPosition" &&
          subProperty &&
          previousObjectPositionX &&
          previousObjectPositionY &&
          !isMixedStyleValue(previousObjectPositionX) &&
          !isMixedStyleValue(previousObjectPositionY)
        ) {
          cssValue = getPreviewObjectPosition(
            `${coerceNumberToString(previousObjectPositionX)} ${coerceNumberToString(previousObjectPositionY)}`,
            subProperty,
            value,
          );
          for (const node of nodesToStyle) {
            const imgDraftComponentNodes = node?.querySelectorAll("img");
            if (imgDraftComponentNodes) {
              imgDraftComponentNodes[0]?.style.removeProperty(
                `--${previewablePropertyDataMapping["objectPosition"].cssVariable}`,
              );
              imgDraftComponentNodes[0]?.classList.add(
                getPreviewPropertyClassName("objectPosition"),
              );
              imgDraftComponentNodes[0]?.style.setProperty(
                `--${previewablePropertyDataMapping["objectPosition"].cssVariable}`,
                cssValue,
              );
            }
          }
        }

        // Note (Sebas, 2022-09-23): If there is no border style set and
        // the property is a border property , we need to add border style
        // for the preview to work.
        if (borderProperties.has(property) && !hasBorderStyles) {
          for (const node of nodesToStyle) {
            node?.style.setProperty("border-style", "solid");
          }
        }
      }
    },
    [store],
  );

  const enableCanvasPreviewCSSProperties = React.useCallback(
    (
      properties: PreviewableProperty[],
      value: string,
      subProperty?: PreviewableSubProperty,
      propertyIndex?: number,
    ) => {
      const canvasToDraftComponentsNodeMapping =
        getCanvasToDraftComponentsNodeMapping(store);
      if (!canvasToDraftComponentsNodeMapping) {
        return;
      }

      for (const property of properties) {
        const nodesToStyle = getCanvasNodesToStyle(
          store,
          canvasToDraftComponentsNodeMapping,
          property,
        );

        for (const node of nodesToStyle) {
          node?.style.removeProperty(
            `--${previewablePropertyDataMapping[property].cssVariable}`,
          );
          node?.classList.add(getPreviewPropertyClassName(property));
        }
      }

      setPreviewCSSPropertyValue(properties, value, subProperty, propertyIndex);
    },
    [setPreviewCSSPropertyValue, store],
  );

  const disableCanvasPreviewCSSProperties = React.useCallback(
    (properties: PreviewableProperty[]) => {
      const canvasToDraftComponentsNodeMapping =
        getCanvasToDraftComponentsNodeMapping(store);

      if (!canvasToDraftComponentsNodeMapping) {
        return;
      }

      for (const property of properties) {
        const nodesToStyle = getCanvasNodesToStyle(
          store,
          canvasToDraftComponentsNodeMapping,
          property,
        );

        for (const node of nodesToStyle) {
          node?.classList.remove(getPreviewPropertyClassName(property));

          if (property === "objectPosition") {
            const imgDraftComponentNodes = node?.querySelectorAll("img");
            if (imgDraftComponentNodes) {
              imgDraftComponentNodes[0]?.classList.remove(
                getPreviewPropertyClassName("objectPosition"),
              );
            }
          }
        }
      }
    },
    [store],
  );

  return {
    disableCanvasPreviewCSSProperties,
    enableCanvasPreviewCSSProperties,
    setPreviewCSSPropertyValue,
  };
}

type CanvasToDraftComponentsNodeMapping = Partial<
  Record<EditorCanvas, Record<Component["id"], HTMLElement>>
>;

function getCanvasToDraftComponentsNodeMapping(store: EditorStore) {
  const visibleCanvases = selectVisibleCanvases(store.getState());
  const draftElementId = selectDraftElementId(store.getState());
  const draftComponentIds = selectDraftComponentIds(store.getState());
  const draftRepeatedIndex = selectDraftRepeatedIndex(store.getState());

  if (!draftElementId || draftComponentIds.length === 0) {
    return;
  }

  const canvasToDraftComponentsNodeMapping: CanvasToDraftComponentsNodeMapping =
    {};

  for (const [key, canvas] of Object.entries(visibleCanvases)) {
    const targetDocument = getTargetFrameDocument(canvas?.targetFrame);
    if (!targetDocument) {
      continue;
    }

    for (const componentId of draftComponentIds) {
      const nodes = getEditorComponentNodes({
        targetDocument,
        canvas: key as EditorCanvas,
        elementId: draftElementId,
        componentId,
      });

      if (nodes.length === 0) {
        continue;
      }

      const draftComponentNode =
        nodes.find(
          (n) => n.dataset.reploRepeatedIndex === draftRepeatedIndex,
        ) ?? nodes[0];

      if (draftComponentNode) {
        canvasToDraftComponentsNodeMapping[key as EditorCanvas] = {
          ...canvasToDraftComponentsNodeMapping[key as EditorCanvas],
          [componentId]: draftComponentNode,
        };
      }
    }
  }

  return canvasToDraftComponentsNodeMapping;
}

function getCanvasNodesToStyle(
  store: EditorStore,
  canvasToDraftComponentsNodeMapping: CanvasToDraftComponentsNodeMapping,
  property: PreviewableProperty,
) {
  const draftComponents = selectDraftComponents(store.getState());
  const draftComponentsStyles = draftComponents
    ? draftComponents.reduce(
        (acc, component) => {
          acc[component.id] = getStylePropsFromComponent(component);
          return acc;
        },
        {} as Record<Component["id"], ComponentStyleProps>,
      )
    : {};
  const activeCanvas = selectActiveCanvas(store.getState());
  const activeCanvasDependentsSizes =
    editorCanvasToMediaSize[activeCanvas].dependents;
  const nodesToStyle = [];
  for (const draftComponent of draftComponents) {
    const activeCanvasNode =
      canvasToDraftComponentsNodeMapping[activeCanvas]?.[draftComponent.id];
    if (activeCanvasNode) {
      nodesToStyle.push(activeCanvasNode);
    }

    for (const dependentSize of activeCanvasDependentsSizes) {
      const dependentSizeStyles =
        draftComponentsStyles[draftComponent.id]?.[`style@${dependentSize}`];
      // If the property is already set in a dependent size, this canvas should
      // not be styled. This is in order to avoid unnecessary styling for
      // previewable properties.
      if (dependentSizeStyles?.[property as RuntimeStyleAttribute]) {
        // NOTE (Martin, 2024-08-05): we entirely break the for-loop because
        // as soon as we recognize a dependent size that has the property set,
        // we know that other dependent sizes will depend on this value.
        break;
      }

      const dependentSizeNode =
        canvasToDraftComponentsNodeMapping[
          mediaSizeToEditorCanvas[dependentSize]
        ]?.[draftComponent.id];

      if (dependentSizeNode) {
        nodesToStyle.push(dependentSizeNode);
      }
    }
  }

  return nodesToStyle;
}

function getPreviewTransformValue(
  previousTransformValues: any,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  let newValues = {
    ...previousTransformValues,
    // Note (Sebas, 2022-09-07): Value can be an empty string so we need
    // to use || instead of ??
    [subProperty as string]: value || null,
  };
  if (subProperty === "scaleXY") {
    newValues = {
      ...previousTransformValues,
      scaleX: value,
      scaleY: value,
    };
  }
  return getTransformStyleString(newValues);
}

function getPreviewTransformOriginValue(
  draftComponentNode: HTMLElement | null,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const previousValues =
    draftComponentNode?.style.getPropertyValue("transform-origin") || "";
  const parsedValues =
    previousValues?.length > 0
      ? previousValues.split(" ")
      : ["50%", "50%", "0px"];
  if (subProperty === "transformOriginX") {
    parsedValues[0] = value;
  } else if (subProperty === "transformOriginY") {
    parsedValues[1] = value;
  } else {
    parsedValues[2] = value;
  }
  return parsedValues.join(" ");
}

function getPreviewBoxShadowsValue(
  previousBoxShadow: string,
  internalPropIndex: number,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const boxShadowObject = parseBoxShadows(previousBoxShadow.split(","));
  const selectedBoxShadowObject = boxShadowObject[internalPropIndex]!;
  selectedBoxShadowObject[
    subProperty as "offsetX" | "offsetY" | "blur" | "spread"
  ] = value;

  return getBoxShadowString(boxShadowObject);
}

function getPreviewObjectPosition(
  previousObjectPositionValue: string | null,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const [objPositionXValue, objPositionYValue] = previousObjectPositionValue
    ? previousObjectPositionValue.split(" ")
    : ["0px", "0px"];

  if (subProperty === "objectPositionX") {
    return `${value} ${objPositionYValue}`;
  }

  if (subProperty === "objectPositionY") {
    return `${objPositionXValue} ${value}`;
  }

  return `${objPositionXValue} ${objPositionYValue}`;
}

function getPreviewBackgroundSize(
  previousBackgroundSizeValue: string | null,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const [bgSizeWidth, bgSizeHeight] = previousBackgroundSizeValue
    ? previousBackgroundSizeValue.split(" ")
    : ["auto", "auto"];

  if (subProperty === "backgroundSizeWidth") {
    return `${value} ${bgSizeHeight}`;
  }

  if (subProperty === "backgroundSizeHeight") {
    return `${bgSizeWidth} ${value}`;
  }

  return `${bgSizeWidth} ${bgSizeHeight}`;
}

function getPreviewTextShadowValue(
  previousTextShadow: string,
  internalPropIndex: number,
  subProperty: PreviewableSubProperty,
  value: string,
) {
  const textShadowObject = parseTextShadows(previousTextShadow.split(","));
  const selectedTextShadowObject = textShadowObject[internalPropIndex]!;
  selectedTextShadowObject[subProperty as "offsetX" | "offsetY" | "blur"] =
    value;

  return getTextShadowString(textShadowObject);
}
