import { useCurrentProjectId } from "@editor/hooks/useCurrentProjectId";
import { selectEnhacedElementMapping } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

const useGetElementsMapping = () => {
  const projectId = useCurrentProjectId();
  const { data: archivedElements = [] } = trpc.element.listArchived.useQuery(
    projectId ? { projectId } : skipToken,
  );

  const { elementMapping } = useEditorSelector((state) => {
    return selectEnhacedElementMapping(state, archivedElements);
  });

  return elementMapping;
};

export default useGetElementsMapping;
