import * as React from "react";

import TourStepTrigger from "@components/flows/TourStepTrigger";
import { createPreviewData } from "@editor/actions/core-actions";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import PreviewPageButton from "@editor/components/header/PreviewPageButton";
import { PublishPageButton } from "@editor/components/header/PublishPageButton";
import { PublishPageButtonWithCallout } from "@editor/components/header/PublishPageButtonWithCallout";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";
import usePublishOnClickInfo from "@editor/hooks/usePublishingInfo";
import {
  selectInternalDebugModeOn,
  setDebugPanelVisibility,
} from "@editor/reducers/core-reducer";
import { selectIsShopifyStoreClosed } from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { getStoreData } from "@editor/utils/project-utils";

import { useExperimentCreate } from "@/features/experiments/tabs/hooks/useExperimentCreate";
import { generateExperimentVariation } from "@/features/experiments/utils";
import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { AnimatePresence, motion } from "framer-motion";
import { BsBoxArrowUpRight, BsPeople } from "react-icons/bs";
import { PiFlask } from "react-icons/pi";

import { errorToast, successToast } from "../common/designSystem/Toast";

export default function PageControlButtons() {
  const modal = useModal();
  const { draftElementType } = usePublishOnClickInfo();
  const { project } = useCurrentProjectContext();
  const store = getStoreData(project);
  const isDebugMode = useIsDebugMode();
  const isStoreClosed = useEditorSelector(selectIsShopifyStoreClosed);
  const reduxStore = useEditorStore();
  const { storeUrl } = useGetStoreNameAndUrl();
  const projectId = useCurrentProjectId();

  const logEvent = useLogAnalytics();
  const { shopifyUrl, urlIsDisabled, tooltipLabel } = usePublishedInfo();

  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const { createExperiment } = useExperimentCreate(workspaceId);

  const handleStartABTest = async () => {
    if (!shopifyUrl) {
      errorToast(
        "Error creating test",
        "Please try again, or contact support@replo.app for help.",
      );
      return;
    }

    await createExperiment({
      location: "editor_header",
      partialExperiment: {
        variations: [
          generateExperimentVariation({
            slug: "Variant-A",
            target: shopifyUrl,
          }),
          generateExperimentVariation({
            slug: "Variant-B",
            target: null,
          }),
        ],
      },
      onExperimentCreateSuccess: () => {
        successToast(
          "Test Created",
          "Select one other page and choose a link to start",
        );
      },
    });
  };

  if (!draftElementType) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      {isDebugMode && <DebugMenuButton />}
      <IconButton
        variant="secondary"
        size="base"
        tooltipText="Share Project"
        icon={<BsPeople size={18} />}
        onClick={() => {
          modal.openModal({
            type: "projectMembershipModal",
          });
        }}
      />
      <div className="w-[1px] h-[16px] bg-slate-300" />
      <IconButton
        variant="secondary"
        size="base"
        icon={<PiFlask size={18} />}
        onClick={() => void handleStartABTest()}
        disabled={!shopifyUrl}
        tooltipText={
          shopifyUrl ? "Run A/B test" : "Publish page to run A/B test"
        }
      />
      <PreviewPageButton
        isProjectConnectedToShopify={Boolean(store?.shopifyUrl)}
        onCreatePreviewData={() => {
          if (!projectId || !storeUrl) {
            return;
          }
          return createPreviewData({
            state: reduxStore.getState(),
            projectId,
            storeUrl,
          });
        }}
        isStoreClosed={isStoreClosed}
      />
      <AnimatePresence>
        {!urlIsDisabled && (
          <motion.div
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: "auto", opacity: 1 }}
            exit={{ width: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          >
            <IconButton
              variant="secondary"
              data-testid="live-page-button"
              size="base"
              disabled={urlIsDisabled}
              icon={<BsBoxArrowUpRight size={18} />}
              tooltipText={tooltipLabel}
              aria-label={tooltipLabel}
              onClick={() => {
                if (shopifyUrl) {
                  logEvent("header.viewPage", {});
                  window.open(shopifyUrl, "_blank");
                }
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <TourStepTrigger step="step-5">
        {store?.shopifyUrl ? (
          <PublishPageButton isPreviewBeforePublishView />
        ) : (
          <PublishPageButtonWithCallout />
        )}
      </TourStepTrigger>
    </div>
  );
}

function DebugMenuButton() {
  const dispatch = useEditorDispatch();
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);
  const handleDebug = React.useCallback(() => {
    dispatch(setDebugPanelVisibility(!isDebugPanelVisible));
  }, [dispatch, isDebugPanelVisible]);

  return (
    <Button
      variant="secondary"
      size="base"
      onClick={handleDebug}
      tooltipText="Replo Debug Menu"
      hasMinDimensions={false}
    >
      🔮
    </Button>
  );
}
