import type { EditorRootState } from "@editor/store";
import type { OriginalStyles } from "@editor/types/core-state";
import type { TrpcQueryReturn, TrpcRouterArgs } from "@editor/utils/trpc";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { BaseQueryApi } from "@reduxjs/toolkit/dist/query/react";
import type { ReploShopifyProduct } from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";
import type {
  AIComponentAction,
  AIMetaEvent,
  DesignBody,
  MobileResponsiveBody,
  MultiBody,
  SavedStylesBody,
  TemplateBody,
  TextV2Body,
} from "schemas/generated/ai";

import * as coreActions from "@actions/core-actions";
import { warningToast } from "@editor/components/common/designSystem/Toast";
import { getTokenFromStorage } from "@editor/infra/auth";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { openBillingModalIfNeeded } from "@editor/reducers/modals-reducer";
import { EditorMode } from "@editor/types/core-state";
import { canvasToStyleMap } from "@editor/utils/editor";
import { trpcClient } from "@editor/utils/trpc";
import {
  handleStreamingAction,
  markStreamingUpdateFinished,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  setEditorMode,
  setStreamingUpdate,
} from "@reducers/core-reducer";

import {
  scrollToComponentId,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import cloneDeep from "lodash-es/cloneDeep";
import { batch } from "react-redux";
import { forEachComponentAndDescendants } from "replo-runtime";
import { getPublisherUrl } from "replo-runtime/shared/config";
import { aiComponentActionSchema, aiMetaEventSchema } from "schemas/ai";
import { errorUserFacingDetailsSchema } from "schemas/errors";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";

// TODO (Evan, 2024-10-15, REPL-14102): All of the AI streaming provider's local state
// should be moved to this slice.
export type AIState = {
  showWelcomeModal: boolean;
  lastAIResponse: string;
  component: Component | null;
};

const initialState: AIState = {
  showWelcomeModal: false,
  lastAIResponse: "",
  component: null,
};

const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    handleChatbotComponent: (
      state,
      action: PayloadAction<{ component: Component }>,
    ) => {
      const { component } = action.payload;
      state.component = component;
    },
    /**
     * Store the chatbot thoughts so we can display them on the frontend
     */
    handleChatbotThoughts: (
      state,
      action: PayloadAction<{ thoughts: string }>,
    ) => {
      const { thoughts } = action.payload;
      state.lastAIResponse = thoughts;
    },
    setShowWelcomeModal: (state, action: PayloadAction<boolean>) => {
      state.showWelcomeModal = action.payload;
    },
  },
});

export const selectShowAIWelcomeModal = (state: EditorRootState) =>
  state.ai.showWelcomeModal;

export const selectComponent = (state: EditorRootState) => state.ai.component;

export const selectLastAIResponse = (state: EditorRootState) =>
  state.ai.lastAIResponse;

export const goals = [
  "maximizeConversion",
  "maximizeCartSize",
  "maximizeCartValue",
  "educateUser",
  "tellBrandStory",
  "lowerBounceRate",
] as const;
export const goalsSchema = z.enum(goals);
export type Goal = z.infer<typeof goalsSchema>;

export const tones = [
  "professional",
  "casual",
  "sassy",
  "confident",
  "joyful",
  "friendly",
  "direct",
  "other",
] as const;
export const tonesSchema = z.enum(tones);
export type Tone = z.infer<typeof tonesSchema>;

export const topics = [
  "title",
  "heroCopy",
  "callToAction",
  "productDescription",
  "benefits",
  "usageInstructions",
  "faq",
] as const;
export const topicsSchema = z.enum(topics);
export type Topic = z.infer<typeof topicsSchema>;

export const pageTopics = [
  "advertorial",
  "listicle",
  "longFormProductOffer",
  "videoSalesLetter",
  "productDropPage",
  "faq",
] as const;
export const pageTopicsSchema = z.enum(pageTopics);
export type PageTopic = z.infer<typeof pageTopicsSchema>;

type AskAiTextPayload = {
  meta: {
    model: "gpt-4" | "gpt-3.5-turbo" | "gpt-4o-mini";
  };
  operation: "generate";
  type: "text";
  options: {
    component: Component;
    context: {
      product?: ReploShopifyProduct;
      additionalContext?: string;
      tone?: string;
      topic?: Topic;
      goal?: Goal;
    };
  };
};

type AskAiPagePayload = {
  meta: {
    model: "gpt-4" | "gpt-3.5-turbo" | "gpt-4o-mini";
  };
  operation: "generate";
  type: "page";
  options: {
    context: {
      product?: ReploShopifyProduct;
      additionalContext?: string;
      tone?: string;
      topic?: PageTopic;
      goal?: Goal;
    };
  };
};

export type ComponentDimensionsMap = Record<
  string,
  { width: number; height: number }
>;

// Note (Evan, 2024-07-09): Essentially a no-op, just copies the component over
const defaultTransformDraftElementComponentStyles = (
  draftElementComponent: Readonly<Component>,
) => ({
  transformedComponent: cloneDeep(draftElementComponent),
  originalStyles: {},
});

type AIStreamingBody = MobileResponsiveBody | TextV2Body;

type KeysPopulatedInQueryFn =
  | "streamingUpdateId"
  | "screenshotDependencies"
  | "model"
  | "elementId";

// Note (Evan, 2024-07-09): This function streams actions from a given url, with a given
// JSON body. We also pass the componentId (to ensure all actions have componentId defined),
// and an optional function that can temporarily transform the draft element component while streaming
// (currently used for fixing the width/height of text components when streaming text). This isn't
// ~technically~ a BaseQueryFn, but it's pretty close - the only differences are that we take
// simpler args (i.e., we put the query args into the body before passing), and we don't return anything.
const aiStreamingQuery = async (
  args: {
    url: string;
    body: Omit<AIStreamingBody, KeysPopulatedInQueryFn>;
    componentId: string;
    // Note (Evan, 2024-07-09): Optional function that modifies styles for the draft element component and its children
    // and returns the modified component and the original styles (as a mapping from component id -> component styles)
    transformDraftElementComponentStyles?: (
      draftElementComponent: Readonly<Component>,
    ) => {
      transformedComponent: Component;
      originalStyles: OriginalStyles;
    };
    // Note (Evan, 2024-10-09): Optional callback that runs when the request closes
    onFinish?: () => void;
    shouldScrollToComponent?: (
      action: AIComponentAction,
      metaEvents: Array<AIMetaEvent>,
    ) => boolean;
  },
  { signal, dispatch, getState }: BaseQueryApi,
) => {
  const {
    url,
    body: _body,
    transformDraftElementComponentStyles = defaultTransformDraftElementComponentStyles,
    onFinish,
    shouldScrollToComponent = () => true,
  } = args;

  // Note (Evan, 2024-08-21): This type assertion is explicitly recommended
  // by the RTK docs, https://redux-toolkit.js.org/rtk-query/usage-with-typescript#typing-dispatch-and-getstate
  const {
    dependencies,
    products,
    productMetafieldValues,
    variantMetafieldValues,
  } = coreActions.getDependencyData(getState() as EditorRootState);

  const draftElement = selectDraftElement_warningThisWillRerenderOnEveryUpdate(
    getState() as EditorRootState,
  );
  if (!draftElement) {
    return;
  }

  const draftElementComponent = draftElement.component;

  const streamingUpdateId = uuidv4();
  const screenshotDependencies = {
    dependencies,
    products,
    productMetafieldValues,
    variantMetafieldValues,
    fullComponent: draftElementComponent,
  };

  const body: AIStreamingBody = {
    ..._body,
    elementId: draftElement.id,
    streamingUpdateId,
    screenshotDependencies,
    model: isFeatureEnabled("ai-claude") ? "claude-3.5-sonnet" : undefined,
  };

  const token = getTokenFromStorage();

  // Note (Evan, 2024-06-04): Return to normal editing when aborted
  const abortHandler = () => {
    dispatch(setStreamingUpdate(null));
    dispatch(setEditorMode(EditorMode.edit));
    dispatch(setCanvasInteractionMode("edit"));
  };

  signal.addEventListener("abort", abortHandler);

  const metaEvents: Array<AIMetaEvent> = [];
  await fetchEventSource(url, {
    method: "POST",
    headers: {
      authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    signal,
    openWhenHidden: true,
    onopen: async () => {
      const { transformedComponent, originalStyles } =
        transformDraftElementComponentStyles(draftElementComponent);

      dispatch(
        setStreamingUpdate({
          id: streamingUpdateId,
          draftElementComponent: transformedComponent,
          isStreaming: true,
          actions: [],
          originalStyles,
          textMap: {},
          repaintKey: 0,
        }),
      );
    },

    onmessage: (event) => {
      // Note (Evan, 2024-06-11): If an error event comes in, throw an error
      // so the onerror handler kicks in
      if (event.event === "error") {
        throw JSON.parse(event.data);
      }
      // NOTE (Gabe 2024-09-19): If it's just a warning, we'll display it as a
      // toast and allow the process to continue.
      if (event.event === "warning") {
        const userFacingDetailsResult = errorUserFacingDetailsSchema.safeParse(
          JSON.parse(event.data),
        );
        if (
          userFacingDetailsResult.success &&
          userFacingDetailsResult.data.type === "toast" &&
          typeof userFacingDetailsResult.data.message === "string" &&
          typeof userFacingDetailsResult.data.detail === "string"
        ) {
          warningToast(
            userFacingDetailsResult.data.message,
            userFacingDetailsResult.data.detail,
          );
        }
        return;
      }

      if (event.event === "meta") {
        const parseResult = aiMetaEventSchema.safeParse(JSON.parse(event.data));
        if (parseResult.success) {
          metaEvents.push(parseResult.data);
        }
        return;
      }

      if (event.data === "") {
        return;
      }

      // Note (Evan, 2024-06-04): This type assertion is explicitly recommended
      // by the RTK docs, https://redux-toolkit.js.org/rtk-query/usage-with-typescript#typing-dispatch-and-getstate
      const state = getState() as EditorRootState;

      // Note (Patrick, 2025-01-24) While we haven't defined the aiComponentActionSchema
      // for all ComponentActionTypes, just parse the event if we're behind the ai-chat feature flag
      const parsedData = isFeatureEnabled("ai-chat")
        ? JSON.parse(event.data)
        : z
            .object({ data: aiComponentActionSchema })
            .parse(JSON.parse(event.data));

      const { data: action, thoughts, component } = parsedData;

      // We also want to bring the thoughts into the chatbot. Thoughts are currently handled in a separate event from actions
      if (thoughts) {
        batch(() => {
          dispatch(
            handleChatbotThoughts({
              thoughts,
            }),
          );
        });
      } else if (component) {
        batch(() => {
          dispatch(
            handleChatbotComponent({
              component,
            }),
          );
        });
      } else if (action) {
        batch(() => {
          dispatch(
            handleStreamingAction({
              ...action,
              activeCanvas: state.canvas.activeCanvas,
            }),
          );
          if (shouldScrollToComponent(action, metaEvents)) {
            dispatch(scrollToComponentId({ componentId: action.componentId }));
          }
        });
      }
    },
    onerror: (error) => {
      // Note (Evan, 2024-06-10): Return to normal editing
      dispatch(setStreamingUpdate(null));
      dispatch(setEditorMode(EditorMode.edit));
      dispatch(setCanvasInteractionMode("edit"));

      // Note (Evan, 2024-06-10): We re-throw this so the error toast shows
      throw error;
    },
    onclose: () => {
      dispatch(markStreamingUpdateFinished());
      dispatch(setCanvasInteractionMode("edit"));

      signal.removeEventListener("abort", abortHandler);
      onFinish?.();
    },
  });
};

type AskAiPayload = AskAiTextPayload | AskAiPagePayload;

export const aiApi = createApi({
  reducerPath: "aiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getPublisherUrl()}/api/v1/ai`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getTokenFromStorage();

      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    askAi: builder.mutation<
      { component: Component },
      { payload: AskAiPayload; storeId: string }
    >({
      query: ({ payload, storeId }) => ({
        url: "/",
        method: "POST",
        body: { payload, storeId },
        params: { storeId },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.catch((error: any) => {
          dispatch(
            openBillingModalIfNeeded({
              key: error.error?.data?.key,
              source: "ai.credits",
            }),
          );
        });
      },
    }),
    aiAltText: builder.mutation<
      TrpcQueryReturn<"ai", "createAltText">,
      // Note (Evan, 2024-05-08): Take the componentId as a param, even though it's not
      // passed to the server, so that we can use it to assign the result
      // to the correct component.
      // Note (Evan, 2024-08-16): Pass the more generally-named 'triggeredManually' instead
      // of 'throwOnError' - we throw on error iff the mutation is triggered manually, we
      // use the more general name since this also determines whether the action dispatched
      // should reflect in the undo/redo history. (When it is automatic, it should not be
      // undo-able)
      Omit<TrpcRouterArgs<"ai", "createAltText">, "throwOnError"> & {
        componentId: string;
        triggeredManually: boolean;
      }
    >({
      queryFn: async ({ imageUrl, triggeredManually }) => {
        const data = await trpcClient.ai.createAltText.mutate({
          imageUrl,
          throwOnError: triggeredManually,
        });
        return { data };
      },
      onQueryStarted: async (
        { componentId, triggeredManually },
        { queryFulfilled, dispatch },
      ) => {
        const { data } = await queryFulfilled;

        if (data.result) {
          dispatch(
            coreActions.applyComponentAction({
              type: "applyCompositeAction",
              activeCanvas: "desktop" as const,
              value: [
                {
                  type: "setStyles",
                  activeCanvas: "desktop" as const,
                  value: { __imageAltText: data.result },
                  componentId,
                },
                {
                  type: "setMarker",
                  value: {
                    _aiGeneratedAltText: true,
                  },
                  componentId,
                  activeCanvas: "desktop" as const,
                },
              ],
              shouldBypassHistory: !triggeredManually,
            }),
          );
        }
      },
    }),

    bulkAiAltText: builder.mutation<
      TrpcQueryReturn<"ai", "createBulkAltText">,
      TrpcRouterArgs<"ai", "createBulkAltText">
    >({
      queryFn: async (imageComponentIdToUrl) => {
        const data = await trpcClient.ai.createBulkAltText.mutate(
          imageComponentIdToUrl,
        );

        return { data };
      },
      onQueryStarted: async (_args, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;

        const actions = Object.entries(data).flatMap(
          ([componentId, altTextValue]) => [
            {
              type: "setStyles" as const,
              activeCanvas: "desktop" as const,
              value: { __imageAltText: altTextValue },
              componentId,
            },
            {
              type: "setMarker" as const,
              value: {
                _aiGeneratedAltText: true,
              },
              componentId,
              activeCanvas: "desktop" as const,
            },
          ],
        );

        if (actions.length === 0) {
          return;
        }

        dispatch(
          coreActions.applyComponentAction({
            type: "applyCompositeAction",
            activeCanvas: "desktop" as const,
            value: actions,
            shouldBypassHistory: true,
          }),
        );
      },
    }),
    aiTemplate: builder.mutation<
      {},
      Omit<TemplateBody, KeysPopulatedInQueryFn> & {
        textComponentDimensions: Partial<
          Record<EditorCanvas, ComponentDimensionsMap>
        >;
        onFinish?: () => void;
      }
    >({
      queryFn: async (
        {
          component,
          textComponentDimensions,
          projectId,
          userPrompt,
          onFinish,
          nonce,
          enableProductImages,
          sectionType,
          conversationMessages,
          systemPrompt,
          initialPrompt,
        },
        api,
      ) => {
        const body: Omit<TemplateBody, KeysPopulatedInQueryFn> = {
          conversationMessages,
          nonce,
          component,
          projectId,
          userPrompt,
          sectionType,
          enableProductImages,
          systemPrompt,
          initialPrompt,
        };

        try {
          // Note (Evan, 2024-07-18): IMPORTANT: this endpoint is currently rate-limited to a maximum of
          // 1 action per 10ms, on the backend (routes/ai.ts) - this is in order to allow the editor sufficient time
          // to process each action. Though currently this approach doesn't seem to add net time to text generation,
          // that could become the case in the future, particularly if the behavior of our LLM provider (currently OpenAI)
          // changes. So if things become slow in the future, that is the place to start.
          await aiStreamingQuery(
            {
              url: `${getPublisherUrl()}/api/v1/ai/template`,
              body,
              componentId: component.id,
              transformDraftElementComponentStyles: (draftElementComponent) => {
                // Note (Evan, 2024-06-07): Set text components' current width/height as minimum so things
                // don't jerk around while streaming. Save any existing minHeight/minWidth so we can restore them when streaming is done.
                const originalStyles: OriginalStyles = {};

                const draftElementComponentCopy = cloneDeep(
                  draftElementComponent,
                );

                forEachComponentAndDescendants(
                  draftElementComponentCopy,
                  (component) => {
                    for (const [_canvas, dimensionsMap] of Object.entries(
                      textComponentDimensions,
                    )) {
                      const dimensions = dimensionsMap[component.id];

                      if (!dimensions) {
                        continue;
                      }

                      const canvas = _canvas as EditorCanvas;

                      const styleKey = canvasToStyleMap[canvas];
                      originalStyles[canvas] = {
                        ...originalStyles[canvas],
                        [component.id]: {
                          minWidth: component.props[styleKey]?.minWidth,
                          minHeight: component.props[styleKey]?.minHeight,
                        },
                      };

                      component.props[styleKey] = {
                        ...component.props[styleKey],
                        minHeight: `${dimensions.height}px`,
                        minWidth: `${dimensions.width}px`,
                      };
                    }
                  },
                );

                return {
                  transformedComponent: draftElementComponentCopy,
                  originalStyles,
                };
              },
              onFinish,
            },
            api,
          );

          return { data: {} };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
              data: error,
            },
          };
        }
      },
    }),
    aiDesign: builder.mutation<
      {},
      Omit<DesignBody, KeysPopulatedInQueryFn> & {
        textComponentDimensions: Partial<
          Record<EditorCanvas, ComponentDimensionsMap>
        >;
        onFinish?: () => void;
      }
    >({
      queryFn: async (
        {
          component,
          textComponentDimensions,
          projectId,
          userPrompt,
          onFinish,
          page,
          nonce,
          conversationMessages,
        },
        api,
      ) => {
        const body: Omit<DesignBody, KeysPopulatedInQueryFn> = {
          page,
          conversationMessages,
          nonce,
          component,
          projectId,
          userPrompt,
        };

        try {
          // Note (Evan, 2024-07-18): IMPORTANT: this endpoint is currently rate-limited to a maximum of
          // 1 action per 10ms, on the backend (routes/ai.ts) - this is in order to allow the editor sufficient time
          // to process each action. Though currently this approach doesn't seem to add net time to text generation,
          // that could become the case in the future, particularly if the behavior of our LLM provider (currently OpenAI)
          // changes. So if things become slow in the future, that is the place to start.
          await aiStreamingQuery(
            {
              url: `${getPublisherUrl()}/api/v1/ai/design`,
              body,
              componentId: component.id,
              transformDraftElementComponentStyles: (draftElementComponent) => {
                // Note (Evan, 2024-06-07): Set text components' current width/height as minimum so things
                // don't jerk around while streaming. Save any existing minHeight/minWidth so we can restore them when streaming is done.
                const originalStyles: OriginalStyles = {};

                const draftElementComponentCopy = cloneDeep(
                  draftElementComponent,
                );

                forEachComponentAndDescendants(
                  draftElementComponentCopy,
                  (component) => {
                    for (const [_canvas, dimensionsMap] of Object.entries(
                      textComponentDimensions,
                    )) {
                      const dimensions = dimensionsMap[component.id];

                      if (!dimensions) {
                        continue;
                      }

                      const canvas = _canvas as EditorCanvas;

                      const styleKey = canvasToStyleMap[canvas];
                      originalStyles[canvas] = {
                        ...originalStyles[canvas],
                        [component.id]: {
                          minWidth: component.props[styleKey]?.minWidth,
                          minHeight: component.props[styleKey]?.minHeight,
                        },
                      };

                      component.props[styleKey] = {
                        ...component.props[styleKey],
                        minHeight: `${dimensions.height}px`,
                        minWidth: `${dimensions.width}px`,
                      };
                    }
                  },
                );

                return {
                  transformedComponent: draftElementComponentCopy,
                  originalStyles,
                };
              },
              onFinish,
            },
            api,
          );

          return { data: {} };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
              data: error,
            },
          };
        }
      },
    }),
    aiTextV2: builder.mutation<
      {},
      Omit<TextV2Body, KeysPopulatedInQueryFn> & {
        textComponentDimensions: Partial<
          Record<EditorCanvas, ComponentDimensionsMap>
        >;
        onFinish?: () => void;
      }
    >({
      queryFn: async (
        { component, textComponentDimensions, projectId, userPrompt, onFinish },
        api,
      ) => {
        const body: Omit<TextV2Body, KeysPopulatedInQueryFn> = {
          component,
          projectId,
          userPrompt,
        };

        try {
          // Note (Evan, 2024-07-18): IMPORTANT: this endpoint is currently rate-limited to a maximum of
          // 1 action per 10ms, on the backend (routes/ai.ts) - this is in order to allow the editor sufficient time
          // to process each action. Though currently this approach doesn't seem to add net time to text generation,
          // that could become the case in the future, particularly if the behavior of our LLM provider (currently OpenAI)
          // changes. So if things become slow in the future, that is the place to start.
          await aiStreamingQuery(
            {
              url: `${getPublisherUrl()}/api/v1/ai/text-v2`,
              body,
              componentId: component.id,
              transformDraftElementComponentStyles: (draftElementComponent) => {
                // Note (Evan, 2024-06-07): Set text components' current width/height as minimum so things
                // don't jerk around while streaming. Save any existing minHeight/minWidth so we can restore them when streaming is done.
                const originalStyles: OriginalStyles = {};

                const draftElementComponentCopy = cloneDeep(
                  draftElementComponent,
                );

                forEachComponentAndDescendants(
                  draftElementComponentCopy,
                  (component) => {
                    for (const [_canvas, dimensionsMap] of Object.entries(
                      textComponentDimensions,
                    )) {
                      const dimensions = dimensionsMap[component.id];

                      if (!dimensions) {
                        continue;
                      }

                      const canvas = _canvas as EditorCanvas;

                      const styleKey = canvasToStyleMap[canvas];
                      originalStyles[canvas] = {
                        ...originalStyles[canvas],
                        [component.id]: {
                          minWidth: component.props[styleKey]?.minWidth,
                          minHeight: component.props[styleKey]?.minHeight,
                        },
                      };

                      component.props[styleKey] = {
                        ...component.props[styleKey],
                        minHeight: `${dimensions.height}px`,
                        minWidth: `${dimensions.width}px`,
                      };
                    }
                  },
                );

                return {
                  transformedComponent: draftElementComponentCopy,
                  originalStyles,
                };
              },
              onFinish,
            },
            api,
          );

          return { data: {} };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
              data: error,
            },
          };
        }
      },
    }),
    aiMobileResponsive: builder.mutation<
      {},
      Omit<MobileResponsiveBody, KeysPopulatedInQueryFn>
    >({
      queryFn: async ({ component, projectId }, api) => {
        try {
          await aiStreamingQuery(
            {
              url: `${getPublisherUrl()}/api/v1/ai/mobile-responsive`,
              body: {
                component,
                projectId,
              },
              componentId: component.id,
            },
            api,
          );

          return { data: {} };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
              data: error,
            },
          };
        }
      },
    }),
    aiApplySavedStyles: builder.mutation<
      {},
      Omit<SavedStylesBody, KeysPopulatedInQueryFn>
    >({
      queryFn: async ({ component, projectId }, api) => {
        try {
          await aiStreamingQuery(
            {
              url: `${getPublisherUrl()}/api/v1/ai/saved-styles`,
              body: {
                component,
                projectId,
              },
              componentId: component.id,
            },
            api,
          );

          return { data: {} };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
              data: error,
            },
          };
        }
      },
    }),
    aiMulti: builder.mutation<
      {},
      Omit<MultiBody, KeysPopulatedInQueryFn> & { onFinish?: () => void }
    >({
      queryFn: async ({ onFinish, ...params }, api) => {
        try {
          await aiStreamingQuery(
            {
              url: `${getPublisherUrl()}/api/v1/ai/multi`,
              body: {
                ...params,
              },
              componentId: params.component.id,
              onFinish,
              shouldScrollToComponent: (action, metaEvents) => {
                // Note (Evan, 2024-11-07): Scroll to text actions (always)
                // and scroll to other actions only once text actions are finished.
                const isTextAction =
                  action.type === "setProps" && action.value["text"];
                if (isTextAction) {
                  return true;
                }
                const isTextFinished = metaEvents.some(
                  (event) => event.type === "textV2.isFinished",
                );
                return isTextFinished;
              },
            },
            api,
          );

          return { data: {} };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
              data: error,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useAskAiMutation,
  useAiAltTextMutation,
  useBulkAiAltTextMutation,
  useAiDesignMutation,
  useAiTextV2Mutation,
  useAiTemplateMutation,
  useAiMobileResponsiveMutation,
  useAiApplySavedStylesMutation,
  useAiMultiMutation,
} = aiApi;

const { reducer, actions } = aiSlice;
export const {
  setShowWelcomeModal,
  handleChatbotThoughts,
  handleChatbotComponent,
} = actions;
export default reducer;
