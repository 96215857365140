import {
  selectDraftComponentIds,
  selectEditorMode,
} from "@editor/reducers/core-reducer";
import { selectIsRightBarAnalyticsOpen } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

import { selectCanvasIsLoading } from "@/features/canvas/canvas-reducer";

export default function useRightBarVisibility() {
  const editorMode = useEditorSelector(selectEditorMode);
  const isCanvasLoading = useEditorSelector(selectCanvasIsLoading);
  const isRightBarAnalyticsOpen = useEditorSelector(
    selectIsRightBarAnalyticsOpen,
  );
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);

  return (
    !isCanvasLoading &&
    editorMode === EditorMode.edit &&
    (draftComponentIds.length > 0 || isRightBarAnalyticsOpen)
  );
}
