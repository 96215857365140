import type { ReploElementMetadata } from "schemas/generated/element";

import * as React from "react";

import { updateAndSaveElement } from "@editor/actions/core-actions";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { useEditorDispatch } from "@editor/store";
import { INVALID_ELEMENT_NAME_CHARACTERS_REGEX } from "@editor/utils/element";
import { trpc, trpcUtils } from "@editor/utils/trpc";

export function useElementName({
  element,
  initialName,
}: {
  element: ReploElementMetadata;
  initialName: string;
}): {
  elementName: string;
  setElementName: (name: string) => void;
  isEditingName: boolean;
  setIsEditingName: (isEditing: boolean) => void;
  handleUpdateElementName: (name: string) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
} {
  const [isEditingName, setIsEditingName] = React.useState(false);
  const [elementName, setElementName] = React.useState(initialName);
  const dispatch = useEditorDispatch();
  const errorToast = useErrorToast();
  React.useEffect(() => {
    setElementName(element.name);
  }, [element.name]);

  const updateElementMetadata = trpc.element.updateElementMetadata.useMutation({
    onMutate: async ({ element: elementToUpdate }) => {
      // NOTE (Ben O., 2025-02-10): to keep the element name in sync in the editor
      // we cancel the outgoing refetch of the element metadata and snapshot the
      // previous value to use in case of error. We also set the trpc call to
      // optimistic update to the new value.
      await trpcUtils.element.getElementMetadataById.cancel(element.id);
      await trpcUtils.element.listAllElementsMetadata.cancel({
        projectId: element.projectId,
      });

      trpcUtils.element.getElementMetadataById.setData(element.id, (old) =>
        old ? { ...old, name: elementToUpdate.name! } : old,
      );
      trpcUtils.element.listAllElementsMetadata.setData(
        { projectId: element.projectId },
        (old) => {
          if (!old) {
            return old;
          }
          return old.map((el) =>
            el.id === elementToUpdate.id
              ? { ...el, name: elementToUpdate.name! }
              : el,
          );
        },
      );

      return { previousName: element.name };
    },
    onError: (_err, _variables, context) => {
      // Note (Ben O. 2025-02-06): If the update fails, we roll back to the previous value
      if (context?.previousName) {
        trpcUtils.element.getElementMetadataById.setData(element.id, {
          ...element,
          name: context.previousName,
        });
        trpcUtils.element.listAllElementsMetadata.setData(
          { projectId: element.projectId },
          (old) => {
            if (!old) {
              return old;
            }
            return old.map((el) =>
              el.id === element.id ? { ...el, name: context.previousName } : el,
            );
          },
        );
      }
      errorToast(
        "Please try again.",
        "Failed to update element name",
        "error.element.update",
        {
          elementDetail: JSON.stringify(element),
        },
      );
    },
    onSuccess: () => {
      void trpcUtils.element.listAllElementsMetadata.invalidate();
      void trpcUtils.element.getElementMetadataById.invalidate(element.id);
      // TODO (Ben O., 2025-02-13): Remove this once fully over to TRPC
      void trpcUtils.element.getById.invalidate(element.id);
    },
  });

  const handleUpdateElementName = React.useCallback(
    (name: string) => {
      const newName = name.replace(INVALID_ELEMENT_NAME_CHARACTERS_REGEX, "");

      if (newName === element.name) {
        return;
      }

      if (isFeatureEnabled("refactor-element-settings")) {
        updateElementMetadata.mutate({
          element: {
            ...element,
            name: newName,
          },
        });
      } else {
        const updatedElement = {
          ...element,
          name: newName,
        };
        dispatch(updateAndSaveElement(element.id, updatedElement));
        setElementName(newName);
        void trpcUtils.element.getById.invalidate(element.id);
      }
    },
    [dispatch, element, updateElementMetadata],
  );

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === "Escape") {
        setIsEditingName(false);
        handleUpdateElementName(elementName);
      }
    },
    [elementName, handleUpdateElementName],
  );

  return {
    elementName,
    setElementName,
    isEditingName,
    setIsEditingName,
    handleUpdateElementName,
    handleKeyDown,
  };
}
