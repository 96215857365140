import * as React from "react";

import { selectDraftComponentsModifierGroups } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { ModifierGroups } from "@editorComponents/modifierGroups";
import StickyBanners from "@editorComponents/StickyBanners";

const DesignTab: React.FC = () => {
  return (
    <div className="relative flex flex-col gap-3">
      <div className="border-b border-slate-200">
        <StickyBanners />
      </div>
      <ComponentModifiers />
    </div>
  );
};

const ComponentModifiers: React.FC = () => {
  const modifierGroups = useEditorSelector(selectDraftComponentsModifierGroups);

  if (!modifierGroups.length) {
    return null;
  }

  return <ModifierGroups groupTypes={modifierGroups} />;
};

export default DesignTab;
