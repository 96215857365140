import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import twMerge from "../../utils/twMerge";
import Label from "../label";
import Description from "../label/Description";
import Switch from "./Switch";

type SwitchWithDescriptionProps = {
  label?: React.ReactNode;
  description?: React.ReactNode;
  tooltipText?: string;
  disabled?: boolean;
  size: "base" | "sm";
  layoutClassName?: string;
  UNSAFE_className?: string;
  isOn?: boolean;
  onChange?: (isOn: boolean) => void;
  groupStyles?: boolean;
};

const SwitchWithDescription = ({
  label,
  description,
  tooltipText,
  disabled = false,
  size,
  isOn,
  onChange,
  layoutClassName,
  UNSAFE_className,
  groupStyles,
}: SwitchWithDescriptionProps) => {
  const [isChecked, setIsChecked] = useControllableState(isOn, false, onChange);

  const onClick = (
    _e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
  ) => {
    setIsChecked(!isChecked);
  };

  return (
    <div
      className={twMerge(
        "flex gap-4 items-center p-[12px] border-[.5px] border-border rounded-lg cursor-pointer",
        groupStyles && "w-full border-none rounded-none",
        layoutClassName,
        UNSAFE_className,
      )}
      onClick={onClick}
    >
      {(label || description) && (
        <div className="flex-1 min-w-0 gap-1">
          <Label
            labelClassName="cursor-pointer"
            layoutClassName="mb-0"
            label={label}
            size={size}
            breakWords
          />
          <Description
            layoutClassName="mt-[2px]"
            description={description}
            size={size}
            breakWords
          />
        </div>
      )}
      <div className="ml-auto shrink-0">
        <Tooltip content={tooltipText} triggerAsChild>
          <Switch
            isOn={isChecked}
            disabled={disabled}
            size={size}
            onChange={setIsChecked}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default SwitchWithDescription;
