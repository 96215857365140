import * as React from "react";

import * as Progress from "@radix-ui/react-progress";

export const AIProgressIndicator: React.FC<{
  completionPercentage: number;
}> = ({ completionPercentage }) => {
  // Never show "true 0"
  const percentageToShow = Math.max(completionPercentage, 3);

  return (
    <Progress.Root value={percentageToShow} max={100} className="h-1">
      <Progress.Indicator
        className="bg-ai h-1 transition-all duration-500 ease-linear"
        style={{
          width: `${percentageToShow}%`,
        }}
      />
    </Progress.Root>
  );
};
