import type { CustomPropsRecord } from "schemas/component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { mapAndConvertComponentStylePropsToStyle } from "../../../shared/utils/breakpoints";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _accessibilityHidden: {
      type: "boolean",
      name: "Accessibility Hidden",
      description:
        "If set, removes this component from the accessibility tree, hiding it from screen readers. This should only be used if the image is purely decorative and does not provide useful information to the user.",
      defaultValue: null,
    },
  };
}

export const customProperties = {
  placeholderMinWidth: "--rpmw",
  placeholderMinHeight: "--rpmh",
};

export const styleElements = {
  root: {
    defaultStyles: {
      overflowX: "auto",
      overflowY: "hidden",
      // Note (Ovishek, 2023-01-27, REPL-6166): There's a diff when safari
      // doesn't respect max-height: 100% on <img /> inside <picture /> in
      // some scenarios, but that's only for display: block. It works
      // fine for display: flex.
      display: "flex",
      alignItems: "center",
      // Note (Martin, 2023-06-23): when Safari is dealing with percentage
      // heights (that <img /> element has on this component), it adheres to
      // the traditional interpretation of the spec, which requires a defined
      // height on the parent, therefore not accepting flex and grid heights as
      // adequate parent references. By defining min sizes here, we ensure that
      // Safari will render the image at the correct size.
      // https://stackoverflow.com/a/44773109
      minWidth: 0,
      minHeight: 0,
    },
    overrideStyles({ component, styleProps }) {
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        // Note (Noah, 2022-03-22, REPL-1365): Disable pointer events for certain images
        // because they can block interactions with other components that are under them,
        // e.g. on the Fulton option dropdown menu.
        // TODO (Noah, REPL-1366): Probably a better way is to have some toggle for pointer-events:
        // none somewhere in the right bar for this
        const shouldDisablePointerEvents =
          ["fixed", "absolute"].includes(styles.position ?? "") &&
          !component.props.onClick;

        let maxWidth = undefined;
        if (!styles.__hasWidth && !styles.maxWidth) {
          maxWidth = !(
            styles.__parentDisplay === "flex" && styles.flexGrow === 1
          )
            ? "400px"
            : "none";
        }

        return {
          pointerEvents: shouldDisablePointerEvents ? "none" : undefined,
          maxWidth,
          // NOTE (Gabe 2023-11-01): On non-chrome browsers, if Image's parent
          // has a height and the Image has an implicit height (Fill Available)
          // then the width of the picture is based on the non-rendered size of
          // the image. By setting the height to 100% the width of the picture
          // ends up being correct.
          height:
            styles.__parentHasHeight && styles.__hasHeight && !styles.height
              ? "100%"
              : undefined,
        };
      });
    },
  },
  img: {
    defaultStyles: {
      display: "block",
      // Note (Noah, 2022-04-014): Set padding/margin to 0 because Bokksu's images in blogs
      // have a default padding applied. This is probably the case for many other themes too,
      // but there's probably a better way to deal with this
      padding: 0,
      margin: 0,
      // Note (Noah, 2023-04-27, USE-107): It may seems like these properties
      // are redundant, but they work around a couple of different bugs with
      // image rendering in various configurations in Safari. All of these should
      // be covered by visual regression tests, but all three of the minHeight,
      // maxHeight, and height are actually required for Safari to render images
      // correctly, and both the minWidth and maxWidth are also required. If you
      // set width to 100% though, Safari screws up rendering images where the height
      // is set but not the width (instead of keeping the intrinsic aspect ratio, it
      // will render the image large).
      minWidth: "100%",
      maxWidth: "100%",
      minHeight: "100%",
      maxHeight: "100%",
      height: "100%",
    },
    overrideStyles({ styleProps }) {
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        return {
          transform: styles.__zoom ? `scale(${styles.__zoom})` : undefined,
          objectPosition: styles.objectPosition,
          objectFit: styles.objectFit,
          transition: styles.transition,
        };
      });
    },
  },
  placeholder: {
    // Note (Noah, 2023-08-19, REPL-8257): Never render placeholder styles except
    // in the editor, since they're never used outside of the editor
    shouldRender: ({ isEditor }) => isEditor,
    defaultStyles: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f0f0f0",
      minWidth: `var(${customProperties.placeholderMinWidth}, auto)`,
      minHeight: `var(${customProperties.placeholderMinHeight}, auto)`,
      cursor: "pointer",
    },
    overrideStyles({ styleProps }) {
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        const hasDefinedWidth = styles.__hasWidth;
        const hasDefinedHeight = styles.__hasHeight;

        return {
          [customProperties.placeholderMinWidth]: !hasDefinedWidth
            ? "270px"
            : "100%",
          [customProperties.placeholderMinHeight]: !hasDefinedHeight
            ? "270px"
            : "100%",
          // NOTE (Fran 2024-04-26): We should override the display property to ensure the placeholder
          // matches the component's visibility
          display: styles.display,
        };
      });
    },
  },
  broken: {
    defaultStyles: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f0f0f0",
      minWidth: `var(${customProperties.placeholderMinWidth}, auto)`,
      minHeight: `var(${customProperties.placeholderMinHeight}, auto)`,
      cursor: "pointer",
      gap: "8px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "500",
      color: "#2563EB",
    },
    overrideStyles({ styleProps }) {
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        const hasDefinedWidth = styles.__hasWidth;
        const hasDefinedHeight = styles.__hasHeight;

        return {
          [customProperties.placeholderMinWidth]: !hasDefinedWidth
            ? "270px"
            : "100%",
          [customProperties.placeholderMinHeight]: !hasDefinedHeight
            ? "270px"
            : "100%",
          // NOTE (Fran 2024-04-26): We should override the display property to ensure the placeholder
          // matches the component's visibility
          display: styles.display ?? "flex",
        };
      });
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    showAccessibilityMenu: true,
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: ["width", "height"],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
