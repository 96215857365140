import type { PopoverContentProps } from "@replo/design-system/components/popover";
import type { FlowStepConfigPropsValueOf } from "schemas/flow";
import type { EditorTourStepPosition } from "schemas/generated/flow";

import * as React from "react";

import { ReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import useGetFlowActions from "@components/flows/hooks/useGetFlowActions";
import TourFlowActions from "@editor/components/flows/tour/TourFlowActions";
import { selectShowAIWelcomeModal } from "@editor/reducers/ai-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { routes } from "@editor/utils/router";
import TourFlowArrow from "@svg/tour-flow-arrow";

import Popover from "@replo/design-system/components/popover";
import twMerge from "@replo/design-system/utils/twMerge";
import { useMatch, useSearchParams } from "react-router-dom";

const parseSideAndAlign = (
  side: EditorTourStepPosition,
): {
  side: PopoverContentProps["side"];
  align: PopoverContentProps["align"];
} => {
  const [mainSide, alignment] = side.split("-");
  return {
    side: mainSide as PopoverContentProps["side"],
    align: alignment as PopoverContentProps["align"],
  };
};

const TourStepTrigger: React.FC<
  React.PropsWithChildren<{
    step: "step-1" | "step-2" | "step-3" | "step-4" | "step-5";
  }>
> = ({ children, step }) => {
  const areStateModalsOpen = useEditorSelector(selectAreModalsOpen);
  const isAIWelcomeModalOpen = useEditorSelector(selectShowAIWelcomeModal);
  const areRouteModalsOpen = !useMatch(routes.editor.element);
  const [searchParams] = useSearchParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { currentFlow, nextStep } = useGetCurrentFlow({
    entityType: "user",
    isDebug,
  });
  const currentStep = nextStep ?? null;
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"tour.editor.generic">;
  const { submitStep, skipStep, goBack, isSubmitting } = useGetFlowActions(
    "user",
    currentStep,
  );

  const onSkipStep = () => {
    if (currentStep) {
      skipStep(currentStep.id, currentStep.type);
    }
  };

  if (
    currentFlow?.slug !== "editor-tour" ||
    currentStep === null ||
    areStateModalsOpen ||
    areRouteModalsOpen ||
    // Note (Evan, 2024-10-10): The AI welcome modal acts as a pseudo-first-step, so if it's showing we should hide the first step
    // of the editor tour.
    (isAIWelcomeModalOpen && step === "step-1")
  ) {
    return children;
  }

  const { side: parsedSide, align } = parseSideAndAlign(stepProps.side);

  return (
    <ReploFlowsStepContext.Provider
      value={{
        currentStep,
        goBack,
        submitStep,
        skipStep,
        isSubmitting,
      }}
    >
      <Popover isOpen={currentStep?.id === step} onOpenChange={onSkipStep}>
        {/* NOTE (Sebas, 2024-02-16): This is to prevent the opening of the popover when
            clicking on the wrapped component. */}
        <Popover.Trigger asChild onClick={(e) => e.preventDefault()}>
          {/* NOTE (Sebas, 2024-02-16): DON'T remove this div, if you do it the popover
              will not show anymore and we don't want that :) */}
          <div className="flex">{children}</div>
        </Popover.Trigger>
        <Popover.Content
          shouldPreventDefaultOnInteractOutside
          side={parsedSide}
          align={align}
          // sideOffset={20}
          className="relative w-[302px] p-4"
          title={stepProps.content.text}
          titleClassnames="text-default text-sm font-normal max-w-[30ch]"
        >
          <TourFlowActions />
          <Triangle side={stepProps.side} />
        </Popover.Content>
      </Popover>
    </ReploFlowsStepContext.Provider>
  );
};

const Triangle: React.FC<{
  side: EditorTourStepPosition;
}> = ({ side }) => {
  if (!side) {
    return null;
  }

  const [position, align] = side.split("-") as [
    "top" | "right" | "bottom" | "left",
    "start" | "center" | "end",
  ];

  const positionStyles = {
    top: {
      start: "bottom-1 left-4 translate-y-full rotate-0",
      center: "bottom-1 left-1/2 -translate-x-1/2 translate-y-full rotate-0",
      end: "bottom-1 right-4 translate-y-full rotate-0",
    },
    bottom: {
      start: "top-1 left-4 -translate-y-full rotate-180",
      center: "top-1 left-1/2 -translate-x-1/2 -translate-y-full rotate-180",
      end: "top-1 right-4 -translate-y-full rotate-180",
    },
    left: {
      start: "right-2 top-4 translate-x-full -rotate-90",
      center: "right-2 top-1/2 translate-x-full -translate-y-1/2 -rotate-90",
      end: "right-2 bottom-4 translate-x-full -rotate-90",
    },
    right: {
      start: "left-2 top-4 -translate-x-full rotate-90",
      center: "left-2 top-1/2 -translate-x-full -translate-y-1/2 rotate-90",
      end: "left-2 bottom-4 -translate-x-full rotate-90",
    },
  };

  return (
    <div className={twMerge("absolute", positionStyles[position][align])}>
      <TourFlowArrow />
    </div>
  );
};

export default TourStepTrigger;
