import {
  selectAreMultipleDraftComponents,
  selectEditorMode,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

export function useShowDraftComponentsControls() {
  const editorMode = useEditorSelector(selectEditorMode);
  const areMultipleDraftComponents = useEditorSelector(
    selectAreMultipleDraftComponents,
  );

  return editorMode === EditorMode.edit && !areMultipleDraftComponents;
}
