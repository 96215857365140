import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";

import Separator from "./designSystem/Separator";

type TextTabProps<T extends string> = {
  options: ReadonlyArray<{
    value: T;
    label: string;
    isVisible?: boolean;
    isDisabled?: boolean;
    tooltipContent?: string;
  }>;
  selectedValue?: T;
  onChange?: (value: T) => void;
  className?: string;
  containerClassName?: string;
};

export const TextTab = <T extends string>({
  options,
  selectedValue,
  onChange,
  className,
  containerClassName,
}: TextTabProps<T>) => {
  const containerRef = React.useRef<HTMLUListElement>(null);
  const selectedIndex = options.findIndex(
    (option) => option.value === selectedValue,
  );
  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const selectedItem = container.children[selectedIndex];
    if (!selectedItem) {
      return;
    }
    // NOTE (Evan, 7/25/23) We only want to scroll if the new element is
    // out of view (to avoid unnecessary motion)
    const { left: containerLeft, width: containerWidth } =
      container.getBoundingClientRect();
    const { left: itemLeft, width: itemWidth } =
      selectedItem.getBoundingClientRect();
    if (
      itemLeft < containerLeft ||
      itemLeft + itemWidth > containerLeft + containerWidth
    ) {
      selectedItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selectedIndex]);

  return (
    <div className="mb-2">
      <ul
        className={twMerge(
          "flex overflow-x-auto no-scrollbar gap-3",
          containerClassName,
        )}
        ref={containerRef}
      >
        {options?.map(
          (
            {
              label,
              value,
              isVisible = true,
              isDisabled = false,
              tooltipContent,
            },
            index,
          ) => {
            const Wrapper = tooltipContent ? Tooltip : React.Fragment;
            const wrapperProps = (
              tooltipContent
                ? { content: tooltipContent, triggerAsChild: true }
                : {}
            ) as any;

            return (
              isVisible && (
                <Wrapper {...wrapperProps} key={index}>
                  <li
                    key={value}
                    id={`text-tab-${value}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isDisabled) {
                        onChange?.(value);
                      }
                    }}
                    role="button"
                    className={classNames(
                      twMerge(
                        "text-xs transition shrink-0 pb-1 border-b-2",
                        className,
                      ),
                      {
                        "cursor-not-allowed opacity-50": isDisabled,
                        "cursor-pointer": !isDisabled,
                        "font-semibold text-blue-600 border-blue-600":
                          value === selectedValue,
                        "font-medium text-subtle hover:text-muted border-transparent":
                          value !== selectedValue,
                      },
                    )}
                  >
                    {label}
                  </li>
                </Wrapper>
              )
            );
          },
        )}
      </ul>

      <Separator className={twMerge("-mt-[1px]")} />
    </div>
  );
};
