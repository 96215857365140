import type { JssStyle, Plugin } from "jss";
import type {
  ComponentIdToStyleRulesMapping,
  ComponentStyleRulesMapping,
} from "replo-runtime/shared/styles";

import { isFeatureEnabled } from "@editor/infra/featureFlags";

import jss from "jss";

class ComponentIdCommentRule {
  type = "rule";
  isProcessed = false;
  key: string;
  value: string;
  options: any;
  constructor(key: string, _: JssStyle, options: any) {
    const splittedKey = key.split(":");
    this.key = key;
    this.value = splittedKey[splittedKey.length - 1]!;
    this.options = options;
  }

  toString() {
    const prefix = this.key.includes(":start") ? "start" : "end";
    return prefix === "start"
      ? generateStartComment(this.value)
      : generateEndComment(this.value);
  }
}

const componentIdCommentJSSPlugin: Plugin = {
  // @ts-expect-error
  onCreateRule(key, value, options) {
    if (!key.startsWith("@componentId:")) {
      return null;
    }
    return new ComponentIdCommentRule(key, value, options);
  },
};

jss.use(componentIdCommentJSSPlugin);

export function injectStylesIntoDocument(styles: string, document: Document) {
  const existingStyleElement = document.head.querySelector(
    "[data-replo-editor-style='true']",
  );
  // NOTE (Matt 2024-11-07): In the editor if no mirror is enabled, we use @container queries instead of @media queries
  // because we are no longer using iframes and @media would be incorrectly giving the wrong screen size.
  // NOTE (Jackson 2025-01-25): We are also replacing vw with cqw because vw now references the
  // browser window width instead of the canvas width, cqw uses our @container widths!
  if (isFeatureEnabled("no-mirror")) {
    styles = styles.replace(/@media /g, "@container replo-canvas ");
    // NOTE (Jackson 2025-01-25): this regex ensures we don't accidentally replace
    // vw with cqw in generated classnames or something like that.
    // NOTE (Matt 2025-02-14): The `;` is crucial in this regex, because it is possible
    // for a generated classname to include a number followed by vw (ex: .r-6vwvcv)
    styles = styles.replace(/(\d+)vw;/g, "$1cqw");
  }
  if (existingStyleElement) {
    existingStyleElement.textContent = styles;
    return;
  }

  const styleElement = document.createElement("style");
  styleElement.dataset.reploEditorStyle = "true";
  styleElement.textContent = styles;
  document.head.append(styleElement);
}

export function generateStyles(rules: ComponentIdToStyleRulesMapping) {
  const allRules = {};
  for (const [componentId, componentStyleRules] of Object.entries(rules)) {
    if (componentStyleRules) {
      Object.assign(allRules, {
        [`@componentId:start:${componentId}`]: {},
        ...componentStyleRules,
        [`@componentId:end:${componentId}`]: {},
      });
    }
  }
  const sheet = jss.createStyleSheet(allRules);
  const styles = sheet.toString();
  jss.removeStyleSheet(sheet);
  return styles;
}

export function getStylesFromDocument(document: Document) {
  const styleElement = document.querySelector(
    "[data-replo-editor-style='true']",
  );
  return styleElement?.textContent;
}

export function upsertStyles(
  rules: ComponentIdToStyleRulesMapping,
  document: Document,
) {
  const styleElement = document.querySelector(
    "[data-replo-editor-style='true']",
  );
  let styles = styleElement?.textContent ?? "";
  for (const [componentId, componentStyleRules] of Object.entries(rules)) {
    if (componentStyleRules) {
      const stylesForComponentExist = checkStylesForComponent(
        styles,
        componentId,
      );
      if (stylesForComponentExist) {
        const stylesToInsert = renderStyleRulesToString(componentStyleRules);
        styles = replaceBetweenDynamicPatterns(
          styles,
          componentId,
          stylesToInsert,
        );
      } else {
        const stylesToInsert = renderStyleRulesToString({
          [`@componentId:start:${componentId}`]: {},
          ...componentStyleRules,
          [`@componentId:end:${componentId}`]: {},
        });
        styles += stylesToInsert;
      }
    }
  }
  return styles;
}

function renderStyleRulesToString(rules: ComponentStyleRulesMapping) {
  const sheet = jss.createStyleSheet(rules);
  const styles = sheet.toString();
  jss.removeStyleSheet(sheet);
  return styles;
}

function generateStartComment(componentId: string) {
  return `/* start-${componentId} */`;
}

function generateEndComment(componentId: string) {
  return `/* end-${componentId} */`;
}

function getStylesRegexForComponent(componentId: string) {
  return new RegExp(
    `\\/\\* start-${componentId} \\*\\/([\\s\\S]*?)\\/\\* end-${componentId} \\*\\/`,
  );
}

function checkStylesForComponent(input: string, componentId: string) {
  const regex = getStylesRegexForComponent(componentId);
  return regex.test(input);
}

function replaceBetweenDynamicPatterns(
  input: string,
  componentId: string,
  replacement: string,
): string {
  const regex = getStylesRegexForComponent(componentId);
  return input.replace(
    regex,
    `${generateStartComment(componentId)}${replacement}${generateEndComment(componentId)}`,
  );
}
