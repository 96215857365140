import type { UseFormHandleSubmit } from "react-hook-form";
import type { Experiment } from "schemas/generated/experiment";
import type { ExperimentEditAction } from "./contexts/ExperimentEditContext";

import * as React from "react";

import EditableHeader from "@editor/components/dashboard/EditableHeader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpcUtils } from "@editor/utils/trpc";

import { useExperimentApi } from "@/features/experiments/utils";

type ExperimentEditableHeaderProps = {
  title: string;
  dispatchExperimentEdit: (action: ExperimentEditAction) => void;
  handleReactHookFormSubmit: UseFormHandleSubmit<Experiment, undefined>;
  isLoadingRequiredData: boolean;
  experimentId: string;
};

export const ExperimentEditableHeader = ({
  title,
  isLoadingRequiredData,
  dispatchExperimentEdit,
  handleReactHookFormSubmit,
  experimentId,
}: ExperimentEditableHeaderProps) => {
  const workspaceId = useCurrentWorkspaceId();

  const {
    update: { mutateAsync: update },
  } = useExperimentApi({ workspaceId: workspaceId ?? "" });

  const onSave = async (data: Experiment) => {
    await update(data);
  };

  const onTitleChange = handleReactHookFormSubmit(onSave);

  return (
    <EditableHeader
      title={title}
      onTitleChange={async (newName: string) => {
        if (newName) {
          dispatchExperimentEdit({
            type: "changeProperty",
            payload: { key: "name", value: newName, shouldDirty: false },
          });
          await onTitleChange();
          await trpcUtils.experiment.findDetail.invalidate({
            experimentId: experimentId,
            workspaceId: workspaceId ?? "",
          });
        }
      }}
      isLoading={isLoadingRequiredData}
    />
  );
};
