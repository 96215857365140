import type {
  MenuItem,
  MenuItemSection,
} from "@editor/components/common/designSystem/Menu";
import type { ContextMenuActions } from "@editor/types/component-tree";
import type { Hotkey } from "@utils/hotkeys";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { VariantWithState } from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import * as React from "react";

import { successToast } from "@editor/components/common/designSystem/Toast";
import { HotkeyIndicator } from "@editor/components/common/HotkeyIndicator";
import AskAIHeader from "@editor/components/editor/ai/AskAiHeader";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import {
  selectComponentDataMapping,
  selectDraftComponentIds,
  selectDraftComponentName,
  selectDraftComponentOrAncestorVariants,
  selectDraftComponentOrDescendantIsOneOfTypes,
  selectNearestComponentWithVariantsId,
  selectRootComponentId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { canGroupIntoContainer } from "@editor/utils/component";
import useContextMenuActions from "@hooks/useContextMenuActions";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import copy from "copy-to-clipboard";
import cloneDeep from "lodash-es/cloneDeep";
import { forEachComponentAndDescendants } from "replo-runtime";
import {
  isAnyComponentAncestorOfType,
  isAnyComponentDescendantOfType,
} from "replo-runtime/shared/utils/component";
import { hasOwnProperty, isEmpty } from "replo-utils/lib/misc";

const MENU_ITEM_ORDER = [
  // AI & Generation Section
  { type: "section", items: ["replo-ai"] },

  // Basic Operations Section
  {
    type: "section",
    items: [
      "copy",
      "paste",
      "pasteFigma",
      "duplicate",
      "turnInto",
      "rename",
      "delete",
    ],
  },

  // Component Organization Section
  {
    type: "section",
    items: ["groupContainer", "createTemplate", "exportToSection", "styles"],
  },

  // Styles & Debug Section
  {
    type: "section",
    items: [
      "copyJson",
      "replaceComponentJSON",
      "copyAiResponsiveInfo",
      "debug",
    ],
  },
] as const;

export default function useContextMenuItems(
  source: "canvasRightClickMenu" | "componentTree",
  onRename?: (() => void) | null,
) {
  const rootComponentId = useEditorSelector(selectRootComponentId);
  const contextMenuActions = useContextMenuActions();
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const activeVariant = useEditorSelector(
    selectDraftComponentOrAncestorVariants,
  ).find((variant) => variant.isActive);
  const componentWithVariantsId = useEditorSelector(
    selectNearestComponentWithVariantsId,
  );
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isDebugMode = useIsDebugMode();
  const draftComponentName = useEditorSelector(selectDraftComponentName);
  const draftComponentOrDescendantIsText = useEditorSelector((state) =>
    selectDraftComponentOrDescendantIsOneOfTypes(state, ["text"]),
  );
  const contextMenuItems = React.useMemo(() => {
    return getContextMenuItems({
      contextMenuActions,
      draftComponentIds,
      activeVariant,
      componentWithVariantsId: componentWithVariantsId ?? undefined,
      source,
      rootComponentId,
      onStartRename: onRename ?? null,
      isDebugMode,
      activeCanvas,
      draftComponentName,
      draftComponentOrDescendantIsText,
      componentDataMapping,
    });
  }, [
    contextMenuActions,
    draftComponentIds,
    activeVariant,
    componentWithVariantsId,
    source,
    rootComponentId,
    onRename,
    isDebugMode,
    activeCanvas,
    draftComponentName,
    draftComponentOrDescendantIsText,
    componentDataMapping,
  ]);

  return contextMenuItems;
}

function getContextMenuItems(config: {
  contextMenuActions: ContextMenuActions | null;
  draftComponentIds: string[];
  activeVariant: VariantWithState | undefined;
  componentWithVariantsId: string | undefined;
  source: "canvasRightClickMenu" | "componentTree";
  rootComponentId: string | null;
  onStartRename: (() => void) | null;
  isDebugMode?: boolean;
  activeCanvas: EditorCanvas;
  draftComponentName: string | null;
  draftComponentOrDescendantIsText: boolean;
  componentDataMapping: ComponentDataMapping;
}): MenuItem[] {
  const {
    contextMenuActions,
    draftComponentIds,
    activeVariant,
    componentWithVariantsId,
    source,
    rootComponentId,
    onStartRename = null,
    isDebugMode = false,
    activeCanvas,
    draftComponentName,
    draftComponentOrDescendantIsText,
    componentDataMapping,
  } = config;
  const isRootComponentSelected = rootComponentId
    ? draftComponentIds.includes(rootComponentId)
    : false;

  if (!contextMenuActions) {
    return [];
  }

  const {
    handlePaste,
    handlePasteFromFigma,
    handleCopy,
    handleDuplicate,
    handleReplaceComponent,
    handleReplaceAllContentWithPlaceholders,
    getStringFromComponentJson,
    getComponent,
    handleGroupContainer,
    handleGroupDelete,
    handleDelete,
    canDelete,
    handleOpenModal,
    getAssetComponent,
    handleChangeImage,
    forceSentryError,
    setDraftComponentId,
    handleResetStylesToDefaultState,
    handlePushPropsToDefaultState,
    handleCopyStyles,
    handlePersistStylesToUpstreamDevices,
    generateAiCopy,
    resetComponentAlignment,
    getAlignSelf,
    resetStyleOverrides,
    openCodeEditor,
    turnInto,
  } = contextMenuActions;

  function getTurnIntoItems() {
    const allComponentsAreContainers = draftComponentIds.every(
      (id) => componentDataMapping[id]?.type === "container",
    );
    const anyComponentIsTooltip = draftComponentIds.some(
      (id) => componentDataMapping[id]?.type === "tooltip",
    );
    const anyAncestorIsButton = draftComponentIds.some((id) =>
      isAnyComponentAncestorOfType(id, componentDataMapping, "button"),
    );
    const anyChildIsButton = draftComponentIds.some((id) =>
      isAnyComponentDescendantOfType(id, componentDataMapping, "button"),
    );
    const anyAncestorIsTicker = draftComponentIds.some((id) =>
      isAnyComponentAncestorOfType(id, componentDataMapping, "marquee"),
    );
    const anyChildIsTicker = draftComponentIds.some((id) =>
      isAnyComponentDescendantOfType(id, componentDataMapping, "marquee"),
    );
    const anyAncestorIsTooltip = draftComponentIds.some((id) =>
      isAnyComponentAncestorOfType(id, componentDataMapping, "tooltip"),
    );
    const anyChildIsTooltip = draftComponentIds.some((id) =>
      isAnyComponentDescendantOfType(id, componentDataMapping, "tooltip"),
    );

    return [
      {
        id: "turnIntoButton",
        type: "leaf",
        title: "Button",
        onSelect: () => turnInto({ type: "button" }),
        isDisabled:
          isRootComponentSelected ||
          !allComponentsAreContainers ||
          anyAncestorIsButton ||
          anyChildIsButton,
      },
      {
        id: "turnIntoProduct",
        type: "leaf",
        title: "Product",
        onSelect: () => turnInto({ type: "product" }),
        isDisabled: isRootComponentSelected || !allComponentsAreContainers,
      },
      {
        id: "turnIntoTicker",
        type: "leaf",
        title: "Ticker",
        onSelect: () =>
          turnInto({ type: "ticker", selectedComponentIds: draftComponentIds }),
        isDisabled:
          isRootComponentSelected ||
          !allComponentsAreContainers ||
          anyAncestorIsTicker ||
          anyChildIsTicker,
      },
      {
        id: "addTooltipToComponent",
        type: "leaf",
        title: "Tooltip Trigger",
        onSelect: () =>
          turnInto({
            type: "tooltipTrigger",
            selectedComponentIds: draftComponentIds,
          }),
        isDisabled:
          isRootComponentSelected ||
          anyComponentIsTooltip ||
          anyAncestorIsTooltip ||
          anyChildIsTooltip,
      },
    ];
  }

  function getChangeMediaContentItem() {
    const [componentId] = draftComponentIds;
    const assetComponent = getAssetComponent(componentId!);
    if (!assetComponent?.component) {
      return null;
    }

    const { component, value } = assetComponent;
    return {
      id: "changeMediaContent",
      type: "leaf",
      title: component.type === "image" ? "Change Image" : "Change Video",
      onSelect: () => {
        handleOpenModal({
          type: "assetLibraryModal",
          props:
            component.type === "image"
              ? {
                  referrer: "modifier/image",
                  value,
                  onChange: (value) => {
                    if (!value) {
                      return;
                    }
                    handleChangeImage(componentId!, value);
                  },
                  assetContentType: "image",
                }
              : {
                  referrer: "modifier/video",
                  value,
                  assetContentType: "video",
                },
        });
      },
    };
  }

  function getStylesItems() {
    const items = [
      {
        type: "leaf",
        id: "copyStyles",
        title: "Copy Styles",
        isDisabled: draftComponentIds.length !== 1,
        onSelect: () => {
          const [componentId] = draftComponentIds;
          if (!componentId) {
            return;
          }
          handleCopyStyles(componentId, activeVariant?.id);
        },
        endEnhancer: hotkeyEndEnhancer("copyStyles"),
      },
      {
        type: "leaf",
        id: "pasteStyles",
        title: "Paste Styles",
        isDisabled: !draftComponentIds.length,
        onSelect: () => {
          void handlePaste("alt");
        },
        endEnhancer: hotkeyEndEnhancer("pasteStyles"),
      },
      {
        type: "leaf",
        id: "resetContentsAlignment",
        title: "Reset Content Alignment",
        isDisabled: draftComponentIds.some((id) => !getAlignSelf(id)),
        onSelect: () => {
          for (const id of draftComponentIds) {
            resetComponentAlignment(id);
          }
        },
      },
      {
        type: "leaf",
        id: "resetStyleOverrides",
        title: "Reset Style Overrides",
        isDisabled: activeCanvas === "desktop" || !draftComponentIds.length,
        onSelect: () => {
          for (const id of draftComponentIds) {
            resetStyleOverrides(id, activeVariant?.id);
          }
        },
      },
    ];

    if (isDebugMode) {
      items.push({
        type: "leaf",
        id: "stripNonDesktopStyles",
        title: "Strip Non-Desktop Styles",
        isDisabled: !draftComponentIds.length,
        onSelect: () => {
          for (const id of draftComponentIds) {
            const component = cloneDeep(getComponent(id));
            if (!component) {
              return "Component not found";
            }

            forEachComponentAndDescendants(component, (component) => {
              delete component.props["style@md"];
              delete component.props["style@sm"];
            });
            handleReplaceComponent(id, component);
          }
        },
      });
    }

    if (
      draftComponentIds.length === 1 &&
      activeVariant &&
      componentWithVariantsId
    ) {
      items.push(
        {
          id: "resetStylesToDefaultState",
          type: "leaf",
          title: "Reset Styles to Default State",
          isDisabled: activeVariant.name === "default",
          onSelect: () => {
            const [componentId] = draftComponentIds;
            handleResetStylesToDefaultState(componentId!, activeVariant.id);
          },
        },
        {
          id: "pushPropsToDefaultState",
          type: "leaf",
          title: "Push Changes to Default State",
          isDisabled: activeVariant.name === "default",
          onSelect: () => {
            const [componentId] = draftComponentIds;
            handlePushPropsToDefaultState(componentId!, activeVariant.id);
          },
        },
      );
    }

    return items;
  }

  function getOtherDebugOptionsItems() {
    return [
      {
        type: "leaf",
        id: "copyComponentId",
        isDisabled: draftComponentIds.length !== 1,
        title: (
          <div
            className={classNameForJSXMenuItems}
            onClick={() => {
              const [componentId] = draftComponentIds;
              if (!componentId) {
                return;
              }
              copy(componentId);
              successToast("Component ID Copied", "");
            }}
          >
            Copy Component ID
          </div>
        ),
      },
      {
        id: "setDraftElement",
        type: "leaf",
        title: (
          <div
            className={classNameForJSXMenuItems}
            onClick={(e) => {
              e.stopPropagation();
              const id = window.prompt();
              if (id) {
                setDraftComponentId(id);
              }
            }}
          >
            Set Draft Component ID
          </div>
        ),
      },
      {
        id: "cleanComponentWithoutImages",
        type: "leaf",
        title: "Replace all content with placeholders",
        isDisabled: draftComponentIds.length !== 1,
        onSelect: () => {
          const [componentId] = draftComponentIds;
          if (!componentId) {
            return;
          }
          handleReplaceAllContentWithPlaceholders(componentId);
        },
      },
      {
        id: "forceSentryError",
        type: "leaf",
        title: "Force Sentry Error",
        onSelect: () => {
          forceSentryError();
        },
      },
      {
        type: "nested",
        title: "Persist Styles Upstream",
        items: [
          {
            id: "persistStylesToUpstreamDevicesExcludingDescendants",
            type: "leaf",
            title: "Copy Styles to Larger Devices (This Component Only)",
            isDisabled: draftComponentIds.length !== 1,
            onSelect: () => {
              const [componentId] = draftComponentIds;
              if (!componentId) {
                return;
              }
              handlePersistStylesToUpstreamDevices(componentId, false);
            },
          },
          {
            id: "persistStylesToUpstreamDevicesExcludingDescendants",
            type: "leaf",
            title: "Copy Styles to Larger Devices (Include Descendants)",
            isDisabled: draftComponentIds.length !== 1,
            onSelect: () => {
              const [componentId] = draftComponentIds;
              if (!componentId) {
                return;
              }
              handlePersistStylesToUpstreamDevices(componentId, true);
            },
          },
        ],
      },
    ];
  }

  const classNameForJSXMenuItems = "w-[12.5rem] px-4 py-2";

  const isAIEnabled =
    draftComponentIds.length === 1 && draftComponentOrDescendantIsText;
  const unorderedItems: MenuItem[] = [
    // Generate AI Copy
    [
      {
        id: "replo-ai",
        type: "leaf",
        title: (
          <div className="grow px-4 py-2">
            <AskAIHeader
              content="Generate AI Copy"
              showIcon
              isDisabled={!isAIEnabled}
            />
          </div>
        ),
        isDisabled: !isAIEnabled,
        onSelect: () => {
          const [componentId] = draftComponentIds;
          generateAiCopy(componentId!);
        },
      },
    ] as MenuItem[],

    // Open Code Editor
    draftComponentIds.length === 1 && openCodeEditor
      ? ([
          {
            id: "openCodeEditor",
            type: "leaf",
            title: "Open Code Editor",
            onSelect: () => openCodeEditor(),
          },
        ] as MenuItem[])
      : [],

    // Group Into Container, Create Saved Component, Export To Section
    [
      {
        type: "leaf",
        id: "groupContainer",
        title: "Group Into Container",
        isDisabled:
          !draftComponentIds.length ||
          isRootComponentSelected ||
          !canGroupIntoContainer(draftComponentIds, componentDataMapping)
            .canGroupIntoContainer,
        onSelect: () => handleGroupContainer(draftComponentIds),
        endEnhancer: hotkeyEndEnhancer("groupIntoContainer"),
      },
      {
        id: "createTemplate",
        type: "leaf",
        title: "Create Saved Component",
        isDisabled: draftComponentIds.length !== 1,
        onSelect: () => {
          handleOpenModal({
            type: "saveTemplateModal",
            props: {
              initialName: draftComponentName,
            },
          });
        },
        endEnhancer: hotkeyEndEnhancer("saveComponentTemplate"),
      },
      {
        id: "exportToSection",
        type: "leaf",
        title: "Export To Section",
        isDisabled: draftComponentIds.length !== 1,
        onSelect: () => {
          handleOpenModal({
            type: "exportToSectionModal",
          });
        },
      },
    ] as MenuItem[],

    // Turn Into
    draftComponentIds.length
      ? ([
          {
            id: "turnInto",
            type: "nested",
            title: "Turn Into",
            items: getTurnIntoItems(),
          },
        ] as MenuItem[])
      : [],

    // Copy, Paste, Paste From Figma
    [
      {
        type: "leaf",
        id: "copy",
        title: `Copy${draftComponentIds.length > 1 ? ` (${draftComponentIds.length})` : ""}`,
        endEnhancer: hotkeyEndEnhancer("copy"),
        isDisabled: !draftComponentIds.length,
        onSelect: () => handleCopy(draftComponentIds),
      },
      {
        type: "leaf",
        id: "paste",
        title: "Paste",
        endEnhancer: hotkeyEndEnhancer("paste"),
        onSelect: () => {
          void handlePaste("normal");
        },
      },
      {
        type: "leaf",
        id: "pasteFigma",
        title: "Paste from Figma",
        onSelect: () => {
          void handlePasteFromFigma();
        },
      },
    ] as MenuItem[],

    // Change Image/Video
    draftComponentIds.length
      ? ([getChangeMediaContentItem()] as MenuItem[])
      : [],

    // Rename, Duplicate, Delete
    draftComponentIds.length
      ? ([
          {
            type: "leaf",
            id: "rename",
            title: "Rename",
            isDisabled:
              draftComponentIds.length !== 1 ||
              isRootComponentSelected ||
              !onStartRename,
            onSelect: () => onStartRename!(),
          },
          {
            type: "leaf",
            id: "duplicate",
            title: "Duplicate",
            isDisabled: !draftComponentIds.length || isRootComponentSelected,
            onSelect: () => {
              for (const id of draftComponentIds) {
                handleDuplicate(id, source);
              }
            },
            endEnhancer: hotkeyEndEnhancer("duplicate"),
          },
          {
            type: "leaf",
            id: "delete",
            title: `Delete${draftComponentIds.length > 1 ? ` (${draftComponentIds.length})` : ""}`,
            isDisabled:
              !draftComponentIds.length ||
              draftComponentIds.some((id) => !canDelete(id)),
            onSelect: () => {
              if (draftComponentIds.length > 1) {
                handleGroupDelete(draftComponentIds);
              } else {
                const [componentId] = draftComponentIds;
                handleDelete(componentId!, source);
              }
            },
            endEnhancer: hotkeyEndEnhancer("delete"),
          },
        ] as MenuItem[])
      : [],

    // Styles
    draftComponentIds.length
      ? ([
          {
            id: "styles",
            type: "nested",
            title: "Styles",
            items: getStylesItems(),
          },
        ] as MenuItem[])
      : [],

    // Debug mode
    isDebugMode
      ? ([
          {
            type: "leaf",
            id: "copyJson",
            isDisabled: draftComponentIds.length !== 1,
            title: (
              <div
                className={classNameForJSXMenuItems}
                onClick={() => {
                  const [componentId] = draftComponentIds;
                  if (!componentId) {
                    return;
                  }
                  copy(getStringFromComponentJson(componentId));
                  successToast("Component JSON Copied", "");
                }}
              >
                Copy Component JSON
              </div>
            ),
          },
          {
            id: "replaceComponentJSON",
            type: "leaf",
            isDisabled: draftComponentIds.length !== 1,
            title: (
              <div
                className={classNameForJSXMenuItems}
                onClick={() => {
                  const [componentId] = draftComponentIds;
                  if (!componentId) {
                    return;
                  }
                  let componentJSON = window.prompt();
                  if (componentJSON) {
                    try {
                      componentJSON = JSON.parse(componentJSON);
                    } catch {}

                    if (componentJSON) {
                      handleReplaceComponent(componentId, componentJSON);
                    }
                  }
                }}
              >
                Set Component JSON
              </div>
            ),
          },
          {
            type: "leaf",
            id: "copyAiResponsiveInfo",
            isDisabled: draftComponentIds.length !== 1,
            title: (
              <div
                className={classNameForJSXMenuItems}
                onClick={() => {
                  const [componentId] = draftComponentIds;
                  if (!componentId) {
                    return;
                  }
                  const component = cloneDeep(getComponent(componentId));
                  if (!component) {
                    return "Component not found";
                  }
                  const actions: object[] = [];

                  forEachComponentAndDescendants(component, (component) => {
                    // NOTE (Gabe 2024-07-09): We merge md and sm styles here
                    // because we currently only care about mobile responsivity. If
                    // we want to have tablet specific styles we'll have to generate
                    // multiple actions.
                    if (
                      !isEmpty(component.props["style@md"]) ||
                      !isEmpty(component.props["style@sm"])
                    ) {
                      actions.push({
                        componentId: component.id,
                        type: "setStylesMobile",
                        value: {
                          ...component.props["style@md"],
                          ...component.props["style@sm"],
                        },
                      });
                    }
                    delete component.props["style@md"];
                    delete component.props["style@sm"];
                  });

                  const result = { component, actions };
                  copy(JSON.stringify(result, null, 2));
                  successToast("AI Responsive Info Copied", "");
                }}
              >
                Copy AI Responsive Info
              </div>
            ),
          },
          {
            type: "nested",
            id: "debug",
            title: "Other Debug Options",
            items: getOtherDebugOptionsItems(),
          },
        ] as MenuItem[])
      : [],
  ].flat();

  // Instead of sorting, we'll organize items into sections
  const itemsById = Object.fromEntries(
    unorderedItems
      .filter((item) => hasOwnProperty(item, "id"))
      .map((item) => [item.id, item]),
  );

  // Create sections with ordered items
  const sections: MenuItemSection[] = MENU_ITEM_ORDER.map((section) => ({
    type: "section" as const,
    items: section.items.map((id) => itemsById[id]),
  })).filter((section) => section.items.length > 0);

  return sections;
}

function hotkeyEndEnhancer(hotkey: Hotkey) {
  const HotKeyEndEnhancer = () => <HotkeyIndicator hotkey={hotkey} title="" />;
  return HotKeyEndEnhancer;
}
