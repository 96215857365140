import type { DraggingDirections } from "@editor/utils/editor";
import type { SavedStyleOpenedFrom } from "../TextSavedStyleModifier";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import { normalizeFontFamily } from "@editor/components/editor/page/element-editor/components/modifiers/utils";
import SolidColorSelector from "@editor/components/editor/page/element-editor/components/SolidColorSelector";
import {
  usePageFontOptions,
  useShopifyFontOptions,
} from "@editor/hooks/useFontFamilyOptions";
import { useFontWeightOptions } from "@editor/hooks/useFontWeightOptions";
import { filterOutPageFonts, GOOGLE_FONT_OPTIONS } from "@editor/utils/font";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import {
  getTextOutlineObject,
  getTextOutlineString,
} from "@editor/utils/textOutline";
import {
  formatTitle,
  getTextShadowString,
  parseTextShadows,
} from "@editor/utils/textShadow";

import { Badge } from "@replo/design-system/components/badge";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Combobox } from "@replo/design-system/components/combobox";
import Popover from "@replo/design-system/components/popover";
import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import intersectionBy from "lodash-es/intersectionBy";
import { AiOutlineFontSize } from "react-icons/ai";
import { BsBorderWidth, BsCaretDownFill } from "react-icons/bs";
import {
  RiAlignCenter,
  RiAlignLeft,
  RiAlignRight,
  RiDropLine,
  RiItalic,
  RiLineHeight,
  RiStrikethrough,
  RiTextSpacing,
  RiUnderline,
} from "react-icons/ri";
import {
  CSS_LENGTH_TYPES,
  CSS_LENGTH_TYPES_WITH_COMPUTED,
} from "replo-runtime/shared/utils/units";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_TEXT_SHADOW = {
  id: uuidv4(),
  offsetX: "0px",
  offsetY: "4px",
  blur: "1px",
  color: "#00000040",
};

const FONT_SIZE_MENU_ITEMS = [
  { label: "Reset", value: "" },
  { label: "12px", value: "12px" },
  { label: "14px", value: "14px" },
  { label: "16px", value: "16px" },
  { label: "18px", value: "18px" },
  { label: "20px", value: "20px" },
  { label: "24px", value: "24px" },
  { label: "32px", value: "32px" },
];

const LETTER_SPACING_MENU_OPTIONS = [
  { label: "Reset", value: "" },
  { label: "1px", value: "1px" },
  { label: "2px", value: "2px" },
  { label: "4px", value: "4px" },
];

const TEXT_ALIGN_OPTIONS = [
  {
    value: "left",
    label: <RiAlignLeft size={16} />,
    tooltipContent: "Align Left",
  },
  {
    value: "center",
    label: <RiAlignCenter size={16} />,
    tooltipContent: "Align Center",
  },
  {
    value: "right",
    label: <RiAlignRight size={16} />,
    tooltipContent: "Align Right",
  },
];

const TEXT_DECORATION_OPTIONS = [
  {
    value: "italic",
    label: <RiItalic size={16} />,
    tooltipContent: "Italic",
  },
  {
    value: "line-through",
    label: <RiStrikethrough size={16} />,
    tooltipContent: "Strikethrough",
  },
  {
    value: "underline",
    label: <RiUnderline size={16} />,
    tooltipContent: "Underline",
  },
];

const TEXT_TRANSFORM_OPTIONS = [
  { value: "capitalize", label: "Ag", tooltipContent: "Capitalize" },
  { value: "uppercase", label: "AG", tooltipContent: "Uppercase" },
  { value: "lowercase", label: "ag", tooltipContent: "Lowercase" },
];

type BaseControlProps = {
  value?: string | null;
  onChange?: (value: string | undefined) => void;
  disableWrapper?: boolean;
  openedFrom?: SavedStyleOpenedFrom;
};

const TOGGLE_GROUP_STYLES = {
  height: "26px",
  width: "100%",
} as const;

export const SavedFontWeightControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  fontFamily?: string | null;
}> = ({ value, onChange, fontFamily }) => {
  const options = useFontWeightOptions(fontFamily ?? null);

  return (
    <Selectable
      className="w-full"
      placeholder="Weight"
      options={options.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      }))}
      value={value?.toString()}
      onSelect={(value: string | null) => onChange?.(value ?? "")}
    />
  );
};

export const SavedFontSizeControl: React.FC<BaseControlProps> = ({
  value,
  onChange,
}) => (
  <SavedStyleControlInput
    label="Size"
    value={value ?? ""}
    onChange={(value) => onChange?.(value || "")}
    placeholder="Font Size"
    anchorValue="16px"
    resetValue="16px"
    menuOptions={FONT_SIZE_MENU_ITEMS}
    metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
    minValues={{ px: 0 }}
    startEnhancer={
      <Tooltip inheritCursor content="Font Size" triggerAsChild>
        <span tabIndex={0}>
          <AiOutlineFontSize />
        </span>
      </Tooltip>
    }
  />
);

export const SavedLineHeightControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  const lineHeightDefaultValue =
    styleAttributeToEditorData.lineHeight.defaultValue;

  return (
    <SavedStyleControlInput
      label="Line"
      value={value}
      onChange={(newValue) => {
        onChange?.(newValue === "auto" ? lineHeightDefaultValue : newValue);
      }}
      placeholder="Line Height"
      anchorValue="16px"
      resetValue={lineHeightDefaultValue}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      minValues={{ px: 0 }}
      startEnhancer={
        <Tooltip inheritCursor content="Line Height" triggerAsChild>
          <span tabIndex={0}>
            <RiLineHeight />
          </span>
        </Tooltip>
      }
    />
  );
};

export const SavedLetterSpacingControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  const letterSpacingDefaultValue =
    styleAttributeToEditorData.letterSpacing.defaultValue;

  return (
    <SavedStyleControlInput
      label="Spacing"
      value={value}
      onChange={(newValue) => {
        onChange?.(newValue);
      }}
      placeholder="Letter Spacing"
      resetValue={letterSpacingDefaultValue}
      anchorValue="1px"
      menuOptions={LETTER_SPACING_MENU_OPTIONS}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      startEnhancer={
        <Tooltip inheritCursor content="Letter Spacing" triggerAsChild>
          <span tabIndex={0}>
            <RiTextSpacing />
          </span>
        </Tooltip>
      }
      allowsNegativeValue
    />
  );
};

export const SavedTextAlignControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      value={value ?? ""}
      options={TEXT_ALIGN_OPTIONS}
      style={TOGGLE_GROUP_STYLES}
      onChange={(value) => value && onChange?.(value)}
    />
  );
};

export const SavedTextDecorationControl: React.FC<{
  value?: string | null;
  onChange?: (value: string | undefined) => void;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      allowsDeselect
      value={value ?? ""}
      style={TOGGLE_GROUP_STYLES}
      options={TEXT_DECORATION_OPTIONS}
      onChange={(value) => onChange?.(value ?? undefined)}
    />
  );
};

export const SavedTextColorControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
  openedFrom?: SavedStyleOpenedFrom;
}> = ({ value, onChange, openedFrom }) => {
  return (
    <SolidColorSelector
      popoverTitle="Text Color"
      value={value ?? ""}
      onChange={(value) => value && onChange?.(value)}
      defaultValue="#000000"
      popoverSide={openedFrom === "leftBar" ? "right" : "left"}
      popoverSideOffset={openedFrom === "leftBar" ? 150 : 87}
    />
  );
};

export const SavedTextTransformControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      style={TOGGLE_GROUP_STYLES}
      value={value ?? "none"}
      options={TEXT_TRANSFORM_OPTIONS}
      allowsDeselect
      onChange={(value) => {
        if (value) {
          onChange?.(value);
        } else {
          onChange?.("none");
        }
      }}
    />
  );
};

export const SavedTextShadowControl: React.FC<BaseControlProps> = ({
  value,
  onChange,
  openedFrom,
}) => {
  const [openShadowPopover, setOpenShadowPopover] =
    React.useState<boolean>(false);

  const textShadow = value ? parseTextShadows([value])[0] : null;

  const handleAddShadow = () => {
    const newShadows = DEFAULT_TEXT_SHADOW;
    onChange?.(getTextShadowString([newShadows]));
    setOpenShadowPopover(true);
  };

  const handleRemoveShadow = () => {
    onChange?.(undefined);
  };

  const handleShadowChange = (updates: Partial<typeof DEFAULT_TEXT_SHADOW>) => {
    if (!textShadow) {
      return;
    }
    onChange?.(getTextShadowString([{ ...textShadow, ...updates }]));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-[74px,auto] w-full items-center">
        <ModifierLabel label="Shadow" />
        <div className="flex flex-col gap-1">
          {textShadow ? (
            // NOTE (Fran 2025-01-27): This div is necessary to avoid the Popover anchor taking up space
            // in the layout because of the flex gap.
            <div>
              <SelectionIndicator
                className="max-w-40"
                title={formatTitle(textShadow)}
                onClick={() => setOpenShadowPopover(true)}
                startEnhancer={
                  <Badge
                    type="color"
                    isFilled
                    backgroundColor={textShadow.color ?? "text-subtle"}
                  />
                }
                endEnhancer={
                  <FormFieldXButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveShadow();
                    }}
                  />
                }
              />
              {openShadowPopover && (
                <TextShadowPopover
                  shadow={textShadow}
                  onChange={handleShadowChange}
                  onClose={() => setOpenShadowPopover(false)}
                  openedFrom={openedFrom}
                />
              )}
            </div>
          ) : (
            <IconButton
              className="w-full max-h-6"
              variant="secondary"
              onClick={handleAddShadow}
              icon={<RiDropLine />}
              tooltipText="Add Shadow"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TextShadowPopover: React.FC<{
  shadow: typeof DEFAULT_TEXT_SHADOW;
  onChange: (updates: Partial<typeof DEFAULT_TEXT_SHADOW>) => void;
  onClose: () => void;
  openedFrom?: SavedStyleOpenedFrom;
}> = ({ shadow, onChange, onClose, openedFrom }) => {
  return (
    <Popover isOpen onOpenChange={(isOpen) => !isOpen && onClose()}>
      <Popover.Content
        title="Text Shadow"
        onRequestClose={onClose}
        shouldPreventDefaultOnInteractOutside
        side={openedFrom === "leftBar" ? "right" : "left"}
        sideOffset={openedFrom === "leftBar" ? 9 : 84}
        onInteractOutside={onClose}
      >
        <div className="flex flex-col gap-2">
          <SavedStyleControlInput
            label="X Axis"
            metrics={CSS_LENGTH_TYPES}
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={shadow.offsetX}
            onChange={(value) => onChange({ offsetX: value })}
            autofocus
            allowsNegativeValue
          />
          <SavedStyleControlInput
            label="Y Axis"
            metrics={CSS_LENGTH_TYPES}
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={shadow.offsetY}
            onChange={(value) => onChange({ offsetY: value })}
            allowsNegativeValue
          />
          <SavedStyleControlInput
            label="Blur"
            metrics={CSS_LENGTH_TYPES}
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            minValues={{ px: 0 }}
            value={shadow.blur}
            onChange={(value) => onChange({ blur: value })}
          />
          <div className="flex items-center">
            <ModifierLabel label="Color" />
            <SolidColorSelector
              popoverTitle="Color"
              value={shadow.color}
              onChange={(value) => onChange({ color: value ?? undefined })}
              defaultValue="#00000040"
              popoverSide={openedFrom === "leftBar" ? "right" : "left"}
              popoverSideOffset={openedFrom === "leftBar" ? 145 : 92}
            />
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

export const SavedTextOutlineControl: React.FC<BaseControlProps> = ({
  value,
  onChange,
}) => {
  const textOutline = value ? getTextOutlineObject(value) : null;

  const handleInputChange = (value: string, inputType: "width" | "color") => {
    let textOutlineString = null;

    if (value && value !== "0px") {
      const newTextOutline = {
        width: textOutline?.width || "1px",
        color: textOutline?.color || "#000000",
        [inputType]: value,
      };
      textOutlineString = getTextOutlineString(newTextOutline);
    }
    onChange?.(textOutlineString ?? undefined);
  };

  return (
    <>
      <LengthInputSelector.Root
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        maxValues={{ px: 30 }}
        maxDragValues={{ px: 30 }}
        field="width"
        resetValue="0px"
        anchorValue="0px"
        value={textOutline?.width ?? null}
        onChange={(value: string) => handleInputChange(value, "width")}
        dragTrigger="label"
      >
        {/* NOTE (Fran 2024-10-16): 74px is the fixed width of the label. */}
        <div className="grid grid-cols-[74px,auto] w-full gap-y-2 items-center">
          <LengthInputSelector.DraggableArea>
            <ModifierLabel label="Outline" />
          </LengthInputSelector.DraggableArea>
          <LengthInputSelector.Input
            placeholder="0px"
            startEnhancer={<BsBorderWidth />}
          />
          <div className="col-start-2">
            <SolidColorSelector
              popoverTitle="Color"
              value={textOutline?.color ?? null}
              onChange={(value: string) => handleInputChange(value, "color")}
              // NOTE (Fran 2024-11-28): 150px is the width of the label plus the spacing between the input and the side of the popover
              // minus the width of the badge and the spacing between the badge and the left side of the input.
              popoverSideOffset={150}
              popoverSide="right"
            />
          </div>
        </div>
      </LengthInputSelector.Root>
    </>
  );
};

export const SavedFontFamilyControl: React.FC<{
  className?: string;
  value?: string | null;
  onChange?: (value: string | null) => void;
  openedFrom?: SavedStyleOpenedFrom;
}> = ({
  className,
  value: controlledValue,
  onChange: controlledOnChange,
  openedFrom,
}) => {
  const pageFontOptions = usePageFontOptions();
  const { fontOptions: shopifyFontOptions, nameToDisplayName } =
    useShopifyFontOptions();

  const filteredPageFonts = intersectionBy(
    pageFontOptions,
    [...shopifyFontOptions, ...GOOGLE_FONT_OPTIONS],
    (font) => font.value,
  );

  const [fontPreviewErrors, setFontPreviewErrors] = React.useState<
    Record<string, boolean>
  >({});

  // NOTE (Jackson, 2025-02-05): prefer vanilla approach over lodash here b/c
  // startCase adds a space before each capital letter (ex: "ABeeZee" -> "A Bee Zee"),
  // which we don't want, same with words()
  const fontStartCase = (str: string) => {
    return str
      .replaceAll("_", " ")
      .replaceAll("-", " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const createFontOption = (
    font: { value: string | null; label: string },
    nameToDisplayName: Record<string, string | undefined>,
    groupTitle?: string,
  ) => {
    const displayName = getDisplayName(font.value, nameToDisplayName);

    let normalizedLabel = font.label;

    if (displayName) {
      normalizedLabel = normalizeFontFamily(displayName) ?? font.label;
    } else {
      normalizedLabel = normalizeFontFamily(font.label) ?? font.label;
    }

    normalizedLabel = fontStartCase(normalizedLabel);

    return {
      ...font,
      label: normalizedLabel,
      component: (
        <div
          style={{
            fontFamily: `"${font.value!}", "Inter", sans-serif`,
            fontSize: "13px",
            lineHeight: "20px",
          }}
        >
          {normalizedLabel}
        </div>
      ),
      ...(groupTitle && { groupTitle }),
    };
  };

  const fontOptions = [
    // Note (Evan, 6/2/23) We add the end enhancers to page fonts here, since we have to know whether they're shopify-uploaded fonts or not
    ...filteredPageFonts.map((font) =>
      createFontOption(font, nameToDisplayName),
    ),

    // Note (Evan, 6/2/23) Filter out page fonts from shopify/google fonts so that we don't have any duplicates
    ...filterOutPageFonts(shopifyFontOptions, filteredPageFonts).map((font) =>
      createFontOption(font, nameToDisplayName, "From Theme"),
    ),
    ...filterOutPageFonts(GOOGLE_FONT_OPTIONS, filteredPageFonts).map(
      (font) => ({
        ...font,
        label: getDisplayName(font.value, nameToDisplayName) ?? font.label,
        groupTitle: "Google Fonts",
        component: !fontPreviewErrors[font.value ?? ""] ? (
          <img
            src={`/images/font-previews/${font.value?.replace(/\s+/g, "_")}.webp`}
            alt={getDisplayName(font.value, nameToDisplayName) ?? font.label}
            width={182}
            height={16}
            loading="lazy"
            onError={() =>
              setFontPreviewErrors((prev) => ({
                ...prev,
                [font.value ?? ""]: true,
              }))
            }
          />
        ) : (
          font.label
        ),
      }),
    ),
  ];

  const onSelect = (fontValue: string | null) => {
    if (controlledOnChange) {
      controlledOnChange(fontValue);
    }
  };

  let displayName: string | undefined = undefined;
  if (controlledValue) {
    displayName =
      nameToDisplayName[controlledValue] ??
      nameToDisplayName[controlledValue.split(",")[0] ?? ""];
  }

  const fontDisplayName =
    displayName ?? normalizeFontFamily(controlledValue ?? null);

  return (
    <ControlGroup label="Font">
      <Combobox.Root
        options={fontOptions}
        value={fontDisplayName || undefined}
        onChange={onSelect}
        previewOnHover
      >
        <Combobox.TriggerButton
          triggerClassName={twMerge(
            classNames("text-ellipsis truncate w-[160px] p-1 h-6"),
            className,
          )}
          placeholder="Select a font"
          endEnhancer={
            <BsCaretDownFill
              className="text-subtle mr-1 flex-shrink-0 ml-auto"
              size={8}
            />
          }
        />
        <Combobox.Content
          title="Fonts"
          sideOffset={openedFrom === "leftBar" ? 8 : 84}
          side={openedFrom === "leftBar" ? "right" : "left"}
          align="center"
          contentClassName="h-[416px] w-[220px]"
          areOptionsSearchable
          inputPlaceholder="Search fonts"
          emptySearchMessage="No matches found."
          itemsOnViewCount={13}
          itemSize={28}
        />
      </Combobox.Root>
    </ControlGroup>
  );
};

const SavedStyleControlInput: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  label: string;
  placeholder: string;
  menuOptions?: { label: string; value: string }[];
  resetValue: string;
  anchorValue: string;
  startEnhancer?: React.ReactNode;
  metrics?: string[];
  draggingDirection?: DraggingDirections;
  autofocus?: boolean;
  allowsNegativeValue?: boolean;
  minValues?: Record<string, number>;
}> = ({
  value,
  onChange,
  label,
  placeholder,
  menuOptions,
  resetValue,
  anchorValue,
  startEnhancer,
  metrics,
  autofocus,
  allowsNegativeValue = false,
  minValues,
}) => {
  return (
    <LengthInputSelector.Root
      className="w-full"
      value={value ?? ""}
      onChange={(value) => onChange?.(value || "")}
      metrics={metrics}
      minValues={minValues}
      allowsNegativeValue={allowsNegativeValue}
      anchorValue={anchorValue}
      resetValue={resetValue}
      field=""
    >
      <div className="flex flex-row items-center justify-center w-full">
        <LengthInputSelector.DraggableArea>
          <ModifierLabel label={label} />
        </LengthInputSelector.DraggableArea>
        <LengthInputSelector.Input
          startEnhancer={startEnhancer}
          menuOptions={menuOptions}
          placeholder={placeholder}
          autofocus={autofocus}
        />
      </div>
    </LengthInputSelector.Root>
  );
};

const getDisplayName = (
  fontValue: string | null,
  nameToDisplayName: Record<string, string | undefined>,
) => {
  if (!fontValue) {
    return undefined;
  }
  return nameToDisplayName[fontValue];
};
