// https://stackoverflow.com/questions/36633033/how-to-organize-typescript-interfaces?

import type {
  AlchemyActionType,
  ConditionFieldEditorValue,
  Operator,
} from "replo-runtime/shared/enums";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Action } from "schemas/actions";
import type { Component } from "schemas/component";
import type { metafieldTypes, productStatus } from "schemas/element";
import type { ReploElementType } from "schemas/generated/element";
import type { FlowInstance, FlowStepCondition } from "schemas/generated/flow";
import type { UploadedFont } from "schemas/generated/project";
import type { ConditionField, ReploState } from "schemas/generated/symbol";
import type { Workspace } from "schemas/generated/workspace";
import type { ProductRef } from "schemas/product";
import type {
  GradientStop,
  RuntimeStyleAttribute,
} from "schemas/styleAttribute";

import isObject from "lodash-es/isObject";

export type {
  DataTable,
  DataTableColumnTypes,
} from "replo-runtime/shared/DataTable";

export type LowercaseAlphabet =
  | "a"
  | "b"
  | "c"
  | "d"
  | "e"
  | "f"
  | "g"
  | "h"
  | "i"
  | "j"
  | "k"
  | "l"
  | "m"
  | "n"
  | "o"
  | "p"
  | "q"
  | "r"
  | "s"
  | "t"
  | "u"
  | "v"
  | "w"
  | "x"
  | "y"
  | "z";
export type Digits = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

export const DEVICE_FRAME_WIDTHS = {
  desktop: 1400 as const,
  tablet: 820 as const,
  mobile: 400 as const,
} satisfies Record<EditorCanvas, number>;

export type WidthOrHeight = "width" | "height";

export type ProductId = string | number;
export type VariantId = string | number;

export type SharedStatePayload<Value = any> = {
  key: string;
  value: Value | ((prevValue: Value) => Value);
};

export type SyncRuntimeStatePayload<Value = any> = {
  key: string;
  value: Value;
};

export type MarketplaceModalRequestType = "browse" | "create" | "share";

/**
 * Note (Mariano, 2022-06-01): This is meant to contain all the feature types available in our editor.
 */
export type FeatureType = "shopifyLiquid" | "shopifyAppBlocks";

interface ElementMetafieldCommon {
  shopifyId?: string;
  namespace: string;
  key: string;
}

export interface IntegerMetafield extends ElementMetafieldCommon {
  type: "number_integer";
  value: number;
}

export interface StringMetafield extends ElementMetafieldCommon {
  type: "string";
  value: string;
}

export interface BooleanMetafield extends ElementMetafieldCommon {
  type: "boolean";
  value: boolean;
}

export type ElementMetafield = ElementMetafieldCommon &
  (IntegerMetafield | StringMetafield | BooleanMetafield);

export interface ElementGenericMetafield {
  key: string;
  namespace: string;
  type: string;
  value: string | number | boolean;
  shopifyId?: string;
}

export type ElementMetafieldType = ElementMetafield["type"];

export interface Option {
  value: string;
  label: string;
}

export type PredefinedVariantType =
  | "hover"
  | "loading"
  | "outOfStock"
  | "activeTab"
  | "selectedOption"
  | "selectedVariant"
  | "selectedSellingPlan"
  | "collapsibleOpen"
  | "beforeAfterDragging"
  | "tooltipOpen"
  | "selectedListItem"
  | "firstItemIsActive"
  | "lastItemIsActive"
  | "currentItemIsActive";

export type PredefinedVariant = {
  name: string;
  query: VariantQuery;
  predefinedVariantType: PredefinedVariantType;
};

export interface ConditionStatement {
  id: string;
  field: ConditionField;
  operator?: Operator | null;
  value?: any;
}

export type VariantQuery = {
  type?: string;
  operator?: string;
  statements: ConditionStatement[];
};

export interface VariantWithState extends ReploState {
  isDefault: boolean;
  isActive: boolean;
}

export interface DraftVariant {
  id: string;
  name: string;
  query: {
    field: string;
  };
}

export type ProductIdsWithVariantIds = Record<string, string[] | null>;

/**
 * Given an action type, retrieve its value. I.e.:
 * ActionValueOf<AlchemyActionType.GoToItem> would be number, since
 * the `value` of the to to item action is number
 */
export type ActionValueOf<TargetType extends AlchemyActionType> = Extract<
  Action,
  { type: TargetType }
>["value"];

/**
 * Same as Action, except the `value` is optional.
 */
export type ActionWithNullableValue = {
  [K in Action["type"]]: { type: K; value: ActionValueOf<K> | undefined };
}[Action["type"]];

export interface AnimationField {
  id: string;
  type: string;
  name: string;
  placeholder?: string;
  options: {
    label: string;
    value: string;
    override?: { [key: string]: string };
  }[];
}

export interface BoxShadow {
  id: string;
  shadowType: "dropShadow" | "inset" | "none";
  offsetX: string;
  offsetY: string;
  blur: string;
  spread: string;
  shadowColor: string;
}

export type TextOutline = {
  width: string;
  color?: string;
};

export type RenderPreviewEnvironment = "componentPreview" | "editor";

// Note (Fran, 2023-03-09): We need this namespace because there are two
// versions of okendo widget and for each one we need to publish different
// liquid code. The window object is not accessible when we publish, so we
// need to pass this through as part of runtime context
export type OkendoNamespace = "okendoReviews" | "okeWidgetApi";

export interface RuntimeFeatureFlags {
  carouselV4: boolean;
  carouselDebug: boolean;
  dataEmDisableOnImages: boolean;
  noMirror: boolean;
  assetsRefresh: boolean;
}

export interface RenderComponentAttributes {
  ref: any;
  key: string;
  id?: string;
  "data-rid": string;
  "data-alchemy-carousel-id"?: string;
  "data-alchemy-url-hashmark"?: string;
  "data-alchemy-url-hashmark-offset"?: number;
  "data-alchemy-url-hashmark-ignore"?: boolean;
  "data-replo-repeated-index"?: string;
  "data-replo-price"?: boolean;
  "data-replo-compare-price"?: boolean;
  "data-replo-compare-percentage"?: boolean;
  "data-replo-compare-difference"?: boolean;
  "data-replo-selling-plan"?: boolean;
  "data-product-id"?: number | string;
  "data-variant-id"?: number | string;
  tabIndex?: number;
  role?: string;
  className?: string;
  "aria-expanded"?: boolean;
  "aria-controls"?: string;
  "aria-hidden"?: boolean;
  "aria-checked"?: boolean;
  "aria-labelledby"?: string;
  "aria-selected"?: boolean;
  "aria-current"?: boolean;
  "aria-label"?: string;

  style?: React.CSSProperties;

  onClick?: (e: any) => void;
  onScroll?: (e: any) => void;
  onMouseEnter?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

export interface RenderComponentProps {
  component: Component;
  context: Context;
  componentAttributes: RenderComponentAttributes;
  extraAttributes?: Partial<RenderComponentAttributes>;
  overrideComponentTagType?: "li"; // Can add more supported override tag types here as needed
}

export type DynamicStyleDataMap = Record<
  string,
  { key: RuntimeStyleAttribute; value: unknown; index: number }
>;

export type ProductStatus = (typeof productStatus)[number];

export interface StoreVariant {
  id: number;
  sku: string | null;
  title: string;
  option1: string;
  option2?: string | null;
  option3?: string | null;
  available: boolean;
  name: string;
  price: string;
  compare_at_price: string | null;
  featured_image?: { src: string } | null;
  selling_plan_allocations?: {
    selling_plan_id: number;
    selling_plan_group_id: string;
  }[];
  selling_plan_ids?: number[];
  selling_plan_group_ids?: string[];
}

export interface StoreSellingPlanOptionDefinition {
  name: string;
  position: number;
  values: (string | null)[];
}

export interface StoreSellingPlanOption {
  name?: string | undefined;
  position: number;
  value: string;
}

// https://shopify.dev/api/liquid/objects#selling_plan_checkout_charge
export interface StoreSellingPlanCheckoutCharge {
  type: "percentage" | "price";
  value: number;
}

// https://shopify.dev/api/liquid/objects#selling_plan_price_adjustment
export interface StoreSellingPlanPriceAdjustment {
  value: number;
  value_type: "percentage" | "fixed_amount" | "price";
}

export interface StoreSellingPlan {
  id: number;
  name: string;
  description: string | null;
  options: StoreSellingPlanOption[];
  price_adjustments: StoreSellingPlanPriceAdjustment[];
}

export interface StoreSellingPlanGroup {
  id: string;
  app_id: string | null;
  options: StoreSellingPlanOptionDefinition[];
  selling_plans: StoreSellingPlan[];
}

export interface StoreProduct {
  title: string;
  images: string[];
  id: number | string;
  type: string;
  status: ProductStatus;
  variants: StoreVariant[];
  options: string[];
  featured_image: string | null;
  description: string;
  handle: string;
  // Note (Noah, 2022-08-19, REPL-3674): Apparently, selling plan groups can be undefined
  // in liquid objects
  selling_plan_groups?: StoreSellingPlanGroup[];
}

export interface LiquidProduct {
  title: string;
  images: string[];
  id: number;
  variants: StoreVariant[];
  options: string[];
  featured_image: string | null;
  description: string;
  handle: string;
  type: string;
  selling_plan_groups?: StoreSellingPlanGroup[];
  status: ProductStatus;
}

// NOTE (Matt, 2023-09-15): We need to reference this list of Price Labels in alchemy-component.ts.
export type VariantPriceLabels = keyof VariantPriceTypes;

interface VariantPriceTypes {
  price: string;
  priceRounded: string;
  priceWithoutSellingPlanDiscount: string;
  priceWithoutSellingPlanDiscountRounded: string;
  displayPrice: string;
  displayPriceWithoutQuantity: string;
  displayPriceWithoutSellingPlanDiscount: string;
  displayPriceRounded: string;
  compareAtPrice: string | null;
  compareAtPriceRounded: string | null;
  compareAtDisplayPrice: string | null;
  compareAtDisplayPriceRounded: string | null;
  compareAtPriceDifference: string | null;
  compareAtPriceDifferencePercentage: string | null;
  compareAtPriceDifferenceRounded: string | null;
  compareAtDisplayPriceDifference: string | null;
  compareAtDisplayPriceDifferenceRounded: string | null;
}

export interface ReploShopifyVariant extends VariantPriceTypes {
  id: number;
  // HACK (Noah, 2021-07-28): The variant needs to have variantId and productId
  // because of how the context resolution for dynamic data works - when there's
  // a variant, we treat it as a productRef and get the variant again, since it
  // calls into the same codepath that happens if it's a non-dynamic productRef.
  // There is probably a better way to do this
  variantId: number;
  productId: string | number;
  sku: string | null;
  name: string;
  option1: string;
  option2?: string | null;
  option3?: string | null;
  title: string;
  available: boolean;
  featuredImage: string | null;
  productHandle: string;
  variantMetafields?: MetafieldValuesMappingWithoutType;
  sellingPlanIds: number[];
  sellingPlanGroupIds: string[];
}

export type ReploShopifyOptionKey = "option1" | "option2" | "option3";
export interface ReploShopifyOptionValue {
  title: string;
  available?: boolean;
}
export interface ReploShopifyOption {
  key: ReploShopifyOptionKey;
  name: string;
  values?: ReploShopifyOptionValue[];
}

export type SelectedOptionValuesMapping = Record<string, string | null>;

export interface ShopifySellingPlanPriceAdjustment {
  value: number;
  value_type: "percentage" | "fixed_amount" | "price";
}

export interface ShopifySellingPlan {
  id: number | string;
  name: string;
  description: string | null;
  options: StoreSellingPlanOption[];
  priceAdjustments: ShopifySellingPlanPriceAdjustment[];
  appId?: string | null;
}

export interface ShopifySellingPlanGroup {
  id: string;
  appId: string | null;
  options: StoreSellingPlanOptionDefinition[];
  sellingPlans: ShopifySellingPlan[];
}

export interface ReploShopifyProduct {
  id: number;
  // NOTE (Noah, 2021-07-24): It's weird to have productId here but currently
  // we need it because some places (like Product components inside Product List
  // components) assume that ProductRefs (productId, variantId) are passed down
  // in component context, but in actuality it's the full product that is passed
  // down. In the future hopefully we can think of some way around this, it's
  // pretty weird
  productId: string | number;
  title: string;
  type: string | undefined;
  images: string[];
  status: ProductStatus;
  featured_image: string | null;
  variants: ReploShopifyVariant[];
  variant: ReploShopifyVariant;
  variantId: number;
  options: ReploShopifyOption[];
  /**
   * Names of the options.
   *
   * @deprecated (Noah, 2023-12-29, REPL-9856): Use `options` instead
   *
   * This unfortunately can't be renamed without a migration since it's used in
   * dynamic data. We should remove it in the future after updating any dynamic
   * data in elements on prod
   */
  optionsValues: string[];
  description: string;
  handle: string;
  quantity: number;
  productMetafields?: MetafieldValuesMappingWithoutType;
  sellingPlanGroups: ShopifySellingPlanGroup[];
}

export interface Bounds {
  top: number | string;
  left: number | string;
  width: number | string;
  height: number | string;
}

export interface Edges {
  top: number | string;
  left: number | string;
  right: number | string;
  bottom: number | string;
}

export interface NumericBounds {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface BoundedComponent {
  bounds: NumericBounds;
  component: Component;
}

export interface Position {
  x: number;
  y: number;
}

export interface Range {
  lower: number;
  upper: number;
}

export interface ReploPriceRule {
  id: any;
  allocationMethod: "each" | "across";
  entitledProductIds?: (string | number)[];
  entitledVariantIds?: number[];
  allProductsAreEntitled: boolean;
  prerequisiteQuantityRange?: {
    greaterThanOrEqualTo: number;
  };
  prerequisiteSubtotalRange?: {
    greaterThanOrEqualTo: number;
  };
  prerequisiteToEntitlementPurchase?: {
    amount: number;
  };
  prerequisiteProductIds?: (string | number)[];
  prerequisiteVariantIds?: number[];
  targetSelection: "entitled" | "all";
  targetType: "lineItem" | "shippingLine";
  title: string;
  value: number;
  valueType: "fixedAmount" | "percentage";
}

export type MetafieldType = (typeof metafieldTypes)[number];

export type MetafieldEntityTypes = "variant" | "product";

export type MetafieldNamespace = string;
export type MetafieldKey = string;
export interface MetafieldDefinition {
  key: MetafieldKey;
  name: string;
  namespace: MetafieldNamespace;
  id: string;
  type: MetafieldType;
}

export interface MetafieldValue {
  key: MetafieldKey;
  value: string;
  namespace: MetafieldNamespace;
  type: MetafieldType;
}

export type MetafieldValuesMapping = Record<
  MetafieldNamespace,
  Record<MetafieldKey, { type: MetafieldType; value: string | number }>
>;
export type MetafieldValuesMappingWithoutType = Record<
  MetafieldNamespace,
  Record<MetafieldKey, string | number>
>;
export type ProductMetafieldMapping = Record<ProductId, MetafieldValuesMapping>;
export type VariantMetafieldMapping = Record<
  StoreVariant["id"],
  MetafieldValuesMapping
>;

export interface MetafieldsMappings {
  productMetafieldsMapping: ProductMetafieldMapping;
  variantMetafieldsMapping: VariantMetafieldMapping;
}

export interface ReferralKey {
  id: string;
  code: string;
}

export interface Project {
  id: string;
  name?: string;
  /**
   * myshopify.com URL of the store (every shopify store has this)
   */
  shopifyUrl: string;

  /**
   * Custom domain for serving Turbo + A-B testing experiments.
   */
  customDomain?: string | undefined;

  /**
   *
   * Custom slug for serving Turbo + A-B testing experiments.
   */
  slug: string;

  /**
   * Public URL of the store
   */
  url: string;

  /**
   * When the store was created (ISO 8601)
   */
  createdAt: string;

  /**
   * Key indicating who the store was referred by, if any
   */
  referrerKey?: ReferralKey;

  /**
   * Current oauth access scopes for the Shopify app, useful for when we need
   * to gate access to features which require re-authorization when we add new scopes
   */
  shopifyAccessScopes?: string[];

  /**
   * Tenancy of the store (should only be used for analytics - if you want to
   * use a local development bundle on a store, add to developmentStores instead)
   */
  tenancy: "testing" | "production";

  /**
   * Custom fonts uploaded by the user
   */
  uploadedFonts?: UploadedFont[];
}

/**
 * This needs to be a 1...N with Workspaces in the future, currently it's just a
 * simple object used to submit the Paypal email
 */
export interface PaymentMethod {
  // method: "paypal";
  // type: "disbursement";
  email: string;
  workspaceId: Workspace["id"];
}

/**
 * This needs to be a 1...N with Workspaces in the future, currently it's just a
 * simple object used to submit the agreement form
 */
export interface PartnershipAgreement {
  isAgreed: boolean;
  workspaceId: Workspace["id"];
}

export interface ReferralCode {
  id: string;
  workspaceId: string;
  code: string;
  createdAt: string;
  revenueSharePercentage: number;
}

export type User = {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  name?: string | null;
  heardFrom: string[];
  profileType: string | null;
  registeredAt: Date | null;
  verifiedAt: Date | null;
  isSuperuser: boolean;
  referrerKey: string | null;
  source: "shopify" | "website" | null;
  authType: string | null;
  createdAt: Date;
  updatedAt: Date;
  lastLogin: Date | null;
  referralCodeId: string | null;
  deletedAt: Date | null;
  workspace?: {
    id: string;
    isOnboarded: boolean;
    isApproved: boolean;
  } | null;
  flowInstances?:
    | {
        instance: FlowInstance;
        nextStep: string | FlowStepCondition[] | null;
      }[]
    | null;
  referralCode?: {
    id: string;
    code: string;
  } | null;
};

export interface EditVariantConditionValueProps {
  type: ConditionFieldEditorValue;
  value: ConditionStatement["value"];
  onChange: (value: any) => void;
  placeholder: string | null;
}

export interface ShopifyBlog {
  id: string;
  handle: string;
  name: string;
  template_suffix?: string;
  tags?: string;
}

export interface ShopifyContent {
  id: string;
  handle: string;
  name: string;
  kind: ReploElementType;
  templateSuffix?: string;
  tags?: string;
  author?: string;
  blogId?: string;
  bodyHtml: string;
  publishedAt?: string;
}

export type EditorPropType =
  | "index"
  | "visibility"
  | "collapsibility"
  | "interactions"
  | "activeBeforeAfterState";

/**
 * Types of data dependencies that a component may have
 */
// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum DependencyType {
  metafields = "metafields",
  products = "products",
  dataTable = "dataTable",
}

/**
 * Types of entities that a component may depend on for metafield values
 */
// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum MetafieldEntityType {
  product = "product",
  variant = "variant",
}

/**
 * Generic interface for metafield dependency
 */
export interface GenericMetafieldDependency {
  type: DependencyType.metafields;
  productHandle: string;
  metafieldKeys: {
    key: string;
    namespace: string;
    type: string;
    value?: string | number | boolean;
  }[];
}

/**
 * A dependency on data from a product metafield
 */
export interface ProductMetafieldsDependency
  extends GenericMetafieldDependency {
  entityType: MetafieldEntityType.product;
  productId: string;
}

/**
 * A dependency on data from a variant metafield
 */
export interface VariantMetafieldsDependency
  extends GenericMetafieldDependency {
  entityType: MetafieldEntityType.variant;
  variantId: string;
}

/**
 * Dependency on a metafield (from unspecified type, different types may have
 * different fields)
 */
export type MetafieldsDependency =
  | ProductMetafieldsDependency
  | VariantMetafieldsDependency
  | never;

export interface ProductsDependency {
  type: DependencyType.products;
  productIds: (string | number)[];
}

export interface DataTableDependency {
  type: DependencyType.dataTable;
  dataTableId: string;
}

/**
 * A generalized dependency. Depending on type, may have different fields
 */
export type Dependency =
  | MetafieldsDependency
  | ProductsDependency
  | DataTableDependency
  | never;

export type Dependencies = Record<string, Dependency[]>;

export interface LinkData {
  url: string;
  isUnderlined: boolean;
  isNewTab: boolean;
}

export interface Referral {
  referrerId?: string;
  firstName: string;
  lastName: string;
  url: string;
  email: string;
  title: string;
  additionalInformation?: string;
  isReachoutOk: boolean;
}

// Note (Chance 2023-07-14) Gradients must have at least 1 stop
export type GradientStops = [GradientStop, ...GradientStop[]];

export interface Gradient {
  tilt: string;
  stops: GradientStop[];
}

export type SolidValue = { type: "solid"; color?: string | null };
export type GradientValue = { type: "gradient"; gradient: Gradient };
export type SolidOrGradient = SolidValue | GradientValue;

export const isSolidValue = (value: SolidOrGradient): value is SolidValue => {
  return value.type === "solid" && value.color !== null;
};

export const isGradient = (value: any): value is Gradient => {
  return (
    isObject(value) &&
    value.hasOwnProperty("tilt") &&
    value.hasOwnProperty("stops")
  );
};

export type GradientOrSolidOnChangeProps =
  | {
      allowsGradientSelection: false;
      onPreviewChange?(value: string | null): void;
      onChange(value: string | null): void;
      value: string | ReploMixedStyleValue | null;
    }
  | {
      allowsGradientSelection: true;
      onPreviewChange?(value: SolidOrGradient): void;
      onChange(value: SolidOrGradient): void;
      value: SolidOrGradient | ReploMixedStyleValue | null;
    };

export interface ColorValue {
  type: "color";
  value?: SolidOrGradient | ReploMixedStyleValue;
}

export interface SwatchImage {
  src: string;
  id?: string;
}

export interface SwatchValue {
  color?: SolidOrGradient;
  image?: SwatchImage;
  imageList?: SwatchImage[];
}

// TODO (Gabe 2023-10-09): This is duplicated from
// apps/replo-publisher/src/types/swatch.ts, updates here need to be made to the
// schema as well.
export interface SwatchOptionRow {
  id: string;
  key: {
    // NOTE (Gabe 2023-10-09): productId is optional because it used to not
    // exist which made having multi product swatches difficult. In order to not
    // break existing swatches it is optional and only used to uniquely identify
    // swatch values when it is present.
    productId?: number | string;
    name: string;
    value: string;
  };
  label: string;
  value?: SwatchValue;
}

export interface SwatchVariantRow {
  id: string;
  key: {
    productId: string | number;
    variantId: string | number;
  };
  label: string;
  value?: SwatchValue | null;
}

export type SwatchType = "option" | "variant";

export interface SwatchData {
  type: SwatchType;
  productIds?: (string | number)[];
  options?: SwatchOptionRow[];
  variants?: SwatchVariantRow[];
}

export interface Swatch {
  id: string;
  name: string;
  data: SwatchData;
}

export interface PartialSwatch {
  id?: string;
  name?: string;
  data?: Partial<SwatchData>;
}

export interface ShopifyComponentCommonProps {
  component?: Component;
  componentId: string;
  componentAttributes: RenderComponentAttributes;
  isLiquidSupported: boolean;
}

export interface BaseSharedLiquidProps {
  attributes: RenderComponentAttributes;
  component: Component;
  context: Context;
}

export interface SharedLiquidReviewsProps extends BaseSharedLiquidProps {
  liquidSource: string;
  reviewsComponentType: "Product Rating" | "Reviews";
}

export interface AddProductVariantToCartEditorPropsValue {
  product: ProductRef;
  quantity?: number | string;
  redirectToCart: boolean;
  redirectToCheckout?: boolean;
  sellingPlanId?: number | string | null;
  allowThirdPartySellingPlan?: boolean;
}
