import * as React from "react";

import { Checkbox } from "@replo/design-system/components/shadcn/core/checkbox";
import classNames from "classnames";

type CheckboxCalloutProps = {
  title: string;
  description?: string | React.ReactNode;
  isChecked: boolean;
  onCheckedChange: (checked: boolean) => void;
  children?: React.ReactNode;
};

export const CheckboxCallout = ({
  title,
  description,
  isChecked,
  onCheckedChange,
  children,
}: CheckboxCalloutProps) => (
  <label
    className={classNames(
      "px-3 py-3 rounded flex border-0.5 items-center gap-2.5",
      isChecked ? "border-primary bg-selectable-selected" : "border-border",
    )}
  >
    <div className="h-full py-1">
      <Checkbox checked={isChecked} onCheckedChange={onCheckedChange} />
    </div>

    <div className="flex flex-col gap-2 cursor-pointer w-full">
      <div className="text-default text-sm font-medium">{title}</div>
      <div className="text-muted text-sm">{description}</div>
      {children}
    </div>
  </label>
);
