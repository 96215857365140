import type { SavedStyleOpenedFrom } from "@editor/components/designLibrary/TextSavedStyleModifier";
import type { SolidOrGradient, SolidValue } from "replo-runtime/shared/types";
import type { SavedColorStyle } from "schemas/generated/designLibrary";
import type {
  SavedStyleColorAttributes,
  SavedStyleColorType,
} from "schemas/generated/savedStyles";

import * as React from "react";

import { COLOR_MENU_ITEMS } from "@common/constants/colorStyles";
import GradientPicker from "@common/designSystem/color/GradientPicker";
import SolidColorPicker from "@common/designSystem/color/SolidColorPicker";
import { DebouncedInput } from "@common/designSystem/Input";
import Selectable from "@common/designSystem/Selectable";
import Separator from "@common/designSystem/Separator";
import ToggleGroup from "@common/designSystem/ToggleGroup";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import useUpdateDesignLibrarySavedStyle from "@editor/hooks/designLibrary/useUpdateDesignLibrarySavedStyle";

import Popover from "@replo/design-system/components/popover";
import {
  cssGradientToGradient,
  gradientToCssGradient,
  isCssGradient,
} from "replo-runtime/shared/utils/gradient";

type ColorSavedStyleModifierValues = {
  id: string;
  name: string;
  attributes: SavedStyleColorAttributes;
};

type ColorSavedStyleModifierProps = {
  isPopoverOpen: boolean;
  onPopoverOpenChange: (isOpen: boolean) => void;
  editingSavedStyle: (SavedColorStyle & { id: string }) | null;
  sideOffset?: number;
  openedFrom?: SavedStyleOpenedFrom;
};

const COLOR_TYPE_OPTIONS = COLOR_MENU_ITEMS.map((item) => ({
  value: item.colorType,
  label: item.title,
}));

export const ColorSavedStyleModifier: React.FC<
  React.PropsWithChildren<ColorSavedStyleModifierProps>
> = ({
  isPopoverOpen,
  onPopoverOpenChange,
  editingSavedStyle,
  sideOffset,
  openedFrom,
}) => {
  const { updateSavedStyle } = useUpdateDesignLibrarySavedStyle();

  const colorValue = getColorValue(
    editingSavedStyle?.attributes.color ?? "#000000",
  );

  const isGradient = colorValue.type === "gradient";
  const [toggleGroupValue, setToggleGroupValue] = React.useState<
    "solid" | "gradient"
  >(isGradient ? "gradient" : "solid");

  const handleUpdateSavedStyle = React.useCallback(
    (values: ColorSavedStyleModifierValues) => {
      void updateSavedStyle({
        savedStyleId: values.id,
        attributes: values.attributes,
        name: values.name,
      });
    },
    [updateSavedStyle],
  );

  if (!editingSavedStyle) {
    return null;
  }

  return (
    <Popover isOpen={isPopoverOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside
        side={openedFrom === "leftBar" ? "right" : "left"}
        align="start"
        sideOffset={sideOffset}
        className="p-2 w-[250px]"
        title="Edit Color Style"
        onInteractOutside={() => {
          onPopoverOpenChange(false);
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <label htmlFor="name" className="text-xs font-medium">
              Name
            </label>
            <DebouncedInput
              id="name"
              placeholder="Name"
              value={editingSavedStyle.name}
              onValueChange={(value) => {
                handleUpdateSavedStyle({
                  ...editingSavedStyle,
                  name: value,
                });
              }}
            />
          </div>
          <div className="flex items-center gap-1">
            <ModifierLabel label="Tag" />
            <Selectable
              id="colorType"
              options={COLOR_TYPE_OPTIONS}
              value={editingSavedStyle.attributes.colorType}
              onSelect={(colorType) => {
                if (colorType) {
                  handleUpdateSavedStyle({
                    ...editingSavedStyle,
                    attributes: {
                      ...editingSavedStyle.attributes,
                      colorType: colorType as SavedStyleColorType,
                    },
                  });
                }
              }}
            />
          </div>
          <Separator />
          <div className="flex flex-col gap-2 w-full">
            <ToggleGroup
              allowsDeselect={false}
              type="single"
              style={{ width: "100%" }}
              options={[
                { label: "Solid", value: "solid" },
                { label: "Gradient", value: "gradient" },
              ]}
              value={toggleGroupValue}
              onChange={(value) => {
                setToggleGroupValue(value as "solid" | "gradient");
              }}
            />
            {toggleGroupValue === "solid" ? (
              <SolidColorPicker
                value={(colorValue as SolidValue)?.color ?? null}
                onChange={(value) => {
                  handleUpdateSavedStyle({
                    ...editingSavedStyle,
                    attributes: {
                      ...editingSavedStyle.attributes,
                      color: value ?? "#000000",
                    },
                  });
                }}
              />
            ) : (
              <GradientPicker
                value={
                  colorValue.type === "solid"
                    ? {
                        type: "gradient",
                        gradient: {
                          tilt: "90deg",
                          stops: [
                            {
                              id: "1",
                              color: colorValue.color ?? "#000000",
                              location: "0%",
                            },
                          ],
                        },
                      }
                    : colorValue
                }
                onChange={(value) => {
                  if ("gradient" in value) {
                    const color = getSavedStyleColorValue(value);
                    if (color) {
                      handleUpdateSavedStyle({
                        ...editingSavedStyle,
                        attributes: {
                          ...editingSavedStyle.attributes,
                          color,
                        },
                      });
                    }
                  }
                }}
              />
            )}
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="top-0 left-0" />
    </Popover>
  );
};

function getColorValue(color: string): SolidOrGradient {
  if (isCssGradient(color)) {
    return {
      type: "gradient",
      gradient: cssGradientToGradient(color),
    };
  }
  return {
    type: "solid",
    color,
  };
}

function getSavedStyleColorValue(color: SolidOrGradient) {
  if (color.type === "gradient") {
    return gradientToCssGradient(color.gradient);
  }
  return color.color;
}
