import type { Component } from "schemas/component";
import type { ChatMessage } from "schemas/generated/ai";

import * as React from "react";

import { Textarea } from "@replo/design-system/components/textarea";
import { ToggleGroup } from "@editor/components/common/designSystem/ToggleGroup";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { getTokenFromStorage } from "@editor/infra/auth";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import { selectLastAIResponse } from "@editor/reducers/ai-reducer";
import {
  selectDraftComponent,
  selectProjectId,
  selectRootComponent,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { BsArrowReturnLeft, BsMagic } from "react-icons/bs";
import { FaTerminal } from "react-icons/fa";
import { getPublisherUrl } from "replo-runtime/shared/config";

import { AIProgressIndicator } from "./AIProgressIndicator";
import { PromptModal } from "./PromptModal";

type Prompts = {
  systemPrompt: string;
  initialPrompt: string;
};

type GenerationType = "design" | "template";

function renderUserContent(
  content: string | { type: "text" | "image"; text?: string; image?: any }[],
): React.ReactNode {
  if (typeof content === "string") {
    return content;
  }
  return content.map((item, index) => {
    if (item.type === "text") {
      return <span key={index}>{item.text}</span>;
    }
    if (item.type === "image") {
      return <img key={index} src={item.image} alt="" />;
    }
    return null;
  });
}

export const AIChat: React.FC = () => {
  const lastAIResponse = useEditorSelector(selectLastAIResponse);
  const [enableProductImages, setEnableProductImages] = React.useState(true);
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);
  const [currentMessage, setCurrentMessage] = React.useState("");
  const [nonce, setNonce] = React.useState(0);
  React.useState<Component | null>(null);
  const [prompts, setPrompts] = React.useState<Prompts | null>(null);
  const [editedPrompts, setEditedPrompts] = React.useState<Prompts | null>(
    null,
  );
  const { initiateGeneration, status, completionPercentage, setIsMenuOpen } =
    useAIStreaming();
  const store = useEditorStore();
  const [showPromptModal, setShowPromptModal] = React.useState(false);
  const [prevDraftComponent, setPrevDraftComponent] =
    React.useState<Component | null>(null);
  const draftComponent = selectDraftComponent(store.getState());
  const [generationType, setGenerationType] =
    React.useState<GenerationType>("template");

  const projectId = selectProjectId(store.getState());

  const isGenerating =
    status === "generating" || status === "generationInitialized";
  // Patrick (2025-02-10): We're not using this toggle group anymore, but we're keeping it here in case we want to use it again in the future.
  const _typeOptions = [
    { label: "Design", value: "design" },
    { label: "Template", value: "template" },
  ];

  const resetChat = React.useCallback(() => {
    setMessages([]);
    setNonce(0);
  }, []);

  const messagesContainerRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = React.useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, []);

  React.useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);

  React.useEffect(() => {
    if (draftComponent && draftComponent.id !== prevDraftComponent?.id) {
      resetChat();
    }
    setPrevDraftComponent(draftComponent);
  }, [draftComponent, prevDraftComponent?.id, resetChat]);

  React.useEffect(() => {
    const generationType = window.localStorage.getItem("generationType");
    if (generationType) {
      setGenerationType(generationType as GenerationType);
    }

    const savedenableProductImages = window.localStorage.getItem(
      "enableProductImages",
    );
    if (savedenableProductImages !== null) {
      setEnableProductImages(savedenableProductImages === "true");
    }
  }, []);

  React.useEffect(() => {
    if (!lastAIResponse) {
      return;
    }

    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];

      const aiMessage: ChatMessage = {
        role: "assistant",
        content: lastAIResponse,
      };

      if (lastMessage?.role === "user") {
        return [...prevMessages, aiMessage];
      } else {
        const msgs = [...prevMessages];
        msgs[msgs.length - 1] = aiMessage;
        return msgs;
      }
    });
  }, [lastAIResponse]);

  const fetchPrompt = React.useCallback(
    async (message: string) => {
      const rootComponent = selectRootComponent(store.getState());

      try {
        const token = getTokenFromStorage();
        const response = await fetch(`${getPublisherUrl()}/api/v1/ai/prompts`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({
            userPrompt: message,
            component: draftComponent,
            page: rootComponent,
            projectId,
            mode: generationType,
            enableProductImages,
            sectionType:
              generationType === "template"
                ? draftComponent?.name ?? "section"
                : undefined,
          }),
        });
        const data = await response.json();
        setPrompts(data);
      } catch (error) {
        console.error("Failed to fetch prompt:", error);
      }
    },
    [projectId, store, draftComponent, generationType, enableProductImages],
  );

  React.useEffect(() => {
    void fetchPrompt("");
  }, [fetchPrompt]);

  const handleInputChange = (value: string) => {
    setCurrentMessage(value);
    void fetchPrompt(value);
  };

  const togglePromptView = () => {
    setShowPromptModal(!showPromptModal);
    if (!showPromptModal) {
      void fetchPrompt(currentMessage);
    }
  };

  const handleSubmit = async () => {
    if (!currentMessage.trim() || isGenerating) {
      return;
    }

    // Add user message to chat
    setMessages((prev) => [...prev, { role: "user", content: currentMessage }]);
    // Clear input
    setCurrentMessage("");
    setIsMenuOpen(true, "previewMenu");

    // Initiate AI generation
    await initiateGeneration({
      type: generationType,
      nonce,
      userPrompt: currentMessage,
      conversationMessages: messages,
      enableProductImages,
      systemPrompt: editedPrompts?.systemPrompt ?? prompts?.systemPrompt,
      initialPrompt: editedPrompts?.initialPrompt ?? prompts?.initialPrompt,
      sectionType:
        generationType === "template"
          ? draftComponent?.name ?? "section"
          : undefined,
    });

    setNonce(nonce + 1);
  };

  useReploHotkeys({
    enter: void handleSubmit,
  });

  return (
    <div className="flex flex-col h-[calc(100%-25px)]">
      {/* Messages Container */}
      <div
        ref={messagesContainerRef}
        className="flex-1 overflow-y-auto p-4 space-y-4"
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={classNames("p-3 rounded-lg max-w-[80%]", {
              "bg-slate-100 ml-auto": message.role === "user",
              "bg-ai/10 mr-auto": message.role === "assistant",
            })}
          >
            {renderUserContent(message.content)}
          </div>
        ))}

        {isGenerating && (
          <div className="flex items-center gap-2 text-ai">
            <BsMagic className="animate-pulseShallow" />
            <span>AI is thinking...</span>
          </div>
        )}
      </div>

      {/* Progress Indicator */}
      {isGenerating && (
        <AIProgressIndicator completionPercentage={completionPercentage ?? 0} />
      )}

      {/* Input Area */}
      <div className="border-t p-4 space-y-2">
        <div className="flex justify-end mb-2 gap-2">
          <ToggleGroup
            type="single"
            options={[
              { label: "Use Images", value: "true" },
              { label: "No Images", value: "false" },
            ]}
            value={enableProductImages ? "true" : "false"}
            onChange={(value) => {
              const newValue = value === "true";
              setEnableProductImages(newValue);
              window.localStorage.setItem(
                "enableProductImages",
                String(newValue),
              );
            }}
            allowsDeselect={false}
            size="sm"
            className="!self-end"
          />
          {/* Patrick (2025-02-10): We're not using this toggle group anymore, but we're keeping it here in case we want to use it again in the future. */}
          {/* <ToggleGroup
            type="single"
            options={typeOptions}
            value={generationType}
            onChange={(value) => {
              window.localStorage.setItem("generationType", value);
              resetChat();
              setGenerationType(value as GenerationType);
            }}
            allowsDeselect={false}
            size="sm"
            className="!self-end"
          /> */}
        </div>

        <Textarea
          value={currentMessage}
          onChange={handleInputChange}
          placeholder={`What updates do you want to make?${
            generationType === "template" ? " (Template Mode)" : ""
          }`}
          onEnter={() => void handleSubmit()}
          layoutClassName="w-full"
          size="base"
          textLength="short"
          isDisabled={isGenerating}
        />

        <div className="flex justify-between items-center">
          <FaTerminal
            className={classNames(
              "cursor-pointer text-gray-400 hover:text-gray-600 transition-colors",
              showPromptModal && "text-gray-600",
            )}
            onClick={togglePromptView}
            size={18}
          />
          <Button
            variant="primary"
            size="sm"
            onClick={() => void handleSubmit()}
            disabled={!currentMessage.trim() || isGenerating}
          >
            <div className="flex items-center gap-2">
              Send
              <BsArrowReturnLeft />
            </div>
          </Button>
        </div>
      </div>

      <PromptModal
        isOpen={showPromptModal}
        onClose={() => setShowPromptModal(false)}
        prompts={prompts}
        component={draftComponent}
        onPromptsChange={setEditedPrompts}
      />
    </div>
  );
};
