import type { ReploElement } from "schemas/generated/element";

import * as React from "react";
import { useCallback, useState } from "react";

import Modal from "@editor/components/common/designSystem/Modal";
import { withElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import { useArchiveElement } from "@editor/hooks/useArchiveElement";
import { useModal } from "@editor/hooks/useModal";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";

import * as RadioGroup from "@radix-ui/react-radio-group";
import Button from "@replo/design-system/components/button";
import { BsFillCircleFill } from "react-icons/bs";

import { elementTypeToEditorData } from "../editor/element";

type ArchiveElementModalProps = {
  element: ReploElement;
};

export const ArchiveElementModal: React.FC<ArchiveElementModalProps> =
  withElementEditorErrorContext(({ element }) => {
    const modal = useModal();
    const [shouldUnpublish, setShouldUnpublish] = useState(false);
    const { path } = usePublishedInfo();
    const archiveElement = useArchiveElement();

    const onSubmit = useCallback(() => {
      archiveElement(element, shouldUnpublish, {
        from: "modal",
        onSuccess: () => {
          modal.closeModal({ type: "archiveElementModal" });
        },
      });
    }, [archiveElement, element, modal, shouldUnpublish]);

    return (
      <Modal
        isOpen={true}
        onRequestClose={() => modal.closeModal({ type: "archiveElementModal" })}
        closesOnOverlayClick={true}
        className="w-[490px]"
        onEnterKeyPress={onSubmit}
      >
        <div className="no-scrollbar max-h-[85vh] overflow-y-auto">
          <div className="flex flex-col gap-2">
            <p className="text-default text-lg font-medium">
              {`This ${elementTypeToEditorData[element.type].singularDisplayName} is Published`}
            </p>
            <p className="text-muted text-sm">
              {`${elementTypeToEditorData[element.type].singularDisplayName} cannot be archived while published. Unpublish it before archiving.`}
            </p>
            <div>
              <div className="border border-muted-foreground rounded-md px-4 py-3 flex flex-col gap-2">
                <div className="flex items-start gap-2">
                  <BsFillCircleFill className="text-green-400 mt-2" size={8} />
                  <div className="flex flex-col gap-1">
                    {element.name}
                    <p className="text-xs text-subtle">{path}</p>
                  </div>
                </div>
              </div>

              <RadioGroup.Root
                value={shouldUnpublish ? "unpublish" : "keep"}
                onClick={() => setShouldUnpublish(!shouldUnpublish)}
                className="flex flex-col gap-2 mt-4"
              >
                <div className="flex items-start gap-2">
                  <RadioGroup.Item
                    value="unpublish"
                    id="unpublish"
                    className="w-4 h-4 mt-1 rounded-full border border-muted bg-white data-[state=checked]:bg-primary data-[state=checked]:border-primary relative"
                  >
                    <RadioGroup.Indicator className="absolute inset-0 flex items-center justify-center after:block after:w-2 after:h-2 after:rounded-full after:bg-white" />
                  </RadioGroup.Item>
                  <label htmlFor="unpublish">
                    <span className="text-default">Select to confirm</span>
                    <p className="text-muted text-sm">Required to archive</p>
                  </label>
                </div>
              </RadioGroup.Root>
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-row justify-end gap-x-2">
          <Button
            size="lg"
            variant="secondary"
            type="button"
            className="w-24"
            onClick={() => modal.closeModal({ type: "archiveElementModal" })}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            variant="primary"
            type="submit"
            className="w-24"
            isLoading={false}
            data-testid="archive-submit-button"
            disabled={!shouldUnpublish}
            onClick={onSubmit}
          >
            Archive
          </Button>
        </div>
      </Modal>
    );
  });
