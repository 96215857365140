import type { MenuItem } from "@editor/components/common/designSystem/Menu";

import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import { Menu, MenuTrigger } from "@editor/components/common/designSystem/Menu";
import Separator from "@editor/components/common/designSystem/Separator";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import Label from "@replo/design-system/components/label";
import Popover from "@replo/design-system/components/popover";
import twMerge from "@replo/design-system/utils/twMerge";
import { format } from "date-fns";
import { BsThreeDots } from "react-icons/bs";
import { LuImageOff, LuPenLine, LuTrash } from "react-icons/lu";
import { formatFileSize } from "replo-utils/lib/misc";

import AssetCardDragOverlay from "./AssetCardDragOverlay";

type AssetCardState = {
  isBroken: boolean;
  isPopoverOpen: boolean;
  isMenuOpen: boolean;
  actionPopoverContent: "delete" | "rename" | null;
};

type AssetCardAction =
  | { type: "setIsBroken"; payload: boolean }
  | { type: "setIsPopoverOpen"; payload: boolean }
  | { type: "setIsMenuOpen"; payload: boolean }
  | { type: "setActionPopoverContent"; payload: "delete" | "rename" | null };

const initialState: AssetCardState = {
  isBroken: false,
  isPopoverOpen: false,
  isMenuOpen: false,
  actionPopoverContent: null,
};

const reducer = (
  state: AssetCardState,
  action: AssetCardAction,
): AssetCardState => {
  switch (action.type) {
    case "setIsBroken":
      return { ...state, isBroken: action.payload };
    case "setIsPopoverOpen":
      return { ...state, isPopoverOpen: action.payload };
    case "setIsMenuOpen":
      return { ...state, isMenuOpen: action.payload };
    case "setActionPopoverContent":
      return { ...state, actionPopoverContent: action.payload };
    default:
      return state;
  }
};

type AssetCardProps = {
  id: string;
  name: string;
  url: string;
  width?: number | null;
  height?: number | null;
  fileExtension?: string;
  sizeBytes?: number;
  createdAt?: Date;
  type: "image" | "video" | "font" | "other";
};

const AssetCard: React.FC<{ asset: AssetCardProps }> = ({ asset }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { isBroken, isPopoverOpen, isMenuOpen, actionPopoverContent } = state;

  const setIsPopoverOpen = (isOpen: boolean) =>
    dispatch({
      type: "setIsPopoverOpen",
      payload: isOpen,
    });

  const setIsMenuOpen = (isOpen: boolean) =>
    dispatch({
      type: "setIsMenuOpen",
      payload: isOpen,
    });

  const setActionPopoverContent = (content: "delete" | "rename" | null) =>
    dispatch({
      type: "setActionPopoverContent",
      payload: content,
    });

  const setIsBroken = (isBroken: boolean) =>
    dispatch({
      type: "setIsBroken",
      payload: isBroken,
    });

  const { currentDragIdentifier } = useCurrentDragType();
  const isDragging = currentDragIdentifier !== null;

  return (
    <Popover isOpen={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside
        hideCloseButton
        side="right"
        align="start"
        sideOffset={4}
        className="flex flex-col gap-1 w-52 rounded"
      >
        <AssetPreview
          asset={asset}
          isBroken={isBroken}
          setIsBroken={setIsBroken}
          isThumbnail={false}
        />
        <span className="typ-label-small w-full break-all">
          {asset.name}
          {/* NOTE (Sebas, 2025-02-11): If there is a file extension and a size, it
          means that the asset is a Replo asset, so we need to include the file
          extension in the name. */}
          {asset.fileExtension && asset.sizeBytes
            ? `.${asset.fileExtension}`
            : ""}
        </span>
        <div className="flex gap-1 typ-body-small text-muted whitespace-nowrap">
          <Label
            label={asset.fileExtension}
            size="sm"
            UNSAFE_className="bg-selectable-selected px-1 py-0.5 rounded"
            layoutClassName="w-fit h-4 mb-0"
            labelClassName="text-primary uppercase"
          />
          {asset.width && asset.height && (
            <span>
              {asset.width}x{asset.height}
            </span>
          )}
          {asset.sizeBytes && (
            <>
              <span>•</span>
              <span>{formatFileSize(asset.sizeBytes)}</span>
            </>
          )}
        </div>
        {asset.createdAt && (
          <span className="typ-body-small text-muted">
            Added {format(asset.createdAt, "MMM d, yyyy")}
          </span>
        )}
      </Popover.Content>
      <Popover.Anchor>
        <div
          className="flex flex-col gap-1 p-1.5 border border-border rounded"
          onMouseEnter={() => {
            if (!isDragging && !isMenuOpen && actionPopoverContent === null) {
              setIsPopoverOpen(true);
            }
          }}
          onMouseLeave={() => setIsPopoverOpen(false)}
        >
          <AssetPreview
            asset={asset}
            isBroken={isBroken}
            setIsBroken={setIsBroken}
            isThumbnail
          />
          <div className="flex items-center justify-between gap-1 relative">
            <span className="block typ-label-small truncate">
              {asset.name}
              {asset.fileExtension ? `.${asset.fileExtension}` : ""}
            </span>
            {/* NOTE (Sebas, 2025-02-11): This component needs to be rendered only for
                Replo assets, for Shopify assets the size is not available. */}
            {asset.sizeBytes && (
              <AssetCardEndEnhancer
                asset={asset}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setActionPopoverContent={setActionPopoverContent}
                actionPopoverContent={actionPopoverContent}
              />
            )}
          </div>
        </div>
      </Popover.Anchor>
    </Popover>
  );
};

const AssetCardEndEnhancer: React.FC<{
  asset: AssetCardProps;
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  setActionPopoverContent: (
    actionPopoverContent: "delete" | "rename" | null,
  ) => void;
  actionPopoverContent: "delete" | "rename" | null;
}> = ({
  asset,
  isMenuOpen,
  setIsMenuOpen,
  setActionPopoverContent,
  actionPopoverContent,
}) => {
  const workspaceId = useCurrentWorkspaceId();
  const { mutateAsync: deleteAsset } = trpc.asset.delete.useMutation({
    onSuccess: () => {
      void trpcUtils.asset.get.invalidate();
      setIsMenuOpen(false);
      setActionPopoverContent(null);
    },
  });
  const { mutateAsync: updateAsset } = trpc.asset.update.useMutation({
    onSuccess: () => {
      void trpcUtils.asset.get.invalidate();
      setIsMenuOpen(false);
      setActionPopoverContent(null);
    },
  });

  const onDeleteAsset = async () => {
    if (!workspaceId) {
      return;
    }

    await deleteAsset({
      assetId: asset.id,
      workspaceId,
    });
  };

  const onRenameAsset = async (assetName: string) => {
    if (!workspaceId) {
      return;
    }

    await updateAsset({
      assetId: asset.id,
      workspaceId,
      name: assetName,
    });
  };

  const menuActions: MenuItem[] = [
    {
      type: "leaf",
      id: "edit",
      title: (
        <div className="flex items-center gap-1 p-1">
          <LuPenLine size={12} /> Rename
        </div>
      ),
      onSelect: () => setActionPopoverContent("rename"),
    },
    {
      type: "leaf",
      id: "delete",
      title: (
        <div className="flex items-center gap-1 p-1">
          <LuTrash size={12} /> Delete
        </div>
      ),
      onSelect: () => setActionPopoverContent("delete"),
    },
  ];

  return (
    <Popover
      isOpen={Boolean(actionPopoverContent)}
      onOpenChange={(open) => {
        if (!open) {
          setActionPopoverContent(null);
        }
      }}
    >
      <Popover.Content
        shouldPreventDefaultOnInteractOutside
        onRequestClose={() => setActionPopoverContent(null)}
        side="right"
        align="start"
        sideOffset={4}
        title={
          actionPopoverContent === "delete" ? "Delete asset" : "Rename asset"
        }
      >
        {actionPopoverContent === "delete" ? (
          <AssetCardDeleteForm
            onDeleteAsset={() => void onDeleteAsset()}
            onCancel={() => setActionPopoverContent(null)}
          />
        ) : (
          <AssetCardRenameForm
            originalAssetName={asset.name}
            onRenameAsset={(assetName) => void onRenameAsset(assetName)}
          />
        )}
      </Popover.Content>
      <Popover.Trigger>
        <Menu
          isOpen={isMenuOpen}
          onRequestOpen={() => setIsMenuOpen(true)}
          onRequestClose={() => setIsMenuOpen(false)}
          align="start"
          size="xs"
          side="right"
          disableTriggerFocusOnClose
          stopPropagationOnItemClick
          items={menuActions}
          contentClassNames="w-40"
          trigger={
            <MenuTrigger>
              <IconButton
                variant="tertiary"
                size="sm"
                className="h-5 w-5 p-1"
                icon={<BsThreeDots />}
                aria-hidden
                hasMinDimensions={false}
              />
            </MenuTrigger>
          }
        />
      </Popover.Trigger>
    </Popover>
  );
};

const AssetCardRenameForm: React.FC<{
  originalAssetName: string;
  onRenameAsset: (assetName: string) => void;
}> = ({ originalAssetName, onRenameAsset }) => {
  const [assetName, setAssetName] = React.useState(originalAssetName);
  return (
    <>
      <Separator className="mb-3" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          void onRenameAsset(assetName);
        }}
        className="flex flex-col gap-1"
      >
        <span className="typ-label-small">Name</span>
        <Input
          value={assetName}
          onChange={(e) => {
            setAssetName(e.target.value);
          }}
          onBlur={() => {
            void onRenameAsset(assetName);
          }}
          onEnter={() => {
            void onRenameAsset(assetName);
          }}
          autoFocus
        />
      </form>
    </>
  );
};

const AssetCardDeleteForm: React.FC<{
  onDeleteAsset: () => void;
  onCancel: () => void;
}> = ({ onDeleteAsset, onCancel }) => {
  return (
    <>
      <span className="typ-body-small">
        If this image is being used on a page, deleting it will break the image
        until it is replaced.
      </span>
      <div className="flex gap-1 mt-2">
        <Button variant="danger" size="base" onClick={onDeleteAsset}>
          Delete
        </Button>
        <Button variant="secondary" size="base" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

const BrokenAssetCard: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div
      className={twMerge(
        "rounded bg-light-surface typ-button-small text-muted text-center flex flex-col gap-1.5 items-center justify-center",
        className,
      )}
    >
      <LuImageOff size={24} />
      <span>Preview unavailable</span>
    </div>
  );
};

const AssetPreview: React.FC<{
  asset: AssetCardProps;
  isBroken: boolean;
  setIsBroken: (isBroken: boolean) => void;
  isThumbnail: boolean;
}> = ({ asset, isBroken, setIsBroken, isThumbnail }) => {
  if (isBroken) {
    return (
      <BrokenAssetCard
        className={isThumbnail ? "h-full min-h-[95px] w-full" : "h-48 w-full"}
      />
    );
  }

  if (asset.type === "image") {
    return (
      <AssetCardDragOverlay id={asset.id} url={asset.url} type={asset.type}>
        <img
          src={asset.url}
          alt={asset.name}
          onError={() => setIsBroken(true)}
          className={twMerge(
            "object-cover rounded",
            isThumbnail ? "aspect-square" : "w-full",
          )}
        />
      </AssetCardDragOverlay>
    );
  }

  if (asset.type === "video") {
    return (
      <AssetCardDragOverlay id={asset.id} url={asset.url} type={asset.type}>
        <video
          src={asset.url}
          className={twMerge(
            "object-cover rounded",
            isThumbnail ? "aspect-square" : "w-full",
          )}
          onError={() => setIsBroken(true)}
          preload="metadata"
        />
      </AssetCardDragOverlay>
    );
  }
};

export default AssetCard;
