import type { ReploEditableElementWithId } from "@editor/utils/element";

import * as React from "react";

import ProductTemplateElementEditor from "@components/editor/elementEditors/ProductTemplateElementEditor";
import { SectionElementEditor } from "@components/editor/elementEditors/SectionElementEditor";
import { ElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import {
  useElementEditorErrorContext,
  withElementEditorErrorContext,
} from "@editor/contexts/ElementEditorErrorContext";
import { useEditableElement } from "@editor/hooks/useEditableElement";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { setLeftBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorDispatch } from "@editor/store";
import { elementFormHasErrors } from "@editor/utils/element";

import Button from "@replo/design-system/components/button";
import { Spinner } from "@replo/design-system/components/spinner";
import { useParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import Separator from "./common/designSystem/Separator";
import ArticleElementEditor from "./editor/elementEditors/ArticleElementEditor";
import PageElementEditor from "./editor/elementEditors/PageElementEditor";

const UpdateElementPane = withElementEditorErrorContext(() => {
  const { elementId } = useParams();
  const {
    element,
    onChangeElement,
    updateElement,
    validateElement,
    isUpdatingElement,
    updateElementMetadata,
  } = useEditableElement({
    elementId,
  });
  const logEvent = useLogAnalytics();
  const dispatch = useEditorDispatch();

  const { errorMapping, setErrors, clearErrors } =
    useElementEditorErrorContext();

  if (!element) {
    return (
      <div className="p-4 text-center">
        <Spinner variant="primary" />
      </div>
    );
  }

  function onSubmit() {
    clearErrors("path", "allErrorsFromAllFormKeys");

    const { isValid, errors: validationErrors } = validateElement(element!);
    if (!isValid) {
      if (validationErrors.title.length > 0) {
        setErrors("title", validationErrors.title);
      }
      if (validationErrors.path.length > 0) {
        setErrors("path", validationErrors.path);
      }
      return;
    }

    if (isFeatureEnabled("refactor-element-settings")) {
      void updateElementMetadata(element as ReploEditableElementWithId);
    } else {
      logEvent("editor.panel.toggle", {
        toggle: "settings",
        isOpened: false,
        openedWithShortcut: false,
      });
      dispatch(setLeftBarActiveTab(null));

      updateElement(element as ReploEditableElementWithId);
    }
  }

  return (
    <ElementEditorDataContext.Provider value={{ element, onChangeElement }}>
      <div className="h-full flex flex-col">
        {/* NOTE (Cole, 2025-02-06): The scrollbar stuff is here to hide the scrollbar across major browsers without needing a tailwind plugin */}
        <div className="flex-1 overflow-x-hidden [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] p-3 pt-2 pb-16 min-h-0">
          {exhaustiveSwitch(element)({
            page: () => <PageElementEditor />,
            shopifyArticle: () => <ArticleElementEditor />,
            shopifyProductTemplate: () => <ProductTemplateElementEditor />,
            shopifySection: () => <SectionElementEditor requestType="update" />,
          })}
        </div>

        <div className="sticky bottom-0 bg-white">
          <div className="w-full px-3 pt-2 pb-1">
            <Separator />
          </div>

          <div className="w-1/4 p-3">
            <Button
              size="base"
              variant="primary"
              type="submit"
              isLoading={isUpdatingElement}
              onClick={onSubmit}
              disabled={elementFormHasErrors(errorMapping)}
              className="w-full"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ElementEditorDataContext.Provider>
  );
});

export default UpdateElementPane;
