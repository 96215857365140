import { selectDraftElementId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

export function useDraftElementMetadata() {
  const draftElementId = useEditorSelector(selectDraftElementId);
  const { data: elementMetadata } =
    trpc.element.getElementMetadataById.useQuery(draftElementId ?? skipToken);

  return elementMetadata;
}
