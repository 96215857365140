import { isPathSafeSlug } from "schemas/utils";
import { z } from "zod";

export const constructedAnalyticsLinkSchema = z
  .object({
    id: z.string().uuid(),
    workspaceId: z.string().uuid(),
    experimentId: z.string().uuid().nullable(),
    url: z.string(),
    isActive: z.boolean(),
    customDomainId: z.string().uuid().nullable(),
    shortNameId: z.string().uuid().nullable(),
    customDomainValue: z.string().nullable(),
    shortNameValue: z.string().nullable(),
    path: z.string(),
  })
  .describe("ConstructedAnalyticsLink");

export const analyticsLinkWithValuesSchema = z
  .object({
    customDomain: z.string().nullable(),
    shortName: z.string().nullable(),
    path: z.string().min(1, "Slug is required").refine(isPathSafeSlug, {
      message: "Slug must contain only letters, numbers, or hyphens",
    }),
  })
  .superRefine((data, ctx) => {
    const { customDomain, shortName, path } = data;

    if (
      (customDomain === null && shortName === null) ||
      (customDomain !== null && shortName !== null)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Exactly one of customDomain or shortName must be set",
        path: ["customDomain", "shortName"],
      });
    }

    if (path === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Path should not be empty",
        path: ["path"],
      });
    }
  })
  .describe("AnalyticsLinkWithValues");
