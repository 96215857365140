import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import Tooltip from "../tooltip";

export interface ChipProps {
  size: "base" | "sm" | "lg";
  children?: React.ReactNode;
  UNSAFE_className?: string;
  layoutClassName?: string;
  startEnhancer?: React.ReactNode;
  active?: boolean;
  onChange?: () => void;
  tooltipText?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Chip: React.FC<ChipProps & React.HTMLAttributes<HTMLButtonElement>> = ({
  size,
  children,
  UNSAFE_className,
  layoutClassName,
  startEnhancer,
  active,
  onChange,
  onClick,
  tooltipText,
  disabled,
  className: _,
  ...domProps
}) => {
  const [isActive, setIsActive] = useControllableState<boolean>(
    active,
    false,
    onChange,
  );

  const onChipClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }
    setIsActive(!isActive);
  };

  const chipSize = {
    sm: "typ-body-small p-2",
    base: "typ-body-medium py-2 px-3",
    lg: "typ-body-large py-2 px-4",
  };

  const chipComponent = (
    <button
      className={twMerge(
        "flex items-center justify-center rounded font-normal border border-border gap-[6px] text-muted cursor-pointer hover:bg-selectable-hover",
        chipSize[size],
        isActive &&
          "border-primary bg-selectable-selected text-primary hover:bg-selectable-selected-hover",
        disabled && "opacity-50 cursor-not-allowed",
        UNSAFE_className,
        layoutClassName,
      )}
      onClick={onChipClick}
      disabled={disabled}
      {...domProps}
    >
      {startEnhancer}
      <span className="leading-none">{children}</span>
    </button>
  );
  return tooltipText ? (
    <Tooltip content={tooltipText} triggerAsChild>
      {chipComponent}
    </Tooltip>
  ) : (
    chipComponent
  );
};

export { Chip };
