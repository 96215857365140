import { isValidHttpUrl } from "replo-utils/lib/url";
import { isPathSafeSlug, slugSchema } from "schemas/utils";
import { variationSchema } from "schemas/variation";
import { z } from "zod";

const experimentStatuses = ["draft", "archived", "complete", "active"] as const;

export type ExperimentStatus = (typeof experimentStatuses)[number];

export const experimentStatusSchema = z.enum(experimentStatuses);

const baseVariationsSchema = z.array(variationSchema);

export const VARIATION_ERROR_MESSAGES = {
  allocationPercent: "Variation percentages must total 100%",
  slug: "Variant names must be unique, between 1 and 256 characters, with only letters, numbers, underscores, and hyphens.",
  target: "Target URLs must be valid and start with https:// or http://",
};

export const requiredExperimentVariationsArraySchema = baseVariationsSchema
  .superRefine((data, ctx) => {
    const areSlugsValid =
      data
        .map((variation) => isPathSafeSlug(variation.slug))
        .filter((value) => value).length === data.length;

    const areSlugsUnique =
      new Set(data.map((variation) => variation.slug)).size === data.length;

    if (!areSlugsUnique || !areSlugsValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: VARIATION_ERROR_MESSAGES.slug,
      });
    }

    const arePercentagesValid =
      data.reduce((sum, v) => v.allocationPercent + sum, 0) === 100;

    if (!arePercentagesValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: VARIATION_ERROR_MESSAGES.allocationPercent,
      });
    }

    const variationsWithTarget = data.filter(
      (variation) => variation.target !== null,
    );

    const areTargetsValid =
      variationsWithTarget
        .map(({ target }) => isValidHttpUrl(target ?? ""))
        .filter((valid) => valid).length === variationsWithTarget.length;

    if (!areTargetsValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: VARIATION_ERROR_MESSAGES.target,
      });
    }
  })
  .describe("RequiredExperimentVariations");

/**
 * NOTE (Kurt, 2024-11-03): Optional variations schema used primarily for the experiment update endpoint.
 * While variations are required when creating an experiment, during updates we want
 * to allow updating individual fields without requiring the entire experiment object.
 * This schema makes it possible to omit variations during updates, but if variations
 * are provided, they must still pass all validation rules (percentage sum = 100, unique slugs).
 * For other operations like experiment creation, we use the required schema above.
 */
export const optionalExperimentVariationsArraySchema = baseVariationsSchema
  .optional()
  .refine(
    (variations) =>
      !variations ||
      variations.length === 0 ||
      variations.reduce((sum, v) => v.allocationPercent + sum, 0) === 100,
    {
      message: "sum of variation percentages must add up to 100",
    },
  )
  .refine(
    (variations) =>
      !variations ||
      variations.length === 0 ||
      new Set(variations.map((v) => v.slug)).size === variations.length,
    {
      message: "All variation names must be unique",
    },
  )
  .describe("OptionalExperimentVariations");

export const experimentSchema = z
  .object({
    id: z.string().uuid(),
    workspaceId: z.string().uuid(),
    slug: slugSchema,
    name: z.string(),
    description: z.string().nullable(),
    createdAt: z.coerce.date(),
    activatedAt: z.coerce.date().nullable(),
    archivedAt: z.coerce.date().nullable(),
    completedAt: z.coerce.date().nullable(),
    variations: requiredExperimentVariationsArraySchema,
    analyticsLinkId: z.string().uuid().nullable(),
    selectedDisplayWinnerVariationId: z.string().uuid().nullable(),
    completedAnalyticsLinkId: z.string().uuid().nullable(),
  })
  .describe("Experiment");

export const experimentDetailSchema = z
  .object({
    id: z.string().uuid(),
    workspaceId: z.string().uuid(),
    slug: slugSchema,
    name: z.string(),
    description: z.string().nullish(),
    createdAt: z.coerce.date(),
    activatedAt: z.coerce.date().nullable(),
    archivedAt: z.coerce.date().nullable(),
    completedAt: z.coerce.date().nullable(),
    variations: requiredExperimentVariationsArraySchema,
  })
  .describe("ExperimentDetail");

export const experimentListSchema = z
  .object({
    experiments: z.array(experimentSchema),
  })
  .describe("ExperimentList");

/**
 * Determine an experiment's status by looking at whether the date keys
 * are defined or not.
 *
 * The order of the if statements matters: for example, if the experiment
 * has a archivedAt and completedAt, we should return "archived" as it
 * should take precedence over being completed.
 *
 * @author Max 2025-01-22
 */
export function getExperimentStatus(dates: {
  createdAt: Date;
  activatedAt: Date | null;
  completedAt: Date | null;
  archivedAt: Date | null;
}): ExperimentStatus {
  const { activatedAt, completedAt, archivedAt } = dates;

  if (archivedAt) {
    return "archived";
  }

  if (completedAt) {
    return "complete";
  }

  if (activatedAt) {
    return "active";
  }

  return "draft";
}
