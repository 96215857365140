import type { ReploElementType } from "schemas/generated/element";

import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

export function useElementsMetadataByType(type: ReploElementType) {
  const { project } = useCurrentProjectContext();
  const projectId = project?.id;

  const { data: elementMetadata = [] } =
    trpc.element.listAllElementsMetadata.useQuery(
      projectId ? { projectId } : skipToken,
    );

  return elementMetadata.filter((element) => element.type === type);
}
