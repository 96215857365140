import * as React from "react";

export const PlaceholderImage = (props: any) => {
  return (
    <svg
      {...props}
      width={props.size ?? "40"}
      height={props.size ?? "40"}
      className={`${props.className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        d="M34.9961 24.9998L29.8528 19.8565C29.2277 19.2316 28.38 18.8806 27.4961 18.8806C26.6122 18.8806 25.7645 19.2316 25.1394 19.8565L9.99609 34.9998M8.32943 4.99994H31.6628C33.5037 4.99994 34.9961 6.49232 34.9961 8.33327V31.6666C34.9961 33.5076 33.5037 34.9999 31.6628 34.9999H8.32943C6.48848 34.9999 4.99609 33.5076 4.99609 31.6666V8.33327C4.99609 6.49232 6.48848 4.99994 8.32943 4.99994ZM18.3294 14.9999C18.3294 16.8409 16.837 18.3333 14.9961 18.3333C13.1551 18.3333 11.6628 16.8409 11.6628 14.9999C11.6628 13.159 13.1551 11.6666 14.9961 11.6666C16.837 11.6666 18.3294 13.159 18.3294 14.9999Z"
        stroke="#475569"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
