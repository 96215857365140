import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";

// NOTE (Matt 2024-04-22): This function determines if a string will be allowed
// inside of a section schema as the "default" prop for text setting.
// Shopify only allows specific html tags and does not allow styles or classnames
// on those tags.
export function isValidDefaultSectionSchemaText(text: string) {
  if (text.length > 5000) {
    return false;
  }
  const allowedTags = [
    "p",
    "h1",
    "h2",
    "h3",
    "h4",
    "strong",
    "b",
    "em",
    "i",
    "u",
    "div",
    "span",
    "ul",
    "ol",
    "li",
    "a",
  ];

  // Check for disallowed tags
  const disallowedTagRegex = new RegExp(
    `<(?!/?(${allowedTags.join("|")})\\b)[^>]+>`,
    "gi",
  );
  if (disallowedTagRegex.test(text)) {
    return false;
  }

  // NOTE (Sebas, 2025-02-14, USE-1652): The start attribute is added when the user is
  // using an ordered list started from a number other than 1. Shopify does
  // not allow this attribute, so we need to remove it.
  const invalidAttributesRegex = /<[^>]*\b(style|class|start)\b[^>]*>/gi;
  if (invalidAttributesRegex.test(text)) {
    return false;
  }

  return true;
}

const mapPropTypeToSchemaType: Record<string, string> = {
  text: "text",
  redirect: "url",
  __imageSource: "image",
  backgroundImage: "image",
};

/**
 * Converts a prop value to a section setting value in dynamic data.
 * The resolution of this data is dependent on the `replo-deps-section-settings`
 * script that is added to the top of the section. It uses liquid to read the
 * corresponding section setting values, and assigns default values based on
 * existing component props. The `propSettingId` will verbatim match a
 * liquid variable that is set in that script tag, as well as the JSON value
 * that can be found when the script tag is parsed.
 *
 * @param propKey The component prop key that is being used as a section setting
 * @param propValue The prop value (only used to determine if it's not dynamic data)
 * @param componentId The id of the component
 * @param modifiers The modifiers (ie ['stylemd'], etc.)
 */
export function convertPropValueToSectionSetting(
  propKey: string,
  propValue: any,
  componentId: string,
  modifiers?: string[],
) {
  const applicableProp = mapPropTypeToSchemaType[propKey];
  if (
    applicableProp &&
    typeof propValue == "string" &&
    !isDynamicDataValue(propValue) &&
    (propKey !== "text" || isValidDefaultSectionSchemaText(propValue))
  ) {
    const propSettingId = `replo_${modifiers ? `${modifiers.join("_")}_` : ""}${applicableProp}_${componentId}`;
    return `{{store.sectionSettings.${propSettingId}}}`;
  }
}
