import type { FormKey } from "@editor/contexts/ElementEditorErrorContext";
import type { ElementErrorType } from "@editor/utils/element";
import type { StringMetafield } from "replo-runtime/shared/types";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import Banner from "@editor/components/common/designSystem/Banner";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import Separator from "@editor/components/common/designSystem/Separator";
import { TextTab } from "@editor/components/common/TextTab";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import { useInitial } from "@editor/hooks/useInitial";
import useOnChangeMetafields from "@editor/hooks/useOnChangeMetafields";
import validatePageModalPath from "@editor/utils/validatePageModalPath";

import Editor, { useMonaco } from "@monaco-editor/react";
import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import debounce from "lodash-es/debounce";
import { removeFolderNameFromElementName } from "replo-utils/element";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { slugify } from "replo-utils/lib/string";

import { ElementEditorErrors } from "./ElementEditorErrors";
import ElementNameEditor from "./ElementNameEditor";
import SeoElementEditor from "./SeoElementEditor";
import SEOVisibilityToggle from "./SEOVisibilityToggle";
import { ShopifyThemeSettings } from "./ShopifyThemeSettings";

type PageElementEditorTab = "general" | "shopifyTheme" | "advanced";

const PAGE_ELEMENT_EDITOR_TABS = [
  { value: "general", label: "General", isVisible: true },
  { value: "shopifyTheme", label: "Shopify Theme", isVisible: true },
  { value: "advanced", label: "Advanced", isVisible: true },
] as const;

const PageElementEditor: React.FC = () => {
  const [selectedTab, setSelectedTab] =
    React.useState<PageElementEditorTab>("general");

  return (
    <div className="flex h-fit flex-col gap-y-2">
      <TextTab
        options={PAGE_ELEMENT_EDITOR_TABS}
        onChange={setSelectedTab}
        selectedValue={selectedTab}
        containerClassName="flex"
        className="text-xs"
      />
      {exhaustiveSwitch({ type: selectedTab })({
        general: () => <PageBasicSettings />,
        shopifyTheme: () => <ShopifyThemeSettings />,
        advanced: () => <PageAdvancedSettings />,
      })}
    </div>
  );
};

const PageBasicSettings: React.FC = () => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const initialElementIsPublished = useInitial(element.isPublished);
  const { errorMapping, setErrors, clearErrors } =
    useElementEditorErrorContext();

  const { metafields, onMetafieldChange } = useOnChangeMetafields(
    element,
    onChangeElement,
  );

  const handleNameChange = (name: string) => {
    const shopifyPagePath = !element?.shopifyPagePath
      ? slugify(removeFolderNameFromElementName(name))
      : element.shopifyPagePath;
    if (errorMapping["path"]?.includes("pathAlreadyExists")) {
      clearErrors("path", ["pathAlreadyExists"]);
    }
    onChangeElement({
      ...element,
      name,
      shopifyPagePath,
    });
  };

  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const shopifyPagePath = e.target.value;

    if (shopifyPagePath.length === 0) {
      setErrors("path", ["pathIsEmpty"]);
    } else {
      clearErrors("path", ["pathIsEmpty"]);
      if (validatePageModalPath(shopifyPagePath)) {
        setErrors("path", ["isInvalidPathName"]);
      } else {
        clearErrors("path", ["isInvalidPathName"]);
      }
    }

    if (errorMapping["path"]?.includes("pathAlreadyExists")) {
      clearErrors("path", ["pathAlreadyExists"]);
    }
    if (errorMapping["path"]?.includes("pathIsEmpty")) {
      clearErrors("path", ["pathIsEmpty"]);
    }

    onChangeElement({
      ...element,
      shopifyPagePath,
    });
  };

  const routes = [
    {
      value: "pages/",
      isSelected: true,
    },
  ];

  return (
    <>
      <ElementNameEditor
        type="page"
        inputName="page-name"
        initialName={element.name}
        onChange={handleNameChange}
      />

      <LabeledControl
        label="URL"
        id="page-data-path"
        error={
          <PathInputError
            errorMapping={errorMapping}
            elementType={element.type}
          />
        }
        size="sm"
        className="mt-2"
      >
        <div className="flex flex-row gap-2 items-center">
          <div className="flex-none">
            <Selectable
              className="bg-light-surface w-fit max-w-[150px] truncate"
              labelClassName="truncate block"
              arrowClassName="text-slate-400"
              options={routes.map((route) => ({
                label: route.value,
                value: route.value,
              }))}
              value={routes.find((route) => route.isSelected)?.value}
              onSelect={() => {}}
              disableDropdownFixedWidth
              dropdownAlign="start"
              size="xs"
              isDisabled={true}
            />
          </div>
          <Input
            id="page-data-path"
            value={element.shopifyPagePath}
            placeholder="path"
            size="sm"
            onChange={handlePathChange}
          />
        </div>
      </LabeledControl>

      {initialElementIsPublished && (
        <SwitchWithDescription
          label="Is Published"
          description="Public users can see this page."
          isOn={element.isPublished}
          onChange={(isPublished) =>
            onChangeElement({ ...element, isPublished })
          }
          size="sm"
        />
      )}

      <SwitchWithDescription
        label="Set As Homepage"
        description="Set this page as the homepage of your store."
        isOn={element.isHomepage}
        onChange={(isHomepage) => onChangeElement({ ...element, isHomepage })}
        size="sm"
      />

      <Separator className="mt-2" />

      <div className="mt-2">
        <SeoElementEditor
          isPageSettings={true}
          metafields={metafields}
          onMetafieldChange={onMetafieldChange}
        />
      </div>
    </>
  );
};

const PathInputError: React.FC<{
  errorMapping: Record<FormKey, ElementErrorType[] | null>;
  elementType: ReploElementType;
}> = ({ errorMapping, elementType }) => {
  const isError =
    errorMapping &&
    Object.keys(errorMapping).some((key) =>
      errorMapping[key as FormKey]?.some((error) =>
        [
          "pathAlreadyExists",
          "isInvalidPathName",
          "pathIsEmpty",
          "loadingMissingData",
          "errorLoadingData",
          "storeHasNoBlogs",
        ].includes(error),
      ),
    );

  if (isError) {
    const errors = Object.values(errorMapping).flat();
    const primaryErrorType = errors.find(
      (error) =>
        error?.toLowerCase().includes("path") && typeof error === "string",
    );
    const errorType = primaryErrorType ?? errors[0];
    if (errorType) {
      return (
        <ElementEditorErrors errorType={errorType} elementType={elementType} />
      );
    }
  }

  return null;
};

const PageAdvancedSettings: React.FC = () => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const { metafields, onMetafieldChange } = useOnChangeMetafields(
    element,
    onChangeElement,
  );
  const [errors, setErrors] = React.useState<string[]>([]);

  const monaco = useMonaco();
  monaco?.editor.defineTheme("replo", {
    base: "vs",
    colors: {
      "editor.background": "#f1f5f9", // slate-100
      "editor.lineHighlightBackground": "#f1f5f9",
    },
    inherit: true,
    rules: [],
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now: eslint-disable-next-line react-hooks/exhaustive-deps
  const handleHeadContentChange = React.useCallback(
    debounce((value: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        `<html>${value}</html>`,
        "application/xml",
      );
      const errors = Array.from(doc.querySelectorAll("parsererror > div"))
        .map((el) => el.innerHTML)
        // NOTE (Evan, 9/25/23) Omitting the closing meta tag is very common,
        // so we hide associated errors to avoid confusion.
        .filter(
          (error) => !error.includes("Opening and ending tag mismatch: meta"),
        );
      setErrors(errors);
      onChangeElement((element) => ({
        ...element,
        customHeadContent: value,
      }));
    }, 300),
    [onMetafieldChange, debounce],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const beforeHeadEndContent =
    // NOTE (Gabe 2023-11-08): This fallback is where we used to store the
    // customHead. It no longer exists in the schema.
    // @ts-expect-error
    element.customHeadContent ??
    (
      metafields?.find((field) => field.key === "before_head_end_content") as
        | StringMetafield
        | undefined
    )?.value;

  return (
    <div className="mt-3">
      <SEOVisibilityToggle
        metafields={metafields}
        onMetafieldChange={onMetafieldChange}
      />

      <>
        <LabeledControl
          label="Custom Page <head>"
          id="page-data-name"
          className="mt-3"
          size="sm"
        >
          <div
            className="flex flex-col gap-2 rounded overflow-hidden"
            data-testid="custom-head-editor"
          >
            <Editor
              height="300px"
              defaultLanguage="html"
              defaultValue={beforeHeadEndContent}
              onChange={(value) => handleHeadContentChange(String(value))}
              options={{
                minimap: { enabled: false },
                scrollbar: { verticalScrollbarSize: 0 },
                lineNumbers: "off",
                lineDecorationsWidth: 0,
                padding: { top: 16 },
              }}
              theme="replo"
            />
            {errors.length > 0 && (
              <Banner
                isDismissable={false}
                backgroundColor="bg-red-300"
                className="rounded p-4 text-xs text-default"
              >
                <div className="flex flex-col gap-2">
                  <p>
                    Replo detected HTML which may be invalid. This may cause
                    issues after this page is published. If you see this page
                    not working correctly, please reach out to support@replo.app
                    with any questions.
                  </p>
                  <ul>
                    {errors.map((error) => (
                      <li
                        key="error"
                        className="ml-4"
                        style={{ listStyleType: "circle" }}
                      >
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              </Banner>
            )}
          </div>
        </LabeledControl>
        <p className="text-xs text-slate-600 pt-1.5">
          {
            "This content will be inserted before the closing </head> tag in your Shopify theme."
          }
        </p>
      </>
    </div>
  );
};

export default PageElementEditor;
