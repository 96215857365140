// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */

import * as React from "react";

import { Portal } from "@radix-ui/react-portal";
import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { useFocusWithin, useHover } from "react-aria";
import toast, { resolveValue, useToaster } from "react-hot-toast/headless";
import {
  BsCheckCircleFill,
  BsInfoCircleFill,
  BsX,
  BsXCircleFill,
} from "react-icons/bs";
import { isString } from "replo-utils/lib/type-check";
import { useEffectEvent } from "replo-utils/react/use-effect-event";
import { unsafe_useLayoutEffectWithoutWarning as useLayoutEffect } from "replo-utils/react/use-layout-effect";
import { usePrefersReducedMotion } from "replo-utils/react/use-prefers-reduced-motion";

export type ToastType = "success" | "error" | "warning" | "info";

type ToastManagerProps = {
  containerStyle?: React.CSSProperties;
  announcementType?: "assertive" | "polite";
};

type ToastCTAProps =
  | { cta: React.ReactElement; ctaOnClick?: never; ctaHref?: never }
  | { cta: string; ctaOnClick: () => void; ctaHref?: never }
  | { cta: string; ctaOnClick?: never; ctaHref?: string }
  | { cta?: never; ctaOnClick?: never; ctaHref?: never };

export type ToastProps = {
  type?: ToastType;
  header: string;
  message?: React.ReactNode;
} & ToastCTAProps;

type ToastComponentProps = {
  isOpen: boolean;
  id: string;
} & ToastProps;

const FOCUS_TOASTS_HOTKEY = "F8";

const ToastComponent: React.FC<
  React.PropsWithChildren<ToastComponentProps>
> = ({
  isOpen,
  id,
  type = "success",
  header,
  message,
  cta,
  ctaOnClick,
  ctaHref,
}) => {
  const icon = {
    success: <BsCheckCircleFill size={16} className="text-green-400" />,
    error: <BsXCircleFill size={16} className="text-red-600" />,
    warning: <BsInfoCircleFill size={16} className="text-yellow-500" />,
    info: <BsInfoCircleFill size={16} className="text-blue-600" />,
  }[type];

  let ctaElement: React.ReactNode;
  if (cta) {
    if (React.isValidElement(cta)) {
      ctaElement = cta;
    } else if (ctaOnClick) {
      ctaElement = <ToastCTAButton onClick={ctaOnClick}>{cta}</ToastCTAButton>;
    } else if (ctaHref) {
      ctaElement = <ToastCTALink to={ctaHref}>{cta}</ToastCTALink>;
    }
  }

  return (
    <div
      className={classNames(
        "relative rounded py-[0.875rem] px-9 w-full border-l-4 bg-white",
        {
          "border-green-400": type === "success",
          "border-yellow-500": type === "warning",
          "border-red-600": type === "error",
          "border-blue-600": type === "info",
          "animate-leave": !isOpen,
        },
      )}
    >
      <div className="flex w-full flex-col">
        <button
          data-replo-toast-announce-exclude=""
          data-testid="toast-close-button"
          type="button"
          onClick={() => toast.dismiss(id)}
          className="absolute top-1 right-1 h-6 w-6 flex items-center justify-center cursor-pointer rounded-full"
          aria-label="Dismiss notification"
        >
          <BsX className="text-slate-400 w-5 h-5" />
        </button>
        <div className="flex w-full justify-items-start">
          <div className="absolute top-[0.875rem] left-[0.875rem]">{icon}</div>
          <div className="flex flex-col gap-1">
            {header ? (
              <h3 className="text-sm font-medium leading-[1.125rem] text-default pr-6">
                {header}
              </h3>
            ) : null}

            {message ? (
              <div className="text-sm text-slate-400 whitespace-pre-line">
                {isString(message) ? <p>{message}</p> : message}
              </div>
            ) : null}

            {ctaElement ? (
              <div className="mt-2" data-replo-toast-announce-exclude="">
                {ctaElement}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export function ToastCTAButton({
  children,
  onClick,
}: {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button variant="secondary" size="base" onClick={onClick}>
      {children}
    </Button>
  );
}

export function ToastCTALink({
  children,
  to,
  rel,
  target,
}: {
  children?: React.ReactNode;
  to: string;
  rel?: React.HTMLProps<HTMLAnchorElement>["rel"];
  target?: React.HTMLAttributeAnchorTarget;
}) {
  return (
    <Button variant="secondary" size="base" to={to} rel={rel} target={target}>
      {children}
    </Button>
  );
}

export const ToastManager = ({
  containerStyle,
  announcementType = "polite",
}: ToastManagerProps) => {
  const { toasts, handlers } = useToaster({
    duration: 6000,
  });
  const prefersReducedMotion = usePrefersReducedMotion();
  const { hoverProps, isHovered } = useHover({
    onHoverChange: (isHovering) => {
      if (isHovering) {
        handlers.startPause();
      } else if (!isFocused) {
        handlers.endPause();
      }
    },
  });
  const [isFocused, setIsFocused] = React.useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocused) => {
      if (isFocused) {
        handlers.startPause();
      } else if (!isHovered) {
        handlers.endPause();
      }
      setIsFocused(isFocused);
    },
  });

  const visibleToasts = toasts.filter((toast) => toast.visible);

  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const hotkey = [FOCUS_TOASTS_HOTKEY];
    function handleKeyDown(event: KeyboardEvent) {
      // NOTE (Chance 2023-12-30): This is borrowed from the Radix
      // implementation. We use `event.code` as it is consistent regardless of
      // meta keys that were pressed. for example, `event.key` for
      // `Control+Alt+t` is `†` and `t !== †`
      const isHotkeyPressed = hotkey.every(
        (key) => (event as any)[key] || event.code === key,
      );
      if (isHotkeyPressed) {
        element?.focus();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const STACK_GAP_EXPANDED = 16;
  const STACK_GAP_CONDENSED = 24;
  const STACK_SCALE_FACTOR = 0.05;

  const renderedToasts: React.ReactElement[] = [];
  let heightDiffOffset = 0;
  let floor = 0;

  for (let trueIndex = 0; trueIndex < toasts.length; trueIndex++) {
    const toast = toasts[trueIndex]!;
    const indexRelativeToOpenToasts = visibleToasts.indexOf(toast);
    const isInFront = indexRelativeToOpenToasts === 0;
    const isOpen = toast.visible;
    const isHidden = !isOpen || indexRelativeToOpenToasts > 2;

    const scale =
      prefersReducedMotion || isInFront || isHovered
        ? 1
        : 1 -
          STACK_SCALE_FACTOR * (isOpen ? indexRelativeToOpenToasts : trueIndex);

    const stackOffsetY = isOpen
      ? visibleToasts
          .slice(0, indexRelativeToOpenToasts)
          .reduce((total, toast) => {
            return total + (toast.height ?? 0);
          }, 0) * -1
      : 0;

    const previousVisibleToast = visibleToasts[indexRelativeToOpenToasts - 1];
    const previousVisibleToastHeight = previousVisibleToast?.height ?? 0;
    const currentToastHeight = toast.height ?? 0;

    if (isInFront || isHidden || prefersReducedMotion || isHovered) {
      // do nothing
    } else if (previousVisibleToastHeight >= currentToastHeight) {
      const diff = previousVisibleToastHeight - currentToastHeight;
      heightDiffOffset += diff;
    } else {
      const diff = currentToastHeight - previousVisibleToastHeight;
      if (diff >= STACK_GAP_CONDENSED) {
        heightDiffOffset -= floor;
      } else {
        heightDiffOffset -= diff;
      }
    }

    floor += STACK_GAP_CONDENSED;

    const offset =
      prefersReducedMotion || isHovered ? stackOffsetY : heightDiffOffset * -1;
    const gap =
      prefersReducedMotion || isHovered
        ? STACK_GAP_EXPANDED
        : STACK_GAP_CONDENSED;
    const index = isOpen ? indexRelativeToOpenToasts : trueIndex;

    const yPosition = offset - gap * index;

    renderedToasts.push(
      <ToastContainer
        key={toast.id}
        announcementType={announcementType}
        id={toast.id}
        tabIndex={isOpen ? 0 : -1}
        data-index={trueIndex}
        data-front={isInFront || undefined}
        data-hovering={isHovered || undefined}
        data-hidden={isHidden || undefined}
        onHeightUpdate={handlers.updateHeight}
        className={classNames(
          "flex absolute right-4 bottom-4 left-4 justify-end z-[var(--z-index)] after:bg-transparent after:absolute after:top-full after:w-full after:h-full",
          {
            "pointer-events-none": isHidden,
            "[&>*]:pointer-events-auto": !isHidden,
            "drop-shadow-lg": isOpen && isInFront,
            "drop-shadow-sm": isOpen && !isInFront,
          },
        )}
        style={{
          // @ts-expect-error
          "--y": `${yPosition}px`,
          "--scale": scale,
          zIndex: toasts.length - trueIndex + 1,
          opacity: isHidden ? 0 : 1,
          transformOrigin: "center bottom",
          transition: prefersReducedMotion
            ? undefined
            : `all 230ms cubic-bezier(0.21, 1.02, 0.73, 1)`,
          transform:
            "translate3d(var(--x, 0), var(--y, 0), 0) scale(var(--scale))",
        }}
      >
        {resolveValue(toast.message, toast)}
      </ToastContainer>,
    );
  }

  return (
    <div
      ref={ref}
      role="region"
      aria-label={`Notifications (${FOCUS_TOASTS_HOTKEY})`}
      tabIndex={-1}
      data-hovering={isHovered || undefined}
      style={{
        // @ts-expect-error
        "--toast-stack-gap": `${STACK_GAP_EXPANDED}px`,
        ...containerStyle,
      }}
      className="fixed z-[9999] bottom-0 right-0 w-80 max-w-[100vw] m-0 outline-none transition-all motion-reduce:transition-none"
      {...focusWithinProps}
      {...hoverProps}
    >
      {renderedToasts}
    </div>
  );
};

const ToastAnnounce: React.FC<
  React.PropsWithChildren<{
    type?: "assertive" | "polite";
    contextLabel?: string;
  }>
> = ({ children, type, contextLabel }) => {
  const [renderAnnounceText, setRenderAnnounceText] = React.useState(false);
  const [isAnnounced, setIsAnnounced] = React.useState(false);

  // Render text content in the next frame to ensure toast is announced in NVDA
  useNextFrame(() => setRenderAnnounceText(true));
  React.useEffect(() => {
    const timer = window.setTimeout(() => setIsAnnounced(true), 1000);
    return () => window.clearTimeout(timer);
  }, []);

  return isAnnounced ? null : (
    <Portal asChild>
      <div className="sr-only" role="status" aria-live={type} aria-atomic>
        {renderAnnounceText && (
          <>
            {contextLabel} {children}
          </>
        )}
      </div>
    </Portal>
  );
};

function getAnnounceTextContent(container: HTMLElement) {
  const textContent: string[] = [];
  const childNodes = Array.from(container.childNodes);

  for (const node of childNodes) {
    if (node.nodeType === node.TEXT_NODE && node.textContent) {
      textContent.push(node.textContent);
    }
    if (isHTMLElement(node)) {
      const isHidden =
        node.ariaHidden || node.hidden || node.style.display === "none";
      const isExcluded = node.dataset.reploToastAnnounceExclude === "";
      if (!isHidden) {
        if (isExcluded) {
          const altText = node.dataset.reploToastAnnounceAlt;
          if (altText) {
            textContent.push(altText);
          }
        } else {
          textContent.push(...getAnnounceTextContent(node));
        }
      }
    }
  }

  // We return a collection of text rather than a single concatenated string.
  // This allows SR VO to naturally pause break between nodes while announcing.
  return textContent;
}

function isHTMLElement(node: Node): node is HTMLElement {
  return node.nodeType === node.ELEMENT_NODE;
}

function useNextFrame(callback: (...args: any[]) => any) {
  const fn = useEffectEvent(callback);
  useLayoutEffect(() => {
    let raf1 = 0;
    let raf2 = 0;
    raf1 = window.requestAnimationFrame(
      // biome-ignore lint/suspicious/noAssignInExpressions: allow assignment in expression
      () => (raf2 = window.requestAnimationFrame(fn)),
    );
    return () => {
      window.cancelAnimationFrame(raf1);
      window.cancelAnimationFrame(raf2);
    };
  }, [fn]);
}

const customToast = (props: ToastProps) =>
  toast.custom((t) => (
    <ToastComponent isOpen={t.visible} id={t.id} {...props} />
  ));

export const warningToast = (header: string, message: string) =>
  toast.custom((t) => (
    <ToastComponent
      isOpen={t.visible}
      id={t.id}
      type="warning"
      message={message}
      header={header}
    />
  ));

export const successToast = (title: string, message?: string) => {
  return toast.custom((t) => (
    <ToastComponent
      isOpen={t.visible}
      id={t.id}
      type="success"
      message={message}
      header={title}
    />
  ));
};

export const infoToast = (title: string, message: React.ReactNode) => {
  return toast.custom((t) => (
    <ToastComponent
      isOpen={t.visible}
      id={t.id}
      type="info"
      message={message}
      header={title}
    />
  ));
};

export const errorToast = (title: string, message: string) => {
  return toast.custom((t) => (
    <ToastComponent
      isOpen={t.visible}
      id={t.id}
      type="error"
      message={message}
      header={title}
    />
  ));
};

export { customToast as toast };
export default customToast;

type ToastContainerProps = React.ComponentPropsWithoutRef<"li"> & {
  id: string;
  onHeightUpdate: (id: string, height: number) => void;
  announcementType: "assertive" | "polite";
  tabIndex?: number;
};

function ToastContainer({
  id,
  onHeightUpdate,
  style,
  announcementType,
  tabIndex = 0,
  ...props
}: ToastContainerProps) {
  const [element, setElement] = React.useState<HTMLDivElement | null>(null);
  const [readyToShow, setReadyToShow] = React.useState(false);
  const _onHeightUpdate = useEffectEvent(onHeightUpdate);
  const updateHeight = React.useCallback(
    (element: HTMLDivElement | null) => {
      const height = element?.getBoundingClientRect().height ?? 0;
      _onHeightUpdate(id, height);
      requestAnimationFrame(() => {
        setReadyToShow(true);
      });
    },
    [_onHeightUpdate, id],
  );

  const ref = React.useCallback(
    (element: HTMLDivElement | null) => {
      setElement(element);
      updateHeight(element);
    },
    [updateHeight],
  );

  React.useEffect(() => {
    if (!element) {
      return;
    }

    const onMutation = () => updateHeight(element);
    const mutationObserver = new MutationObserver(onMutation);
    mutationObserver.observe(element, {
      subtree: true,
      childList: true,
      characterData: true,
    });
    return () => {
      mutationObserver.disconnect();
    };
  }, [element, updateHeight]);

  const announceTextContent = React.useMemo(() => {
    return element ? getAnnounceTextContent(element) : null;
  }, [element]);

  return (
    <>
      {announceTextContent && (
        <ToastAnnounce type={announcementType}>
          {announceTextContent}
        </ToastAnnounce>
      )}
      <div
        id={getToastId(id)}
        role="status"
        aria-live="off"
        aria-atomic
        tabIndex={tabIndex}
        ref={ref}
        style={{
          ...style,
          // NOTE (Chance 2023-12-29): These overrides are applied on the first
          // render, but removed after an animation frame to create an entrance
          // animation that uses the same physics as all other transitions instead
          // of creating separate keyframes and duplicating the timing props.
          // @ts-expect-error
          "--y": readyToShow ? style?.["--y"] : "100%",
          opacity: readyToShow ? style?.opacity : 0,
        }}
        {...props}
      />
    </>
  );
}

function getToastId(id: string | number) {
  return `replo-toast-${id}`;
}
