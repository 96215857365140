import type {
  ReploSimpleProject,
  ReploSimpleProjectWithStats,
} from "schemas/generated/project";

import * as React from "react";

import ProjectMenu from "@editor/components/dashboard/projects/ProjectMenu";
import RenameProjectModal from "@editor/components/dashboard/projects/RenameProjectModal";
import { useEditedTimeString } from "@editor/hooks/useEditedTimeString";
import { getProjectName } from "@editor/utils/project-utils";

import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { isShopifyIntegrationEnabled } from "schemas/utils";

type ProjectItemProps = {
  project: ReploSimpleProjectWithStats | ReploSimpleProject;
  onSelectProject: (e: React.MouseEvent) => void;
  className?: string;
  shouldShowCreatedAt?: boolean;
  showWorkspaceColumn?: boolean;
  hideEllipsisButton?: boolean;
};

const ListProjectItem: React.FC<ProjectItemProps> = ({
  project,
  onSelectProject,
  className,
  shouldShowCreatedAt = true,
  showWorkspaceColumn = false,
  hideEllipsisButton = false,
}) => {
  const editedTimeString = useEditedTimeString(
    project.lastEditedAt,
    project.createdAt,
  );
  const [isRenameProjectModalOpen, setIsRenameProjectModalOpen] =
    React.useState(false);
  const uniqueId = `project-${project.id}-${React.useId()}`;

  return (
    <>
      <div
        className={twMerge(
          classNames(
            "grid w-full grid-cols-12 items-center justify-center py-4 pr-4 pl-4 gap-2 text-sm text-default",
            className,
          ),
        )}
      >
        <button
          type="button"
          onClick={onSelectProject}
          className="col-span-3 flex flex-row items-center gap-x-4 text-left"
          id={uniqueId}
        >
          {getProjectName(project)}
        </button>
        {showWorkspaceColumn ? (
          <button
            type="button"
            onClick={onSelectProject}
            className="col-span-3 flex flex-row items-center gap-x-4 text-left"
            aria-describedby={uniqueId}
            tabIndex={-1}
          >
            <span aria-hidden>{project.ownerWorkspace?.name ?? "-"}</span>
            <span className="sr-only">
              {project.ownerWorkspace?.name != null
                ? `Workspace ${project.ownerWorkspace?.name}`
                : "No workspace"}
            </span>
          </button>
        ) : null}
        {shouldShowCreatedAt && (
          <button
            type="button"
            onClick={onSelectProject}
            className="col-span-3 text-slate-400 flex"
            aria-describedby={uniqueId}
            tabIndex={-1}
          >
            <span className="sr-only">Edited</span>
            {editedTimeString.charAt(0).toUpperCase() +
              editedTimeString.slice(1)}
          </button>
        )}
        <div
          className={twMerge(
            "flex flex-row items-center",
            showWorkspaceColumn ? "col-span-2" : "col-span-3",
          )}
        >
          {isShopifyIntegrationEnabled(project) ? (
            <Tooltip
              content={project.integrations?.shopify?.store.shopifyUrl}
              triggerAsChild
            >
              <Link
                tabIndex={0}
                to={
                  project.integrations?.shopify?.store.shopifyUrl
                    ? `https://${project.integrations?.shopify?.store.shopifyUrl}`
                    : ""
                }
                target="_blank"
              >
                <img
                  src="/images/shopify-logo.svg"
                  alt="Shopify logo"
                  aria-hidden
                />
                <span className="sr-only">
                  Shopify integration is available
                </span>
              </Link>
            </Tooltip>
          ) : (
            <Tooltip
              content="Shopify integration is not available"
              triggerAsChild
            >
              <div tabIndex={0}>
                <p className="ml-2" aria-hidden>
                  -
                </p>
                <span className="sr-only">
                  Shopify integration is not available
                </span>
              </div>
            </Tooltip>
          )}
        </div>
        {!hideEllipsisButton && (
          <div
            className={twMerge(
              "flex",
              showWorkspaceColumn ? "col-span-1" : "col-span-3",
            )}
          >
            <ProjectMenu
              project={project}
              isRenameModalOpen={isRenameProjectModalOpen}
              onRenameOpen={() => setIsRenameProjectModalOpen(true)}
            />
          </div>
        )}
      </div>
      <RenameProjectModal
        isOpen={isRenameProjectModalOpen}
        onRequestClose={() => setIsRenameProjectModalOpen(false)}
        projectId={project.id}
        projectName={getProjectName(project)}
      />
    </>
  );
};

export default ListProjectItem;
