import * as React from "react";

import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useCurrentContext from "@editor/hooks/useCurrentContext";
import {
  selectDraftComponentId,
  selectObjectFit,
  selectObjectPosition,
  selectPropLoading,
  selectPropPoster,
  selectPropUrl,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import ModifierGroup from "@editorExtras/ModifierGroup";
import ModifierLabel from "@editorExtras/ModifierLabel";
import VideoSourceIndicator from "@editorModifiers/VideoSourceIndicator";

import { isDynamicDataValue } from "replo-runtime";
import { evaluateVariableAsString } from "replo-runtime/store/AlchemyVariable";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { isValidHttpUrl } from "replo-utils/lib/url";

const VideoSourceModifier: React.FC = () => {
  const context = useCurrentContext();
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const applyComponentAction = useApplyComponentAction();
  const objectFit = useEditorSelector(selectObjectFit);
  // Note (Noah, 2024-03-06): Fine to cast this as string here since we never support
  // setting numbers as object position
  const rawObjectPosition = useEditorSelector(selectObjectPosition);
  const objectXPosition = rawObjectPosition?.[0];
  const objectYPosition = rawObjectPosition?.[1];

  const value = useEditorSelector(selectPropUrl);
  const evaluatedValue = isDynamicDataValue(value)
    ? evaluateVariableAsString(value, context)
    : value;
  const posterSrc = useEditorSelector(selectPropPoster);
  const loading = useEditorSelector(selectPropLoading);

  // NOTE (Sebas, 2025-01-07): If the evaluatedValue is null or a mixed style value,
  // we should consider it as a valid URL to avoid showing the error message.
  const isValidUrl =
    evaluatedValue && !isMixedStyleValue(value)
      ? isValidHttpUrl(evaluatedValue)
      : true;
  return (
    <ModifierGroup title="Video Source">
      <div data-testid="video-source-modifier" className="flex items-center">
        <ModifierLabel label="Video URL" />
        <VideoSourceIndicator
          src={value}
          assetLoadingValue={loading ?? "eager"}
          posterSrc={posterSrc ?? undefined}
          objectFitValue={objectFit}
          objectPositionValues={{
            x: objectXPosition,
            y: objectYPosition,
          }}
          onChangeObjectFit={(value) => {
            applyComponentAction({
              type: "setStyles",
              value: { objectFit: value },
            });
          }}
          onChangeObjectPositionX={(value: string) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                objectPosition: `${value} ${objectYPosition || "50%"}`,
              },
            });
          }}
          onChangeObjectPositionY={(value: string) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                objectPosition: `${objectXPosition || "50%"} ${value}`,
              },
            });
          }}
          onRemove={() => {
            applyComponentAction({
              type: "setProps",
              value: { url: "" },
            });
          }}
          onChangeAssetLoading={(value) => {
            applyComponentAction({
              type: "setProps",
              value: { loading: value },
            });
          }}
          onChangePoster={(value) => {
            applyComponentAction({
              type: "setProps",
              value: { poster: value },
            });
          }}
          componentId={draftComponentId ?? undefined}
        />
      </div>
      {!isValidUrl ? (
        <ErrorMessage error="Please enter a valid URL" className="mt-1" />
      ) : null}
    </ModifierGroup>
  );
};
export default VideoSourceModifier;
