import React from "react";

import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";

export interface TagProps {
  size: "base" | "sm";
  variant: "success" | "info" | "danger" | "ai" | "beta" | "neutral";
  children?: React.ReactNode;
  UNSAFE_className?: string;
  layoutClassName?: string;
  startEnhancer?: React.ReactNode;
  tooltipText?: string;
}

const Tag: React.FC<TagProps> = ({
  size,
  variant,
  children,
  UNSAFE_className,
  layoutClassName,
  startEnhancer,
  tooltipText,
}) => {
  const sizeClassName = {
    sm: "text-[10px] py-[2px] px-1 gap-1",
    base: "typ-body-small py-1 px-[6px] gap-1",
  };

  const variantClassName = {
    success: "bg-success-soft text-success",
    info: "bg-info-soft text-info",
    danger: "bg-danger-soft text-danger",
    ai: "bg-ai-soft text-ai",
    beta: "bg-beta-soft text-beta",
    neutral: "bg-neutral-soft text-neutral",
  };

  const tagComponent = (
    <div
      className={twMerge(
        "flex items-center rounded font-normal",
        sizeClassName[size],
        variantClassName[variant],
        layoutClassName,
        UNSAFE_className,
      )}
    >
      {startEnhancer}
      {children}
    </div>
  );

  return tooltipText ? (
    <Tooltip content={tooltipText} triggerAsChild>
      {tagComponent}
    </Tooltip>
  ) : (
    tagComponent
  );
};

export { Tag };
