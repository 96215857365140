import * as React from "react";

import { CreateExperimentButton } from "@/features/experiments/components/CreateExperimentButton";

export const ExperimentsSetup = () => {
  return (
    <div className="h-[70vh] text-center grid grid-cols-12">
      <div className="col-start-5 col-span-4 gap-4 flex flex-col justify-center">
        <img
          src="/images/experiments/all-experiments-no-data.svg"
          alt="Experiments Overview"
        />
        <div className="text-xl font-semibold ">
          Test your ideas, see what converts.
        </div>
        <div className="text-slate-500 text-sm font-normal">
          Run simple A/B tests by splitting traffic to different pages. Discover
          the winner and improve store performance.
        </div>
        <CreateExperimentButton />
      </div>
    </div>
  );
};

export default ExperimentsSetup;
