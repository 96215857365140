import type { ObjectFitType } from "@editor/types/object-fit";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import { Input } from "@common/designSystem/Input";
import AssetPicker from "@editor/components/common/designSystem/AssetPicker";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import InlineAssetSelector from "@editor/components/common/designSystem/InlineAssetSelector";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import { useAiAltTextMutation } from "@editor/reducers/ai-reducer";
import {
  selectDraftComponentIds,
  selectImageAltText,
} from "@editor/reducers/core-reducer";
import {
  resetEditorBrokenMediaComponentId,
  selectEditorBrokenMediaComponentIds,
} from "@editor/reducers/editor-media-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { selectComponentMarkers } from "@editor/reducers/tree-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";

import IconButton from "@replo/design-system/components/button/IconButton";
import Popover from "@replo/design-system/components/popover";
import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { BsInfoCircleFill, BsMagic, BsX } from "react-icons/bs";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { evaluateVariableAsString } from "replo-runtime/store/AlchemyVariable";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";

import ModifierLabel from "./extras/ModifierLabel";

type ImageSourceSelectorProps = {
  src?: string | ReploMixedStyleValue;
  objectFitValue?: ObjectFitType | ReploMixedStyleValue;
  assetLoadingValue?: AssetLoadingType | ReploMixedStyleValue;
  objectPositionValues?: {
    x?: string | ReploMixedStyleValue;
    y?: string | ReploMixedStyleValue;
  };
  onChangeImageSource(value: string): void;
  onChangeObjectFit?(value: string): void;
  onChangeAssetLoading?(value: AssetLoadingType): void;
  onChangeObjectPositionX?(value: string): void;
  onChangeObjectPositionY?(value: string): void;
  onRemove?(): void;
  componentContext?: Context;
  allowsDynamicData?: boolean;
  componentId?: string;
  className?: string;
  openPopoverWhenEmpty?: boolean;
  generateAltText?: (imageUrl: string) => void;
  isGeneratingAltText?: boolean;
  size: "sm" | "base";
  offset?: number;
};

const imageSourceAttribute = "__imageSource";

const ImageSourceSelector = ({
  src: originalSource,
  objectFitValue,
  assetLoadingValue,
  objectPositionValues,
  onChangeImageSource,
  onChangeObjectFit,
  onChangeAssetLoading,
  onChangeObjectPositionX,
  onChangeObjectPositionY,
  onRemove: _onRemove,
  componentContext,
  allowsDynamicData = true,
  componentId,
  className,
  openPopoverWhenEmpty = false,
  size,
  offset,
}: ImageSourceSelectorProps) => {
  const dispatch = useEditorDispatch();
  const editorBrokenMediaComponentIds = useEditorSelector(
    selectEditorBrokenMediaComponentIds,
  );
  const assetIsDynamicData = !isMixedStyleValue(originalSource)
    ? isDynamicDataValue(originalSource)
    : false;
  const evaluatedSource =
    originalSource && !isMixedStyleValue(originalSource)
      ? evaluateVariableAsString(originalSource, componentContext)
      : null;
  const resolvedSource =
    assetIsDynamicData && componentContext && evaluatedSource
      ? evaluatedSource
      : originalSource;
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const firstDraftComponentId = draftComponentIds[0];

  const isImageBroken = draftComponentIds.some((id) =>
    editorBrokenMediaComponentIds.includes(id),
  );
  const isPopoverVisible = !resolvedSource || isImageBroken;

  const [isVisible, setVisible] = useOverridableState(
    openPopoverWhenEmpty ? isPopoverVisible : false,
    undefined,
    firstDraftComponentId ?? undefined,
  );
  const modal = useModal();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const applyComponentAction = useApplyComponentAction();

  const [generateAiAltText, { isLoading: isGeneratingAltText, isSuccess }] =
    useAiAltTextMutation();

  const _openModal = () => {
    modal.openModal({
      type: "assetLibraryModal",
      props: {
        referrer: "modifier/image",
        value: !isMixedStyleValue(originalSource)
          ? originalSource ?? null
          : null,
        onChange: (value: string) => {
          generateAltTextIfNecessary(value);
          onChangeImageSource(value);
          if (isImageBroken && firstDraftComponentId) {
            dispatch(resetEditorBrokenMediaComponentId(firstDraftComponentId));
          }
        },
        assetContentType: "image",
      },
    });
  };

  const _onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.URL,
        referrerData: {
          type: "style",
          styleAttribute: imageSourceAttribute,
        },
        initialPath: !isMixedStyleValue(originalSource)
          ? getPathFromVariable(originalSource)
          : undefined,
      },
    });
  };

  const _onClickAltTextDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "style",
          styleAttribute: "__imageAltText",
        },
        initialPath: altTextValue
          ? getPathFromVariable(altTextValue)
          : undefined,
      },
    });
  };

  const _handleAltTextChange = React.useCallback(
    (value: string) => {
      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          {
            type: "setStyles",
            value: { __imageAltText: value },
          },
          // Note (Evan, 2024-04-25): When the user manually sets alt text,
          // mark the text as NOT ai-generated so we know not to replace it.
          {
            type: "setMarker",
            value: { _aiGeneratedAltText: false },
          },
        ],
      });
    },
    [applyComponentAction],
  );

  const initialAltTextValue = useEditorSelector(selectImageAltText);
  const [altTextValue, setAltTextValue] = useOverridableState(
    isMixedStyleValue(initialAltTextValue)
      ? "Mixed"
      : initialAltTextValue ?? "",
    useDebouncedCallback(_handleAltTextChange, 300),
  );

  // Note (Evan, 2024-04-26): Used to cancel generation requests
  const abortRef = React.useRef<(() => void) | null>(null);

  const componentMarkers = useEditorSelector((state) =>
    selectComponentMarkers(state, firstDraftComponentId ?? ""),
  );

  const isAltTextAiGenerated =
    Boolean(componentMarkers?._aiGeneratedAltText) &&
    altTextValue &&
    !isDynamicDataValue(altTextValue);

  // Note (Evan, 2024-04-25): Replace alt text with AI-generated text if it doesn't exist or if it was already AI-generated
  const generateAltTextIfNecessary = (value: string) => {
    if (
      (!altTextValue || isAltTextAiGenerated) &&
      firstDraftComponentId &&
      value.length > 0
    ) {
      const { abort } = generateAiAltText({
        componentId: firstDraftComponentId,
        imageUrl: value,
        triggeredManually: false,
      });
      abortRef.current = abort;
    }
  };

  const removeAiAltTextIfNecessary = () => {
    if (isAltTextAiGenerated) {
      setAltTextValue("");
    }
  };

  const onRemove = () => {
    removeAiAltTextIfNecessary();
    _onRemove?.();
    if (isImageBroken && firstDraftComponentId) {
      dispatch(resetEditorBrokenMediaComponentId(firstDraftComponentId));
    }
  };

  const onClickSelectAsset = () => {
    // Note (Noah, 2023-12-07): Regardless of whether we allow changing
    // object-fit etc, always set the popover visible instead of going straight
    // to the asset modal because we always support updating the alt text, for
    // which we need to open the popover
    setVisible(true);
  };

  const altTextInputProps = useOverridableInput({
    value: altTextValue,
    onValueChange: setAltTextValue,
  });

  const altTextInputRef = React.useRef<HTMLInputElement>(null);

  const getTooltipText = () => {
    if (!resolvedSource || isGeneratingAltText) {
      return "Generating with AI";
    }
    return isAltTextAiGenerated ? "Re-generate with AI" : "Generate with AI";
  };

  return (
    <div className={twMerge("flex flex-grow flex-col", className)}>
      <Popover
        isOpen={isVisible}
        onOpenChange={setVisible}
        // Note (Noah, 2022-04-23, REPL-1738): Can't ignore outside interactions
        // here because if we do, that makes the asset library modal not scrollable
        shouldIgnoreOutsideInteractions={false}
      >
        {typeof originalSource === "string" && assetIsDynamicData ? (
          <DynamicDataValueIndicator
            type="image"
            templateValue={originalSource}
            onClick={onClickSelectAsset}
            onRemove={onRemove}
            componentId={componentId}
          />
        ) : (
          <InlineAssetSelector
            emptyTitle="Select Image"
            inputPlaceholder="Image URL"
            onClickSelectAsset={onClickSelectAsset}
            onRemoveAsset={onRemove}
            allowRemoveAsset={Boolean(_onRemove)}
            asset={
              isMixedStyleValue(originalSource)
                ? originalSource
                : {
                    type: "image",
                    // TODO (Noah, 2023-05-23): Added this "as string" since we were assuming
                    // it before we switched the return type of evaluateVariable to unknown,
                    // but need to investigate more since the undefined case should be handled
                    // somewhere above
                    src: resolvedSource as string,
                  }
            }
            allowsDynamicData={allowsDynamicData}
            onClickDynamicData={_onClickDynamicData}
            onInputChange={(value: string) => {
              generateAltTextIfNecessary(value);
              onChangeImageSource(value);
              if (isImageBroken && firstDraftComponentId) {
                dispatch(
                  resetEditorBrokenMediaComponentId(firstDraftComponentId),
                );
              }
            }}
            size={size}
          />
        )}
        <Popover.Anchor className={classNames("relative top-0 left-0")} />
        {isVisible && (
          <Popover.Content
            title="Image"
            shouldPreventDefaultOnInteractOutside={areModalsOpen}
            // NOTE (Juan, 2024-10-21): This offset is different from the others because this
            // popover is being trigger from an entire input and not from an internal badge.
            sideOffset={offset ?? 84}
          >
            <AssetPicker
              allowsSettingDynamicData={allowsDynamicData}
              onClickDynamicDataForUrl={_onClickDynamicData}
              objectFitValue={objectFitValue}
              assetLoadingValue={assetLoadingValue}
              objectPositionValue={objectPositionValues}
              assetType="image"
              emptyTitle="No Image Selected"
              selectAssetTitle="Select Image"
              changeAssetTitle="Change Image"
              url={resolvedSource}
              onChangeObjectFit={onChangeObjectFit}
              onChangeAssetLoading={onChangeAssetLoading}
              onChangeObjectPositionX={onChangeObjectPositionX}
              onChangeObjectPositionY={onChangeObjectPositionY}
              onClickSelectAsset={_openModal}
              componentId={componentId}
              urlIsDynamicData={assetIsDynamicData}
              templateValue={originalSource}
              onClickRemoveDynamicData={onRemove}
              altTextComponent={
                <div className="flex items-center w-full">
                  <ModifierLabel label="Alt Text" />
                  {(() => {
                    if (isDynamicDataValue(altTextValue)) {
                      return (
                        <DynamicDataValueIndicator
                          type="text"
                          templateValue={altTextValue ?? ""}
                          onClick={() => _onClickAltTextDynamicData()}
                          onRemove={() => setAltTextValue("")}
                          componentId={componentId}
                        />
                      );
                    }

                    if (componentContext?.isInsideProductImageCarousel) {
                      return (
                        <div className="flex-grow flex items-center gap-1">
                          <SelectionIndicator
                            title=""
                            placeholder="From Product Image"
                            disabled
                            endEnhancer={
                              <Tooltip
                                triggerAsChild
                                content="To change this image's alt text, edit in Shopify Admin"
                              >
                                <div className="flex items-center justify-center">
                                  <BsInfoCircleFill
                                    className="text-subtle"
                                    size={12}
                                  />
                                </div>
                              </Tooltip>
                            }
                          />
                        </div>
                      );
                    }

                    return (
                      <div className="flex gap-1">
                        <div className="flex-grow relative">
                          <Input
                            {...altTextInputProps}
                            ref={altTextInputRef}
                            onFocus={() => {
                              abortRef.current?.();
                            }}
                            // Note (Evan, 2024-04-26): This makes the text fade in only when a generation completes, not when it's aborted
                            unsafe_inputClassName={twMerge(
                              isSuccess &&
                                "transition-opacity duration-500 ease-in-out",
                              isGeneratingAltText ? "opacity-0" : "opacity-100",
                            )}
                            placeholder="Description"
                          />
                          {isGeneratingAltText && (
                            <div
                              className="absolute bottom-0 left-0 w-full cursor-pointer transition-all duration-700 ease-out"
                              onClick={() => {
                                abortRef.current?.();
                                altTextInputRef.current?.focus();
                              }}
                            >
                              <SimpleSkeletonLoader width="100%" height="24" />
                              <span className="absolute top-1/2 left-2 text-xs text-slate-400 animate-pulseDeep -translate-y-1/2">
                                Generating
                              </span>
                              <div className="absolute right-2 top-1/2 -translate-y-1/2 text-slate-400">
                                <BsX />
                              </div>
                            </div>
                          )}
                        </div>
                        <IconButton
                          variant="secondary"
                          className="text-ai shrink-0 p-0 h-[unset]"
                          tooltipText={getTooltipText()}
                          onClick={() => {
                            if (
                              resolvedSource &&
                              !isMixedStyleValue(resolvedSource) &&
                              firstDraftComponentId
                            ) {
                              const { abort } = generateAiAltText({
                                componentId: firstDraftComponentId,
                                imageUrl: resolvedSource,
                                triggeredManually: true,
                              });
                              abortRef.current = abort;
                            }
                          }}
                          disabled={
                            !resolvedSource ||
                            isGeneratingAltText ||
                            draftComponentIds.length > 1
                          }
                          icon={<BsMagic size={16} />}
                          aria-label={getTooltipText()}
                        />
                        {allowsDynamicData && (
                          <div className="shrink-0">
                            <DynamicDataButton
                              onClick={() => _onClickAltTextDynamicData()}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })()}
                </div>
              }
            />
          </Popover.Content>
        )}
      </Popover>
    </div>
  );
};

export default ImageSourceSelector;
