import type { ElementRequestType } from "@editor/utils/element";
import type {
  ReploElement,
  ReploElementType,
  ReploPartialElement,
} from "schemas/generated/element";

import * as React from "react";

import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import {
  getDuplicatedElementName,
  getDuplicateElementPath,
  getPartialFromElement,
} from "@editor/utils/element";

import { useParams } from "react-router-dom";

/**
 * Helper hook to get ReploPartialElement according to the draft element.
 */
export default function useElementPartial(
  requestType: ElementRequestType,
  elementType: ReploElementType,
  editingElement: ReploElement,
  pages: ReploElement[],
) {
  const defaultElement = useDefaultReploPartialElement(elementType);
  const [element, setElement] = React.useState<ReploPartialElement>(
    requestType !== "new" && editingElement
      ? getPartialFromElement(editingElement)
      : defaultElement,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook is cursed, disabling for now until I have more info on why "pages" is needed as a dep
  React.useEffect(() => {
    if (
      !requestType.startsWith("duplicate") ||
      element.name !== editingElement?.name ||
      element.shopifyPagePath !== editingElement?.shopifyPagePath
    ) {
      return;
    }
    const { name, shopifyPagePath } = editingElement;
    const now = new Date();
    const isDuplicateSupport = requestType === "duplicate.support";
    const nextName = getDuplicatedElementName(name, now, elementType);

    const nextShopifyPagePath = getDuplicateElementPath(
      name,
      now,
      shopifyPagePath,
      isDuplicateSupport,
    );

    if (
      nextName !== element.name ||
      nextShopifyPagePath !== element.shopifyPagePath
    ) {
      setElement((oldElement) => ({
        ...oldElement,
        shopifyPagePath: nextShopifyPagePath,
        name: nextName,
      }));
    }
  }, [requestType, pages, editingElement, element, elementType]);

  return [element, setElement] as const;
}

/**
 * Helper hook to get default ReploPartialElement using the store id.
 */
export function useDefaultReploPartialElement(
  elementType: ReploElementType,
): ReploPartialElement {
  const params = useParams();
  const projectId = useCurrentProjectId();

  return {
    name: "",
    projectId: projectId ?? params.projectId ?? "",
    type: elementType,
    hideDefaultHeader: false,
    hideDefaultFooter: false,
    hideShopifyAnnouncementBar: false,
    shopifyPagePath: "",
    shopifyTemplateSuffix: "",
    isHomepage: false,
    isPublished: false,
    shopifyMetafields: [],
    shopifyArticleImage: { src: "" },
  };
}
