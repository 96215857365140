import type { Project } from "replo-runtime/shared/types";
import type { ReploElement, ReploElementType } from "schemas/generated/element";

import pickBy from "lodash-es/pickBy";
import { generatePath } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";
import { CATEGORIES_IDS } from "schemas/componentTemplates";

export type EditorRoute = {
  projectId: string;
  elementId?: string;
  productId?: string;
};

export const routes = {
  signup: "/auth/signup",
  dashboard: "/home",
  allProjects: "/all-projects",
  editor: {
    project: "/editor/:projectId",
    element: "/editor/:projectId/:elementId",
    product: "/editor/:projectId/:elementId/:productId",
    settings: "/editor/:projectId/project-settings",
    integrations: "/editor/:projectId/integrations",
  },
  workspace: {
    projects: "/workspace/:workspaceId/projects",
    integrations: {
      list: "/workspace/:workspaceId/integrations",
      shopify: "/workspace/:workspaceId/integrations/shopify",
    },
    billing: "/workspace/:workspaceId/billing",
    experiments: {
      list: "/workspace/:workspaceId/abtesting",
      settings: "/workspace/:workspaceId/abtesting/settings",
      details: "/workspace/:workspaceId/abtesting/:experimentId/details",
      results: "/workspace/:workspaceId/abtesting/:experimentId/results",
      edit: "/workspace/:workspaceId/abtesting/:experimentId/edit",
    },
  },
  marketplaceModal: "marketplace",
  marketplaceModalCategory: "marketplace/category/:categorySlug",
  marketplaceModalCollection: "marketplace/collection/:collectionId",
  marketplaceModalDetails: "marketplace/details/:templateId",
  templateSharedDetails: "details/:templateId",
  analytics: {
    dashboard: "/workspace/:workspaceId/analytics",
    overview: "/workspace/:workspaceId/analytics/overview",
    pageDetails: "/workspace/:workspaceId/analytics/deep-dive",
  },
  growthAudits: "/workspace/:workspaceId/growth-audits",
};

/**
 * Generate the pathname for the editor route based on the provided parameters.
 *
 * @example
 * const pathname = generateEditorPathname('settings', { projectId: '123' });
 * '/editor/123/settings'
 *
 * @example
 * const pathname = generateEditorPathname('', { projectId: '123', elementId: '456' });
 * '/editor/123/456'
 */
export function generateEditorPathname(
  to: string,
  params: {
    projectId: Project["id"];
    elementId?: ReploElement["id"];
    productId?: string;
    [key: string]: string | undefined;
  },
  queryParams?: Record<string, string>,
) {
  let basePath = routes.editor.project;

  if (params.elementId) {
    basePath = routes.editor.element;
  } else if (params.productId) {
    basePath = routes.editor.product;
  }

  const pathWithoutSearchParams = generatePath(
    `${basePath}/${to}`,
    pickBy(params),
  );

  if (!isEmpty(queryParams)) {
    const urlSearchParams = new URLSearchParams(queryParams);
    return `${pathWithoutSearchParams}?${urlSearchParams.toString()}`;
  }

  return pathWithoutSearchParams;
}

export function addReferralToUrl(url: string, referral: string | null) {
  if (!referral) {
    return url;
  }
  return `${url}?referral=${referral}`;
}

export const generateMarketplacePathnameAndCategoryId = ({
  elementType,
  projectId,
  elementId,
}: {
  elementType: ReploElementType;
  projectId?: string;
  elementId?: string;
}) => {
  let generatedPath: string;
  let categoryId: string | undefined;

  if (elementType === "shopifyArticle") {
    categoryId = CATEGORIES_IDS.blogPostPage;
    generatedPath = generateEditorPathname(routes.marketplaceModal, {
      projectId: projectId ?? "",
      elementId: elementId ?? "",
    });
  } else if (elementType === "shopifyProductTemplate") {
    categoryId = CATEGORIES_IDS.productPageTemplates;
    generatedPath = generateEditorPathname(routes.marketplaceModal, {
      projectId: projectId ?? "",
      elementId: elementId ?? "",
    });
  } else {
    generatedPath = generateEditorPathname(routes.marketplaceModal, {
      projectId: projectId ?? "",
      elementId: elementId ?? "",
    });
  }

  return {
    generatedPath,
    categoryId,
  };
};
