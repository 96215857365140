import type { ReploElement } from "schemas/generated/element";

import * as React from "react";
import { useCallback, useState } from "react";

import { successToast } from "@common/designSystem/Toast";
import Modal from "@editor/components/common/designSystem/Modal";
import { withElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import { useModal } from "@editor/hooks/useModal";
import { setSelectedArchivedElementId } from "@editor/reducers/core-reducer";
import { trpc } from "@editor/utils/trpc";

import * as RadioGroup from "@radix-ui/react-radio-group";
import Button from "@replo/design-system/components/button";
import { useDispatch } from "react-redux";
import { capitalizeFirstLetter } from "replo-utils/lib/string";

import { elementTypeToEditorData } from "../editor/element";
import { formatVersionHistoryDateAndTime } from "../version-history/VersionCard";

export type PermanentlyDeleteElementModalProps = {
  element: ReploElement;
};

export const PermanentlyDeleteElementModal: React.FC<PermanentlyDeleteElementModalProps> =
  withElementEditorErrorContext(({ element }) => {
    const modal = useModal();
    const [shouldUnpublish, setShouldUnpublish] = useState(false);
    const utils = trpc.useUtils();
    const { mutate: deleteMutation, isPending: isLoading } =
      trpc.element.delete.useMutation();
    const dispatch = useDispatch();

    const handleDeleteForever = useCallback(
      (element: ReploElement) => {
        deleteMutation(element.id, {
          onSuccess: () => {
            successToast(
              `${capitalizeFirstLetter(
                elementTypeToEditorData[element.type].singularDisplayName,
              )} deleted successfully`,
              `Your ${capitalizeFirstLetter(
                elementTypeToEditorData[element.type].singularDisplayName,
              )} has been permanently deleted`,
            );

            // Invalidate and select first element
            void utils.element.listArchived
              .fetch({ projectId: element.projectId })
              .then((elements) => {
                const firstElement = elements?.[0];
                if (firstElement) {
                  dispatch(setSelectedArchivedElementId(firstElement.id));
                } else {
                  dispatch(setSelectedArchivedElementId(null));
                }
              });
            void utils.element.listArchived.invalidate();
            modal.closeModal({ type: "permanentlyDeleteElementModal" });
          },
        });
      },
      [deleteMutation, utils.element.listArchived, modal, dispatch],
    );

    return (
      <Modal
        isOpen
        onRequestClose={() =>
          modal.closeModal({ type: "permanentlyDeleteElementModal" })
        }
        closesOnOverlayClick={true}
        className="w-[490px]"
        onEnterKeyPress={() => handleDeleteForever(element)}
      >
        <div className="no-scrollbar max-h-[85vh] overflow-y-auto">
          <div className="flex flex-col gap-2">
            <p className="text-default text-lg font-medium">
              Permanently Delete this{" "}
              {elementTypeToEditorData[element.type].singularDisplayName}
            </p>
            <p className="text-muted">This action cannot be undone</p>
            <div>
              <div className="border border-muted-foreground rounded-md px-4 py-3 flex flex-col gap-2">
                <div className="flex items-start gap-2">
                  <div className="flex flex-col gap-1 pl-2 text-default">
                    {element.name}
                    <p className="text-xs text-subtle">
                      {element.archivedAt
                        ? `Archived ${formatVersionHistoryDateAndTime(element.archivedAt)}`
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <RadioGroup.Root
                value={shouldUnpublish ? "delete" : "keep"}
                onClick={() => setShouldUnpublish(!shouldUnpublish)}
                className="flex flex-col gap-2 mt-4"
              >
                <div className="flex items-start gap-2">
                  <RadioGroup.Item
                    value="delete"
                    id="delete"
                    className="w-4 h-4 mt-1 rounded-full border border-muted bg-white data-[state=checked]:bg-primary data-[state=checked]:border-primary relative"
                  >
                    <RadioGroup.Indicator className="absolute inset-0 flex items-center justify-center after:block after:w-2 after:h-2 after:rounded-full after:bg-white" />
                  </RadioGroup.Item>
                  <label htmlFor="delete">
                    <span className="text-default">Select to confirm </span>
                  </label>
                </div>
              </RadioGroup.Root>
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-row justify-end gap-x-2">
          <Button
            size="lg"
            variant="secondary"
            type="button"
            className="w-24"
            onClick={() =>
              modal.closeModal({ type: "permanentlyDeleteElementModal" })
            }
          >
            Cancel
          </Button>
          <Button
            size="lg"
            variant="danger"
            type="submit"
            isLoading={isLoading}
            data-testid="delete-submit-button"
            disabled={!shouldUnpublish}
            onClick={() => {
              handleDeleteForever(element);
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    );
  });
