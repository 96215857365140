import type { SourceType } from "@editor/components/editor/page/AssetLibraryModal";

import { useErrorToast } from "@editor/hooks/useErrorToast";
import { isAssetsRefreshEnabled } from "@editor/infra/featureFlags";
import { useUpdateOrCreateAssetMutation } from "@editor/reducers/api-reducer";
import { selectProject } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { useUploadAsset } from "@/features/assets/useUploadAsset";
import { useDropzone } from "react-dropzone";
import { isEmpty } from "replo-utils/lib/misc";
import { MAX_FILE_UPLOAD_COUNT } from "schemas/asset";

function useFileDropZone({
  sourceType,
  onDropBeforeUpload,
  onUploadComplete,
  onError,
  acceptDropAssetType,
  allowClickToUpload,
  customValidator,
}: {
  sourceType: SourceType;
  onDropBeforeUpload?: () => string | null;
  onUploadComplete: (res: any, imageComponentId: string | null) => void;
  onError?: (imageComponentId: string | null) => void;
  acceptDropAssetType: string[] | string | null | undefined;
  allowClickToUpload: boolean;
  customValidator?: (file: File) => {
    code: string;
    message: string;
  } | null;
}) {
  const [updateOrCreateAsset, { isLoading }] = useUpdateOrCreateAssetMutation();
  const { uploadAsset, isUploading } = useUploadAsset();
  const assetsRefreshEnabled = isAssetsRefreshEnabled();
  const project = useEditorSelector(selectProject);
  const hasShopifyIntegration = Boolean(project?.integrations?.shopify);
  const errorToast = useErrorToast();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (!hasShopifyIntegration && !assetsRefreshEnabled) {
        errorToast(
          "Store Not Connected",
          "Your Shopify store must be connected in order to add images in Replo. Please connect your store and try again.",
          "error.asset.upload.shopify_not_connected",
          {},
        );
        return;
      }

      if (!isEmpty(fileRejections)) {
        // Note (Evan, 2024-03-26): Extract all unique error codes
        const allErrorCodes = fileRejections.reduce((acc, fileRejection) => {
          fileRejection.errors.forEach((error) => acc.add(error.code));
          return acc;
        }, new Set<string>());
        if (allErrorCodes.has("too-many-files")) {
          errorToast(
            "Files Exceeding Limit",
            `Too many files selected. Please upload up to ${MAX_FILE_UPLOAD_COUNT} files at a time for a successful upload.`,
            "error.asset.upload.too_many_files",
            {},
          );
          return;
        }
      }

      const uploadPromises = acceptedFiles.map(async (file: any) => {
        const imageComponentId = onDropBeforeUpload?.();
        const uploadFunction = assetsRefreshEnabled
          ? () => uploadAsset(file)
          : () =>
              updateOrCreateAsset({
                file,
                filename: file.name,
                storeId: project?.id ?? "",
                sourceType,
              });

        try {
          const res = await uploadFunction();
          if (res && !("error" in res)) {
            onUploadComplete(res, imageComponentId ?? null);
          } else {
            onError?.(imageComponentId ?? null);
          }
        } catch {
          onError?.(imageComponentId ?? null);
        }
      });

      // NOTE (Sebas, 2025-01-31): We cannot await the uploadPromises because the onDrop
      // function is called synchronously and the type does not allow for async.
      void Promise.all(uploadPromises);
    },
    accept: acceptDropAssetType ?? undefined,
    maxFiles: MAX_FILE_UPLOAD_COUNT,
    noClick: !allowClickToUpload,
    noKeyboard: true,
    validator: (file) => customValidator?.(file) ?? null,
  });

  return {
    getRootProps,
    getInputProps,
    openFileDialog,
    isDragActive,
    isLoadingAssetUpload: assetsRefreshEnabled ? isUploading : isLoading,
  };
}

export default useFileDropZone;
