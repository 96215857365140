import * as React from "react";

import { BackButton } from "@editor/components/common/BackButton";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { routes } from "@editor/utils/router";

import { ExperimentActionsBar } from "@/features/experiments/components/ExperimentActionsBar";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import { ExperimentsEditTabV2 } from "@/features/experiments/tabs/ExperimentsEditTabV2";
import { generatePath, useNavigate } from "react-router-dom";

import { ExperimentEditableHeader } from "../ExperimentEditableHeader";
import { useExperimentDetails } from "./hooks/useExperimentDetails";
import { useExperimentEdit } from "./hooks/useExperimentEdit";

const ExperimentDetailsTab: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { dispatchExperimentEdit, handleReactHookFormSubmit } =
    useExperimentEdit();
  const { experiment } = useExperimentDetails();
  const navigate = useNavigate();

  if (!experiment || !workspaceId) {
    return null;
  }

  const experimentsListPath = generatePath(routes.workspace.experiments.list, {
    workspaceId,
  });

  return (
    <ExperimentsLayout
      headerTitleComponent={
        <ExperimentEditableHeader
          title={experiment.name}
          experimentId={experiment.id}
          dispatchExperimentEdit={dispatchExperimentEdit}
          handleReactHookFormSubmit={handleReactHookFormSubmit}
          isLoadingRequiredData={!workspaceId}
        />
      }
      headerActions={
        <ExperimentActionsBar
          workspaceId={workspaceId ?? ""}
          experiment={experiment}
        />
      }
      showStatus
      backButton={<BackButton onClick={() => navigate(experimentsListPath)} />}
    >
      <ExperimentsEditTabV2 />
    </ExperimentsLayout>
  );
};

export default ExperimentDetailsTab;
