import type * as React from "react";
import type { Action } from "schemas/actions";
import type { Animation } from "schemas/animations";
import type { ItemsConfig } from "schemas/dynamicData";
import type { ReploFlatComponent } from "schemas/generated/component";
import type { ReploState } from "schemas/generated/symbol";
import type {
  ProductRefOrDynamic,
  SelectedSellingPlanIdOrOneTimePurchase,
} from "schemas/product";
import type { RuntimeStyleProperties } from "schemas/styleAttribute";
import type { Variable } from "schemas/variable";

import { z } from "zod";

export const ReploComponentTypes = [
  "container",
  "symbolRef",
  "text",
  "button",
  "spacer",
  "circle",
  "icon",
  "modal",
  "image",
  "accordionBlock",
  "subscribeAndSave",
  "collapsible",
  "slidingCarousel",
  "player",
  "player__playIcon",
  "player__muteIcon",
  "player__fullScreenIcon",
  "collectionSelect",
  "product",
  "productCollection",
  "quantitySelector",
  "dropdown",
  "variantSelect",
  "optionSelect",
  "variantSelectDropdown",
  "optionSelectDropdown",
  "sellingPlanSelect",
  "sellingPlanSelectDropdown",
  "collection",
  "collectionV2",
  "googleMapsEmbed",
  "klaviyoEmbed",
  "temporaryCart",
  "temporaryCartItems",
  "vimeoEmbed",
  "vimeoEmbedV2",
  "rebuyWidget",
  "buyWithPrimeButton",
  "youtubeEmbed",
  "youtubeEmbedV2",
  "carouselV2",
  "carouselV2__panels",
  "carouselV2__indicator",
  "carouselV3",
  "carouselV3Slides",
  "carouselV3Control",
  "carouselV3Indicators",
  "carouselPanelsCount",
  "shopifySection",
  "shopifyAppBlocks",
  "shopifyRawLiquid",
  "collapsibleV2",
  "collapsibleV2Header",
  "collapsibleV2Content",
  "tabsBlock",
  "tabs__list",
  "tabs__panelsContent",
  "tabs__onePanelContent",
  "tabsV2__block",
  "tabsV2__list",
  "tabsV2__panelsContent",
  "marquee",
  "rawHtmlContent",
  "starRating",
  "tikTokEmbed",
  "rechargeSubscriptionWidget",
  "staySubscriptionWidget",
  "okendoReviewsWidget",
  "okendoProductRatingSummary",
  "junipProductRating",
  "junipReviews",
  "yotpoProductRating",
  "yotpoReviews",
  "looxProductRating",
  "looxReviews",
  "reviewsIoProductRating",
  "reviewsIoReviews",
  "h1",
  "h2",
  "h3",
  "spinner",
  "dynamicCheckoutButtons",
  "paymentTerms",
  "countdownTimer",
  "judgeProductRatingWidget",
  "judgeProductReviewsWidget",
  "feraProductRatingWidget",
  "feraProductReviewsWidget",
  "feraStoreReviewsWidget",
  "feraMediaGalleryWidget",
  "shopifyProductReviewsWidget",
  "shopifyProductRatingWidget",
  "stampedProductReviewsWidget",
  "stampedProductRatingWidget",
  "knoCommerceWidget",
  "infiniteOptionsWidget",
  "kachingBundles",
  "postscriptSignupForm",
  "staySubscriptionWidget",
  "toggleContainer",
  "toggleIndicator",
  "tooltip",
  "tooltipContent",
  "beforeAfterSlider",
  "beforeAfterSliderThumb",
  "beforeAfterSliderBeforeContent",
  "beforeAfterSliderAfterContent",
  "selectionList",
] as const;

export type ReploComponentType = (typeof ReploComponentTypes)[number];

export type ReploComponent = Component;

const reploComponentPropsStylesSchema = z
  .object({
    backgroundColor: z.string().nullish(),
    backgroundImage: z.string().nullish(),
    color: z.string().nullish(),
    __imageSource: z.string().nullish(),
    borderTopColor: z.string().nullish(),
    borderLeftColor: z.string().nullish(),
    borderBottomColor: z.string().nullish(),
    borderRightColor: z.string().nullish(),
    width: z.union([z.number(), z.string()]).nullish(),
  })
  .passthrough();

export const reploComponentPropsSchema = z
  .object({
    style: reploComponentPropsStylesSchema.optional(),
    "style@md": reploComponentPropsStylesSchema.optional(),
    "style@sm": reploComponentPropsStylesSchema.optional(),
    text: z.string().optional(),
    src: z.string().optional(),
  })
  .catchall(z.any())
  .describe("ReploComponentProps");

export const reploComponentSchemaBase = z
  .object({
    id: z.string(),
    name: z.string().optional(),
    type: z.enum(ReploComponentTypes).describe("ReploComponentTypes"),
    props: reploComponentPropsSchema,
    children: z.optional(z.array(z.any())),
    variants: z.any(),
    variantOverrides: z.any(),
  })
  .describe("ComponentBase");

// Describe the partial schema
export const reploComponentSchemaBasePartial = reploComponentSchemaBase
  .partial()
  .describe("PartialComponent");

export const reploComponentSchema = reploComponentSchemaBase
  .extend({})
  .superRefine((component, ctx) => {
    const flatComponents = reploComponentToFlatComponents(component);
    const result = reploFlatComponentArraySchema.safeParse(flatComponents);
    if (!result.success) {
      result.error.issues.forEach((issue) => ctx.addIssue(issue));
    }
  })
  .describe("ReploComponent");

// TODO: Add more specific schemas for components, we should also improve the schemas for the props and styles.

export const reploFlatComponentSchema = z
  .object({
    id: z.string(),
    name: z.string().optional(),
    type: z.enum(ReploComponentTypes).describe("ReploComponentTypes"),
    props: reploComponentPropsSchema,
    children: z.optional(z.array(z.string().uuid())),
  })
  .describe("ReploFlatComponent");

type ReploComponentInner = {
  id: string;
  name?: string | undefined;
  type: ReploComponentType;
  props: Component["props"];
  children?: any[] | undefined;
};

export const reploFlatComponentArraySchema = z.array(reploFlatComponentSchema);

/**
 * Transform a deeply nested replo component to a flat replo component array.
 */
export function reploComponentToFlatComponents(
  component: Component,
): ReploFlatComponent[] {
  const components: Array<ReploFlatComponent> = [];
  const stack: Array<ReploComponentInner> = [component];
  while (stack.length > 0) {
    const currentComponent = stack.pop();
    if (currentComponent) {
      const flat: ReploFlatComponent = { ...currentComponent };
      if (currentComponent.children) {
        flat.children = currentComponent.children.map((child) => child.id);
      }
      components.push(flat);
      stack.push(...(currentComponent.children || []));
    }
  }
  return components;
}

export const CustomComponentPropTypes = [
  // Intrinsic types
  "boolean",
  "string",
  "multiline",
  "float",
  "integer",
  "date",
  "timeInterval",
  "image",
  "seconds",
  "color",
  "pixels",
  "pixelsIncludingNegativeValues",
  "percentage",

  /**
   * A single Shopify product.
   */
  "product",

  /**
   * A single variant of a Shopify product.
   */
  "product_variant",

  /**
   * A list of Shopify products (potentially specifiable by hardcoded products, or
   * a collection, etc)
   */
  "products",

  /**
   * A list of product variants.
   */
  "productVariants",

  /**
   * A subscription selling plan of a Shopify product.
   */
  "productSellingPlan",

  /**
   * A list of selling plans of a Shopify product.
   */
  "productSellingPlans",

  /**
   * A hashmark (e.g. #product) that represents a component on the page which can be
   * linked to or scrolled to. Different from string, since we need to render the
   * hashmark, potentially only allow users to select hashmarks which exist, etc.
   */
  "hashmark",

  /**
   * A Replo component. Used for components which have "render props", e.g. the video
   * player which has a specific component always defined for its controls
   */
  "component",

  /**
   * A list of items. Could be dynamic, e.g. product images, or static as a list. Used
   * for things like tabs, carousels, etc
   */
  "inlineItems",

  /**
   * A list of key/value pairs to be rendered as htmlAttributes on an ReploComponent.
   * Currently only used on specific elements to add dataset attributes, but could be
   * expanded later to include other html attributes.
   */
  "htmlAttributes",

  /**
   * A list of items which can only be specified as a dynamic reference to something else
   * (e.g. product images of the current product)
   */
  "dynamicItems",

  /**
   * A single product option (e.g. Size, Color, etc).
   */
  "productOptions",

  /**
   * Either a product, or if there's no product selected the value will be taken from the
   * current product component's context
   */
  "productFromPropsOrFromContext",

  /**
   * Source code types (these render a code editor)
   */
  "liquidCodeEditor",
  "htmlCodeEditor",
  "cssCodeEditor",

  /**
   * A star rating. Used for reviews, etc
   */
  "ratingBalancer",

  /**
   * A list of Shopify theme sections.
   */
  "shopifyThemeSections",

  /**
   * One option out of a given list.
   */
  "selectable",

  /**
   * A list of swatches. Note (Noah, 2022-11-13): This is currently used only in
   * option/variant lists, it's a bit weird because you don't actually _select_ a
   * swatch here, you can just edit swatches using this custom prop modifier. Swatch
   * values are available in dynamic data.
   */
  "swatches",

  /**
   * An arbitrary dynamic value. Used for things like carousels where the user can select
   * a value to auto-scroll to when it changes.
   */
  "anyDynamicValue",

  /* Unused */
  "collection",
  "location",
] as const;
export type CustomComponentPropType = (typeof CustomComponentPropTypes)[number];
type ComponentState = { currentIndex: 0 }; // Carousel state, add more states here if needed

type ComponentPropType =
  | string
  | number
  | Variable
  | ComponentState
  | Action[]
  | CollectionSelectFilter
  | boolean;

export type CustomPropDefinition = {
  id: string;
  name: string;
  type: CustomComponentPropType;
  description?: string;
  defaultValue: any;
  isEnabled?: (config: { component: Component }) => boolean;
  shouldDisplay?: (config: {
    component: Component;
    userIsSuperuser: boolean;
  }) => boolean;
  disabledDescription?: string;
  disableDynamicData?: boolean;
  preserveValueWhenDisabled?: boolean;

  // TODO (Noah, 2023-12-07): Bit weird to have these in this type since they do
  // not apply to all prop definition types. Would be nice to have this as a
  // discriminated union based on the "type" property, getting a correct
  // discriminated union of types for these props is harder than it looks
  selectableValues?:
    | { type: "options"; options: { label: string; value: string }[] }
    | { type: ReploComponentType };
  allowsSettingObjectFit?: boolean;
  min?: number;
  placeholder?: string;
};

export type CustomPropsRecord = Record<
  string,
  Omit<CustomPropDefinition, "id"> & { id?: string }
>;

type CollectionSelectDataTableFilterStatement = {
  id: string;
  columnName: string;
  type: "dropdown";
  value: {
    dropdownId: string;
    dataTableColumn: string;
    // TODO (Noah, 2021-07-19): In the future, have an "op" here so that we don't
    // always assume it's ==, could be >=, etc
  };
};

type CollectionSelectFilter = {
  type: "dataTableFilter";
  statements: CollectionSelectDataTableFilterStatement[];
};

export type ProductOptionWithValues = { label: string; values?: string[] };

const overridableProps = [
  "_autoWidth",
  "_activeArea",
  "_itemsPerView",
  "_itemsPerMove",
] as const;

type OverridableProp = (typeof overridableProps)[number];

export interface Component {
  type: ReploComponentType;
  name?: string;
  children?: Component[];
  id: string;
  state?: Record<string, any>;
  symbolId?: string;
  customPropDefinitions?: CustomPropsRecord;
  props: {
    style?: RuntimeStyleProperties;
    "style@md"?: RuntimeStyleProperties;
    "style@sm"?: RuntimeStyleProperties;
    "style@lg"?: RuntimeStyleProperties;
    "style@xl"?: RuntimeStyleProperties;
    items?: any;
    _urlHashmark?: string;
    _accessibilityRole?: string;
    _accessibilityHidden?: boolean;
    _accessibilityChecked?: boolean;
    _accessibilityLabelledBy?: string;
    _accessibilityHtmlTagType?:
      | "ol"
      | "ul"
      | "aside"
      | "section"
      | "blockquote";
    _accessibilityScreenreaderInstructions?: string;
    _accessibilityLabel?: string;
    onClick?: Action[];
    onHover?: Action[];
    filter?: CollectionSelectFilter;
    text?: string;
    textStyle?: React.CSSProperties;
    _query?: string;
    _embedCode?: string;
    url?: string;
    hideControls?: boolean;
    _closeModalOnOutsideClick?: boolean;
    _overlayColor?: string;
    _option?: ProductOptionWithValues | string;
    _productVariants?: number[];
    _productSellingPlans?: number[];
    _hideDefaultArrowDisplay?: boolean;
    _oneTimePurchaseText?: string;
    _defaultText?: string;
    _product?: ProductRefOrDynamic;
    _defaultSelectedSellingPlanId?: SelectedSellingPlanIdOrOneTimePurchase;
    _items?: ItemsConfig;
    _liquidContent?: string;
    _shopifySectionName?: string;
    _dragToScroll?: boolean;
    _autoNextInterval?: string;
    _autoClose?: boolean;
    _pauseOnHover?: boolean;
    _animationStyle?: "slide" | "fade" | "off";
    _activeArea?: "first" | "center";
    _infinite?: boolean;
    direction?: "next" | "previous";
    _openComponent?: Component;
    _collapsibleComponent?: Component;
    _defaultOpen?: boolean;
    _isAnimated?: boolean;
    _seconds?: string;
    _rightDirection?: boolean;
    iconName?: string;
    size?: number | string;
    _mouseWheel?: boolean;
    _slideClickTransition?: boolean;
    _loop?: boolean;
    _autoplay?: boolean;
    _preload?: boolean;
    _defaultMuted?: boolean;
    _autoFullscreen?: boolean;
    _htmlContent?: string;
    _rating?: number;
    _size?: string;
    _totalStars?: number;
    _starColor?: string;

    // Note (Noah, 2023-12-24): Not sure exactly why we ever set string here,
    // but there are definitely prod components which have a string value for
    // these values
    _itemsPerView?: number | string;
    _itemsPerMove?: number | string;

    _autoWidth?: boolean;
    _endTime?: string;
    _useCustomConfig?: boolean;
    _includeOneTimePurchase?: boolean;
    _defaultQuantity?: number;
    [propName: string]:
      | ComponentPropType
      | React.CSSProperties
      | Record<string, any>
      | CollectionSelectFilter
      | Action[]
      | SelectedSellingPlanIdOrOneTimePurchase
      | undefined;
    _rebuyWidgetEmbed?: string;
    _knoCommerceApiKey?: string;
    _knoCommerceAccountId?: string;
    _surveyId?: string;
    _loadIndicatorImage?: { src: string; style: Record<string, any> };
    _internalUseRepetition?: boolean;
    _showOptionsNotSoldTogether?: boolean;
    _useVariantUrlParameter?: boolean;
    _siteId?: string;
    _widgetId?: string;
    _sliderComponent?: Component;
    _beforeComponent?: Component;
    _afterComponent?: Component;
    overrides?: {
      sm: Pick<Component["props"], OverridableProp>;
      md: Pick<Component["props"], OverridableProp>;
    };
    _tooltipContentComponent?: Component;
    _title?: string;
    _triggeringAction?: "onClick" | "onHover";
  };
  animations?: Animation[];
  variants?: ReploState[];
  variantOverrides?: Record<
    string,
    { componentOverrides?: Record<string, Partial<Component>> }
  >;
  markers?: {
    _aiDisabled?: boolean;

    // Note (Evan, 2024-04-25): i.e., whether the alt text for an image was generated with AI
    _aiGeneratedAltText?: boolean;
  };
}
