import * as React from "react";

import Button from "@replo/design-system/components/button/Button";
import { LuFolderSearch } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

type NoMatchingProjectsProps = {
  searchQueryInputValue: string;
};

const NoMatchingProjects: React.FC<NoMatchingProjectsProps> = ({
  searchQueryInputValue,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center py-[15%] gap-4">
      <div className="flex items-center justify-center relative">
        <div className="border-1 border-dashed border-blue-200 rounded-full h-[149px] w-[149px] absolute"></div>
        <div className="border-1 border-dashed border-blue-200 rounded-full h-[218px] w-[218px] absolute"></div>
        <div className="border-1 border-dashed border-blue-200 rounded-full h-[284px] w-[284px] absolute"></div>
        <div className="rounded-full bg-blue-50 h-16 w-16 flex items-center justify-center relative z-10">
          <LuFolderSearch className="text-blue-600" size={24} />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-4 bg-white relative z-10">
        <div className="text-center max-w-[300px] flex flex-col gap-1">
          <div className="text-slate-800 text-lg font-semibold">
            No results matching &quot;{searchQueryInputValue}&quot;
          </div>
          <div className="text-slate-600 text-[14px] ">
            Start building with Replo — we can&apos;t wait to bring your ideas
            to life!
          </div>
        </div>
        <Button
          size="base"
          variant="primary"
          onClick={() => navigate("../project/new")}
        >
          New Project
        </Button>
      </div>
    </div>
  );
};

export default NoMatchingProjects;
