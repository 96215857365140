import { successToast } from "@editor/components/common/designSystem/Toast";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";
import { trpc, trpcUtils } from "@editor/utils/trpc";

function useDeleteDesignLibrarySavedStyle() {
  const store = useEditorStore();
  const errorToast = useErrorToast();
  const { mutateAsync, isPending } =
    trpc.designLibrary.savedStyles.delete.useMutation({
      onSuccess: () => {
        successToast(
          "Saved style deleted",
          "The saved style selected was deleted",
        );
      },
      onError: () => {
        errorToast(
          "Failed to delete saved style",
          "Please try again, or contact support@replo.app for help.",
          "error.saved_style.delete",
          {},
        );
      },
      onSettled: async () => {
        const projectId = selectProjectId(store.getState());

        if (!projectId) {
          return;
        }

        await trpcUtils.designLibrary.get.invalidate({ projectId });
      },
    });

  return {
    deleteSavedStyle: async (savedStyleId: string) => {
      const projectId = selectProjectId(store.getState());

      if (!projectId) {
        return;
      }

      await mutateAsync({
        savedStyleId,
        projectId,
      });
    },
    isLoading: isPending,
  };
}

export default useDeleteDesignLibrarySavedStyle;
