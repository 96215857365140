import type { UnpublishableWarningType } from "@editor/components/header/unpublishableWarnings";

import * as React from "react";

import Modal from "@editor/components/common/designSystem/Modal";
import { unpublishableWarnings } from "@editor/components/header/unpublishableWarnings";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import usePublishOnClickInfo from "@editor/hooks/usePublishingInfo";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { selectUnpublishableComponentIssues } from "@editor/reducers/core-reducer";
import { selectIsShopifyStoreClosed } from "@editor/reducers/ui-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";

import Button from "@replo/design-system/components/button";
import { isEmpty } from "replo-utils/lib/misc";

import { ModalLayout } from "../common/ModalLayout";

type PublishPageButtonProps = {
  isPreviewBeforePublishView: boolean;
};

export const PublishPageButton: React.FC<PublishPageButtonProps> = ({
  isPreviewBeforePublishView,
}) => {
  const reduxStore = useEditorStore();
  const [productTemplateConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [warningsModalOpen, setWarningsModalOpen] = React.useState(false);
  const isStoreClosed = useEditorSelector(selectIsShopifyStoreClosed);
  const errorToast = useErrorToast();
  const logEvent = useLogAnalytics();
  const setDraftElement = useSetDraftElement();
  const { products } = useStoreProductsFromDraftElement();

  const onSelectComponentId = (componentId: string) => {
    setDraftElement({ componentIds: [componentId] });
    setWarningsModalOpen(false);
  };

  const {
    publishNotAvailableReason,
    draftElementType,
    numAssignedProducts,
    publish,
    isPublishing,
    elementRecentlyPublished,
    pageEditorData,
  } = usePublishOnClickInfo();

  const publishOnClick = () => {
    const componentUnpublishableIssues = selectUnpublishableComponentIssues(
      reduxStore.getState(),
      products,
    );

    if (publishNotAvailableReason) {
      errorToast(
        publishNotAvailableReason.title,
        publishNotAvailableReason.message,
        "error.publish.unavailable",
        {
          publishNotAvailableReasonTitle: publishNotAvailableReason.title,
          publishNotAvailableReasonMessage: publishNotAvailableReason.message,
        },
      );
      return;
    }
    if (!isEmpty(componentUnpublishableIssues)) {
      setWarningsModalOpen(true);
    } else if (
      draftElementType === "shopifyProductTemplate" &&
      numAssignedProducts > 0
    ) {
      setConfirmationModalOpen(true);
    } else {
      publish();
    }
  };

  return (
    <>
      <div className="flex flex-1">
        <Button
          isFullWidth
          onClick={() => {
            if (isPreviewBeforePublishView) {
              logEvent("header.publish", {
                buttonLocation: "preview",
              });
            }
            publishOnClick();
          }}
          /**
           * Note (Noah, 2022-12-18): We specifically don't want the button to show
           * as disabled while an update to the element is saving, because it's really
           * distracting if it changes between blue and gray all the time when you're
           * editing the page. Instead, we bail out early in onClick if that's the case
           */
          disabled={elementRecentlyPublished || isPublishing || isStoreClosed}
          variant={isPublishing ? "secondary" : "primary"}
          size="base"
          tooltipText={`Publish ${pageEditorData.singularDisplayName} to Shopify`}
          isLoading={isPublishing}
          id="publish-button"
          data-testid="publish-button"
        >
          {elementRecentlyPublished
            ? "Published"
            : `Publish ${pageEditorData.singularDisplayName || ""} `}
        </Button>
      </div>
      <Modal
        isOpen={productTemplateConfirmationModalOpen}
        className="max-w-xl"
        onRequestClose={() => {
          setConfirmationModalOpen(false);
        }}
      >
        <ProductTemplateConfirmationModalContent
          numProducts={numAssignedProducts ?? 0}
          onCancel={() => {
            setConfirmationModalOpen(false);
          }}
          onConfirm={() => {
            publish();
            setConfirmationModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={warningsModalOpen}
        className="max-w-xl"
        onRequestClose={() => {
          setWarningsModalOpen(false);
        }}
      >
        <ModalLayout
          width="100%"
          mainContent={() => (
            <UnpublishableComponentsWarningModal
              onSelectComponentId={onSelectComponentId}
              onPublish={() => {
                publish();
                setWarningsModalOpen(false);
              }}
            />
          )}
        />
      </Modal>
    </>
  );
};

const ProductTemplateConfirmationModalContent = ({
  numProducts,
  onConfirm,
  onCancel,
}: {
  numProducts: number;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <div
      className="flex flex-col items-start rounded-lg bg-white p-3 text-default space-y-3"
      data-testid="product-template-publish-confirmation-modal"
    >
      <h1 className="text-lg font-medium text-default">
        Publish Product Template?
      </h1>
      <div className="text-sm">
        <div className="mb-2">
          After publishing, this Product Template will apply to the{" "}
          {numProducts > 1 ? numProducts : ""} assigned product
          {numProducts > 1 ? "s" : ""} on your live store.
        </div>
      </div>
      <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-end">
        <div className="mt-4 flex gap-2 items-center">
          <Button
            variant="secondary"
            size="lg"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button variant="primary" size="lg" type="button" onClick={onConfirm}>
            Publish Product Template
          </Button>
        </div>
      </div>
    </div>
  );
};
const UnpublishableComponentsWarningModal = ({
  onSelectComponentId,
  onPublish,
}: {
  onSelectComponentId: (componentId: string) => void;
  onPublish: () => void;
}) => {
  const { products } = useStoreProductsFromDraftElement();
  const componentUnpublishableIssues = useEditorSelector((state) =>
    selectUnpublishableComponentIssues(state, products),
  );
  const processedIssueTypes = new Set();
  return (
    <div className="w-full flex flex-col items-start rounded-lg bg-white p-3 text-default gap-3">
      <h1 className="text-lg font-medium text-default">Publishing Errors</h1>
      {componentUnpublishableIssues.flatMap(
        (componentUnpublishableIssue, i) => {
          return componentUnpublishableIssue.issues.map((issue) => {
            if (processedIssueTypes.has(issue.type)) {
              return null;
            }
            processedIssueTypes.add(issue.type);
            const issueData =
              unpublishableWarnings[issue.type as UnpublishableWarningType];
            return (
              <div key={issue.type}>
                {i > 0 && <hr className="h-px my-2 bg-gray-200 border-0" />}
                <h2 className="text-base font-medium">{issueData?.title}</h2>
                <p className="mb-4">{issueData?.text}</p>
                <ul className="list-disc list-inside mb-4">
                  {componentUnpublishableIssues
                    .filter((component) =>
                      component.issues.some((iss) => iss.type === issue.type),
                    )
                    .map(({ componentId, componentData }) => (
                      <li key={componentId} className="mt-2 pl-4">
                        <button
                          onClick={() => onSelectComponentId(componentId)}
                          className="underline text-blue-600 hover:text-blue-800"
                        >
                          {componentData.label}
                        </button>
                      </li>
                    ))}
                </ul>
                {issueData?.solution && (
                  <p>
                    <b>Solution: </b>
                    {issueData?.solution}
                  </p>
                )}
              </div>
            );
          });
        },
      )}
      <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-end bottom-0 sticky">
        <div className="mt-4 w-full">
          <Button
            variant="primary"
            size="lg"
            isFullWidth
            type="button"
            onClick={onPublish}
          >
            Publish Anyway
          </Button>
        </div>
      </div>
    </div>
  );
};
