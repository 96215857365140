import type { MediaSize } from "schemas/breakpoints";
import type { Component } from "schemas/component";
import type { AssetLoadingType } from "../../../shared/asset-loading";
import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { canUseDOM } from "replo-utils/dom/misc";
import { filterNulls } from "replo-utils/lib/array";
import { normalizeUrlScheme } from "replo-utils/lib/url";
import { mediaSizes } from "schemas/breakpoints";

import { BrokenImage as BrokenImageComponent } from "../../../shared/BrokenImage";
import LoaderImage from "../../../shared/LoaderImage";
import { PlaceholderImage } from "../../../shared/PlaceholderImage";
import {
  EditorCanvasContext,
  FeatureFlagsContext,
  GlobalWindowContext,
  RenderEnvironmentContext,
  ReploEditorActiveCanvasContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import {
  convertToShopifyOrReploSizedImageSource,
  getCurrentMediaSizeStyleProps,
  getMediaSizeStyles,
  getStylePropsFromComponent,
  isShopifyOrReploSizeableImage,
  isShopifyOrReploSizeableImageUrl,
  mapComponentStyleProps,
  MEDIA_MAX_SIZE,
  mediaQueries,
} from "../../../shared/utils/breakpoints";
import { useComponentClassNames } from "../../../shared/utils/renderComponents";

interface ImageProps extends RenderComponentProps {
  src?: string;
  alt?: string;
  loading?: AssetLoadingType;
}

const Image = (props: ImageProps) => {
  const { component, context, componentAttributes } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isImageUploading = useRuntimeContext(
    RuntimeHooksContext,
  ).useIsUploadingEditorMediaId(component.id);
  const isImageBroken = useRuntimeContext(
    RuntimeHooksContext,
  ).useIsBrokenEditorMediaId(component.id);
  const setIsImageBroken =
    useRuntimeContext(RuntimeHooksContext).useSetEditorBrokenMediaComponentId();

  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);
  const isNoMirrorEnabled = isEditorApp && featureFlags.noMirror;
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { componentStyles, currentMediaSizeStyles } =
    useComponentStyles(component);
  const editorCanvas = useRuntimeContext(EditorCanvasContext);
  const isPreviewMode = isEditorApp && !isEditorCanvas;
  const previewWidth = useRuntimeContext(RuntimeHooksContext).usePreviewWidth();

  const addDataEmDisableOnImages = featureFlags.dataEmDisableOnImages;

  const classNameMap = useComponentClassNames("image", component, context);

  const src = getSrc(
    currentMediaSizeStyles.__imageSource ??
      props.src ??
      // NOTE (Sebas, 2024-11-27): Defaulting to the desktop image source if the
      // current media size's image source is null. This prevents broken images
      // on the published page when users remove images for tablet or mobile.
      component.props.style?.__imageSource,
  );

  // NOTE (Chance 2024-03-26): An empty alt attribute marks the image as
  // decorative. Good for backwards compatibility on some screen readers that
  // may not respect the role attribute.
  const isHiddenFromA11yTree = props.component.props._accessibilityHidden;

  let alt = props.alt;
  if (isHiddenFromA11yTree) {
    alt = "";
  } else if (currentMediaSizeStyles.__imageAltText) {
    alt = currentMediaSizeStyles.__imageAltText;
  }

  if (isEditorApp && !src && isImageUploading) {
    return (
      <div {...componentAttributes} className={classNameMap?.placeholder}>
        <LoaderImage />
      </div>
    );
  }

  // Render default placeholder for the editor
  // TODO (Fran 2025-01-21): use FF (featureFlags.assetsRefresh) to render old placeholder
  if (isEditorApp && !src) {
    return (
      <div {...componentAttributes} className={classNameMap?.placeholder}>
        <PlaceholderImage />
      </div>
    );
  }

  // Render broken image for the editor
  if (isEditorApp && isImageBroken && featureFlags.assetsRefresh) {
    return (
      <div {...componentAttributes} className={classNameMap?.broken}>
        <BrokenImageComponent />
        <span
          style={{
            color: "#475569",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          Image unavailable
        </span>
      </div>
    );
  }

  const withSourceSet =
    Boolean(context.isPublishing && context.useSectionSettings) ||
    needsSourceSet({ component, src });

  let loading: AssetLoadingType = "eager";
  if (!isEditorApp && props.loading) {
    loading = props.loading;
  }

  // NOTE (Jackson, 2025-01-31): In no-mirror, we cannot use media queries for
  // responsive images. Unfortunately container queries aren't supported here.
  // We have to manually determine the correct src based on either which canvas
  // is active or the preview width.
  // further reading: https://github.com/w3c/csswg-drafts/issues/5889
  if (isNoMirrorEnabled) {
    let mediaSize: MediaSize;

    if (isPreviewMode) {
      if (previewWidth > MEDIA_MAX_SIZE.md) {
        mediaSize = "lg";
      } else if (previewWidth > MEDIA_MAX_SIZE.sm) {
        mediaSize = "md";
      } else {
        mediaSize = "sm";
      }
    } else {
      const canvasToMediaSize = {
        mobile: "sm",
        tablet: "md",
        desktop: "lg",
      } as const;
      mediaSize = canvasToMediaSize[editorCanvas];
    }

    const mediaSizeStyles = getMediaSizeStyles(componentStyles, mediaSize);
    const mediaSizeSrc = getSrc(mediaSizeStyles.__imageSource ?? props.src);

    const responsiveSrc = convertToShopifyOrReploSizedImageSource(
      mediaSizeSrc,
      mediaSize,
      mediaSizeStyles?.width,
    );

    return (
      <picture
        {...props.componentAttributes}
        key={props.componentAttributes.key}
        {...(addDataEmDisableOnImages ? { "data-em-disable": true } : {})}
      >
        <img
          role={isHiddenFromA11yTree ? "presentation" : undefined}
          src={responsiveSrc}
          alt={alt}
          className={classNameMap?.img}
          loading={loading}
          draggable={false}
          {...(addDataEmDisableOnImages ? { "data-em-disable": true } : {})}
        />
      </picture>
    );
  }

  return (
    <picture
      {...props.componentAttributes}
      key={props.componentAttributes.key}
      {...(addDataEmDisableOnImages ? { "data-em-disable": true } : {})}
      onError={() => {
        if (isEditorApp && featureFlags.assetsRefresh) {
          setIsImageBroken(component.id);
        }
      }}
    >
      {withSourceSet &&
        mediaSizes.map((mediaSize) => {
          const mediaSizeStyles = getMediaSizeStyles(
            componentStyles,
            mediaSize,
          );
          const mediaSizeSrc = getSrc(
            mediaSizeStyles.__imageSource ?? props.src,
          );

          return (
            <source
              key={mediaSize}
              srcSet={convertToShopifyOrReploSizedImageSource(
                mediaSizeSrc,
                mediaSize,
                mediaSizeStyles?.width,
              )}
              media={mediaQueries[mediaSize]}
              {...(addDataEmDisableOnImages ? { "data-em-disable": true } : {})}
            />
          );
        })}
      <img
        role={isHiddenFromA11yTree ? "presentation" : undefined}
        src={src}
        alt={alt}
        className={classNameMap?.img}
        loading={loading}
        {...(addDataEmDisableOnImages ? { "data-em-disable": true } : {})}
      />
    </picture>
  );
};

/**
 * React hook that returns all component styles along with the current
 * media size styles.
 */
function useComponentStyles(component: Component) {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const { activeCanvas } = useRuntimeContext(ReploEditorActiveCanvasContext);
  const componentStyles = React.useMemo(() => {
    return replaceUnderscoreDoubleQuoteValuesWithSingle(
      mapComponentStyleProps(
        getStylePropsFromComponent(component),
        (styles) => styles,
      ),
    );
  }, [component]);

  const currentMediaSizeStyles = React.useMemo(() => {
    return (
      getCurrentMediaSizeStyleProps(componentStyles, {
        // TODO (Chance 2024-05-29): This would probably be better if we were
        // using a match media listener, but this is probably fine since
        // `device` will trigger a render anyway
        matcher: (query) => globalWindow?.matchMedia(query).matches ?? false,
        isEditor: isEditorApp,
        canvas: activeCanvas,
      }) ?? {}
    );
  }, [componentStyles, activeCanvas, globalWindow, isEditorApp]);

  return {
    componentStyles,
    currentMediaSizeStyles,
  };
}

/**
 * Note (Martin, 2022-05-11): Convert double quotes and backticks in custom
 * styles (those that start with __) to single quotes so that the styling
 * doesn't break. REPL-2128
 */
function replaceUnderscoreDoubleQuoteValuesWithSingle<
  Props extends Component["props"],
>(props: Props): Props {
  const propsCopy = { ...props };
  const styleKeys = mediaSizes.map((size) => `style@${size}` as const);

  // Note (Chance, 2023-05-07) Noah mentioned that we'd normally use
  // `mapMediaCanvasStyles` for this, but I kept the logic inline for now
  // because I am trying to unwrap some of our abstractions. As this is an
  // isolated case, it's clearer to me what needs to be done to ensure Image
  // is properly supported.
  Object.keys(propsCopy).forEach((prop) => {
    const styleKey = prop as "style" | `style@${MediaSize}`;
    if (["style", ...styleKeys].includes(styleKey)) {
      const styleCopy = { ...propsCopy[styleKey] };
      Object.entries(styleCopy).forEach(([key, value]) => {
        if (key.includes("__") && typeof value === "string") {
          // NOTE (Gabe 2023-06-16): Because of how using Object.entries/keys
          // works, we lose the type of the key and have to ignore the type
          // error since we know that the object won't contain anything more
          // than the keys we've specified on RuntimeStyleProperties.

          // @ts-ignore
          styleCopy[key] = value.replace(/["`]/g, "'");
        }
      });
      propsCopy[styleKey] = styleCopy;
    }
  });
  return propsCopy;
}

function getSrc(value: unknown) {
  // Note (Noah, 2022-12-14, REPL-5566): If we resolved a dynamic data
  // url to a non-string, for example when we switch tabs dynamic data
  // from swatches to a data collection and the same dynamic data key now
  // resolves to something else, just treat this as if there was no source
  // specified so the page doesn't crash
  if (typeof value !== "string") {
    return undefined;
  }

  // Workaround for anything with //
  return normalizeUrlScheme(value);
}

function needsSourceSet({
  src,
  component,
}: {
  src: string | undefined;
  component: ImageProps["component"];
}) {
  const imageSources = new Set(
    filterNulls(
      mediaSizes.map((size) => component.props[`style@${size}`]?.__imageSource),
    ),
  );

  if (imageSources.size > 0) {
    return true;
  }

  // NOTE (Chance 2024-05-24, USE-983): This is a defensive measure +
  // optimization. `isShopifyOrReploSizeableImage` uses a potentially
  // expensive regex to check if a string is liquid expression with an
  // image URL. We never evaluate liquid in the browser so we can skip
  // this check. In extreme cases the test can cause infinite recursion
  // and cause the page/editor to crash.
  const testFunction = canUseDOM
    ? isShopifyOrReploSizeableImageUrl
    : isShopifyOrReploSizeableImage;

  if (src) {
    const hasAnyShopifyImage =
      testFunction(src) ||
      [...imageSources].some((source) => testFunction(source));

    return hasAnyShopifyImage;
  }

  return false;
}

export default Image;
