import type { ExperimentStatus } from "schemas/experiment";
import type { Experiment } from "schemas/generated/experiment";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import DeleteConfirmationModal from "@editor/components/common/DeleteConfirmationModal";
import Modal from "@editor/components/common/designSystem/Modal";
import {
  RadioGroup,
  RadioGroupItem,
} from "@editor/components/common/designSystem/RadioGroup";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { ModalLayout } from "@editor/components/common/ModalLayout";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import { HelpDocumentationIcon } from "@/features/experiments/components/HelpDocumentationIcon";
import { useExperimentEdit } from "@/features/experiments/tabs/hooks/useExperimentEdit";
import { useExperimentApi } from "@/features/experiments/utils";
import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { skipToken } from "@tanstack/react-query";
import { BiTrash } from "react-icons/bi";
import { BsBoxArrowUpRight, BsPlayFill, BsStopCircle } from "react-icons/bs";
import { generatePath, useNavigate } from "react-router-dom";
import { BillingTiers } from "schemas/billing";
import { getExperimentStatus } from "schemas/experiment";

type ExperimentActionsBarProps = {
  workspaceId: string | null;
  experiment: Experiment;
};

export const ExperimentActionsBar: React.FC<ExperimentActionsBarProps> = ({
  workspaceId,
  experiment,
}) => {
  const { watch, hasDataChanged } = useExperimentEdit();
  const logEvent = useLogAnalytics();
  const errorToast = useErrorToast();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;
  const navigate = useNavigate();

  const { data: planAllowsActivation } = trpc.experiment.canActivate.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );

  const {
    update: updateExperiment,
    remove: removeExperiment,
    complete: completeExperiment,
  } = useExperimentApi({
    workspaceId: workspaceId ?? "",
  });

  const update = updateExperiment.mutateAsync;
  const isUpdating = updateExperiment.isPending;
  const remove = removeExperiment.mutateAsync;
  const isRemoving = removeExperiment.isPending;
  const complete = completeExperiment.mutateAsync;
  const isCompleting = completeExperiment.isPending;

  const [isCompletionModalOpen, setCompletionModalOpen] = React.useState(false);
  const [isDeletionConfirmationOpen, setDeletionConfirmationOpen] =
    React.useState(false);

  const disableControls = isRemoving || isUpdating || isCompleting;

  const modal = useModal();

  if (!experiment || !workspaceId) {
    return null;
  }
  const experimentId = experiment.id;

  const status = getExperimentStatus(experiment);

  const allowActivate = status === "draft";
  const allowComplete = status === "active";

  const openBillingModal = () =>
    modal.openModal({
      type: "billingModal",
      props: {
        source: "experiments",
        billingPlanMessageKey: "billingPlan.experimentActivationAttempt",
      },
    });

  const updateWithStatus = async (status: ExperimentStatus) => {
    await update({
      ...experiment,
      status,
      variations: experiment.variations.map((v) => ({ ...v, isWinner: false })),
      analyticsLinkId: experiment.analyticsLinkId,
    });
  };

  // Action handlers
  const onActivate = async () => {
    if (planAllowsActivation) {
      await updateWithStatus("active");
      successToast("Experiment Activated", "");
      logEvent("experiment.launch", {
        variantCount: watch("variations").length ?? 0,
        description: watch("description") ?? "",
        billingPlanTier: subscriptionTier,
      });
    } else {
      openBillingModal();
    }
  };

  const onComplete = () => setCompletionModalOpen(true);
  const onCompleteCancel = () => {
    setCompletionModalOpen(false);
  };
  const onCompleteConfirm = async (
    winnerId: string,
    displaySelectionId: string,
  ) => {
    const winningVariation = experiment.variations.find(
      (variation) => variation.id === winnerId,
    );

    if (!winningVariation) {
      errorToast(
        "Error Completing Experiment",
        "No winning variation found. Please try again, or contact support@replo.app for help.",
        "error.experiment.complete",
        {
          winnerId,
          displaySelectionId,
        },
      );
      return;
    }

    await complete({
      workspaceId,
      experimentId: experiment.id,
      selectedDisplayWinnerVariationId:
        displaySelectionId === "no-winner" ? null : displaySelectionId,
      winnerVariationId: winningVariation.id,
    });

    logEvent("experiment.completed", { billingPlanTier: subscriptionTier });
    successToast("Experiment Completed 🥳", "");
    navigate(
      generatePath(routes.workspace.experiments.results, {
        workspaceId,
        experimentId: experiment.id,
      }),
    );
  };

  const onDeleteButtonClick = () => setDeletionConfirmationOpen(true);
  const onDeleteConfirmation = async () => {
    await remove({
      experimentId,
      workspaceId,
    });
    logEvent("experiment.deleted", { billingPlanTier: subscriptionTier });
    successToast("Experiment Deleted", "");
    navigate(generatePath(routes.workspace.experiments.list, { workspaceId }));
  };
  const onDeleteCancel = () => setDeletionConfirmationOpen(false);

  const disableActivateErrors = [];

  if (hasDataChanged) {
    disableActivateErrors.push(
      "Save your current changes in order to start the experiment.",
    );
  }

  if (!experiment.analyticsLinkId) {
    disableActivateErrors.push(
      "An experiment link must be provided to activate your experiment.",
    );
  }

  if (watch("variations").some((variation) => !variation.target)) {
    disableActivateErrors.push(
      "All variations must have a target URL in order to activate your experiment.",
    );
  }

  const hasDisableActivateErrors = disableActivateErrors.length > 0;

  const disableDeleteErrors = [];

  if (status === "active") {
    disableDeleteErrors.push(
      "Please complete your experiment in order to delete it.",
    );
  }

  const hasDisableDeleteErrors = disableDeleteErrors.length > 0;

  return (
    <div>
      {isDeletionConfirmationOpen && (
        <DeleteConfirmationModal
          assetName={experiment.name}
          assetTypeDisplayName="Experiment"
          confirmationType="delete"
          extraMessage={
            <span className="font-medium">
              It will no longer serve traffic.
            </span>
          }
          onDelete={() => void onDeleteConfirmation()}
          onRequestClose={onDeleteCancel}
        />
      )}
      <CompleteModal
        variations={experiment.variations}
        onCancel={onCompleteCancel}
        onConfirm={(winnerId, displaySelectionId) =>
          void onCompleteConfirm(winnerId, displaySelectionId)
        }
        isOpen={isCompletionModalOpen}
        initialSelection={experiment.variations[0]?.id ?? ""}
      />
      <div className="flex-none flex items-center content-center justify-between gap-x-3">
        <HelpDocumentationIcon />
        <IconButton
          variant="secondary"
          size="base"
          onClick={() => void onDeleteButtonClick()}
          tooltipText={
            hasDisableDeleteErrors ? disableDeleteErrors[0] : undefined
          }
          disabled={disableControls || hasDisableDeleteErrors}
          icon={<BiTrash size={16} />}
        />
        {allowActivate && (
          <Button
            variant="primary"
            size="base"
            tooltipText={
              hasDisableActivateErrors ? disableActivateErrors[0] : undefined
            }
            onClick={() => void onActivate()}
            disabled={disableControls || hasDisableActivateErrors}
            endEnhancer={<BsPlayFill size={12} />}
            collisionPadding={10}
          >
            Start Test
          </Button>
        )}
        {allowComplete && (
          <Button
            variant="secondary"
            size="base"
            onClick={() => void onComplete()}
            isLoading={disableControls}
            endEnhancer={<BsStopCircle size={12} />}
          >
            End Test
          </Button>
        )}
      </div>
    </div>
  );
};

type CompleteModalProps = {
  variations: Array<Variation>;
  isOpen: boolean;
  onConfirm: (winnerId: string, displaySelectionId: string) => void;
  onCancel: () => void;
  initialSelection?: string;
};

type SelectionStepProps = {
  title: string;
  children: React.ReactNode;
};

const SelectionStep = ({ title, children }: SelectionStepProps) => (
  <div className="flex flex-col gap-6 w-full">
    <div className="flex flex-col gap-2">
      <div className="text-xl font-medium text-slate-800">{title}</div>
    </div>
    {children}
  </div>
);

export const CompleteModal: React.FC<CompleteModalProps> = ({
  variations,
  isOpen,
  onConfirm,
  onCancel,
  initialSelection,
}) => {
  const [currentStep, setCurrentStep] =
    React.useState<ExperimentCompletionStep>("selectWinner");
  const [displaySelection, setDisplaySelection] = React.useState<string>(
    initialSelection ?? variations[0]?.id ?? "",
  );
  const [winnerSelection, setWinnerSelection] = React.useState<string | null>(
    null,
  );

  const handleClose = () => {
    setCurrentStep("selectWinner");
    setWinnerSelection(null);
    setDisplaySelection(initialSelection ?? variations[0]?.id ?? "");
    onCancel();
  };

  const handleBack = () => {
    setCurrentStep("selectWinner");
  };

  const handleNext = () => {
    if (currentStep === "selectWinner") {
      setCurrentStep("selectDirectTrafficTarget");
    } else {
      onConfirm(winnerSelection ?? displaySelection, displaySelection);
    }
  };

  return (
    <Modal isOpen={isOpen} className="w-auto" onRequestClose={handleClose}>
      <ModalLayout
        height="auto"
        width="533px"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 w-full">
            {currentStep === "selectWinner" ? (
              <SelectionStep title="Declare an outcome">
                <WinnerSelectionStep
                  variations={variations}
                  selection={displaySelection}
                  onSelectionChange={setDisplaySelection}
                />
              </SelectionStep>
            ) : (
              <SelectionStep title="Direct all traffic to">
                <DirectTrafficStep
                  variations={variations}
                  selectedVariation={variations.find(
                    (v) => v.id === winnerSelection,
                  )}
                  setWinnerSelection={setWinnerSelection}
                />
              </SelectionStep>
            )}
            <div className="flex items-center justify-between gap-2">
              <span className="text-xs text-slate-400">
                Step {currentStep === "selectWinner" ? 1 : 2} of 2
              </span>
              <div className="flex gap-2">
                {currentStep === "selectDirectTrafficTarget" && (
                  <Button variant="secondary" size="base" onClick={handleBack}>
                    Back
                  </Button>
                )}
                <Button
                  variant="primary"
                  size="base"
                  onClick={handleNext}
                  disabled={
                    currentStep === "selectDirectTrafficTarget" &&
                    !winnerSelection
                  }
                >
                  {currentStep === "selectDirectTrafficTarget"
                    ? "End and Direct Traffic"
                    : "Next"}
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </Modal>
  );
};

type WinnerSelectionStepProps = {
  variations: Array<Variation>;
  selection: string;
  onSelectionChange: (id: string) => void;
};

const WinnerSelectionStep: React.FC<WinnerSelectionStepProps> = ({
  variations,
  selection,
  onSelectionChange,
}) => (
  <div className="flex flex-col w-full">
    <RadioGroup
      value={selection}
      onValueChange={onSelectionChange}
      className="flex flex-col gap-2"
    >
      {variations.map((variation) => (
        <div
          key={variation.id}
          className="flex items-center gap-2.5 py-3 px-4 border border-slate-300 rounded cursor-pointer hover:bg-slate-50"
          onClick={() => onSelectionChange(variation.id)}
          role="radio"
          aria-checked={selection === variation.id}
        >
          <div className="flex-1 min-w-0">
            <div className="flex items-center justify-between">
              <div className="text-sm font-semibold">
                Variant {variation.slug} wins
              </div>
            </div>
            <div className="flex items-center text-sm font-normal text-slate-500 overflow-hidden">
              <span className="truncate block">{variation.target}</span>
              <IconButton
                className="flex-shrink-0"
                variant="inherit"
                icon={<BsBoxArrowUpRight size={12} />}
                to={variation.target ?? ""}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
          <RadioGroupItem
            value={variation.id}
            isSelected={selection === variation.id}
          />
        </div>
      ))}
      <div
        key="no-winner"
        className="flex items-center gap-1 py-3 px-4 border border-slate-300 rounded cursor-pointer hover:bg-slate-50"
        onClick={() => onSelectionChange("no-winner")}
        role="radio"
        aria-checked={selection === "no-winner"}
      >
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <div className="text-sm font-semibold">No winner</div>
          </div>
          <div className="flex items-center text-sm font-normal text-slate-500">
            Results were inconclusive or too close to call
          </div>
        </div>
        <RadioGroupItem
          value="no-winner"
          isSelected={selection === "no-winner"}
        />
      </div>
    </RadioGroup>
  </div>
);

type DirectTrafficStepProps = {
  variations: Array<Variation>;
  selectedVariation?: Variation;
  setWinnerSelection: (id: string) => void;
};

const DirectTrafficStep: React.FC<DirectTrafficStepProps> = ({
  variations,
  selectedVariation,
  setWinnerSelection,
}) => {
  // TODO (Kurt, 2024-11-16): After we update the experiment model in the database
  // along with updating how traffic is directed once a winner is declared, we will add
  // an "another page" option here where the user can specify a different page than
  // the available variations to direct traffic to. This is not possible yet due to
  // how the winner is tightly coupled to the variations and in the edgeserver.
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <RadioGroup
          value={selectedVariation?.id ?? "no-winner"}
          onValueChange={setWinnerSelection}
          className="flex flex-col gap-2"
        >
          {variations.map((variation) => (
            <div
              key={variation.id}
              className="flex items-center gap-2.5 py-3 px-4 border border-slate-300 rounded cursor-pointer hover:bg-slate-50"
              onClick={() => setWinnerSelection(variation.id)}
              role="radio"
              aria-checked={selectedVariation?.id === variation.id}
            >
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                  <div className="text-sm font-semibold">
                    Variant {variation.slug}
                  </div>
                </div>
                <div className="flex items-center text-sm font-normal text-slate-500 overflow-hidden">
                  <span className="truncate block">{variation.target}</span>
                  <IconButton
                    className="flex-shrink-0"
                    variant="inherit"
                    icon={<BsBoxArrowUpRight size={12} />}
                    to={variation.target ?? ""}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
              <RadioGroupItem
                value={variation.id}
                isSelected={selectedVariation?.id === variation.id}
              />
            </div>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

type ExperimentCompletionStep = "selectWinner" | "selectDirectTrafficTarget";
