import * as React from "react";

import {
  selectDraftComponent,
  selectDraftComponentsVisibleBreakpoints,
  selectGetAttribute,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import useApplyComponentAction from "./useApplyComponentAction";

export function useVisibleBreakpoints() {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();
  const visibleBreakpoints = useEditorSelector(
    selectDraftComponentsVisibleBreakpoints,
  );

  const onChangeVisibility = React.useCallback(
    (values: string[]) => {
      const setPropsValue: Record<string, any> = {};
      const getAttribute = selectGetAttribute(store.getState());
      for (const value of values) {
        if (
          isMixedStyleValue(visibleBreakpoints) ||
          !visibleBreakpoints.includes(value)
        ) {
          // Making the component VISIBLE for the selected breakpoint value
          setPropsValue[`${value}`] = {
            display:
              getAttribute(draftComponent, `props.${value}.__display`)?.value ||
              styleAttributeToEditorData["__display"].defaultValue,
          };
        } else {
          const thisDisplayValue =
            getAttribute(draftComponent, `props.${value}.display`)?.value ||
            styleAttributeToEditorData["display"].defaultValue;
          // Making the component HIDDEN for the selected breakpoint value.
          // Set __display correctly, so that display: flex components get
          // reset to display: flex (instead of block) when they're unhidden
          setPropsValue[`${value}`] = {
            __display: thisDisplayValue,
            display: "none",
          };

          const valueToSmallerAliases: Record<string, string[]> = {
            style: ["sm", "md"],
            "style@md": ["sm"],
          };

          // If we're setting the desktop style to hidden and we don't have a
          // mobile style set, we need to set the mobile style to block so
          // it will still show up on mobile
          if (valueToSmallerAliases[value]) {
            for (const alias of valueToSmallerAliases[value]!) {
              const smallerDisplayValue = getAttribute(
                draftComponent,
                `props.style@${alias}.display`,
              )?.value;

              if (!smallerDisplayValue) {
                const newValue = smallerDisplayValue || thisDisplayValue;
                setPropsValue[`style@${alias}`] = {
                  display: newValue,
                  __display: newValue,
                };
              }
            }
          }
        }
      }
      applyComponentAction({
        type: "setProps",
        value: setPropsValue,
      });
    },
    [store, draftComponent, visibleBreakpoints, applyComponentAction],
  );

  return {
    visibleBreakpoints,
    onChangeVisibility,
  };
}
