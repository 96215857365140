import type { SubmitHandler } from "react-hook-form";

import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import Modal from "@common/designSystem/Modal";
import toast from "@editor/components/common/designSystem/Toast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { publisherApi } from "@editor/reducers/api-reducer";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";

const formSchema = z.object({
  projectName: z.string().min(1, "Please provide a project name"),
});
type FormDataType = z.infer<typeof formSchema>;

type RenameProjectModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  projectId: string;
  projectName: string;
};

const RenameProjectModal: React.FC<RenameProjectModalProps> = ({
  isOpen,
  onRequestClose,
  projectId,
  projectName,
}) => {
  const dispatch = useDispatch();
  const logAnalytics = useLogAnalytics();

  const { mutate: updateProjectName } = trpc.project.updateName.useMutation({
    onSuccess: ({ project }) => {
      onRequestClose();
      dispatch(
        publisherApi.util.invalidateTags(["workspaces", "projects", "project"]),
      );
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.project.listWithStats.invalidate();
      toast({
        header: "Project Name Updated",
        message: `Project name has been changed to "${project.name}".`,
      });
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<FormDataType>({
    resolver: zodResolver(formSchema),
    defaultValues: { projectName: projectName ?? "" },
  });

  const onSubmit: SubmitHandler<FormDataType> = async (data) => {
    logAnalytics("project.edit", {
      type: "rename",
    });
    updateProjectName({
      projectId,
      name: data.projectName,
    });
  };

  const handleRenameProject = (e: React.FormEvent) => {
    void handleSubmit(onSubmit)(e);
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      className="w-[400px]"
    >
      <div className="flex flex-col gap-4">
        <p className="text-base font-medium">Rename Project</p>
        <LabeledControl label="Project Name" id="save-project-name">
          <Input
            size="base"
            autoFocus
            placeholder={projectName ?? "Enter project name..."}
            {...register("projectName")}
          />
        </LabeledControl>
        <Button
          variant="primary"
          size="lg"
          isFullWidth={true}
          onClick={handleRenameProject}
          isLoading={isSubmitting}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default RenameProjectModal;
