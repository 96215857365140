// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type {
  Active,
  DraggableSyntheticListeners,
  Modifier,
  PointerActivationConstraint,
  UniqueIdentifier,
} from "@dnd-kit/core";
import type { SortingStrategy } from "@dnd-kit/sortable";

import * as React from "react";

import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  horizontalListSortingStrategy,
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Badge } from "@replo/design-system/components/badge";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { RiMenuLine } from "react-icons/ri";

type SortableListOrientation = "vertical" | "horizontal" | "both-axis";
export type SortEnd = {
  oldIndex: number;
  newIndex: number;
};

export type ListProps = {
  onReorderEnd: (data: SortEnd) => void;
  onReorderStart?: (active: Active) => void;
  withDragHandle?: boolean;
  orientation?: SortableListOrientation;
  restrictToParent?: boolean;
  children: React.ReactElement[];
  activationConstraint?: PointerActivationConstraint;
  // When is set to true, this prop adds a box shadow to the items when dragging them.
  withShadow?: boolean;
};

type ContextProps = {
  withDragHandle: boolean;
  orientation: SortableListOrientation;
  withShadow: boolean;
};
const ChildContext = React.createContext<ContextProps>({
  withDragHandle: false,
  orientation: "vertical",
  withShadow: true,
});

export const SortableList: React.FC<ListProps> = ({
  orientation = "vertical",
  restrictToParent = true,
  onReorderStart,
  onReorderEnd,
  children,
  withDragHandle,
  withShadow = true,
  activationConstraint = {
    // We need to set a delay higher than `20` if we want to keep the `click` functionallity
    // of the sortable items, this will basically delay the drag event, letting the click event
    // to run before sorting. A good example of this are the `actions modifiers`
    delay: 100,
    tolerance: 15,
  },
}) => {
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);
  const itemsConfig = {
    withDragHandle: Boolean(withDragHandle),
    orientation,
    withShadow,
  };

  const items = children.map((c: React.ReactElement) => c.props.id);
  const getIndex = (id: UniqueIdentifier): number => {
    return items.indexOf(id);
  };
  const activeIndex = activeId ? getIndex(activeId) : -1;

  const modifiersMap: Record<SortableListOrientation, Modifier[]> = {
    vertical: [restrictToVerticalAxis],
    horizontal: [restrictToHorizontalAxis],
    "both-axis": [] as Modifier[],
  };

  const modifiers = modifiersMap[orientation];

  if (restrictToParent) {
    modifiers.push(restrictToParentElement);
  }

  const strategiesMap: Record<SortableListOrientation, SortingStrategy> = {
    vertical: verticalListSortingStrategy,
    horizontal: horizontalListSortingStrategy,
    "both-axis": rectSortingStrategy,
  };

  const strategy = strategiesMap[orientation];
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }
        onReorderStart?.(active);
        setActiveId(active.id);
      }}
      sensors={sensors}
      onDragEnd={({ over }) => {
        setActiveId(null);
        if (over) {
          const overIndex = getIndex(over.id);
          if (activeIndex !== overIndex) {
            onReorderEnd?.({ oldIndex: activeIndex, newIndex: overIndex });
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
      modifiers={modifiers}
    >
      <SortableContext items={items} strategy={strategy}>
        <ul
          className={classNames("flex w-full", {
            "flex-wrap gap-2": orientation === "both-axis",
            "min-w-[200px] flex-col gap-y-2": orientation === "vertical",
            "flex-row justify-start gap-x-1": orientation === "horizontal",
          })}
        >
          <ChildContext.Provider value={itemsConfig}>
            {children}
          </ChildContext.Provider>
        </ul>
      </SortableContext>
    </DndContext>
  );
};

type ItemProps = {
  id: UniqueIdentifier;
  children: React.ReactNode;
  className?: string;
};

const SortableItem: React.FC<ItemProps> = React.memo(
  ({ id, className, children }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
      setActivatorNodeRef,
    } = useSortable({ id });

    const { withDragHandle, orientation, withShadow } =
      React.useContext(ChildContext);

    const style = {
      transform: CSS.Transform.toString({
        x: transform?.x ?? 0,
        y: transform?.y ?? 0,
        scaleX: isDragging ? 1.03 : 1,
        scaleY: isDragging ? 1.03 : 1,
      }),
      transition,
    };

    return (
      <li
        ref={setNodeRef}
        style={style}
        className={twMerge(
          classNames(
            "grid list-none items-center justify-between gap-x-1",
            orientation !== "both-axis" ? "w-full" : "w-auto grid-flow-col",
            // If we show the drag handle we want to keep it at the right with a fixed width
            // and let the `children` content to grow until fits the available space
            // otherwise we just want to make `children` to take the full width
            {
              "grid-cols-[repeat(9,_minmax(0,_1fr))_1.5rem]":
                withDragHandle && orientation !== "both-axis",
              "grid-cols-9": !withDragHandle && orientation !== "both-axis",
              "relative z-50": isDragging,
              "shadow-md": isDragging && withShadow,
            },
          ),
          className,
        )}
        {...attributes}
        {...(!withDragHandle && listeners)}
      >
        <div
          className={classNames({
            "col-span-9": orientation !== "both-axis",
          })}
        >
          {children}
        </div>
        {withDragHandle && (
          <ReorderableHandle
            isDragging={isDragging}
            listeners={listeners}
            ref={setActivatorNodeRef}
          />
        )}
      </li>
    );
  },
);

type HandleProps = {
  isDragging: boolean;
  listeners?: DraggableSyntheticListeners;
};

const ReorderableHandle = React.memo(
  React.forwardRef<HTMLDivElement, HandleProps>(
    ({ isDragging, listeners }, ref) => {
      return (
        <div
          className={classNames(
            "col-start-10",
            isDragging ? "cursor-grabbing" : "cursor-grab",
          )}
          ref={ref}
          {...listeners}
        >
          <Badge
            type="icon"
            isFilled
            layoutClassName="h-6 w-6"
            UNSAFE_className="bg-subtle"
            icon={<RiMenuLine size={12} className="text-subtle" />}
          />
        </div>
      );
    },
  ),
);

SortableItem.displayName = "SortableItem";
ReorderableHandle.displayName = "ReorderableHandle";

export { SortableItem };

export { arrayMove } from "@dnd-kit/sortable";
