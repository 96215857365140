import type { SavedTextStyle } from "schemas/generated/designLibrary";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import { TEXT_MENU_ITEMS } from "@common/constants/textStyles";
import { DebouncedInput } from "@common/designSystem/Input";
import Selectable from "@common/designSystem/Selectable";
import {
  SavedFontFamilyControl,
  SavedFontSizeControl,
  SavedFontWeightControl,
  SavedLetterSpacingControl,
  SavedLineHeightControl,
  SavedTextAlignControl,
  SavedTextColorControl,
  SavedTextDecorationControl,
  SavedTextOutlineControl,
  SavedTextShadowControl,
  SavedTextTransformControl,
} from "@editor/components/designLibrary/controls/SavedTextStyleControls";
import ReploPreviewTextSavedStyle from "@editor/components/designLibrary/ReploPreviewTextSavedStyle";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import useUpdateDesignLibrarySavedStyle from "@editor/hooks/designLibrary/useUpdateDesignLibrarySavedStyle";

import Popover from "@replo/design-system/components/popover";

type TextSavedStyleModifierValues = {
  id: string;
  name: string;
  attributes: SavedStyleTextAttributes;
};

export type SavedStyleOpenedFrom = "leftBar" | "rightBar";

type TextSavedStyleModifierProps = {
  isPopoverOpen: boolean;
  onPopoverOpenChange: (isOpen: boolean) => void;
  editingSavedStyle: (SavedTextStyle & { id: string }) | null;
  sideOffset?: number;
  openedFrom?: SavedStyleOpenedFrom;
};

const HTML_TAG_OPTIONS = TEXT_MENU_ITEMS.map(({ id, title }) => ({
  value: id,
  label: title,
}));

const TextSavedStyleModifier: React.FC<TextSavedStyleModifierProps> = ({
  isPopoverOpen,
  onPopoverOpenChange,
  editingSavedStyle,
  sideOffset,
  openedFrom,
}) => {
  const { updateSavedStyle } = useUpdateDesignLibrarySavedStyle();

  // NOTE (Fran 2024-11-25): The offset is the width of the left bar minus 8px to account
  // for the padding of the panel.

  const handleAttributeChange = React.useCallback(
    (values: TextSavedStyleModifierValues) => {
      void updateSavedStyle({
        savedStyleId: values.id,
        attributes: values.attributes,
        name: values.name,
      });
    },
    [updateSavedStyle],
  );

  if (!editingSavedStyle) {
    return null;
  }

  return (
    <Popover isOpen={isPopoverOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside
        side={openedFrom === "leftBar" ? "right" : "left"}
        align="start"
        className="p-2 w-[250px]"
        title="Edit Text Style"
        sideOffset={sideOffset}
        onInteractOutside={() => {
          onPopoverOpenChange(false);
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex w-full h-20">
            <ReploPreviewTextSavedStyle
              attributes={editingSavedStyle.attributes}
              text={editingSavedStyle.name}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="name" className="text-xs font-medium">
              Name
            </label>
            <DebouncedInput
              id="name"
              placeholder="Name"
              value={editingSavedStyle.name}
              onValueChange={(value) => {
                handleAttributeChange({
                  ...editingSavedStyle,
                  name: value,
                });
              }}
            />
          </div>
          <div className="w-full">
            <SavedFontFamilyControl
              className="hover:bg-slate-100"
              value={editingSavedStyle.attributes.fontFamily}
              onChange={(fontFamily) => {
                handleAttributeChange({
                  ...editingSavedStyle,
                  attributes: {
                    ...editingSavedStyle.attributes,
                    fontFamily: fontFamily ?? null,
                  },
                });
              }}
              openedFrom={openedFrom}
            />
          </div>
          <ControlGroup label="Weight">
            <SavedFontWeightControl
              fontFamily={editingSavedStyle.attributes.fontFamily}
              value={editingSavedStyle.attributes.fontWeight}
              onChange={(fontWeight) => {
                handleAttributeChange({
                  ...editingSavedStyle,
                  attributes: {
                    ...editingSavedStyle.attributes,
                    fontWeight: fontWeight as
                      | "100"
                      | "200"
                      | "300"
                      | "400"
                      | "500"
                      | "600"
                      | "700"
                      | "800"
                      | "900"
                      | "950"
                      | undefined,
                  },
                });
              }}
            />
          </ControlGroup>
          <SavedFontSizeControl
            value={editingSavedStyle.attributes.fontSize}
            onChange={(fontSize) => {
              handleAttributeChange({
                ...editingSavedStyle,
                attributes: {
                  ...editingSavedStyle.attributes,
                  fontSize,
                },
              });
            }}
          />
          <SavedLineHeightControl
            value={editingSavedStyle.attributes.lineHeight}
            onChange={(lineHeight) => {
              handleAttributeChange({
                ...editingSavedStyle,
                attributes: {
                  ...editingSavedStyle.attributes,
                  lineHeight,
                },
              });
            }}
            disableWrapper
          />
          <SavedLetterSpacingControl
            value={editingSavedStyle.attributes.letterSpacing}
            onChange={(letterSpacing) => {
              handleAttributeChange({
                ...editingSavedStyle,
                attributes: {
                  ...editingSavedStyle.attributes,
                  letterSpacing,
                },
              });
            }}
            disableWrapper
          />
          <ControlGroup label="Color">
            <SavedTextColorControl
              value={editingSavedStyle.attributes.color}
              onChange={(color) => {
                handleAttributeChange({
                  ...editingSavedStyle,
                  attributes: {
                    ...editingSavedStyle.attributes,
                    color,
                  },
                });
              }}
              disableWrapper
              openedFrom={openedFrom}
            />
          </ControlGroup>
          <ControlGroup label="Style">
            <SavedTextDecorationControl
              value={editingSavedStyle.attributes.textDecoration}
              onChange={(textDecoration) => {
                handleAttributeChange({
                  ...editingSavedStyle,
                  attributes: {
                    ...editingSavedStyle.attributes,
                    textDecoration: textDecoration as
                      | "underline"
                      | "line-through"
                      | "italic"
                      | "none"
                      | undefined,
                  },
                });
              }}
            />
          </ControlGroup>
          <ControlGroup label="Casing">
            <SavedTextTransformControl
              value={editingSavedStyle.attributes.textTransform}
              onChange={(textTransform) => {
                handleAttributeChange({
                  ...editingSavedStyle,
                  attributes: {
                    ...editingSavedStyle.attributes,
                    textTransform: textTransform as
                      | "none"
                      | "lowercase"
                      | "uppercase"
                      | undefined,
                  },
                });
              }}
              disableWrapper
            />
          </ControlGroup>
          <ControlGroup label="Alignment">
            <SavedTextAlignControl
              value={editingSavedStyle.attributes.textAlign}
              onChange={(textAlign) => {
                handleAttributeChange({
                  ...editingSavedStyle,
                  attributes: {
                    ...editingSavedStyle.attributes,
                    textAlign: textAlign as
                      | "left"
                      | "right"
                      | "center"
                      | undefined,
                  },
                });
              }}
              disableWrapper
            />
          </ControlGroup>
          <SavedTextOutlineControl
            value={editingSavedStyle.attributes.textStroke}
            onChange={(textStroke) => {
              handleAttributeChange({
                ...editingSavedStyle,
                attributes: {
                  ...editingSavedStyle.attributes,
                  textStroke,
                },
              });
            }}
          />
          <SavedTextShadowControl
            value={editingSavedStyle.attributes.textShadow}
            onChange={(textShadow) => {
              handleAttributeChange({
                ...editingSavedStyle,
                attributes: {
                  ...editingSavedStyle.attributes,
                  textShadow,
                },
              });
            }}
            openedFrom={openedFrom}
          />
          <div className="flex items-center gap-1">
            <ModifierLabel label="Tag" />
            <Selectable
              id="htmlTag"
              options={HTML_TAG_OPTIONS}
              value={editingSavedStyle.attributes.htmlTag}
              onSelect={(htmlTag) => {
                handleAttributeChange({
                  ...editingSavedStyle,
                  attributes: {
                    ...editingSavedStyle.attributes,
                    htmlTag: htmlTag as
                      | "h1"
                      | "h2"
                      | "h3"
                      | "h4"
                      | "h5"
                      | "h6"
                      | "p",
                  },
                });
              }}
            />
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="top-0 left-0" />
    </Popover>
  );
};

export default TextSavedStyleModifier;
