import type { ReploSimpleProjectWithStats } from "schemas/generated/project";

import * as React from "react";

import ProjectImage from "@editor/components/dashboard/projects/GalleryProjectItem/ProjectImage";
import ProjectTitle from "@editor/components/dashboard/projects/GalleryProjectItem/ProjectTitle";
import ShopifyButton from "@editor/components/dashboard/projects/GalleryProjectItem/ShopifyButton";
import StatSection from "@editor/components/dashboard/projects/GalleryProjectItem/StatSection";
import ProjectMenu from "@editor/components/dashboard/projects/ProjectMenu";
import RenameProjectModal from "@editor/components/dashboard/projects/RenameProjectModal";
import { useEditedTimeString } from "@editor/hooks/useEditedTimeString";
import { getProjectName } from "@editor/utils/project-utils";

import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { isShopifyIntegrationEnabled } from "schemas/utils";

type ProjectItemProps = {
  project: ReploSimpleProjectWithStats;
  onSelectProject: (e: React.MouseEvent) => void;
  className?: string;
  shouldShowEditedAt?: boolean;
  showWorkspaceColumn?: boolean;
  hideEllipsisButton?: boolean;
};

const GalleryProjectItem: React.FC<ProjectItemProps> = ({
  project,
  onSelectProject,
  className,
  shouldShowEditedAt = true,
  showWorkspaceColumn = false,
  hideEllipsisButton = false,
}) => {
  const editedTimeString = useEditedTimeString(
    project.lastEditedAt,
    project.createdAt,
  );
  const [isRenameProjectModalOpen, setIsRenameProjectModalOpen] =
    React.useState(false);
  const uniqueId = `project-${project.id}-${React.useId()}`;

  return (
    <>
      <div
        className={twMerge(
          classNames(
            "flex flex-col bg-white border border-slate-300 border-solid border-gray-200 rounded-lg",
            className,
          ),
        )}
      >
        <ProjectImage
          projectId={project.id}
          workspaceId={project.ownerWorkspaceId}
          onSelectProject={onSelectProject}
        />
        <div className="flex flex-col p-4 gap-[8px]">
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <div className="overflow-hidden">
                <ProjectTitle
                  title={getProjectName(project)}
                  uniqueId={uniqueId}
                  onSelectProject={onSelectProject}
                />
              </div>

              {!hideEllipsisButton && (
                <div className="flex flex-row items-center -mr-2">
                  <ProjectMenu
                    project={project}
                    isRenameModalOpen={isRenameProjectModalOpen}
                    onRenameOpen={() => setIsRenameProjectModalOpen(true)}
                    isVertical
                  />
                </div>
              )}
            </div>

            {showWorkspaceColumn && (
              <div
                className="text-slate-500 text-xs mb-2"
                aria-describedby={uniqueId}
              >
                {project.ownerWorkspace?.name ?? "-"}
              </div>
            )}
          </div>

          <StatSection elementCounts={project.elementCounts} />

          <div className="flex flex-row justify-between items-center">
            {shouldShowEditedAt && (
              <div
                className="text-slate-500 text-xs"
                aria-describedby={uniqueId}
              >
                {editedTimeString}
              </div>
            )}

            <ShopifyButton
              isConnected={isShopifyIntegrationEnabled(project)}
              shopifyUrl={project.integrations?.shopify?.store.shopifyUrl}
              project={project}
            />
          </div>
        </div>
      </div>
      <RenameProjectModal
        isOpen={isRenameProjectModalOpen}
        onRequestClose={() => setIsRenameProjectModalOpen(false)}
        projectId={project.id}
        projectName={getProjectName(project)}
      />
    </>
  );
};

export default GalleryProjectItem;
