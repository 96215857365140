// eslint-disable-next-line no-restricted-imports
import { extendTailwindMerge } from "tailwind-merge";

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      h: ["h-small", "h-base", "h-large"],
      "font-size": [
        "typ-header-small",
        "typ-header-base",
        "typ-header-h3",
        "typ-header-h2",
        "typ-header-h1",
        "typ-body-small",
        "typ-body-base",
        "typ-body-large",
        "typ-label-small",
        "typ-label-base",
        "typ-button-small",
        "typ-button-base",
        "typ-button-large",
      ],
    },
    theme: {
      padding: ["none", "small", "base", "large"],
    },
  },
});

export default twMerge;
