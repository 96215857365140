import type { ReploProject } from "schemas/generated/project";

import * as React from "react";

import InputComponent from "@editor/components/common/designSystem/Input";
import toast from "@editor/components/common/designSystem/Toast";
import { Loader } from "@editor/components/common/Loader";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { publisherApi } from "@editor/reducers/api-reducer";
import { selectPublishedElementsAmount } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getProjectName } from "@editor/utils/project-utils";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function usePublishedElementsAmount(projectId: string | undefined) {
  // TODO (BEN O, 02-01-2025): remove once fully moved to trpc
  const publishedElementsFromRedux = useEditorSelector(
    selectPublishedElementsAmount,
  );
  const { data: elementsList } = trpc.element.listAllElementsMetadata.useQuery(
    projectId ? { projectId } : skipToken,
  );

  return React.useMemo(() => {
    if (isFeatureEnabled("refactor-element-settings")) {
      return elementsList?.filter((element) => element.isPublished).length ?? 0;
    }
    return publishedElementsFromRedux;
  }, [elementsList, publishedElementsFromRedux]);
}

export const ProjectSettingsTab = () => {
  const { project, isLoading: isProjectLoading } = useCurrentProjectContext();

  if (isProjectLoading || !project) {
    return <Loader />;
  }

  return <InnerProjectsSettingsTab project={project} />;
};

type InnerProjectsSettingsTabProps = {
  project: ReploProject;
};

const InnerProjectsSettingsTab: React.FC<InnerProjectsSettingsTabProps> = ({
  project,
}) => {
  const { openModal } = useModal();

  const projectId = project.id;
  const { data: projectActiveSubscription } =
    trpc.subscriptions.getActiveSubscriptionByProject.useQuery(
      projectId ?? skipToken,
    );
  const projectName = project ? getProjectName(project) : "";
  const [editedProjectName, setEditedProjectName] =
    React.useState<string>(projectName);
  const publishedElementsAmount = usePublishedElementsAmount(projectId);
  const dispatch = useDispatch();
  const { mutate: updateProjectName } = trpc.project.updateName.useMutation({
    onSuccess: () => {
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.project.findByUserId.invalidate();
      void trpcUtils.project.listWithStats.invalidate();
      void trpcUtils.project.get.invalidate();
      // TODO (Sebas, 2024-07-12): Remove these tags once the migration to TRPC
      // is done.
      dispatch(publisherApi.util.invalidateTags(["workspaces", "projects"]));
      toast({
        header: "Project Name Updated",
        message: `Project name has been changed to “${editedProjectName}”.`,
      });
    },
    onSettled: () => {
      setIsRenameProjectLoading(false);
    },
  });
  const navigate = useNavigate();
  const userIsOwner = useIsWorkspaceOwner(project?.ownerWorkspace?.id);
  const hasActiveShopifySubscriptionOnThisProject = Boolean(
    projectActiveSubscription?.paymentProcessor === "shopify" &&
      project?.integrations?.shopify?.store.shopifyUrl,
  );
  const hasPublishedElements =
    publishedElementsAmount && publishedElementsAmount > 0;
  const shouldDisableDeleteButton =
    hasPublishedElements ||
    !userIsOwner ||
    hasActiveShopifySubscriptionOnThisProject;
  const [isRenameProjectLoading, setIsRenameProjectLoading] =
    React.useState(false);

  const handleRenameClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setIsRenameProjectLoading(true);
    updateProjectName({
      projectId,
      name: editedProjectName,
    });
  };

  const handleDeleteProject = () => {
    openModal({
      type: "deleteProjectModal",
      props: {
        projectId,
        callback: () => {
          navigate("/home");
        },
        from: "details",
      },
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center content-center text-sm">
        <div className="font-semibold flex gap-3 items-center">
          Project Settings
        </div>
      </div>
      <div className="max-w-2xl">
        <p className="text-sm text-slate-400">
          Manage settings for your project.
        </p>
        <div className="space-y-6 text-sm pt-4">
          <div className="flex flex-col w-full max-w-3xl gap-3">
            <div className="font-semibold mb-2">Rename Project</div>
            <InputComponent
              size="base"
              type="text"
              maxLength={256}
              value={editedProjectName}
              placeholder={projectName}
              onChange={(e) => setEditedProjectName(e.currentTarget.value)}
            />
            <div className="pt-2">
              <Button
                variant="primary"
                size="base"
                onClick={(e) => void handleRenameClick(e)}
                disabled={editedProjectName === ""}
                isLoading={isRenameProjectLoading}
              >
                Rename
              </Button>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <h3 className="text-sm font-semibold">Delete Project</h3>
            <p className="text-sm text-slate-400">
              You must unpublish any live pages and cancel the Replo
              subscription associated with this project before deleting it.
            </p>
            <div className="text-slate-700 text-sm font-semibold flex items-center gap-2">
              Live Pages: {publishedElementsAmount}
            </div>
            <div>
              <Button
                variant="danger"
                size="base"
                className="text-xs"
                disabled={shouldDisableDeleteButton}
                onClick={handleDeleteProject}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
