import * as React from "react";

import Selectable from "@editor/components/common/designSystem/Selectable";
import Separator from "@editor/components/common/designSystem/Separator";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsCheck } from "react-icons/bs";
import z from "zod";

export type Field =
  | "project.name"
  | "project.lastEditedAt"
  | "project.workspace"
  | "project.createdAt";
export type Direction = "asc" | "desc";

export type OrderState = {
  field: Field;
  direction: Direction;
};

const LOCAL_STORAGE_SORT_KEY = "replo-projects-sort";

const DEFAULT_ORDER: OrderState = {
  field: "project.lastEditedAt",
  direction: "desc",
};

const orderStateSchema = z.object({
  field: z.enum([
    "project.name",
    "project.lastEditedAt",
    "project.workspace",
    "project.createdAt",
  ]),
  direction: z.enum(["asc", "desc"]),
});

const isOrderState = (value: unknown): value is OrderState => {
  return orderStateSchema.safeParse(value).success;
};

type SortDropdownProps = {
  order: OrderState;
  onOrderChange: (order: OrderState) => void;
  showWorkspaceSort?: boolean;
};

// ToDo (Ben O., 01-23-2025): Replace this with selectable once design system implements this
const SortDropdown: React.FC<SortDropdownProps> = ({
  order: externalOrder,
  onOrderChange,
  showWorkspaceSort = false,
}) => {
  const [order, setOrder] = useLocalStorageState<OrderState>(
    LOCAL_STORAGE_SORT_KEY,
    externalOrder ?? DEFAULT_ORDER,
    { validate: isOrderState },
  );

  React.useEffect(() => {
    onOrderChange(order);
  }, [order, onOrderChange]);

  const [isOpen, setIsOpen] = React.useState(false);
  const logAnalytics = useLogAnalytics();
  const selectableRef = React.useRef<HTMLDivElement>(null);
  const [selectableWidth, setSelectableWidth] = React.useState<number>(0);

  React.useEffect(() => {
    if (selectableRef.current) {
      setSelectableWidth(selectableRef.current.offsetWidth);
    }
  }, []);

  const getValueLabel = () => {
    const field = order.field;
    const direction = order.direction;
    let label = "Sort by";

    if (field === "project.name") {
      label = `Alphabetical${direction === "desc" ? " (Z-A)" : " (A-Z)"}`;
    } else if (field === "project.lastEditedAt") {
      label = `Last edited (${direction === "desc" ? "Newest" : "Oldest"} first)`;
    } else if (field === "project.workspace") {
      label = `Workspace${direction === "desc" ? " (Z-A)" : " (A-Z)"}`;
    } else if (field === "project.createdAt") {
      label = `Created (${direction === "desc" ? "Newest" : "Oldest"} first)`;
    } else if (field === "project.shopifyUrl") {
      label = `Shopify URL${direction === "desc" ? " (Z-A)" : " (A-Z)"}`;
    }

    return label;
  };

  const handleSortChange = (field: Field, direction: Direction) => {
    logAnalytics("dashboard.projectView.sort", {
      sortBy: field,
      order: direction,
    });
    setOrder({ field, direction });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Enter":
      case " ":
        e.preventDefault();
        setIsOpen((prev) => !prev);
        break;
      case "Escape":
        e.preventDefault();
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const selectableOptions = [
    {
      value: `${order.field}-${order.direction}`,
      label: () => (
        <div className="flex w-full shrink-0 items-center p-1 text-xs text-default gap-2">
          <span className="font-normal truncate">{getValueLabel()}</span>
        </div>
      ),
    },
  ];

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div
          ref={selectableRef}
          role="combobox"
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-label="Sort options"
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <Selectable
            size="sm"
            value={`${order.field}-${order.direction}`}
            options={selectableOptions}
            onSelect={() => setIsOpen(true)}
            placeholder="Sort by"
            valueIndicator={() => (
              <div className="flex w-full items-center px-2 gap-2 py-[6.5px] text-slate-500 text-sm">
                <span className="truncate">{getValueLabel()}</span>
              </div>
            )}
            contentClassName="divide-y divide-slate-100"
            labelClassName="text-slate-500 px-2"
            isOpen={false}
            style={{
              height: 32, // NOTE (Ben O., 2025-01-21): this should be changed once the design system is updated
            }}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="p-2"
        style={{ width: selectableWidth }}
      >
        <div className="flex flex-col gap-2 text-default typ-body-small">
          <div className="flex flex-col gap-1">
            <h3 className="typ-header-small px-2">Sort By</h3>
            <RadioGroup
              defaultValue={order.field}
              value={order.field}
              onValueChange={(value) =>
                handleSortChange(value as Field, order.direction)
              }
              className="flex flex-col gap-0.5 items-start"
            >
              <RadioGroupItem
                value="project.name"
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  order.field === "project.name" && "bg-menu-item-selected",
                  order.field !== "project.name" && "hover:bg-menu-item-hover",
                )}
              >
                Project Name
                {order.field === "project.name" && <BsCheck size={16} />}
              </RadioGroupItem>
              <RadioGroupItem
                value="project.lastEditedAt"
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  order.field === "project.lastEditedAt" &&
                    "bg-menu-item-selected",
                  order.field !== "project.lastEditedAt" &&
                    "hover:bg-menu-item-hover",
                )}
              >
                Last Edited
                {order.field === "project.lastEditedAt" && (
                  <BsCheck size={16} />
                )}
              </RadioGroupItem>
              <RadioGroupItem
                value="project.createdAt"
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  order.field === "project.createdAt" &&
                    "bg-menu-item-selected",
                  order.field !== "project.createdAt" &&
                    "hover:bg-menu-item-hover",
                )}
              >
                Created Date
                {order.field === "project.createdAt" && <BsCheck size={16} />}
              </RadioGroupItem>
              {showWorkspaceSort && (
                <RadioGroupItem
                  value="project.workspace"
                  className={twMerge(
                    "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                    order.field === "project.workspace" &&
                      "bg-menu-item-selected",
                    order.field !== "project.workspace" &&
                      "hover:bg-menu-item-hover",
                  )}
                >
                  Workspace
                  {order.field === "project.workspace" && <BsCheck size={16} />}
                </RadioGroupItem>
              )}
            </RadioGroup>
          </div>
          <Separator />
          <div className="flex flex-col gap-1">
            <h3 className="typ-header-small px-2">Sort Order</h3>
            <RadioGroup
              defaultValue={order.direction}
              value={order.direction}
              onValueChange={(value) =>
                handleSortChange(order.field, value as Direction)
              }
              className="flex flex-col gap-0.5 items-start"
            >
              <RadioGroupItem
                value="asc"
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  order.direction !== "asc" && "hover:bg-menu-item-hover",
                  order.direction === "asc" && "bg-menu-item-selected",
                )}
              >
                {order.field === "project.name" ||
                order.field === "project.workspace"
                  ? "A to Z"
                  : "Oldest First"}
                {order.direction === "asc" && <BsCheck size={16} />}
              </RadioGroupItem>
              <RadioGroupItem
                value="desc"
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  order.direction !== "desc" && "hover:bg-menu-item-hover",
                  order.direction === "desc" && "bg-menu-item-selected",
                )}
              >
                {order.field === "project.name" ||
                order.field === "project.workspace"
                  ? "Z to A"
                  : "Newest First"}
                {order.direction === "desc" && <BsCheck size={16} />}
              </RadioGroupItem>
            </RadioGroup>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default SortDropdown;
