import * as React from "react";

import ProjectsTable from "@editor/components/dashboard/projects/ProjectsTable";
import DashboardLayout from "@editor/components/layouts/DashboardLayout";
import { trpc } from "@editor/utils/trpc";

import { Outlet, useNavigate } from "react-router-dom";

const AllProjects: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading } = trpc.project.listWithStats.useQuery(
    {},
    {
      refetchOnMount: true,
    },
  );

  return (
    <DashboardLayout>
      <div className="bg-white no-scrollbar col-span-9 mt-4 flex flex-grow flex-col items-start gap-y-2 overflow-scroll">
        <ProjectsTable
          title="All Projects"
          subtitle="These are all the projects shared with you across all workspaces"
          projects={data?.projects}
          isLoading={isLoading}
          buttonLabel="New Project"
          onButtonClick={() => {
            // NOTE (Sebas, 2024-12-11): We need to pass the state to the new project flow
            // so we can redirect to the correct page after the modal is closed.
            navigate("project/new", { state: { from: "allProjects" } });
          }}
          showWorkspaceColumn
        />
        <Outlet />
      </div>
    </DashboardLayout>
  );
};

export default AllProjects;
