import type { RelativeRoutingType, To } from "react-router-dom";
import type { SpinnerVariants } from "../spinner/Spinner";
import type { ButtonSize, ButtonVariant } from "./button-shared";

import React from "react";

import { Spinner } from "@replo/design-system/components/spinner";
import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { Link } from "react-router-dom";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  variant: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
  className?: string;
  id?: string;
  isFullWidth?: boolean;
  // Patrick (2025-01-23): Added this React.ReactElement | false here to accept react elements only (so we can clone the className), but also allow
  // syntax like arrowRight && <BsArrowRight />, so that we don't have to explicitly pass through undefined
  startEnhancer?: React.ReactElement | false;
  endEnhancer?: React.ReactElement | false;
  tooltipText?: React.ReactNode;
  to?: To;
  target?: string;
  rel?: string;
  collisionPadding?: number;
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
  spinnerSize?: number;
  hasMinDimensions?: boolean;
  contentClassName?: string;
  childContainerClassName?: string;
}

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      hasMinDimensions = true,
      children,
      variant,
      size = "sm",
      isLoading = false,
      startEnhancer,
      childContainerClassName,
      endEnhancer,
      tooltipText,
      to,
      target,
      rel,
      contentClassName,
      collisionPadding = 0,
      replace,
      state,
      preventScrollReset,
      relative,
      isFullWidth,
      spinnerSize,
      disabled,
      className,
      onClick,
      ...props
    },
    ref,
  ) => {
    const baseClass = twMerge(
      "group flex items-center justify-center font-medium cursor-pointer transition duration-300",
      "rounded font-sans whitespace-nowrap",

      // Special styles
      disabled && "opacity-50 cursor-not-allowed",
      isFullWidth && "w-full",

      // Size related styles
      size === "sm" && "p-small h-small typ-button-small",
      size === "base" && "p-base h-base typ-button-base",
      size === "lg" && "p-large h-large typ-button-large",

      // Loading styles
      isLoading && variant === "tertiary" && "bg-light-surface",
      isLoading && variant === "link" && "bg-info-soft",
      isLoading && variant === "dangerLink" && "bg-danger-soft",

      // Min Dimension styles
      hasMinDimensions && size === "sm" && "min-w-[64px]",
      hasMinDimensions && size === "base" && "min-w-[80px]",
      hasMinDimensions && size === "lg" && "min-w-[92px]",

      // Variant styles
      variantClassNames[variant],

      className,
    );

    const baseEnhancerClass = twMerge(
      size === "sm" && "typ-button-small",
      size === "base" && "typ-button-base",
      size === "lg" && "typ-button-large",
    );

    const content = (
      <div
        className={twMerge(
          "flex items-center",
          isFullWidth && "w-full",
          contentClassName,
        )}
      >
        {/* Patrick (2025-01-23): 
          We're cloning the startEnhancer here in order to pass the baseEnhancerClass to it as a default.
          We force the enhancer to be a reactElement.

          If this gets inefficient in the future, we can memoize the startEnhancer
        */}
        {startEnhancer &&
          React.cloneElement(startEnhancer, {
            className: twMerge(
              baseEnhancerClass,
              startEnhancer.props.className,
            ),
          })}
        <div
          className={twMerge(
            "relative flex items-center justify-center",
            isFullWidth && "w-full",
            childContainerClassName,
          )}
        >
          {isLoading && (
            <Spinner
              UNSAFE_className="absolute"
              size={spinnerSize ? spinnerSize : 16}
              variant={spinnerVariantMap[variant]}
            />
          )}
          {children && (
            <div
              className={twMerge(
                isFullWidth && "w-full",
                isLoading && "invisible",
                startEnhancer && "ml-1",
                endEnhancer && "mr-1",
              )}
            >
              {children}
            </div>
          )}
        </div>
        {/* Patrick (2025-01-23): 
          We're cloning the startEnhancer here in order to pass the baseEnhancerClass to it as a default.
          We force the enhancer to be a reactElement.

          If this gets inefficient in the future, we can memoize the endEnhancer
        */}
        {endEnhancer &&
          React.cloneElement(endEnhancer, {
            className: twMerge(baseEnhancerClass, endEnhancer.props.className),
          })}
      </div>
    );

    const sharedProps = {
      className: baseClass,
      style: props.style,
      disabled,
      "aria-disabled": disabled || undefined,
      onClick,
    };

    if (to) {
      const linkProps = {
        to,
        target,
        rel: rel || (target === "_blank" ? "noreferrer" : undefined),
        replace,
        state,
        preventScrollReset,
        relative,
        // Patrick (12-19-2024): The reason we have this onClick here is because we can't disable a <Link> so if it's disabled we need the onClick to not work
        onClick: (event: any) => {
          if (disabled) {
            event.preventDefault();
          } else if (onClick) {
            onClick(event);
          }
        },
      };
      return (
        <Tooltip
          content={tooltipText}
          collisionPadding={collisionPadding}
          isFullWidth={isFullWidth}
          triggerAsChild
        >
          <Link
            {...sharedProps}
            {...linkProps}
            ref={ref as React.Ref<HTMLAnchorElement>}
          >
            {content}
          </Link>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        content={tooltipText}
        collisionPadding={collisionPadding}
        isFullWidth={isFullWidth}
        triggerAsChild
      >
        <button
          {...sharedProps}
          {...props}
          onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
          type={props.type || "button"}
          ref={ref as React.Ref<HTMLButtonElement>}
        >
          {content}
        </button>
      </Tooltip>
    );
  },
);

const variantClassNames: Record<ButtonVariant, string> = {
  primary: "bg-primary hover:bg-primary-hover text-white",
  secondary: "bg-light-surface hover:bg-light-surface-hover",
  tertiary: "bg-transparent hover:bg-light-surface",
  link: "bg-transparent text-primary hover:bg-info-soft",
  danger: "bg-danger hover:bg-danger-hover text-white",
  dangerLink: "bg-transparent hover:bg-danger-soft text-danger",
  secondaryDanger: "text-red-600 bg-red-100 hover:bg-gray-200",
  inherit: "bg-inherit text-inherit m-0 p-0",
  noStyle: "",
};

const spinnerVariantMap: Record<ButtonVariant, SpinnerVariants> = {
  primary: "white",
  secondary: "secondary",
  tertiary: "secondary",
  danger: "danger",
  link: "primary",
  dangerLink: "danger",
  secondaryDanger: "danger",
  inherit: "primary",
  noStyle: "primary",
};

Button.displayName = "Button";

export default Button;
