import type { Option } from "@replo/design-system/components/combobox/types";
import type { ConstructedAnalyticsLink } from "schemas/generated/analyticsLink";
import type { Experiment } from "schemas/generated/experiment";

import * as React from "react";

import Input from "@common/designSystem/Input";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { useModal } from "@hooks/useModal";

import { DetailsContainer } from "@/features/experiments/components/DetailsContainer";
import { useExperimentEdit } from "@/features/experiments/tabs/hooks/useExperimentEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button";
import { Combobox } from "@replo/design-system/components/combobox";
import { Spinner } from "@replo/design-system/components/spinner";
import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken } from "@tanstack/react-query";
import copy from "copy-to-clipboard";
import { useForm } from "react-hook-form";
import {
  BsCaretDownFill,
  BsInfoCircle,
  BsLockFill,
  BsSearch,
} from "react-icons/bs";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { BillingTiers } from "schemas/billing";
import { EDGESERVER_DOMAIN_PROD } from "schemas/edgeserver";
import { isPathSafeSlug } from "schemas/utils";
import * as z from "zod";

type SubSectionType = "domain" | "shortName" | "slug";

type SubSectionProps = {
  title: string;
  className?: string;
  type: SubSectionType;
  value: string;
  options?: Option[];
  helpTooltipText: string;
  error?: string;
  setValue: (value: string) => void;
  onBlur?: (value: string) => void;
  onEnter?: (value: string) => void;
};

const SubSection: React.FC<SubSectionProps> = ({
  title,
  className,
  type,
  value,
  options,
  helpTooltipText,
  error,
  setValue,
  onBlur,
  onEnter,
}) => {
  const { value: localValue, onChange: handleChange } = useOverridableInput({
    value,
    onValueChange: setValue,
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      onEnter(localValue);
    }
  };

  const inputComponent = exhaustiveSwitch({ type })({
    shortName: () => (
      <Combobox.Root
        options={options ?? []}
        value={value}
        onChange={setValue}
        areOptionsCreatable
      >
        <Combobox.TriggerInput
          placeholder="Select Group Name"
          startEnhancer={<BsSearch className="h-3 w-3" />}
        />
        <Combobox.Content />
      </Combobox.Root>
    ),
    domain: () => (
      <Combobox
        options={options ?? []}
        value={value}
        onChange={setValue}
        placeholder="Select Domain"
        endEnhancer={
          <BsCaretDownFill className="h-2 w-2 text-subtle ml-auto" />
        }
      />
    ),
    slug: () => (
      <Input
        size="base"
        placeholder="Enter Slug"
        value={localValue}
        onChange={handleChange}
        onBlur={() => onBlur?.(localValue)}
        onKeyDown={handleKeyDown}
        unsafe_inputClassName="text-xs"
      />
    ),
  });

  return (
    <div className={twMerge(className, "flex flex-col gap-1")}>
      <div className="flex flex-row gap-1 items-center">
        <span className="text-sm font-semibold">{title}</span>
        <span className="text-danger">*</span>
        <Tooltip
          content={helpTooltipText}
          triggerAsChild
          side="top"
          delay={300}
        >
          <div>
            {" "}
            <BsInfoCircle size={12} className="text-muted" />{" "}
          </div>
        </Tooltip>
      </div>

      {inputComponent}

      {error && <span className="text-xs text-danger mt-1">{error}</span>}
    </div>
  );
};

type DomainOrShortName = {
  value: string;
  id: string;
};

type CreateNewLinkProps = {
  experimentName: Experiment["name"];
  handleLinkSubsectionChange: (value: string) => void;
  shouldForceCreateNewLink: boolean;
  workspaceId: string | undefined;
  links: ConstructedAnalyticsLink[];
  customDomains: DomainOrShortName[];
  shortNames: DomainOrShortName[];
};

const createNewLinkSchema = (links: ConstructedAnalyticsLink[]) =>
  z
    .object({
      customDomain: z.string(),
      shortName: z.string().nullable(),
      path: z.string().min(1, "Slug is required").refine(isPathSafeSlug, {
        message: "Slug must contain only letters, numbers, or hyphens",
      }),
    })
    .superRefine((data, ctx) => {
      if (!data.customDomain && !data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Either a custom domain or short name must be provided",
          path: ["_form"],
        });
      }

      if (data.customDomain === EDGESERVER_DOMAIN_PROD && !data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Short name is required when using the default domain",
          path: ["_form"],
        });
      }

      if (data.customDomain !== EDGESERVER_DOMAIN_PROD && data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Short name should not be provided when using a custom domain",
          path: ["_form"],
        });
      }

      const doesLinkAlreadyExist = links.some((link) => {
        const { customDomainValue, shortNameValue, path } = link;

        return (
          customDomainValue ===
            (data.customDomain === EDGESERVER_DOMAIN_PROD
              ? null
              : data.customDomain) &&
          shortNameValue === data.shortName &&
          path === data.path
        );
      });

      if (doesLinkAlreadyExist) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "This combination of domain, group name and slug is already used by another link.",
          path: ["_form"],
        });
      }
    });

type AnalyticsLinkFormData = z.infer<ReturnType<typeof createNewLinkSchema>> & {
  _form?: string;
};

const CreateNewLink: React.FC<
  Omit<CreateNewLinkProps, "customDomains" | "shortNames">
> = (props) => {
  const { workspaceId } = props;
  const { data: customDomains, isLoading: isLoadingCustomDomains } =
    trpc.workspace.getCustomDomains.useQuery(
      workspaceId ? workspaceId : skipToken,
    );

  const { data: shortNames, isLoading: isLoadingShortNames } =
    trpc.workspace.getShortNames.useQuery(
      workspaceId ? workspaceId : skipToken,
    );

  if (
    isLoadingCustomDomains ||
    isLoadingShortNames ||
    !customDomains ||
    !shortNames
  ) {
    return <Spinner size={25} variant="primary" />;
  }

  return (
    <LoadedCreateNewLink
      customDomains={customDomains}
      shortNames={shortNames}
      {...props}
    />
  );
};

const LoadedCreateNewLink: React.FC<CreateNewLinkProps> = ({
  experimentName,
  handleLinkSubsectionChange,
  shouldForceCreateNewLink,
  workspaceId,
  links,
  customDomains,
  shortNames,
}) => {
  const {
    mutateAsync: createAnalyticsLinkAsync,
    isPending: isCreatingAnalyticsLink,
  } = trpc.analyticsLink.create.useMutation({});

  const { dispatchExperimentEdit } = useExperimentEdit();

  const domainOptions = [
    { value: EDGESERVER_DOMAIN_PROD, label: EDGESERVER_DOMAIN_PROD },
    ...(customDomains ?? []).map((domain) => ({
      value: domain.value,
      label: domain.value,
    })),
  ];

  const shortNameOptions = (shortNames ?? []).map((shortName) => ({
    value: shortName.value,
    label: shortName.value,
  }));

  const customDomainOptions = domainOptions.filter(
    (option) => option.value !== EDGESERVER_DOMAIN_PROD,
  );

  const domainOptionsValue =
    customDomainOptions[0]?.value ?? EDGESERVER_DOMAIN_PROD;

  const defaultAnalyticsLinkCreate: AnalyticsLinkFormData = {
    customDomain: domainOptionsValue,
    shortName:
      domainOptionsValue !== EDGESERVER_DOMAIN_PROD
        ? null
        : shortNameOptions[0]?.value ?? null,
    path: experimentName,
  };

  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<AnalyticsLinkFormData>({
    resolver: zodResolver(createNewLinkSchema(links)),
    defaultValues: defaultAnalyticsLinkCreate,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const formLevelError = errors._form?.message;

  const handleDomainChange = (value: string) => {
    setValue("customDomain", value);
    if (value !== EDGESERVER_DOMAIN_PROD) {
      setValue("shortName", null);
    } else {
      setValue("shortName", shortNameOptions[0]?.value ?? null);
    }
    void trigger();
  };

  const handleShortNameChange = (value: string) => {
    setValue("shortName", value);
    void trigger();
  };

  const handleSlugChange = (value: string) => {
    setValue("path", value);
    void trigger();
  };

  const onSubmit = async (data: AnalyticsLinkFormData) => {
    if (workspaceId) {
      const analyticsLink = {
        ...data,
        customDomain:
          data.customDomain === EDGESERVER_DOMAIN_PROD
            ? null
            : data.customDomain,
      };

      const result = await createAnalyticsLinkAsync({
        workspaceId,
        analyticsLink,
      });
      await trpcUtils.analyticsLink.list.invalidate({ workspaceId });
      await trpcUtils.workspace.getShortNames.invalidate(workspaceId);

      handleLinkSubsectionChange("chooseLink");
      successToast("Experiment Link Created", "");
      dispatchExperimentEdit({
        type: "changeProperty",
        payload: { key: "analyticsLinkId", value: result.analyticsLinkId },
      });
    }
  };

  const customDomain = watch("customDomain");
  const isShortNameFieldRequired =
    !customDomain || customDomain === EDGESERVER_DOMAIN_PROD;

  const handleSubmission = handleSubmit(onSubmit);
  const onCreateLink = () => {
    void handleSubmission();
  };

  const modal = useModal();
  const openAddCustomDomainModal = () =>
    modal.openModal({
      type: "customDomainModal",
    });

  return (
    <div className="border border-slate-300 rounded-md p-4 flex flex-col gap-3">
      <div className="grid grid-cols-12 gap-3">
        <SubSection
          title="Domain"
          className="col-span-4"
          type="domain"
          value={customDomain}
          options={[
            ...domainOptions,
            {
              value: "addCustomDomain",
              label: "Add Custom Domain",
              component: (
                <span className="text-accent text-xs font-medium">
                  Add Custom Domain
                </span>
              ),
              onClick: openAddCustomDomainModal,
            },
          ]}
          setValue={handleDomainChange}
          helpTooltipText="Use our default reploedge domain, or use your own custom domain"
          error={errors.customDomain?.message}
        />
        {isShortNameFieldRequired && (
          <SubSection
            title="Group Name"
            className="col-span-4"
            type="shortName"
            value={watch("shortName") ?? ""}
            options={shortNameOptions}
            setValue={handleShortNameChange}
            helpTooltipText="A unique name identifying your workspace, to distinguish it from others in the Replo system. Only required when using reploedge.com as your domain"
            error={errors.shortName?.message}
          />
        )}
        <SubSection
          title="Slug"
          className="col-span-4"
          type="slug"
          value={watch("path") ?? ""}
          setValue={handleSlugChange}
          helpTooltipText="The URL path that distinguishes this link from the other links in your workspace"
          error={errors.path?.message}
        />
      </div>
      {formLevelError && (
        <span className="text-xs text-danger mt-1">{formLevelError}</span>
      )}
      <div className="flex flex-row justify-end gap-2">
        {!shouldForceCreateNewLink && (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handleLinkSubsectionChange("chooseLink")}
          >
            Cancel
          </Button>
        )}

        <Button
          variant="primary"
          size="sm"
          onClick={onCreateLink}
          isLoading={isCreatingAnalyticsLink}
          disabled={!isValid}
        >
          Create Link
        </Button>
      </div>
    </div>
  );
};

const ChooseLinkOption: React.FC<{
  url: string;
  isActive: boolean;
}> = ({ url, isActive }) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="flex-1 truncate">{url}</span>
      {isActive && <span className="h-1 w-1 bg-green-600 rounded-full" />}
    </div>
  );
};

type ChooseLinkProps = {
  analyticsLinkId: string | null;
  handleChooseLinkChange: (analyticsLinkId: string) => void;
  links: ConstructedAnalyticsLink[];
  isEditable?: boolean;
};
const ChooseLink: React.FC<ChooseLinkProps> = ({
  analyticsLinkId,
  handleChooseLinkChange,
  links,
  isEditable = true,
}) => {
  const handleOptionClick = (analyticsLinkId: string) => {
    handleChooseLinkChange(analyticsLinkId);
  };

  return (
    <Combobox
      onChange={handleOptionClick}
      options={
        links.map((constructedLink) => {
          const { id, url, isActive } = constructedLink;
          return {
            value: id,
            label: url,
            component: <ChooseLinkOption url={url} isActive={isActive} />,
            isDisabled: isActive,
          };
        }) ?? []
      }
      value={analyticsLinkId ?? undefined}
      isDisabled={!isEditable}
      triggerClassName="w-full"
      placeholder="Select Link"
      endEnhancer={
        isEditable ? (
          <BsCaretDownFill className="ml-auto" size={8} />
        ) : (
          <BsLockFill className="ml-auto" size={12} />
        )
      }
    />
  );
};

type LinkSectionProps = {
  isEditable?: boolean;
  linkSubSection: "chooseLink" | "createNewLink";
  handleLinkSubsectionChange: (value: string) => void;
  links: ConstructedAnalyticsLink[];
  experiment: {
    analyticsLinkId: Experiment["analyticsLinkId"];
    name: Experiment["name"];
  };
  shouldForceCreateNewLink: boolean;
};

export const LinkSection: React.FC<LinkSectionProps> = ({
  isEditable = true,
  linkSubSection,
  handleLinkSubsectionChange,
  links,
  experiment,
  shouldForceCreateNewLink,
}) => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;

  const { dispatchExperimentEdit } = useExperimentEdit();

  const handleChooseLinkChange = (analyticsLinkId: string) => {
    dispatchExperimentEdit({
      type: "changeProperty",
      payload: { key: "analyticsLinkId", value: analyticsLinkId },
    });
  };

  const getSelectedLinkUrl = (): string | undefined => {
    if (!links || !experiment.analyticsLinkId) {
      return undefined;
    }

    const selectedLink = links.find(
      (link) => link.id === experiment.analyticsLinkId,
    );

    return selectedLink?.url;
  };

  return (
    <DetailsContainer
      title="Your Test Link"
      isRequired
      headerComponent={
        isEditable && linkSubSection === "chooseLink" ? (
          <div className="text-accent">
            <Button
              variant="tertiary"
              size="base"
              className="text-accent"
              onClick={() => handleLinkSubsectionChange("createNewLink")}
            >
              Create New Link
            </Button>
          </div>
        ) : undefined
      }
    >
      <div className="flex flex-col gap-3">
        {linkSubSection === "chooseLink" ? (
          <ChooseLink
            analyticsLinkId={experiment.analyticsLinkId}
            handleChooseLinkChange={handleChooseLinkChange}
            links={links ?? []}
            isEditable={isEditable}
          />
        ) : (
          <CreateNewLink
            experimentName={experiment.name}
            handleLinkSubsectionChange={handleLinkSubsectionChange}
            shouldForceCreateNewLink={shouldForceCreateNewLink}
            workspaceId={workspaceId}
            links={links ?? []}
          />
        )}

        <div className="flex flex-row justify-end">
          <div className="flex flex-row gap-2">
            {experiment.analyticsLinkId && linkSubSection === "chooseLink" && (
              <Button
                variant={!isEditable ? "tertiary" : "secondary"}
                size="base"
                onClick={() => {
                  const url = getSelectedLinkUrl();
                  if (url) {
                    copy(url);
                    logEvent("experiment.link.copy", {
                      billingPlanTier: subscriptionTier,
                    });
                  }
                }}
                className={!isEditable ? "text-blue-600" : ""}
              >
                Copy Link
              </Button>
            )}

            {!isEditable && (
              <Button
                variant="secondary"
                size="base"
                to={getSelectedLinkUrl() ?? ""}
                onClick={() => {
                  logEvent("experiment.link.preview", {
                    billingPlanTier: subscriptionTier,
                  });
                }}
              >
                Preview
              </Button>
            )}
          </div>
        </div>
      </div>
    </DetailsContainer>
  );
};
