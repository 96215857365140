import * as React from "react";

import { Loader } from "@editor/components/common/Loader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { isDevelopment } from "@editor/utils/env";
import { trpc } from "@editor/utils/trpc";

import { AllMetricsTable } from "@/features/analytics/AllMetricsTable";
import AnalyticsLayout from "@/features/analytics/Layout";
import { SetupPage } from "@/features/analytics/Setup";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BillingTiers } from "schemas/billing";

export const AnalyticsOverview: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { data: isEnabled, isLoading: isLoadingEnableCheck } =
    trpc.analytics.isEnabled.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );
  const { data: workspaceSubscription, isLoading: isLoadingSubscription } =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspaceId ?? reactQuerySkipToken,
    );
  const isLoading =
    !workspaceId || isLoadingEnableCheck || isLoadingSubscription;
  const isCurrentlyPaying =
    Boolean(workspaceSubscription) &&
    workspaceSubscription?.name !== BillingTiers.FREE;

  return (
    <AnalyticsLayout>
      {isLoading ? (
        <Loader className="grow h-full min-h-0 h-[70vh]" />
      ) : (
        <LoadedAnalyticsOverview
          isEnabled={isEnabled ?? false}
          isCurrentlyPaying={isCurrentlyPaying}
        />
      )}
    </AnalyticsLayout>
  );
};

type LoadedAnalyticsOverviewProps = {
  isEnabled: boolean;
  isCurrentlyPaying: boolean;
};

const LoadedAnalyticsOverview = ({
  isEnabled,
  isCurrentlyPaying,
}: LoadedAnalyticsOverviewProps) => {
  const showAllMetricsTable = isEnabled || isDevelopment;
  return (
    <>
      {showAllMetricsTable ? (
        <AllMetricsTable />
      ) : (
        <SetupPage isCurrentlyPaying={isCurrentlyPaying} />
      )}
    </>
  );
};
