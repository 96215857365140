import * as React from "react";

export const BrokenImage = (props: any) => {
  return (
    <svg
      {...props}
      width={props.size ?? "40"}
      height={props.size ?? "40"}
      className={props.className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.32812 3.33325L36.6615 36.6666M17.3448 17.35C17.0351 17.6597 16.6674 17.9054 16.2628 18.073C15.8582 18.2406 15.4245 18.3269 14.9865 18.3269C14.5485 18.3269 14.1148 18.2406 13.7102 18.073C13.3055 17.9054 12.9378 17.6597 12.6281 17.35C12.3184 17.0403 12.0728 16.6727 11.9052 16.268C11.7376 15.8634 11.6513 15.4297 11.6513 14.9917C11.6513 14.5537 11.7376 14.12 11.9052 13.7154C12.0728 13.3108 12.3184 12.9431 12.6281 12.6334M22.4948 22.4999L9.99479 34.9999M29.9948 19.9999L34.9948 24.9999V8.33325C34.9948 7.4492 34.6436 6.60135 34.0185 5.97623C33.3934 5.35111 32.5455 4.99992 31.6615 4.99992H14.9948M5.97812 5.9834C5.66743 6.29097 5.4206 6.65692 5.25184 7.06023C5.08308 7.46353 4.99573 7.89621 4.99479 8.3334V31.6667C4.99479 32.5508 5.34598 33.3986 5.9711 34.0238C6.59622 34.6489 7.44407 35.0001 8.32812 35.0001H31.6615C32.5781 35.0001 33.4148 34.6334 34.0115 34.0167"
        stroke="#475569"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
