import type { ComponentActionType } from "@editor/types/component-action-type";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { DataTable } from "replo-runtime/shared/DataTable";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type {
  ReploElement,
  ReploElementVersionRevision,
} from "schemas/generated/element";
import type { ReploProject } from "schemas/generated/project";
import type { ReploSymbol } from "schemas/generated/symbol";
import type { RuntimeStyleProperties } from "schemas/styleAttribute";

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum EditorMode {
  edit = "edit",
  preview = "preview",
  versioning = "versioning",
  aiGeneration = "aiGeneration",
  archived = "archived",
}

export type OriginalStyles = Partial<
  Record<EditorCanvas, Record<string, RuntimeStyleProperties>>
>;

export interface StreamingUpdate {
  id: string;
  isStreaming: boolean;
  actions: ComponentActionType[];
  draftElementComponent: ReploElement["component"];
  originalStyles: OriginalStyles;
  // Note (Evan, 2024-07-16): A mapping from a text component's ID to its new text value
  textMap: Record<string, string>;
  // Note (Evan, 2024-07-17): Increments to repaint the canvas. Note that we can't just use
  // actions.length, since some actions (specifically setting text) do not require a repaint.
  repaintKey: number;
}

export interface ElementVersionRevisionHistory {
  title: string;
  items: ReploElementVersionRevision[];
}
export interface ElementsState {
  draftElementId: string | null;
  draftComponentIds: string[];
  draftRepeatedIndex: string | null;
  draftSymbolInstanceId: string | null;
  /* Set when we are editing the master of a symbol */
  draftSymbolId: string | null;
  componentIdToDraftVariantId: Record<string, string | undefined>;
  // NOTE (Reinaldo 2022-24-02): Mapping is the current source of truth for the elements structure
  // which came from the server
  mapping: Record<ReploElement["id"], ReploElement>;
  versionMapping: Record<ReploElement["id"], number>;
  elementRevisions: Record<ReploElement["id"], ReploElementVersionRevision[]>;
  selectedRevisionId: string | null;
  selectedArchivedElementId: ReploElement["id"] | null;
  isLoading: boolean;
  draftElementColors: string[];
  draftElementFontFamilies: string[];
  streamingUpdate: StreamingUpdate | null;
  srcDocFontUrls: string[];
  srcDocFonts: {
    family: string;
    fontFaceDeclarations: string[];
  }[];
}

export interface DataTablesState {
  draft: DataTable | null;
  mapping: Record<string, DataTable>;
  isLoading: boolean;
}

export type EditorOptions = {
  isDebugPanelVisible: boolean;
};

export interface HistoryStateType {
  index: number;
  operations: Record<
    number,
    {
      type: string;
      elementId: string;
      componentIds: string[];
      undo: any;
      redo: any;
    }
  >;
  lastOperation: "undo" | "redo" | null;
  maxSize: number;
}

export interface SymbolsState {
  mapping: Record<string, ReploSymbol>;
}

export interface GenericRequestState {
  isLoading: boolean;
}

export interface CoreState {
  project: ReploProject | null;
  elements: ElementsState;
  symbols: SymbolsState;
  dataTables: DataTablesState;
  editorOptions: EditorOptions;
  isLoading: boolean;
  isPublishing: boolean;
  isPreviewMode: boolean;
  editorMode: EditorMode;
  publishingError: any;
  history: HistoryStateType;
  elementRecentlyPublished: boolean;
  pendingElementUpdates: string[];
  updatesSinceLastRequestFinished: number;
  elementUpdateInProgress: boolean;
  componentDataMapping: ComponentDataMapping;
}
