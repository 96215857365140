import * as React from "react";

import { getComponentEditorData } from "@components/editor/componentTypes";
import {
  selectDraftComponentIds,
  selectDraftComponentType,
} from "@editor/reducers/core-reducer";

import { BsInfoCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";

const DraftComponentPaneHeader = () => {
  const draftComponentIds = useSelector(selectDraftComponentIds);
  const draftComponentType = useSelector(selectDraftComponentType);
  const draftComponentEditorData = getComponentEditorData(
    draftComponentType ?? "",
  );

  if (draftComponentIds.length === 1 && !draftComponentEditorData) {
    return null;
  }

  return (
    <div className="flex flex-row items-center justify-between pt-1">
      <span className="text-xs font-bold text-default">
        {draftComponentIds.length > 1
          ? `${draftComponentIds.length} Items Selected`
          : draftComponentEditorData?.defaultName}
      </span>
      {draftComponentIds.length === 1 &&
        draftComponentEditorData?.documentationLink && (
          <a
            href={draftComponentEditorData.documentationLink}
            className="flex cursor-pointer items-center text-xs text-blue-600"
            target="_blank"
            rel="noreferrer"
          >
            Learn More <BsInfoCircleFill aria-hidden className="ml-1" />
          </a>
        )}
    </div>
  );
};

export default DraftComponentPaneHeader;
