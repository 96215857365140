import type { ReploElement } from "schemas/generated/element";

import { useCallback } from "react";

import { successToast } from "@editor/components/common/designSystem/Toast";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { setEditorMode } from "@editor/reducers/core-reducer";
import { setLeftBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorDispatch } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { generatePath, useLocation, useNavigate } from "react-router-dom";

type UnarchiveOptions = {
  onSuccess?: () => void;
  from: "modal" | "button" | "pane";
};

export function useUnarchiveElement() {
  const dispatch = useEditorDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = useCurrentProjectId();
  const { mutate: unarchiveMutation, isPending } =
    trpc.element.unarchive.useMutation();

  const handleUnarchive = useCallback(
    (element: ReploElement, options: UnarchiveOptions) => {
      unarchiveMutation(element.id, {
        // TODO (Juan, 2025-01-27): Move onSuccess callback to TRPC mutation in a follow-up PR
        // This will make the code more consistent with other mutations in the app
        onSuccess: () => {
          successToast(
            `${elementTypeToEditorData[element.type].singularDisplayName} unarchived successfully`,
            `Your ${elementTypeToEditorData[element.type].singularDisplayName} has been unarchived successfully`,
          );

          void trpcUtils.element.listArchived.invalidate();
          void trpcUtils.element.listAllElementsMetadata.invalidate();

          navigate(
            `${generatePath(routes.editor.element, {
              projectId,
              elementId: element.id,
            })}${location.search}`,
          );

          dispatch(setEditorMode(EditorMode.edit));
          dispatch(setLeftBarActiveTab("elements"));

          options.onSuccess?.();
        },
      });
    },
    [unarchiveMutation, navigate, projectId, location.search, dispatch],
  );

  return {
    unarchiveElement: handleUnarchive,
    isLoading: isPending,
  };
}
