import * as React from "react";

import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";

const TAG_DISPLAY_MAP: Record<string, string> = {
  h1: "H1",
  h2: "H2",
  h3: "H3",
};

const StyleBadges: React.FC = () => {
  const { colorSavedStyles, textSavedStyles } =
    useGetDesignLibrarySavedStyles();

  return (
    <div className="flex gap-1">
      {colorSavedStyles.map((style, index) => (
        <div
          key={`color-${index}`}
          className="w-6 h-6 rounded border border-slate-300"
          style={{ backgroundColor: style.attributes.color }}
        />
      ))}
      {textSavedStyles.map((style, index) => (
        <div
          key={`text-${index}`}
          className="w-6 h-6 rounded bg-white border border-slate-300 flex items-center justify-center text-xs font-medium"
        >
          {TAG_DISPLAY_MAP[style.attributes.htmlTag] ?? "P"}
        </div>
      ))}
    </div>
  );
};

export default StyleBadges;
