import type { SelectedValue } from "@editorComponents/Lists";
import type {
  ProductIdsWithVariantIds,
  StoreVariant,
} from "replo-runtime/shared/types";
import type { ProductRef } from "schemas/product";

import * as React from "react";

import * as ProductSelectionPopover from "@components/editor/page/ProductSelectionPopover";
import {
  Group,
  GroupHeader,
} from "@editor/components/common/designSystem/Group";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { useSpecificStoreProducts } from "@editor/hooks/useStoreProducts";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { RegularList } from "@editorComponents/Lists";

import Popover from "@replo/design-system/components/popover";
import Tooltip from "@replo/design-system/components/tooltip";
import truncate from "lodash-es/truncate";
import { filterNulls } from "replo-utils/lib/array";
import { parseInteger } from "replo-utils/lib/math";
import { hasOwnProperty } from "replo-utils/lib/misc";

import { useIsDebugMode } from "../debug/useIsDebugMode";

export type VariantSelectionPopoverContextValue = {
  onSubmit: (value: ProductIdsWithVariantIds | null) => void;
  value?: ProductIdsWithVariantIds | null;
};
const VariantSelectionPopoverContext =
  React.createContext<VariantSelectionPopoverContextValue | null>(null);
VariantSelectionPopoverContext.displayName = "VariantSelectionPopoverContext";

function useVariantSelectionPopoverContext() {
  const context = React.useContext(VariantSelectionPopoverContext);
  if (!context) {
    throw new Error(
      "useVariantSelectionPopoverContext must be used within a VariantSelectionPopover component",
    );
  }
  return context;
}

const VariantSelectionPopover: React.FC<
  React.PropsWithChildren & VariantSelectionPopoverContextValue
> = ({ onSubmit, value, children }) => {
  return (
    <div>
      <Popover>
        <VariantSelectionPopoverContext.Provider value={{ onSubmit, value }}>
          {children}
        </VariantSelectionPopoverContext.Provider>
      </Popover>
    </div>
  );
};

const SELECT_ALL_VALUES = "SELECT_ALL_VALUES";

const VariantList = ({
  handleChange,
  variants,
  selectedVariants,
}: {
  variants: StoreVariant[];
  selectedVariants: string[] | null;
  handleChange: (updatedSelectedVariants: string[] | null) => void;
}) => {
  const selectedItems =
    selectedVariants ?? variants.map(({ id }) => id.toString());
  const options = React.useMemo(() => {
    const variantOptionMapping = variants.map((variant) => {
      return {
        label: truncate(variant.name, { length: 60 }),
        value: variant.id.toString(),
        isSelectable: true,
        isDefaultActive: selectedItems.includes(variant.id.toString()),
      };
    });
    const allOptionsSelected = variantOptionMapping.every(
      ({ value }) =>
        selectedItems.includes(value) || value == SELECT_ALL_VALUES,
    );
    if (allOptionsSelected && !selectedItems.includes(SELECT_ALL_VALUES)) {
      selectedItems.push(SELECT_ALL_VALUES);
    }
    if (variantOptionMapping[0]?.value != SELECT_ALL_VALUES) {
      variantOptionMapping.unshift({
        label: `Select All ${variantOptionMapping.length}`,
        value: SELECT_ALL_VALUES,
        isSelectable: true,
        isDefaultActive: allOptionsSelected,
      });
    }
    return variantOptionMapping;
  }, [selectedItems, variants]);
  const handleSelect = (value: SelectedValue) => {
    const variantIdString = value?.toString();
    if (variantIdString) {
      let updatedItems = selectedItems;
      if (variantIdString === SELECT_ALL_VALUES) {
        const currentlyVisibleValues = filterNulls(
          options.map(({ value }) => value),
        );
        const selectAllIsCurrentlyChecked = currentlyVisibleValues.every(
          (value) => selectedItems.includes(value),
        );
        updatedItems = selectAllIsCurrentlyChecked
          ? selectedItems.filter(
              (item) => !currentlyVisibleValues.includes(item),
            )
          : Array.from(new Set([...selectedItems, ...currentlyVisibleValues]));
      } else {
        updatedItems = selectedItems.includes(variantIdString)
          ? selectedItems.filter((item) => item !== variantIdString)
          : [...selectedItems, variantIdString];
      }
      handleChange(
        updatedItems.filter((item) => {
          return item !== SELECT_ALL_VALUES;
        }),
      );
    }
  };
  return (
    <RegularList
      selectedItems={selectedItems}
      itemSize={40}
      itemsOnViewCount={5}
      options={options}
      isMultiselect
      onSelect={handleSelect}
    />
  );
};

const VariantSelectionPopoverContent = () => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const { onSubmit, value } = useVariantSelectionPopoverContext();
  const productIds = Object.keys(value ?? {});

  const { products } = useSpecificStoreProducts(productIds!, {
    forceSkip: !productIds,
  });
  return (
    <Popover.Content shouldPreventDefaultOnInteractOutside={areModalsOpen}>
      <Group name="Variants" className="mt-4 flex flex-col">
        <div className="mt-1 flex flex-col gap-3">
          {products.map(
            (product) =>
              hasOwnProperty(value ?? {}, product.id.toString()) &&
              product.variants.length > 0 && (
                <Group
                  key={product.id}
                  name={product.title}
                  isCollapsible
                  isDefaultOpen
                  header={<GroupHeader className="overflow-hidden w-[320px]" />}
                >
                  <VariantList
                    handleChange={(variants) => {
                      onSubmit({
                        ...value,
                        [product.id.toString()]:
                          variants?.length == product.variants.length
                            ? null
                            : variants,
                      });
                    }}
                    variants={product.variants}
                    selectedVariants={value?.[product.id] ?? null}
                  />
                </Group>
              ),
          )}
        </div>
      </Group>
    </Popover.Content>
  );
};

const VariantSelectionPopoverTrigger = () => {
  const { onSubmit, value } = useVariantSelectionPopoverContext();
  const isDebugMode = useIsDebugMode();
  const [shouldKeepTooltipOpen] = React.useState(() =>
    Boolean(
      isDebugMode &&
        JSON.parse(
          localStorage.getItem("replo.debug.keepProductTooltipOpen") ?? "false",
        ),
    ),
  );

  const productIds = Object.keys(value ?? {});

  const { products } = useSpecificStoreProducts(productIds!, {
    forceSkip: !productIds,
  });

  const { selectedProducts, selectedVariants } = React.useMemo(() => {
    return Object.entries(value ?? {}).reduce(
      (mapping, [productId, variantIds]) => {
        // NOTE (Matt 2024-06-12): We need this parseInt because
        // the productRefs in ProductSelectionPopover use an Integer.
        const variantsForProduct = variantIds
          ? variantIds
          : products
              .find(({ id }) => id.toString() === productId.toString())
              ?.variants.map(({ id }) => id.toString());
        mapping.selectedProducts.push({
          productId: parseInteger(productId),
        });

        if (variantsForProduct) {
          mapping.selectedVariants.push(...variantsForProduct);
        }
        return mapping;
      },
      {
        selectedProducts: [] as ProductRef[],
        selectedVariants: [] as string[],
      },
    );
  }, [value, products]);

  const getPopoverLabel = () => {
    const selectedCount = selectedVariants.length;
    return {
      buttonText:
        selectedCount > 0
          ? `${selectedCount} ${
              selectedCount === 1 ? "Variant" : "Variants"
            } Selected`
          : "Select Variants",
      tooltipText: null,
    };
  };

  const { buttonText, tooltipText } = getPopoverLabel();

  const handleUpdateProducts = React.useCallback(
    (productRefs: ProductRef[]) => {
      if (productRefs.length < 1) {
        onSubmit(null);
      } else {
        let productIdsToRemove = Object.keys(value ?? {});
        const newValue = productRefs.reduce(
          (updatedProductMapping, productRef) => {
            const productIdString = productRef.productId.toString();
            const indexInExistingList =
              productIdsToRemove.indexOf(productIdString);
            if (indexInExistingList >= 0) {
              productIdsToRemove = productIdsToRemove
                .slice(0, indexInExistingList)
                .concat(productIdsToRemove.slice(indexInExistingList + 1));
            } else {
              updatedProductMapping[productIdString] = [];
            }
            return updatedProductMapping;
          },
          { ...(value ?? {}) },
        );
        productIdsToRemove.forEach((productId) => delete newValue[productId]);
        onSubmit(newValue);
      }
    },
    [value, onSubmit],
  );
  return (
    <>
      <ProductSelectionPopover.Root
        onSubmit={handleUpdateProducts}
        value={selectedProducts}
        isMultiSelection
        className="w-full text-center"
      >
        <ProductSelectionPopover.Content offset={85} />
        <ProductSelectionPopover.Trigger />
      </ProductSelectionPopover.Root>
      <div className="flex flex-1 text-subtle flex-col truncate w-full text-center mt-2">
        <Popover.Trigger asChild>
          <div data-testid="trigger-variant">
            {selectedProducts.length > 0 && (
              <Tooltip
                content={tooltipText}
                triggerAsChild
                layoutClassName="w-full"
                disableHoverableContent={!shouldKeepTooltipOpen}
              >
                <div>
                  <SelectionIndicator
                    className="w-full cursor-pointer"
                    endEnhancer={
                      <FormFieldXButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onSubmit(null);
                        }}
                      />
                    }
                    title={selectedVariants.length > 0 ? buttonText : undefined}
                    placeholder={
                      selectedVariants.length === 0 ? buttonText : undefined
                    }
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </Popover.Trigger>
      </div>
    </>
  );
};

const VariantSelectionPopoverAnchor = Popover.Anchor;
VariantSelectionPopoverAnchor.displayName = "VariantSelectionPopoverAnchor";

export {
  VariantSelectionPopoverAnchor as Anchor,
  VariantSelectionPopoverContent as Content,
  VariantSelectionPopover as Root,
  VariantSelectionPopoverTrigger as Trigger,
};
