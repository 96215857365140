import * as React from "react";

import DashboardLayout from "@components/layouts/DashboardLayout";
import ProjectsTable from "@editor/components/dashboard/projects/ProjectsTable";
import useConnectShopifyIntegrationNavigator from "@editor/hooks/useConnectShopifyIntegrationNavigator";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import useUserHasNewAnnouncements from "@editor/hooks/useUserHasNewAnnouncements";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { Outlet, useNavigate } from "react-router-dom";

const DashboardHome: React.FC = () => {
  useUserHasNewAnnouncements();
  useConnectShopifyIntegrationNavigator();
  const navigate = useNavigate();
  const workspaceId = useCurrentWorkspaceId();

  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : skipToken,
    );
  const { data, isLoading } = trpc.project.listWithStats.useQuery({
    workspaceId: workspaceId ?? undefined,
  });

  return (
    <DashboardLayout>
      <div className="bg-white no-scrollbar col-span-9 mt-4 flex flex-grow flex-col items-start gap-y-2 overflow-scroll">
        <ProjectsTable
          title={
            !isLoading ? `${workspaceData?.workspace?.name} Projects` : null
          }
          subtitle={`These are the projects within ${workspaceData?.workspace?.name}`}
          projects={data?.projects}
          isLoading={isLoading || isLoadingWorkspace}
          buttonLabel="New Replo Project"
          onButtonClick={() => navigate("../project/new")}
        />
      </div>
      <Outlet />
    </DashboardLayout>
  );
};

export default DashboardHome;
