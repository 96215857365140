import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { selectDraftElementType } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import size from "lodash-es/size";

export function useDefaultCollapsedElementTypeGroups(elementId?: string) {
  const { project } = useCurrentProjectContext();
  const projectId = project?.id;
  const draftElementType = useEditorSelector(selectDraftElementType);

  const { data: elementMetadata = [] } =
    trpc.element.listAllElementsMetadata.useQuery(
      projectId ? { projectId } : skipToken,
    );

  return React.useMemo(() => {
    const collapsedGroups = new Set<ReploElementType>();

    if (size(elementMetadata) === 0) {
      return Array.from(collapsedGroups);
    }

    // If there's an element ID from params, collapse its group
    if (elementId) {
      const element = elementMetadata.find((e) => e.id === elementId);
      if (element?.type) {
        collapsedGroups.add(element.type);
        return Array.from(collapsedGroups);
      }
    }

    // If there's a draft element type, collapse its group
    if (draftElementType) {
      collapsedGroups.add(draftElementType);
      return Array.from(collapsedGroups);
    }

    return Array.from(collapsedGroups);
  }, [elementMetadata, elementId, draftElementType]);
}
