import type { ReploElement } from "schemas/generated/element";
import type {
  DynamicDataStore,
  DynamicDataStoreContextValue,
  RuntimeContextNullableValueMap,
} from "../shared/runtime-context";

import * as React from "react";

import * as ReactDOM from "react-dom";
import { isString } from "replo-utils/lib/type-check";

import { RuntimeVersionAttribute } from "../shared/constants";
import { getSectionSettings } from "../shared/liquid";
import {
  getAlchemyEditorWindow,
  getAlchemyGlobalPaintContext,
} from "../shared/Window";
import { WrappedAlchemyElement } from "./AlchemyElement";
import { RuntimeScript } from "./RuntimeScript";
import { fullPageQuerySelector } from "./utils/cssSelectors";

/**
 * Render/hydrate Replo elements into the current page.
 *
 * This function is the main backbone of the Replo runtime - it takes a mapping
 * of Replo elements and either renders or hydrates their React component trees
 * into the appropriate DOM node (which node it renders to and whether it
 * renders or hydrates depends on the environment (in `extras`) and the element
 * type).
 *
 * paint() should be run once when the runtime loads on production pages and
 * every time the component tree is updated in the editor.
 *
 * @param args.targetWindow The window to paint into (used to query for DOM
 * nodes, etc)
 * @param args.elements elements to paint
 * @param args.forceRemount If true, always forces a call to
 * React.render/hydrate to hard-remount the React tree. If false, effectively
 * calls a setState method to update the existing component instead, if the
 * component was already mounted.
 * @param args.elementToRepaint Overrides of what element to paint, if any. In
 * runtime, determineFromPageContent should be passed so that we look at the
 * page HTML to determine what page we're supposed to be painting.
 */
export const paint = (args: {
  targetWindow: (Window & typeof globalThis) | Window;
  elements: ReploElement[];
  contexts: RuntimeContextNullableValueMap;
  forceRemount?: boolean;
  elementToPaint: ElementToPaint;
  ProviderComponent?: React.FunctionComponent;
}) => {
  const { targetWindow, elementToPaint, elements, forceRemount, contexts } =
    args;
  const shopifyProductTemplateElementId =
    elementToPaint.type === "specificElement"
      ? elementToPaint.specificElementId
      : targetWindow.document.querySelector(
          "#replo__productTemplate__element__id, #alchemy__productTemplate__element__id",
        )?.innerHTML;
  const specificElementId =
    elementToPaint.type === "specificElement"
      ? elementToPaint.specificElementId
      : null;
  // @ts-ignore
  window.alchemy ??= {};
  window.alchemyEditor ??= {};
  const { isEditorApp } = contexts.renderEnvironment;
  const editorCanvas = contexts.editorCanvas;
  // NOTE (Chance 2024-06-27): We only set the global targetWindow in the editor
  // for the desktop canvas. Ultimately this should probably just go away.
  if (!isEditorApp || editorCanvas === "desktop") {
    window.alchemy!.targetWindow = targetWindow;
  }

  if (elements) {
    for (const reploElement of elements) {
      if (specificElementId && reploElement.id !== specificElementId) {
        continue;
      }
      if (
        reploElement.type === "shopifyProductTemplate" &&
        reploElement.id !== shopifyProductTemplateElementId
      ) {
        continue;
      }

      let targetNode: string | null = null;
      if (reploElement.type === "shopifySection") {
        if (contexts.renderEnvironment.isEditorApp) {
          if (
            elementToPaint.type !== "specificElement" ||
            elementToPaint.specificElementId !== reploElement.id
          ) {
            continue;
          }
        } else {
          const { storeId } = contexts.shopifyStore;
          const { runtimeVersion } = contexts.extraContext;
          // Note (Noah, 2022-01-03): This is a hack to make Parents Are Human's page on their non-live
          // theme work correctly. We shouldn't have even supported this case in the first place, but
          // there's an issue because the prerendered HTML is out of date and thus has an old runtimeVersion,
          // even after publishing. Ignoring the runtimeVersion calculation is fine for this store and
          // allows them to update the draft theme without issue. This will be fixed complately when we
          // support saving page templates to draft themes!
          const shouldIgnoreRuntimeVersion =
            storeId &&
            [
              "0f22d19c-d854-40d0-a6ea-22dead547505",
              "e1a87b0a-196a-44b0-ba18-61ea0eb0140e",
            ].includes(storeId);
          // If runtime version is found, we use it to find the proper section,
          // otherwise we default to just use the element id.
          targetNode = `.replo-section-${reploElement.id}, #replo-section-${
            reploElement.id
          }${
            runtimeVersion && !shouldIgnoreRuntimeVersion
              ? `[${RuntimeVersionAttribute}="${runtimeVersion}"]`
              : ""
          }`;
        }
      }

      hydrateElement({
        reploElement,
        targetNode,
        contexts,
        forceRemount,
        ProviderComponent: args.ProviderComponent,
      });
    }
  }
};

/**
 * Main entry point for rendering an AlchemyElement as a React component into
 * the DOM. This finds the appropriate mount point node (or nodes) and hydrates
 * them based on some criteria (whether we're in the editor, what kind of
 * element it is, etc). Note that if the React component is already mounted, we
 * may choose to just update its state instead of creating a new React
 * component.
 *
 * @param args.reploElement Element to render
 * @param args.forceRemount If true, ALWAYS re-mount the component, overriding
 * whatever else was in the mount node
 * @param args.targetNode If provided, render into this node instead of the
 * default page node. Useful for OS2 sections.
 */
function hydrateElement(args: {
  reploElement: ReploElement;
  forceRemount?: boolean;
  targetNode?: string | null;
  contexts: RuntimeContextNullableValueMap;
  ProviderComponent?: React.FunctionComponent;
}) {
  const { reploElement, forceRemount, targetNode, contexts } = args;
  const globalWindow = contexts.globalWindow;

  initModalElementHack(globalWindow?.document);
  cachePrerenderedNodes(globalWindow?.document);

  const targetDocument = globalWindow?.document;
  if (targetNode) {
    const targetNodes = targetDocument?.querySelectorAll(targetNode);
    if (targetNodes) {
      for (const node of targetNodes) {
        hydrateNode({
          node: node as HTMLElement,
          reploElement,
          contexts,
          forceRemount,
          ProviderComponent: args.ProviderComponent,
        });
      }
    }
  } else {
    let targetNode = targetDocument?.querySelector(
      fullPageQuerySelector,
    ) as HTMLElement;

    const isEditor = contexts.renderEnvironment.isEditorApp;
    /**
     * Note (Ovishek, 2023-02-07, REPL-6283): So this is case where we
     * save ourselves from not rendering anything in the editor while targetNode is not found.
     * This is basically happens when we fail to fetch the proxy html from their store
     * multiple reasons for this to happen, but we decide to at least show them the components
     */
    if (isEditor && !targetNode && targetDocument) {
      targetNode = getFallbackTargetNode(targetDocument) as HTMLElement;
    }

    if (!targetNode && targetDocument) {
      console.warn(
        "[Replo] Target node with correct runtime version not found. Falling back to hydrate any Replo node",
      );
      targetNode = targetDocument?.querySelector(
        fullPageQuerySelector,
      ) as HTMLElement;
    }

    if (targetNode) {
      hydrateNode({
        node: targetNode,
        reploElement,
        contexts,
        forceRemount,
        ProviderComponent: args.ProviderComponent,
      });
    } else {
      console.error({
        message: "Target node not found",
        storeId: contexts.shopifyStore.storeId,
        elementId: reploElement.id,
      });
    }
  }
}

function initializeDynamicDataStore(
  initialStore = {},
): DynamicDataStoreContextValue {
  const dynamicDataStore = {
    store: initialStore,
    setStore: (newStore: DynamicDataStore) => {
      dynamicDataStore.store = newStore;
    },
  };
  return dynamicDataStore;
}

function hydrateNode(args: {
  node: HTMLElement;
  reploElement: ReploElement;
  forceRemount: boolean | undefined;
  contexts: RuntimeContextNullableValueMap;
  ProviderComponent?: React.FunctionComponent;
}) {
  const { node, reploElement, forceRemount, contexts } = args;
  const globalWindow = contexts.globalWindow;
  const globalContext = getAlchemyGlobalPaintContext();
  const targetDocument = globalWindow?.document;
  const isEditor = contexts.renderEnvironment.isEditorApp;
  const elementIdToElementMethods = isEditor
    ? getAlchemyEditorWindow()?.alchemyEditor?.elementIdToElementMethods
    : getAlchemyGlobalPaintContext()?.elementIdToElementMethods;
  if (
    !forceRemount &&
    elementIdToElementMethods?.[reploElement.id] &&
    Boolean(node.dataset.alchemyElementRoot)
  ) {
    const methods = elementIdToElementMethods[reploElement.id];
    const canvas = contexts.editorCanvas;
    if (canvas && methods?.updateElement[canvas]) {
      methods.updateElement[canvas]({
        element: reploElement,
        contexts,
        canvas,
      });
    }
    return;
  }

  // NOTE (Matt 2024-04-24): We need this for the published page
  // specifically for sections that are using section settings.
  // This should only run for sections, as it is checking for
  // node.dataset.sectionId which does not exist on other elements.
  // We need each instance of a section on the page to have its
  // own DynamicDataStore.
  if (
    contexts.renderEnvironment.isPublishedPage &&
    targetDocument &&
    node.dataset.sectionId
  ) {
    const sectionSettings = getSectionSettings(
      targetDocument,
      node.dataset.sectionId,
    );
    contexts.dynamicDataStore = initializeDynamicDataStore({
      sectionSettings,
    });
  }

  node.dataset.alchemyElementRoot = reploElement.id;

  // Note (Noah, 2021-09-03): We take the first div to mount because if we're coming
  // into the editor from an already-mounted element (e.g. if we've clicked the
  // edit button) there might be some random other children here like styles
  const queryForMountNodeRoot = node;
  let mountNode = queryForMountNodeRoot.querySelector("div");

  // Note (Noah, 2021-06-26): In the rare case of there being a full page element
  // but not a node to mount it on, we create a mount node. This happens when a
  // new full page element has never been published as a Shopify page
  if (!mountNode && targetDocument) {
    const div = targetDocument.createElement("div");
    div.dataset.alchemyElementMountNode = "true";
    queryForMountNodeRoot!.append(div);
    mountNode = div;
  }

  if (
    globalContext &&
    !globalContext?.hasLoadedScriptCallbacks &&
    targetDocument
  ) {
    targetDocument.addEventListener(
      "load",
      (event: Event) => {
        // @ts-ignore
        if (event?.target?.nodeName === "SCRIPT") {
          Object.values(globalContext?.scriptCallbacks || {}).forEach(
            (callback) => {
              // @ts-ignore
              callback(event.target.getAttribute("src"));
            },
          );
        }
      },
      true,
    );
    globalContext.hasLoadedScriptCallbacks = true;
  }

  const WrapperComponent = args.ProviderComponent
    ? args.ProviderComponent
    : React.Fragment;

  const tree = (
    <WrapperComponent>
      <RuntimeScript
        projectId={reploElement.projectId}
        elementId={reploElement.id}
        version={contexts.extraContext.runtimeVersion}
        isElementPreview={contexts.renderEnvironment.isElementPreview}
      />
      <WrappedAlchemyElement
        key={reploElement.id}
        element={reploElement}
        contexts={contexts}
      />
    </WrapperComponent>
  );

  // Note (Noah, 2021-10-07): If editor, always re-render. Otherwise we can get
  // weird react hydration issues if the page being edited is different from the
  // one published
  // eslint-disable-next-line react/no-deprecated
  const render = isEditor ? ReactDOM.render : ReactDOM.hydrate;
  renderWithoutReact18ErrorMessage(render, tree, mountNode);
}

/**
 * Render a separate React root for modal components, if one does not exist.
 * This is necessary because element containers may be deeply nested inside
 * elements which have transforms or position: relative defined, which messes
 * with the modal's fixed positioning. In order to make sure we're not nested,
 * we manually insert a modal mount point as the last element of body. This
 * ensures all modals are presented from the body element (the Modal component
 * targets this mount point as its parentElement). (modalMountHack)
 *
 * TODO (Fran, 2023-03-29): Remove this when we remove ModalV1
 */
function initModalElementHack(targetDocument: Document | null | undefined) {
  if (
    targetDocument &&
    !targetDocument.querySelector("#alchemy-modal-body-child")
  ) {
    const body = targetDocument.querySelector("body");
    const bodyChild = targetDocument.createElement("div");
    bodyChild.setAttribute("id", "alchemy-modal-body-child");
    bodyChild.classList.add("alchemy-reset");

    if (body) {
      body.append(bodyChild);
      const modalContainer = targetDocument.createElement("div");
      bodyChild.append(modalContainer);

      renderWithoutReact18ErrorMessage(
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.render,
        <div id="alchemy-modal-mount-point" className="alchemy-reset" />,
        modalContainer,
      );
    }
  }
}

// TODO (Chance 2023-08-17) We know ReactDOM.render is deprecated and the
// warning muddies up the console unneccesarily. Get rid of this when we can
// safely switch to the `createRoot` API.
function renderWithoutReact18ErrorMessage(
  renderer: ReactDOM.Renderer,
  element: React.ReactElement,
  node: Element | null,
) {
  const logError = console.error;
  console.error = (...args) => {
    if (!isReact18RenderErrorMessage(args[0])) {
      return logError.call(console, ...args);
    }
  };
  return renderer(element, node, () => {
    console.error = logError;
  });
  function isReact18RenderErrorMessage(value: unknown) {
    return (
      isString(value) &&
      value.startsWith(
        "Warning: ReactDOM.render is no longer supported in React 18",
      )
    );
  }
}

/**
 * For any pre-rendered nodes (e.g. Shopify Liquid) whose content we want to directly
 * copy from the initially rendered page and never change, take those nodes and
 * cache them so we can use them later in render.
 *
 * Note (Noah, 2021-10-11): We cache the prerendered nodes' CHILD's (not node itself's) content,
 * because otherwise we can run into issues with React hydration, where the hydrate
 * process will update the existing nodes (which from React's perspective are
 * empty) instead of rendering new ones, and thus the pre-cached nodes, which are
 * the same instances, will have their pre-rendered content eliminated.
 */
function cachePrerenderedNodes(targetDocument: Document | null | undefined) {
  const globalContext = getAlchemyGlobalPaintContext();

  if (!globalContext) {
    return;
  }

  globalContext.prerenderedNodes = globalContext.prerenderedNodes || {};
  const prerenderedNodes = targetDocument
    ? targetDocument.querySelectorAll("[data-alchemy-prerendered-component-id]")
    : [];
  Array.from(prerenderedNodes).forEach((node) => {
    globalContext.prerenderedNodes[
      // @ts-ignore
      node.dataset.alchemyPrerenderedComponentId
    ] = node.querySelector("[data-alchemy-prerendered-placeholder]");
  });
}

/**
 * This function creates a new div element and adds it to the main container.
 * Also if there's no main container, it creates one and adds to the body.
 */
function getFallbackTargetNode(targetDocument: Document) {
  let targetNode = null;
  let mainNode = targetDocument.querySelector("main");
  // Note (Ovishek, 2023-02-07): Sometimes if the mirror html is totally broken and
  // we don't get the main element on the html, then we are creating one and put it
  // inside the body
  if (!mainNode) {
    const body = targetDocument.querySelector("body");
    mainNode = targetDocument.createElement("main");
    body?.append(mainNode);
  }

  if (mainNode) {
    const newDivFullPageElement = targetDocument.createElement("div");
    newDivFullPageElement?.setAttribute("id", "replo-fullpage-element");
    newDivFullPageElement?.style.setProperty("display", "block", "important");
    mainNode?.append(newDivFullPageElement);
    targetNode = newDivFullPageElement;
  }
  return targetNode;
}

export type ElementToPaint =
  | {
      type: "specificElement";
      specificShopifyPageId?: string;
      specificElementId?: string;
    }
  | { type: "determineFromPageContent" };
