import type { AIComponentAction } from "schemas/generated/ai";

import * as React from "react";

import { Textarea } from "@replo/design-system/components/textarea";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  handleStreamingAction,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  setEditorMode,
  setStreamingUpdate,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

import {
  scrollToComponentId,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";
import Button from "@replo/design-system/components/button";
import { Spinner } from "@replo/design-system/components/spinner";
import { BsPlayFill } from "react-icons/bs";
import { batch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

export const JSONActionPlayer: React.FC = () => {
  const dispatch = useEditorDispatch();
  const [jsonInput, setJsonInput] = React.useState("");
  const [isPlaying, setIsPlaying] = React.useState(false);
  const activeCanvas = useEditorSelector((state) => state.canvas.activeCanvas);

  const { setIsMenuOpen } = useAIStreaming();
  const store = useEditorStore();

  const handlePlay = async () => {
    if (!jsonInput.trim() || isPlaying) {
      return;
    }

    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(store.getState());

    setIsPlaying(true);

    try {
      setIsMenuOpen(true, "previewMenu");
      const actions: AIComponentAction[] = JSON.parse(jsonInput);
      const streamingUpdateId = uuidv4();
      const draftElementComponent = draftElement?.component;

      if (!draftElementComponent) {
        return;
      }

      // Create a dummy component just to initialize the streaming state
      dispatch(
        setStreamingUpdate({
          id: streamingUpdateId,
          draftElementComponent: draftElementComponent,
          isStreaming: false,
          actions: [],
          originalStyles: {},
          textMap: {},
          repaintKey: 0,
        }),
      );
      dispatch(setEditorMode(EditorMode.aiGeneration));
      dispatch(setCanvasInteractionMode("locked"));

      // Process each action with a small delay to simulate streaming
      for (const action of actions) {
        batch(() => {
          dispatch(
            handleStreamingAction({
              ...action,
              activeCanvas,
            }),
          );

          if (action.componentId) {
            dispatch(scrollToComponentId({ componentId: action.componentId }));
          }
        });

        // Add a small delay between actions
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
    } catch (error) {
      console.error("Failed to parse or play JSON:", error);
      dispatch(setStreamingUpdate(null));
      dispatch(setEditorMode(EditorMode.edit));
      dispatch(setCanvasInteractionMode("edit"));
    } finally {
      setIsPlaying(false);
    }
  };

  useReploHotkeys({
    enter: () => void handlePlay(),
  });

  return (
    <div className="flex flex-col h-[calc(100%-25px)]">
      <div className="border-t p-4 space-y-2 flex-1">
        <Textarea
          value={jsonInput}
          onChange={setJsonInput}
          placeholder={`Paste your JSON actions here... Example:
[{
  "type": "setStyles",
  "componentId": "your-component-id",
  "value": {
    "backgroundColor": "#1A1A1A"
  }
}]`}
          onEnter={() => void handlePlay()}
          size="base"
          layoutClassName="h-full min-h-[200px] w-full"
          isDisabled={isPlaying}
        />

        <div className="flex justify-end">
          <Button
            variant="primary"
            size="sm"
            onClick={() => void handlePlay()}
            disabled={!jsonInput.trim() || isPlaying}
          >
            <div className="flex items-center gap-2">
              Play Actions
              {isPlaying ? (
                <Spinner size={10} variant="white" />
              ) : (
                <BsPlayFill />
              )}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
