import type { ButtonProps } from "@replo/design-system/components/button";
import type { ButtonSize } from "@replo/design-system/components/button/button-shared";

import * as React from "react";

import { successToast } from "@editor/components/common/designSystem/Toast";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";

import Button from "@replo/design-system/components/button";

import { useExperimentCreate } from "../tabs/hooks/useExperimentCreate";

type CreateExperimentButtonProps = {
  size?: ButtonSize;
};

export const CreateExperimentButton: React.FC<CreateExperimentButtonProps> = ({
  size = "base",
}) => {
  const commonProps: ButtonProps = {
    size: "base",
    variant: "primary",
    className:
      "bg-blue-600 text-white py-2 px-2.5 transition-colors font-medium",
  };

  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const { createExperiment, isCreatingExperiment } =
    useExperimentCreate(workspaceId);

  const handleCreateNewExperiment = async () => {
    await createExperiment({
      location: "experiment_list_tab",
      onExperimentCreateSuccess: () => {
        successToast("Test Created", "");
      },
    });
  };

  return (
    <Button
      {...commonProps}
      size={size}
      onClick={() => void handleCreateNewExperiment()}
      isLoading={isCreatingExperiment}
    >
      Create New A/B Test
    </Button>
  );
};
