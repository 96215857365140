import * as React from "react";

import { trackError } from "@editor/infra/analytics";
import { trpc } from "@editor/utils/trpc";

import { slugify } from "replo-utils/lib/string";
import { ReploError } from "schemas/errors";

class CloudflareUploadError extends ReploError {}

export const useCloudflareUpload = () => {
  const { mutateAsync: createSignedUrl } =
    trpc.cloudflare.createSignedUrl.useMutation();

  const uploadToCloudflare = React.useCallback(
    async (
      file: Blob,
      originalFilename: string,
      workspaceId: string,
    ): Promise<{ data: { url: string } } | { error: unknown }> => {
      const filename = slugify(originalFilename);
      const type = getType(file);

      if (type === "other") {
        throw new Error("Unsupported file type");
      }

      try {
        const { signedUrl, publicUrl } = await createSignedUrl({
          workspaceId,
          filename,
          type,
        });

        const uploadResponse = await fetch(signedUrl, {
          method: "PUT",
          body: file,
        });

        if (!uploadResponse.ok) {
          throw new Error("Failed to upload file to Cloudflare");
        }

        return { data: { url: publicUrl } };
      } catch (error) {
        let errorMessage = "Failed to upload file to Cloudflare";
        if (error instanceof Error) {
          errorMessage = error.message;
        }

        trackError(
          new CloudflareUploadError({
            message: `Failed to upload image ${originalFilename} to Cloudflare`,
            additionalData: { error: errorMessage },
          }),
        );

        return { error };
      }
    },
    [createSignedUrl],
  );

  return uploadToCloudflare;
};

const getType = (file: Blob) => {
  const type = file.type.split("/")[0];
  if (type === "image") {
    return "images";
  } else if (type === "video") {
    return "videos";
  } else {
    return "other";
  }
};
