// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { EditorRoute } from "@editor/utils/router";
import type { ReploElement } from "schemas/generated/element";
import type { ReploProject } from "schemas/generated/project";

import * as React from "react";

import { resetProductData } from "@editor/reducers/commerce-reducer";
import { selectProjectId, setProjectData } from "@editor/reducers/core-reducer";
import { getFieldMapping } from "@editor/reducers/utils/core-reducer-utils";
import { useEditorDispatch, useEditorStore } from "@editor/store";
import {
  getLastSelectedElementIdForProject,
  saveCurrentElementId,
} from "@editor/utils/localStorage";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { setCanvasHtml } from "@/features/canvas/canvas-reducer";
import { skipToken } from "@tanstack/react-query";
import mapValues from "lodash-es/mapValues";
import {
  generatePath,
  matchPath,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";

type CurrentProjectContext = {
  project?: ReploProject;
  isLoading: boolean;
  // TODO (Fran 2024-08-29): improve this type
  error: any;
};

const CurrentProjectContext = React.createContext<CurrentProjectContext>({
  project: undefined,
  isLoading: false,
  error: undefined,
});

export const useCurrentProjectContext = () => {
  return React.useContext(CurrentProjectContext);
};

const CurrentProjectProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { projectId } = useParams();
  const { project, error, isLoading } = useGetProjectQuery(projectId ?? null);

  return (
    <CurrentProjectContext.Provider
      value={{
        project,
        error,
        isLoading,
      }}
    >
      {children}
    </CurrentProjectContext.Provider>
  );
};

export default CurrentProjectProvider;

function useGetProjectQuery(projectId: string | null) {
  const {
    data: projectData,
    isSuccess: isProjectSuccess,
    isLoading: isProjectLoading,
    error,
    refetch,
  } = trpc.project.get.useQuery(projectId ?? skipToken);

  // NOTE (Ben O. 2025-02-06): We use this to prefetch the element metadata for the project as soon as project id is ready
  // and to block the editor from loading before the metadata is ready.
  const {
    data: elementMetadata,
    isLoading: isElementMetadataLoading,
    isSuccess: isElementMetadataSuccess,
  } = trpc.element.listAllElementsMetadata.useQuery(
    projectId ? { projectId } : skipToken,
  );

  const isLoading = isProjectLoading || isElementMetadataLoading;
  const dispatch = useEditorDispatch();
  const store = useEditorStore();
  const navigate = useNavigate();
  React.useEffect(() => {
    const existingProjectId = selectProjectId(store.getState());

    // NOTE (Fran 2024-08-23): Reinitialize the project and reset all product data whenever
    // the user switches to a different project.
    if (existingProjectId !== projectId) {
      void trpcUtils.element.getById.invalidate();
      dispatch(resetProductData());
      dispatch(setCanvasHtml(""));
    }
  }, [projectId, store, dispatch]);

  React.useEffect(() => {
    // NOTE (Fran 2024-08-23): Initialize the project only once when data is available.
    // Re-initialization occurs only if the project changes.
    if (
      projectData &&
      isProjectSuccess &&
      elementMetadata &&
      isElementMetadataSuccess
    ) {
      const elementMetadataMapping = getFieldMapping(elementMetadata, "id");

      // ToDo (Ben O., 2025-02-13): Remove this once fully over to TRPC element settings
      const elementMapping = getFieldMapping(projectData.elements ?? [], "id");
      const versionMapping = mapValues(
        elementMapping,
        (value) => value.version,
      );

      // try to match the path with the router element or product routes
      let pathMatch = null;
      pathMatch = matchPath(routes.editor.element, window.location.pathname);
      if (!pathMatch) {
        pathMatch = matchPath(routes.editor.product, window.location.pathname);
      }

      // Grab the element ID from the URL if one is specified,
      // otherwise try to get it from local storage
      const lastSavedElementId = getLastSelectedElementIdForProject(
        projectData.id,
      );

      let draftElementIdFromUrlOrLocalStorage =
        pathMatch?.params?.elementId || null;

      if (
        lastSavedElementId &&
        elementMetadataMapping[lastSavedElementId]?.projectId === projectData.id
      ) {
        draftElementIdFromUrlOrLocalStorage = lastSavedElementId;
      }

      // Set the element to the one from the URL or the localstorage or first one
      const draftElementMetadata =
        (getFromRecordOrNull(
          elementMetadataMapping,
          draftElementIdFromUrlOrLocalStorage,
        ) as ReploElement | null) ??
        ((elementMetadata?.[0] ?? null) as ReploElement | null);

      if (
        lastSavedElementId !== draftElementMetadata?.id &&
        draftElementMetadata
      ) {
        saveCurrentElementId(
          draftElementMetadata.id,
          elementMetadataMapping[draftElementMetadata.id]!.projectId,
        );
      }

      if (draftElementMetadata?.id) {
        navigate(
          generatePath(routes.editor.element, {
            projectId: projectData.id,
            elementId: draftElementMetadata.id,
          } as EditorRoute),
          {
            replace: true,
          },
        );
      }

      dispatch(
        setProjectData({
          project: projectData,
          draftElementId: draftElementMetadata?.id,
          elementMapping: elementMetadataMapping,
          versionMapping,
        }),
      );
    }
  }, [
    projectData,
    isProjectSuccess,
    dispatch,
    elementMetadata,
    isElementMetadataSuccess,
    navigate,
  ]);

  React.useEffect(() => {
    if ((error as any)?.error?.status === 401) {
      window.location.href = "/auth/login";
    }
  }, [error]);

  return { project: projectData, refetch, isLoading, error };
}
