import { ReploError, ReploValidationError } from "schemas/errors";

export class ComponentCannotBeMoved extends ReploError {
  message: string;

  constructor(config: { message: string; type: string }) {
    super({ message: config.message, additionalData: { type: config.type } });
    this.message = config.message;

    // Note: (Noah, 2022-02-04): This is here to help us identify
    // why this error is getting caught by sentry sometimes, it's not
    // strictly necessary for anything
    // @ts-ignore
    this[config.type] = true;
  }
}

export class ImageSourceTooLongError extends ReploValidationError {
  constructor(params: { provided: any; maxLength: number }) {
    const { provided, maxLength } = params;
    super(
      `Invalid image source. A valid source must be a string with less than ${maxLength} characters.\n\nProvided: ${
        typeof provided === "string" ? provided : typeof provided
      }`,
    );
  }
}

export class UpdateElementFailedError extends ReploError {}
export class UpdateElementTimedOutError extends ReploError {}

export function isImageSourceTooLongError(
  error: unknown,
): error is ImageSourceTooLongError {
  return (
    error != null &&
    typeof error === "object" &&
    error instanceof ImageSourceTooLongError
  );
}
