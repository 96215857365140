import type {
  Direction,
  Field,
  OrderState,
} from "@editor/components/dashboard/projects/SortDropdown";
import type { ReploSimpleProjectWithStats } from "schemas/generated/project";

import * as React from "react";

import { Input } from "@editor/components/common/designSystem/Input";
import Separator from "@editor/components/common/designSystem/Separator";
import { ToggleGroup } from "@editor/components/common/designSystem/ToggleGroup";
import { Loader } from "@editor/components/common/Loader";
import Header from "@editor/components/dashboard/Header";
import GalleryProjectItem from "@editor/components/dashboard/projects/GalleryProjectItem/index";
import ListProjectItem from "@editor/components/dashboard/projects/ListProjectItem";
import { EmptyProjectMembership } from "@editor/components/dashboard/projects/memberships/EmptyProjectMembership";
import NoMatchingProjects from "@editor/components/dashboard/projects/NoMatchingProjects";
import SortDropdown from "@editor/components/dashboard/projects/SortDropdown";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { getProjectName, getStoreData } from "@editor/utils/project-utils";
import { generateEditorPathname, routes } from "@editor/utils/router";

import { BsGrid, BsList, BsSearch } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";
import { useDebouncedState } from "replo-utils/react/use-debounced-state";
import z from "zod";

const viewModeSchema = z.enum(["gallery", "list"]);
type ViewMode = z.infer<typeof viewModeSchema>;

const isViewMode = (value: unknown): value is ViewMode => {
  return viewModeSchema.safeParse(value).success;
};

const LOCAL_STORAGE_VIEW_MODE_KEY = "replo-projects-view-mode";

type ProjectsTableProps = {
  title: string | null;
  subtitle: string;
  projects: ReploSimpleProjectWithStats[] | undefined;
  isLoading: boolean;
  href?: string;
  onButtonClick?(): void;
  buttonLabel?: string;
  showWorkspaceColumn?: boolean;
};

type ProjectsHeaderProps = {
  title: string | null;
  subtitle: string;
  buttonLabel?: string;
  href?: string;
  onButtonClick?(): void;
  isLoading: boolean;
  hasProjects: boolean;
};

const ProjectsHeader: React.FC<ProjectsHeaderProps> = ({
  title,
  subtitle,
  buttonLabel = "",
  href,
  onButtonClick,
  isLoading,
  hasProjects,
}) => {
  return (
    <>
      <Header
        title={title}
        subtitle={subtitle}
        buttonLabel={buttonLabel}
        href={href}
        onButtonClick={hasProjects ? onButtonClick : undefined}
        isLoading={isLoading}
      />
      <Separator className="my-4 col-span-12" />
    </>
  );
};

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  title,
  subtitle,
  projects,
  isLoading,
  href,
  onButtonClick,
  buttonLabel = "",
  showWorkspaceColumn = false,
}) => {
  const { setWorkspaceId } = useWorkspaceDashboardContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const logAnalytics = useLogAnalytics();
  const [viewMode, setViewMode] = useLocalStorageState<ViewMode>(
    LOCAL_STORAGE_VIEW_MODE_KEY,
    "gallery",
    { validate: isViewMode },
  );

  const [order, setOrder] = React.useState<OrderState>({
    field: "project.lastEditedAt",
    direction: "desc",
  });

  const sortedProjects = React.useMemo(
    () => sortProjectMemberships(order.field, order.direction, projects),
    [projects, order.field, order.direction],
  );

  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const [searchQueryInputValue, searchQuery, setSearchQuery] =
    useDebouncedState("");

  const filteredProjects = sortedProjects.filter((project) =>
    getProjectName(project).toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const shouldShowEmptyProjectMembership = !isLoading && isEmpty(projects);
  const shouldShowProjectsTable = !isLoading && !isEmpty(projects);

  const onSelectProject = (
    project: ReploSimpleProjectWithStats,
    e?: React.MouseEvent,
  ) => {
    const storeData = getStoreData(project);
    logAnalytics("dashboard.project.click", {
      storeId: project.id,
      name: project.name ?? "",
      storeUrl: storeData?.shopifyUrl ?? undefined,
      from:
        location.pathname === routes.allProjects
          ? "allProjects"
          : "orgProjects",
    } as const);
    setWorkspaceId(project.ownerWorkspace?.id ?? params.workspaceId ?? null);

    // Note (Juan, 2024-12-30) Allow users to open project in new tab with Cmd/Ctrl + click
    // This matches the default browser behavior for links
    if (e?.metaKey || e?.ctrlKey) {
      window.open(
        generateEditorPathname("", { projectId: project.id }),
        "_blank",
      );
      return;
    }

    navigate(generateEditorPathname("", { projectId: project.id }), {
      state: { getCanvasDoc: true },
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prev) => {
        if (prev === null) {
          return 0;
        } else {
          return (prev + 1) % filteredProjects.length;
        }
      });
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prev) => {
        if (prev === null) {
          return filteredProjects.length - 1;
        } else {
          return (prev - 1 + filteredProjects.length) % filteredProjects.length;
        }
      });
      e.preventDefault();
    } else if (
      e.key === "Enter" &&
      selectedIndex !== null &&
      filteredProjects[selectedIndex]
    ) {
      onSelectProject(filteredProjects[selectedIndex]);
      e.preventDefault();
    } else if (e.key === "Escape") {
      // Extra UX feature to escape the search input
      setSelectedIndex(null);
      e.preventDefault();
      (e.target as HTMLElement).blur();
    }
  };

  const renderProjectsList = () => {
    if (filteredProjects.length === 0) {
      return (
        <NoMatchingProjects searchQueryInputValue={searchQueryInputValue} />
      );
    }

    if (viewMode === "gallery" && isFeatureEnabled("new-project-page-ui")) {
      return (
        <div className="min-w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {filteredProjects.map((project, index) => (
            <GalleryProjectItem
              key={project.id}
              project={project}
              showWorkspaceColumn={showWorkspaceColumn}
              onSelectProject={(e) => onSelectProject(project, e)}
              className={`${selectedIndex === index ? "bg-blue-100" : ""}`}
            />
          ))}
        </div>
      );
    }

    return (
      <div className="min-w-full flex flex-col">
        <div className="grid w-full grid-cols-12 items-center justify-center py-2 pr-4 pl-4 gap-2 text-sm text-slate-500 border-b border-slate-200">
          <div className="col-span-3">Project Name</div>
          {showWorkspaceColumn && <div className="col-span-3">Workspace</div>}
          <div className="col-span-3">Edited</div>
          <div className={showWorkspaceColumn ? "col-span-2" : "col-span-3"}>
            Shopify
          </div>
          <div
            className={showWorkspaceColumn ? "col-span-1" : "col-span-3"}
          ></div>
        </div>
        <div className="divide-y">
          {filteredProjects.map((project, index) => (
            <ListProjectItem
              key={project.id}
              project={project}
              showWorkspaceColumn={showWorkspaceColumn}
              onSelectProject={(e) => onSelectProject(project, e)}
              className={`${selectedIndex === index ? "bg-blue-100" : ""}`}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col px-6 w-full">
      <ProjectsHeader
        title={title}
        subtitle={subtitle}
        buttonLabel={buttonLabel}
        href={href}
        onButtonClick={onButtonClick}
        isLoading={isLoading}
        hasProjects={!isEmpty(projects)}
      />
      {shouldShowEmptyProjectMembership ? (
        <EmptyProjectMembership
          shouldShowCreateProjectButton={isEmpty(projects)}
        />
      ) : null}
      {isLoading ? (
        <div className="col-span-12">
          <Loader className="mt-16" />
        </div>
      ) : null}
      {shouldShowProjectsTable ? (
        <>
          <div className="flex flex-col sm:flex-row gap-4 items-center mb-4">
            <div className="w-full sm:flex-1 sm:max-w-xs">
              <Input
                size="base"
                placeholder="Search by project name"
                type="text"
                value={searchQueryInputValue}
                onChange={(event) => setSearchQuery(event.target.value)}
                onEnter={() => {
                  if (filteredProjects.length === 1 && filteredProjects[0]) {
                    onSelectProject(filteredProjects[0]);
                  }
                }}
                onKeyDown={handleKeyDown}
                startEnhancer={<BsSearch className="text-slate-400" />}
                onBlur={() => setSelectedIndex(null)}
              />
            </div>

            <div className="w-full sm:w-auto">
              <SortDropdown
                order={order}
                onOrderChange={setOrder}
                showWorkspaceSort={showWorkspaceColumn}
              />
            </div>

            {isFeatureEnabled("new-project-page-ui") && (
              <div className="w-full sm:w-auto sm:ml-auto">
                <ToggleGroup
                  type="single"
                  size="lg"
                  options={[
                    {
                      label: <BsGrid />,
                      value: "gallery",
                    },
                    {
                      label: <BsList />,
                      value: "list",
                    },
                  ]}
                  onChange={(value) => {
                    setViewMode(value as ViewMode);
                    logAnalytics("dashboard.projectView.toggle", {
                      viewMode: value,
                    });
                  }}
                  value={viewMode}
                  allowsDeselect={false}
                />
              </div>
            )}
          </div>
          {renderProjectsList()}
        </>
      ) : null}
    </div>
  );
};

const sortProjectMemberships = (
  field: Field,
  direction: Direction,
  data?: ReploSimpleProjectWithStats[],
) => {
  const sortedData = [...(data ?? [])];
  sortedData.sort((a, b) => {
    if (field === "project.name") {
      const nameA = getProjectName(a).toLowerCase();
      const nameB = getProjectName(b).toLowerCase();
      return direction === "asc"
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    }
    if (field === "project.lastEditedAt") {
      const dateA: number = new Date(a.lastEditedAt ?? a.createdAt).getTime();
      const dateB: number = new Date(b.lastEditedAt ?? b.createdAt).getTime();
      return direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (field === "project.createdAt") {
      const dateA: number = new Date(a.createdAt).getTime();
      const dateB: number = new Date(b.createdAt).getTime();
      return direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (field === "project.workspace" && a.ownerWorkspace && b.ownerWorkspace) {
      const nameOfA = a.ownerWorkspace?.name;
      const nameOfB = b.ownerWorkspace?.name;
      return direction === "asc"
        ? nameOfA.localeCompare(nameOfB)
        : nameOfB.localeCompare(nameOfA);
    }
    return 0;
  });
  return sortedData;
};

export default ProjectsTable;
