import type { SavedStyleOpenedFrom } from "@editor/components/designLibrary/TextSavedStyleModifier";
import type { UploadResult } from "@editor/reducers/commerce-reducer";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import { successToast } from "@editor/components/common/designSystem/Toast";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import DropZone from "@editor/components/editor/page/Dropzone";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import useResetDesignLibraryTextValue from "@editor/hooks/designLibrary/useResetDesignLibraryTextValue";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import {
  usePageFontOptions,
  useShopifyFontOptions,
} from "@editor/hooks/useFontFamilyOptions";
import {
  selectDraftComponentNodeFromActiveCanvas,
  selectFontFamily,
  selectIsShopifyIntegrationEnabled,
  selectSavedStyle,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { filterOutPageFonts, GOOGLE_FONT_OPTIONS } from "@editor/utils/font";
import { normalizeFontFamily } from "@editorModifiers/utils";

import Button from "@replo/design-system/components/button";
import { Combobox } from "@replo/design-system/components/combobox";
import twMerge from "@replo/design-system/utils/twMerge";
import intersectionBy from "lodash-es/intersectionBy";
import { BsCaretDownFill } from "react-icons/bs";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { hasOwnProperty } from "replo-utils/lib/misc";
import { useForceUpdate } from "replo-utils/react/use-force-update";

const getDisplayName = (
  fontValue: string | null,
  nameToDisplayName: Record<string, string | undefined>,
) => {
  if (!fontValue) {
    return undefined;
  }
  return nameToDisplayName[fontValue];
};

// NOTE (Jackson, 2025-02-05): prefer vanilla approach over lodash here b/c
// startCase adds a space before each capital letter (ex: "ABeeZee" -> "A Bee Zee"),
// which we don't want, same with words()
const fontStartCase = (str: string) => {
  return str
    .replaceAll("_", " ")
    .replaceAll("-", " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const normalizeFontLabel = (
  label: string,
  displayName: string | undefined,
): string => {
  let normalizedLabel = label;

  if (displayName) {
    normalizedLabel = normalizeFontFamily(displayName) ?? label;
  } else {
    normalizedLabel = normalizeFontFamily(label) ?? label;
  }

  return fontStartCase(normalizedLabel);
};

const createFontOption = (
  font: { value: string | null; label: string },
  nameToDisplayName: Record<string, string | undefined>,
  groupTitle?: string,
) => {
  const displayName = getDisplayName(font.value, nameToDisplayName);
  const normalizedLabel = normalizeFontLabel(font.label, displayName);

  return {
    ...font,
    label: normalizedLabel,
    component: (
      <div
        style={{
          fontFamily: `"${font.value!}", "Inter", sans-serif`,
          fontSize: "13px",
          lineHeight: "20px",
        }}
      >
        {normalizedLabel}
      </div>
    ),
    ...(groupTitle && { groupTitle }),
  };
};

const FontFamilyControl: React.FC<{
  className?: string;
  value?: string | null;
  onChange?: (value: string | null) => void;
  openedFrom?: SavedStyleOpenedFrom;
}> = ({
  className,
  value: controlledValue,
  onChange: controlledOnChange,
  openedFrom,
}) => {
  const store = useEditorStore();
  // TODO (Sebas, 2024-10-25): This is used mainly to make the font family control
  // full width when the old right bar is enabled. This will be removed once
  // the new right bar is enabled by default.
  const applyComponentAction = useApplyComponentAction();
  const selectedFontFamily = useEditorSelector(selectFontFamily);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      !isMixedStyleValue(selectedFontFamily)
        ? selectedFontFamily ?? null
        : null,
    )?.fontFamily;

  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();

  const fontFamily =
    controlledValue ?? deletedSavedStyleValue ?? selectedFontFamily ?? null;
  const normalizedFontFamily = normalizeFontFamily(
    !isMixedStyleValue(fontFamily) ? fontFamily : null,
  )
    ?.replaceAll("_", " ")
    .replaceAll("-", " ");
  const pageFontOptions = usePageFontOptions();
  const {
    fontOptions: shopifyFontOptions,
    fallbacks,
    refetch,
    nameToDisplayName,
  } = useShopifyFontOptions();

  // NOTE (Gabe 2024-05-15): Only show the fonts that are actually available
  // (via Shopify or Google fonts).
  const filteredPageFonts = intersectionBy(
    pageFontOptions,
    [...shopifyFontOptions, ...GOOGLE_FONT_OPTIONS],
    (font) => font.value,
  );

  const [fontPreviewErrors, setFontPreviewErrors] = React.useState<
    Record<string, boolean>
  >({});

  const fontOptions = [
    // First get all page fonts
    ...filteredPageFonts.map((font) =>
      createFontOption(font, nameToDisplayName),
    ),

    // Note (Evan, 6/2/23) Filter out page fonts from shopify/google fonts so that we don't have any duplicates
    ...filterOutPageFonts(shopifyFontOptions, filteredPageFonts).map((font) =>
      createFontOption(font, nameToDisplayName, "From Theme"),
    ),
    ...filterOutPageFonts(GOOGLE_FONT_OPTIONS, filteredPageFonts).map(
      (font) => ({
        ...font,
        label: getDisplayName(font.value, nameToDisplayName) ?? font.label,
        groupTitle: "Google Fonts",
        component: !fontPreviewErrors[font.value ?? ""] ? (
          <img
            src={`/images/font-previews/${font.value?.replace(/\s+/g, "_")}.webp`}
            alt={getDisplayName(font.value, nameToDisplayName) ?? font.label}
            width={182}
            height={16}
            loading="lazy"
            onError={() =>
              setFontPreviewErrors((prev) => ({
                ...prev,
                [font.value ?? ""]: true,
              }))
            }
          />
        ) : (
          font.label
        ),
      }),
    ),
  ];

  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );

  const rerender = useForceUpdate();
  const computedFontStyle =
    draftComponentNode && getComputedStyle(draftComponentNode).fontFamily;

  const onSelect = (fontValue: string | null) => {
    if (controlledOnChange) {
      // Controlled mode
      controlledOnChange(fontValue);
    } else {
      // Uncontrolled mode (existing behavior)
      const newFontValue = fontValue
        ? `${fontValue}, ${fallbacks.join(",")}`
        : null;

      const actions = [];
      const savedStyle = selectSavedStyle(store.getState());
      if (isMixedStyleValue(savedStyle)) {
        const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
        if (actionsWithoutSavedStyles) {
          actions.push(...actionsWithoutSavedStyles);
        }
      }

      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          ...actions,
          {
            type: "setStyles",
            value: {
              fontFamily: newFontValue,
            },
          },
        ],
      });
    }
  };

  // NOTE (Sebas, 2023-03-22): When we reset the font to the default value,
  // we need to wait some time to get the updated computed font style.
  // NOTE (Chance, 2024-04-09) This should probably be handled in onSelect
  // instead to reduce complexity.
  const fontFamilyRef = React.useRef(fontFamily);
  React.useEffect(() => {
    const previousFontFamily = fontFamilyRef.current;
    fontFamilyRef.current = fontFamily;
    if (previousFontFamily && !fontFamily) {
      const id = setTimeout(() => {
        rerender();
      }, 500);
      return () => {
        clearTimeout(id);
      };
    }
  }, [rerender, fontFamily]);

  const onFontUploadComplete = async (data: UploadResult) => {
    const newFonts = await refetch();
    const fontName = newFonts?.find((font) =>
      // NOTE (Evan, 7/13/23) We replace spaces with underscores here because
      // we're matching against the Shopify files system, which does the same.
      data.asset.publicUrl.includes(font.name.replace(/ /g, "_")),
    )?.name;
    if (fontName) {
      onSelect(fontName);
    }
  };

  // Note (Evan, 2024-08-07): Attempt to retrieve a display name from
  // nameToDisplayName, checking first for the fontFamily, then the
  // fontFamily after removing any comma-joined fallbacks
  let displayName: string | undefined = undefined;
  if (fontFamily && !isMixedStyleValue(fontFamily)) {
    displayName =
      nameToDisplayName[fontFamily] ??
      nameToDisplayName[fontFamily.split(",")[0] ?? ""];
  }

  const fontDisplayName = displayName ?? normalizedFontFamily;

  let placeholder = "Mixed";
  if (!isMixedStyleValue(fontFamily)) {
    const normalizedFont = normalizeFontFamily(computedFontStyle);
    placeholder = normalizedFont ? fontStartCase(normalizedFont) : "";
  }

  return (
    <ControlGroup label="Font">
      <div className="w-full overflow-hidden">
        <Combobox.Root
          options={fontOptions}
          value={
            fontFamily && !isMixedStyleValue(fontFamily) ? fontDisplayName : ""
          }
          onChange={onSelect}
          previewOnHover
        >
          <Combobox.TriggerButton
            triggerClassName={twMerge(className, "w-full")}
            placeholder={placeholder}
            buttonProps={{
              contentClassName: "w-full",
            }}
            endEnhancer={
              fontFamily ? (
                <div className="ml-auto">
                  <FormFieldXButton
                    onClick={() => {
                      onSelect("");
                    }}
                  />
                </div>
              ) : (
                <BsCaretDownFill
                  className="text-subtle mr-1 flex-shrink-0 ml-auto"
                  size={8}
                />
              )
            }
          />
          <Combobox.Content
            contentClassName="w-[220px] min-h-[416px]"
            title="Fonts"
            areOptionsSearchable
            inputPlaceholder="Search fonts"
            emptySearchMessage="No matches found. Try uploading your own font!"
            sideOffset={openedFrom === "leftBar" ? 8 : 90}
            side={openedFrom === "leftBar" ? "right" : "left"}
            align="center"
            itemsOnViewCount={8}
            itemSize={28}
          >
            <Combobox.Footer>
              <UploadCustomFont
                onUploadComplete={(data) => {
                  void onFontUploadComplete(data);
                }}
              />
            </Combobox.Footer>
          </Combobox.Content>
        </Combobox.Root>
      </div>
    </ControlGroup>
  );
};
const UploadCustomFont: React.FC<{
  onUploadComplete(data: UploadResult): void;
}> = ({ onUploadComplete }) => {
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const errorToast = useErrorToast();
  const onUpload = (
    res:
      | { data: UploadResult }
      | { error: FetchBaseQueryError | SerializedError },
  ) => {
    if (uploadWasSuccessful(res)) {
      successToast("Font Uploaded", "");
      onUploadComplete(res.data);
    } else {
      errorToast(
        "Failed Uploading Font",
        "Please try again or reach out to support@replo.app for help.",
        "error.font.upload",
        {
          error: res.error,
        },
      );
    }
  };

  return (
    <>
      {isShopifyIntegrationEnabled ? (
        <div id="text-style-modifier-font-upload">
          <DropZone
            acceptDropAssetType={[".woff", ".woff2"]}
            onUploadComplete={onUpload}
            projectId={projectId}
            sourceType="files"
            inputSize="small"
            uploadText="Upload Custom Fonts"
            className="border-none pt-0"
          />
        </div>
      ) : (
        <Button
          variant="secondary"
          style={{ minWidth: 0 }}
          className="bg-transparent text-slate-400 hover:bg-transparent"
          disabled={true}
          tooltipText="Connect to Shopify to add custom fonts"
        >
          Upload Custom Fonts
        </Button>
      )}
    </>
  );
};

function uploadWasSuccessful(
  res:
    | { data: UploadResult }
    | { error: FetchBaseQueryError | SerializedError },
): res is { data: UploadResult } {
  return hasOwnProperty(res, "data");
}

export default FontFamilyControl;
