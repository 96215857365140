import type { ShopifyAsset } from "schemas/generated/asset";

import React from "react";

import AssetCard from "./AssetCard";

const ShopifyAssetCard: React.FC<{ asset: ShopifyAsset }> = ({ asset }) => {
  return (
    <AssetCard
      asset={{
        id: asset.id,
        name: asset.name ?? "",
        url: asset.publicUrl,
        width: asset.width,
        height: asset.height,
        fileExtension: getAssetExtension(asset),
        type: asset.type,
      }}
    />
  );
};

const getAssetExtension = (asset: ShopifyAsset): string => {
  const parts = asset.name?.split(".");
  return parts?.[parts.length - 1] ?? "";
};

export default ShopifyAssetCard;
