import * as React from "react";

import { configMenuMap } from "@editor/components/editor/page/element-editor/components/config-menus/configMap";
import {
  selectDraftComponentHasVariants,
  selectDraftComponentType,
  selectNearestAncestorHasVariants,
} from "@editor/reducers/core-reducer";
import { selectRightBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import DesignTab from "@editorComponents/DesignTab";
import InteractionsTab from "@editorComponents/InteractionsTab";
import CustomPropModifier from "@editorModifiers/CustomPropModifier";
import VariantModifier from "@editorModifiers/VariantModifier";

const DraftComponentPane: React.FC = () => {
  const currentTab = useEditorSelector(selectRightBarActiveTab);
  const draftComponentType = useEditorSelector(selectDraftComponentType);

  const hasVariants = useEditorSelector(selectDraftComponentHasVariants);
  const hasParentVariants = useEditorSelector(selectNearestAncestorHasVariants);
  const shouldShowVariantModifier =
    currentTab === "design" || hasVariants || hasParentVariants;

  if (!draftComponentType) {
    return null;
  }

  const ConfigMenu =
    configMenuMap[draftComponentType]?.config ?? CustomPropModifier;
  const AccessibilityMenu = configMenuMap[draftComponentType]?.accessibility;

  return (
    // Note (Juan, 2024-03-20): pb-32 is needed to improve rightBar visibility when most modifiers are open.
    <div className="flex flex-col gap-2 p-2 pb-32 text-default max-w-[250px]">
      {shouldShowVariantModifier && <VariantModifier />}
      {
        {
          design: <DesignTab />,
          interactions: <InteractionsTab />,
          custom: (
            // NOTE (Juan, 2024-10-29): py-1 is needed to match the variant modifiers padding on DesignTab
            <div className="py-1">
              <ConfigMenu />
            </div>
          ),
          accessibility: AccessibilityMenu ? (
            // NOTE (Juan, 2024-10-29): py-1 is needed to match the variant modifiers padding on DesignTab
            <div className="py-1">
              <AccessibilityMenu />
            </div>
          ) : null,
        }[currentTab]
      }
    </div>
  );
};

export default DraftComponentPane;
