import * as React from "react";

import ComponentTemplateDragOverlay from "@editor/components/ComponentTemplateDragOverlay";
import { image as imageComponentTemplate } from "@editor/components/editor/templates/image";
import { videoPlayer as videoComponentTemplate } from "@editor/components/editor/templates/videoPlayer";

const AssetCardDragOverlay: React.FC<
  React.PropsWithChildren<{
    id: string;
    url: string;
    type: "image" | "video";
  }>
> = ({ id, url, type, children }) => {
  const template =
    type === "image" ? imageComponentTemplate : videoComponentTemplate;

  return (
    <div className="relative">
      <ComponentTemplateDragOverlay
        key={id}
        template={{
          ...template,
          template: {
            ...template.template!,
            props: {
              ...template.template!.props,
              src: url,
            },
          },
        }}
        scope="left-bar"
      />
      {children}
    </div>
  );
};

export default AssetCardDragOverlay;
