import type { MenuItem } from "@common/designSystem/Menu";
import type { ReploSimpleProject } from "schemas/generated/project";

import * as React from "react";

import { Menu, MenuTrigger } from "@common/designSystem/Menu";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useModal } from "@editor/hooks/useModal";

import IconButton from "@replo/design-system/components/button/IconButton";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { filterNulls } from "replo-utils/lib/array";

type ProjectMenuProps = {
  project: ReploSimpleProject;
  isVertical?: boolean;
  onRenameOpen: () => void;
  isRenameModalOpen: boolean;
};

const ProjectMenu: React.FC<ProjectMenuProps> = ({
  project,
  isVertical = false,
  onRenameOpen,
  isRenameModalOpen,
}) => {
  const { openModal } = useModal();
  const isWorkspaceOwner = useIsWorkspaceOwner(project?.ownerWorkspace?.id);
  const shopifyData = project.integrations?.shopify?.store;

  const selectableItems: MenuItem[] = isWorkspaceOwner
    ? filterNulls([
        {
          type: "leaf",
          id: "renameProject",
          title: "Rename",
          onSelect: onRenameOpen,
        },
        {
          type: "leaf",
          id: "moveProject",
          title: "Move",
          onSelect: () => {
            if (project.ownerWorkspace?.id) {
              openModal({
                type: "moveProjectModal",
                props: {
                  projectId: project.id,
                  projectHasShopifyIntegration: Boolean(
                    project.integrations?.shopify,
                  ),
                  oldWorkspaceId: project?.ownerWorkspace?.id,
                  shopifyUrl: project.integrations?.shopify?.store.shopifyUrl,
                },
              });
            }
          },
        },
        shopifyData?.integrationId
          ? {
              type: "leaf",
              id: "removeShopifyIntegration",
              title: "Remove Shopify",
              onSelect: () =>
                openModal({
                  type: "removeShopifyIntegrationModal",
                  props: {
                    projectId: project.id,
                    integrationId: shopifyData.integrationId!,
                  },
                }),
            }
          : null,
        {
          type: "leaf",
          id: "deleteProject",
          title: "Delete",
          onSelect: () =>
            openModal({
              type: "deleteProjectModal",
              props: {
                projectId: project.id,
                from: "list",
              },
            }),
        },
      ])
    : [
        {
          type: "leaf",
          id: "renameProject",
          title: "Rename",
          onSelect: onRenameOpen,
        },
      ];

  return (
    <Menu
      align="end"
      size="sm"
      items={selectableItems}
      trigger={
        <MenuTrigger
          tooltipText={isRenameModalOpen ? "" : "More options"}
          asChild
        >
          <IconButton
            variant="tertiary"
            size="sm"
            icon={
              isVertical ? (
                <BsThreeDotsVertical size={16} className="text-slate-400" />
              ) : (
                <BsThreeDots size={16} className="text-slate-400" />
              )
            }
            aria-hidden
          >
            <span className="sr-only">More options</span>
          </IconButton>
        </MenuTrigger>
      }
    />
  );
};

export default ProjectMenu;
