import type { Component } from "schemas/component";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

export const getSharedStateKey = (
  component: Pick<Component, "id" | "type">,
  // why is this id? it's more of a prop name...
  id: string,
  config?: {
    hasAccordionAncestor?: boolean;
    accordionId?: string;
  },
) => {
  // why does this exist? they're all the same except for collapsibleV2
  return exhaustiveSwitch(component)({
    button: `${component.id}.${id}`,
    carouselV2: `${component.id}.${id}`,
    carouselV2__indicator: `${component.id}.${id}`,
    carouselV2__panels: `${component.id}.${id}`,
    carouselV3: `${component.id}.${id}`,
    carouselV3Control: `${component.id}.${id}`,
    carouselV3Indicators: `${component.id}.${id}`,
    carouselV3Slides: `${component.id}.${id}`,
    carouselPanelsCount: `${component.id}.${id}`,
    circle: `${component.id}.${id}`,
    collapsible: `${component.id}.${id}`,
    collapsibleV2: () => {
      if (config?.hasAccordionAncestor) {
        return `${config?.accordionId}.accordionOpenItems`;
      }
      return `${component.id}.${id}`;
    },
    collapsibleV2Header: `${component.id}.${id}`,
    collapsibleV2Content: `${component.id}.${id}`,
    collection: `${component.id}.${id}`,
    collectionSelect: `${component.id}.${id}`,
    collectionV2: `${component.id}.${id}`,
    container: `${component.id}.${id}`,
    countdownTimer: `${component.id}.${id}`,
    dropdown: `${component.id}.${id}`,
    googleMapsEmbed: `${component.id}.${id}`,
    spinner: `${component.id}.${id}`,
    h1: `${component.id}.${id}`,
    h2: `${component.id}.${id}`,
    h3: `${component.id}.${id}`,
    icon: `${component.id}.${id}`,
    image: `${component.id}.${id}`,
    klaviyoEmbed: `${component.id}.${id}`,
    marquee: `${component.id}.${id}`,
    modal: `${component.id}.${id}`,
    optionSelect: `${component.id}.${id}`,
    optionSelectDropdown: `${component.id}.${id}`,
    player: `${component.id}.${id}`,
    player__fullScreenIcon: `${component.id}.${id}`,
    player__muteIcon: `${component.id}.${id}`,
    player__playIcon: `${component.id}.${id}`,
    product: `${component.id}.${id}`,
    productCollection: `${component.id}.${id}`,
    quantitySelector: `${component.id}.${id}`,
    rawHtmlContent: `${component.id}.${id}`,
    rechargeSubscriptionWidget: `${component.id}.${id}`,
    staySubscriptionWidget: `${component.id}.${id}`,
    shopifyRawLiquid: `${component.id}.${id}`,
    shopifyAppBlocks: `${component.id}.${id}`,
    shopifySection: `${component.id}.${id}`,
    slidingCarousel: `${component.id}.${id}`,
    spacer: `${component.id}.${id}`,
    starRating: `${component.id}.${id}`,
    symbolRef: `${component.id}.${id}`,
    tabsBlock: `${component.id}.${id}`,
    tabsV2__block: `${component.id}.${id}`,
    tabsV2__list: `${component.id}.${id}`,
    tabsV2__panelsContent: `${component.id}.${id}`,
    tabs__list: `${component.id}.${id}`,
    tabs__onePanelContent: `${component.id}.${id}`,
    tabs__panelsContent: `${component.id}.${id}`,
    temporaryCart: `${component.id}.${id}`,
    temporaryCartItems: `${component.id}.${id}`,
    text: `${component.id}.${id}`,
    tikTokEmbed: `${component.id}.${id}`,
    toggleContainer: `${component.id}.${id}`,
    toggleIndicator: `${component.id}.${id}`,
    tooltip: `${component.id}.${id}`,
    tooltipContent: `${component.id}.${id}`,
    variantSelect: `${component.id}.${id}`,
    variantSelectDropdown: `${component.id}.${id}`,
    sellingPlanSelect: `${component.id}.${id}`,
    vimeoEmbed: `${component.id}.${id}`,
    vimeoEmbedV2: `${component.id}.${id}`,
    youtubeEmbed: `${component.id}.${id}`,
    youtubeEmbedV2: `${component.id}.${id}`,
    okendoProductRatingSummary: `${component.id}.${id}`,
    okendoReviewsWidget: `${component.id}.${id}`,
    junipProductRating: `${component.id}.${id}`,
    junipReviews: `${component.id}.${id}`,
    yotpoProductRating: `${component.id}.${id}`,
    yotpoReviews: `${component.id}.${id}`,
    looxProductRating: `${component.id}.${id}`,
    looxReviews: `${component.id}.${id}`,
    knoCommerceWidget: `${component.id}.${id}`,
    reviewsIoProductRating: `${component.id}.${id}`,
    reviewsIoReviews: `${component.id}.${id}`,
    dynamicCheckoutButtons: `${component.id}.${id}`,
    paymentTerms: `${component.id}.${id}`,
    sellingPlanSelectDropdown: `${component.id}.${id}`,
    accordionBlock: `${component.id}.${id}`,
    rebuyWidget: `${component.id}.${id}`,
    buyWithPrimeButton: `${component.id}.${id}`,
    stampedProductReviewsWidget: `${component.id}.${id}`,
    stampedProductRatingWidget: `${component.id}.${id}`,
    feraProductRatingWidget: `${component.id}.${id}`,
    feraProductReviewsWidget: `${component.id}.${id}`,
    feraStoreReviewsWidget: `${component.id}.${id}`,
    feraMediaGalleryWidget: `${component.id}.${id}`,
    shopifyProductReviewsWidget: `${component.id}.${id}`,
    shopifyProductRatingWidget: `${component.id}.${id}`,
    judgeProductRatingWidget: `${component.id}.${id}`,
    judgeProductReviewsWidget: `${component.id}.${id}`,
    infiniteOptionsWidget: `${component.id}.${id}`,
    kachingBundles: `${component.id}.${id}`,
    postscriptSignupForm: `${component.id}.${id}`,
    subscribeAndSave: `${component.id}.${id}`,
    beforeAfterSlider: `${component.id}.${id}`,
    beforeAfterSliderThumb: `${component.id}.${id}`,
    beforeAfterSliderBeforeContent: `${component.id}.${id}`,
    beforeAfterSliderAfterContent: `${component.id}.${id}`,
    selectionList: `${component.id}.${id}`,
  });
};
