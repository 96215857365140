import type {
  DesignLibrary,
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import * as React from "react";

import Input from "@common/designSystem/Input";
import Selectable from "@common/designSystem/Selectable";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import ColorSavedStyleGroup from "@editor/components/designLibrary/ColorSavedStyleGroup";
import SavedStylesEmptyState from "@editor/components/designLibrary/SavedStylesEmptyState";
import TextSavedStyleGroup from "@editor/components/designLibrary/TextSavedStyleGroup";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useHasWorkspaceMembership } from "@editor/hooks/useHasWorkspaceMembership";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { getHexColor } from "@editor/utils/colors";
import { trpc, trpcClient, trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { BsCheckCircleFill, BsStars } from "react-icons/bs";
import { animated, config, useTransition } from "react-spring";
import { isEmpty } from "replo-utils/lib/misc";
import { urlFormSchema } from "schemas/url";

type SelectableOption = "url" | "shopify" | "replo";
type FormValues = {
  url: string;
};

type OverridedStyles = {
  text: SavedTextStyle[] | null;
  color: SavedColorStyle[] | null;
};

type ImportSavedStylesProps = {
  isImportingStyles: boolean;
  overridedStyles: OverridedStyles;
  isLoading: boolean;
  setIsImportingStyles: React.Dispatch<boolean>;
  setOverridedStyles: React.Dispatch<OverridedStyles>;
  setIsLoading: React.Dispatch<boolean>;
};

const SavedStylesPane: React.FC = () => {
  const [isImportingStyles, setIsImportingStyles] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [overridedStyles, setOverridedStyles] = React.useState<OverridedStyles>(
    {
      text: null,
      color: null,
    },
  );

  const { colorSavedStyles, textSavedStyles } =
    useGetDesignLibrarySavedStyles();

  const hasSavedStyles =
    [...(colorSavedStyles ?? []), ...(textSavedStyles ?? [])].length > 0;

  return (
    <div className="flex flex-col w-full h-full gap-3 pl-3 py-1 overflow-y-scroll styled-scrollbar pb-10">
      {!hasSavedStyles && (
        <ImportSavedStyles
          isImportingStyles={isImportingStyles}
          setIsImportingStyles={setIsImportingStyles}
          setOverridedStyles={setOverridedStyles}
          overridedStyles={overridedStyles}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      )}
      <SavedStyleGroups
        overrideTextStyles={overridedStyles.text ?? undefined}
        overrideColorStyles={overridedStyles.color ?? undefined}
        isLoading={isLoading}
      />
      {!hasSavedStyles && !isImportingStyles && (
        <div className="mr-1">
          <SavedStylesEmptyState title="No Styles Added Yet" showCta />
        </div>
      )}
    </div>
  );
};

const ImportSavedStyles: React.FC<ImportSavedStylesProps> = ({
  isImportingStyles,
  overridedStyles,
  isLoading,
  setIsImportingStyles,
  setOverridedStyles,
  setIsLoading,
}) => {
  const [selectedOption, setSelectedOption] =
    React.useState<SelectableOption | null>(null);
  const [selectedReploProjectId, setSelectedReploProjectId] = React.useState<
    string | null
  >(null);

  const { project } = useCurrentProjectContext();
  const projectId = project?.id;
  const projectHasShopifyIntegration = Boolean(project?.integrations?.shopify);
  const { isUserMemberOfWorkspace } = useHasWorkspaceMembership();
  const { data: designLibraries } =
    trpc.designLibrary.getProjectsWithDesignLibraries.useQuery(
      projectId ? { projectId } : skipToken,
    );
  const errorToast = useErrorToast();

  const {
    mutateAsync: createDesignLibrary,
    isPending: isCreatingDesignLibrary,
  } = trpc.designLibrary.create.useMutation({
    onSettled: async () => {
      if (projectId) {
        await trpcUtils.designLibrary.get.invalidate({
          projectId,
        });
      }
      setOverridedStyles({ text: null, color: null });
      setIsImportingStyles(false);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      url: "",
    },
    resolver: zodResolver(urlFormSchema),
  });

  const urlError = errors.url?.message;
  const urlValue = watch("url");
  const shouldDisableButton =
    (selectedOption === "url" && (Boolean(urlError) || urlValue === "")) ||
    (selectedOption === "replo" && selectedReploProjectId === null);

  const options = [
    { label: "From a URL", value: "url" },
    {
      label: "Connected Shopify site",
      value: "shopify",
      isDisabled: !projectHasShopifyIntegration,
    },
    {
      label: "Another Replo project",
      value: "replo",
      isDisabled: !isUserMemberOfWorkspace,
    },
  ];

  const reploProjectOptions = isEmpty(designLibraries)
    ? [
        {
          value: null,
          label: "There are no available projects",
          isDisabled: true,
        },
      ]
    : designLibraries.map((designLibrary) => ({
        value: designLibrary.id,
        label: designLibrary.name ?? "Untitled",
      }));

  const onSubmit = async ({ url }: FormValues) => {
    setIsImportingStyles(true);
    setIsLoading(true);
    if (selectedOption === "replo" && projectId && selectedReploProjectId) {
      const data = await trpcClient.designLibrary.get.query({
        projectId: selectedReploProjectId,
      });
      setOverridedStyles({
        text: getOverrideStyles(data, "text"),
        color: getOverrideStyles(data, "color"),
      });
    }
    if (selectedOption === "url" || selectedOption === "shopify") {
      try {
        const { color, text } = await trpcClient.ai.generatePrimaryStyles.query(
          {
            url,
            projectId,
          },
        );
        setOverridedStyles({
          text: makeStylesPrimary(text),
          color: makeStylesPrimary(color),
        });
      } catch {
        errorToast(
          "Failed to generate styles",
          "Please try again, or contact support@replo.app for help.",
          "error.ai.generatePrimaryStyles",
          {},
        );
        setIsImportingStyles(false);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const onConfirm = async () => {
    if (selectedOption === "replo" && projectId && selectedReploProjectId) {
      await createDesignLibrary({
        projectId,
        duplicateFromProjectId: selectedReploProjectId,
      });
    }

    if (
      (selectedOption === "url" || selectedOption === "shopify") &&
      projectId
    ) {
      await createDesignLibrary({
        projectId,
        savedStyles: {
          text:
            overridedStyles.text?.map((savedStyle) => ({
              ...savedStyle,
              color: savedStyle.attributes.color
                ? getHexColor(savedStyle.attributes.color)
                : null,
            })) ?? [],
          color:
            overridedStyles.color?.map((savedStyle) => ({
              ...savedStyle,
              color: savedStyle.attributes.color
                ? getHexColor(savedStyle.attributes.color)
                : null,
            })) ?? [],
        },
      });
    }
    logEvent("library.style.import.confirm", {});
  };

  const logEvent = useLogAnalytics();

  return (
    <div className="flex flex-col gap-3 pr-1">
      {/* IMPORT BOX */}
      {!isImportingStyles && (
        <div className="flex flex-col text-default text-xs gap-2 border-border rounded-md p-2 border">
          <div className="typ-header-small">Import brand styles</div>
          <div className="typ-body-small text-muted">
            Transfer type, color, and more from another source.
          </div>
          <form
            className="flex flex-col gap-2"
            onSubmit={(e) => {
              logEvent("library.style.import", {
                importMethod: selectedOption,
              });
              e.preventDefault();
              void handleSubmit(onSubmit)(e);
            }}
          >
            <Selectable
              placeholder="Transfer from"
              options={options}
              labelClassName={selectedOption ? "text-default" : "text-subtle"}
              value={selectedOption}
              onSelect={(value) => {
                setSelectedOption(value as SelectableOption);
                if (value === "shopify") {
                  setValue(
                    "url",
                    project?.integrations?.shopify?.store.shopifyUrl ?? "",
                  );
                } else {
                  reset();
                }
              }}
            />

            {(selectedOption === "url" || selectedOption === "shopify") && (
              <>
                <Input
                  aria-invalid={Boolean(urlError) ? "true" : undefined}
                  aria-describedby={Boolean(urlError) ? "error-url" : undefined}
                  autoComplete="off"
                  placeholder="https://"
                  {...register("url", {
                    required: "Url is required",
                  })}
                  isDisabled={selectedOption === "shopify"}
                  type="text"
                  validityState={Boolean(urlError) ? "invalid" : "valid"}
                />
                {urlError && <ErrorMessage id="error-url" error={urlError} />}
              </>
            )}
            {selectedOption === "replo" && (
              <Selectable
                placeholder="Select a Replo project"
                options={reploProjectOptions}
                onSelect={(value) => setSelectedReploProjectId(value)}
              />
            )}
            {selectedOption && (
              <Button
                variant="primary"
                className="w-full"
                disabled={shouldDisableButton}
                type="submit"
                isLoading={isCreatingDesignLibrary}
              >
                Generate Styles
              </Button>
            )}
          </form>
        </div>
      )}
      {isLoading && <SavedStylesLoadingState isLoading={isLoading} />}
      {/* END IMPORT BOX */}
      {!isLoading && isImportingStyles && (
        <ConfirmSavedStyle
          onConfirm={() => void onConfirm()}
          onCancel={() => {
            logEvent("library.style.import.reject", {});
            reset();
            setIsImportingStyles(false);
            setOverridedStyles({ text: null, color: null });
          }}
        />
      )}
    </div>
  );
};

const SavedStylesLoadingState: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  const { project } = useCurrentProjectContext();
  const projectName = project?.name;

  const timeoutsRef = React.useRef<ReturnType<typeof setTimeout>[]>([]);
  const [content, setContent] = React.useState<string[]>([
    "Analyzing text styles...",
  ]);
  const transitions = useTransition(content, {
    from: { opacity: 0, innerHeight: 0 },
    enter: { opacity: 1, innerHeight: 28 },
    config: config.stiff,
  });

  React.useEffect(() => {
    // NOTE (Fran 2024-12-20): This is a multistage animation that runs when the loading state is triggered.
    // It's based on a React Spring example https://codesandbox.io/s/k467x.
    // The timings are not based on any real data, but they are designed to be
    // long enough to be noticeable, but not too long. Since the AI generation styles takes time,
    // we want to show the user that something is happening.
    if (isLoading) {
      const initLoadingAnimation = () => {
        timeoutsRef.current.forEach(clearTimeout);
        timeoutsRef.current = [];
        timeoutsRef.current.push(
          setTimeout(
            () => setContent((prev) => [...prev, "Parsing color palette..."]),
            1500,
          ),
        );
        timeoutsRef.current.push(
          setTimeout(
            () =>
              setContent((prev) => [
                ...prev,
                "Naming and tagging your styles...",
              ]),
            4000,
          ),
        );
        timeoutsRef.current.push(
          setTimeout(
            () =>
              setContent((prev) => [
                ...prev,
                projectName
                  ? `Preparing styles for ${projectName}...`
                  : "Preparing styles...",
              ]),
            6500,
          ),
        );
      };
      initLoadingAnimation();
    }
    return () => {
      timeoutsRef.current.forEach(clearTimeout);
      timeoutsRef.current = [];
    };
  }, [isLoading, projectName]);

  return (
    <div className="flex flex-col gap-2 border rounded-md p-4 items-center justify-center h-40 transition-all">
      {transitions(({ innerHeight, ...styles }, item, _, index) => {
        const isLast = index === content.length - 1;
        return (
          <animated.div
            style={styles}
            className="flex items-center gap-2 w-full text-default"
          >
            <animated.div
              style={{ maxHeight: innerHeight }}
              className="flex gap-2"
            >
              {!isLast ? (
                <BsCheckCircleFill
                  size={16}
                  className="text-primary shrink-0"
                />
              ) : (
                <BsStars size={16} className="text-primary shrink-0" />
              )}
              <span className="typ-body-small">{item}</span>
            </animated.div>
          </animated.div>
        );
      })}
    </div>
  );
};

const SavedStyleGroups: React.FC<{
  overrideTextStyles?: SavedTextStyle[];
  overrideColorStyles?: SavedColorStyle[];
  isLoading?: boolean;
}> = ({ overrideTextStyles, overrideColorStyles, isLoading }) => {
  return (
    <div className="pr-1">
      <TextSavedStyleGroup
        overrideSavedStyles={overrideTextStyles}
        isLoading={isLoading}
      />
      <ColorSavedStyleGroup
        overrideSavedStyles={overrideColorStyles}
        isLoading={isLoading}
      />
    </div>
  );
};

const ConfirmSavedStyle: React.FC<{
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ onConfirm, onCancel }) => {
  return (
    <div className="flex flex-col justify-between text-default text-xs gap-2 border-border rounded-md p-3 border h-28">
      <div className="typ-header-small">Save brand styles?</div>
      <div className="typ-body-small text-muted">
        If you accept, you can always edit styles later.
      </div>
      <div className="flex items-center gap-2">
        <Button variant="primary" onClick={onConfirm} className="w-full">
          Save
        </Button>
        <Button variant="secondary" onClick={onCancel} className="w-full">
          No
        </Button>
      </div>
    </div>
  );
};

function getOverrideStyles<T extends SavedTextStyle | SavedColorStyle>(
  designLibrary: DesignLibrary | null,
  type: "text" | "color",
): T[] | null {
  if (!designLibrary) {
    return null;
  }
  const filteredStyles = Object.values(designLibrary.savedStyles).reduce<T[]>(
    (savedStyles, savedStyle) => {
      if (savedStyle.type === type) {
        savedStyles.push({
          type: savedStyle.type,
          name: savedStyle.name,
          attributes: savedStyle.attributes,
          optionalUsageGuidelines: savedStyle.optionalUsageGuidelines,
          deletedAt: savedStyle.deletedAt,
        } as T);
      }
      return savedStyles;
    },
    [],
  );

  return filteredStyles;
}

function makeStylesPrimary<T extends SavedTextStyle | SavedColorStyle>(
  styles: T[],
) {
  return styles.map((style) => ({
    ...style,
    isPrimary: true,
  }));
}

export default SavedStylesPane;
