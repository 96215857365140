import type { EditorRoute } from "@editor/utils/router";
import type {
  ReploElement,
  ReploElementMetadata,
} from "schemas/generated/element";

import * as React from "react";

import { toast } from "@common/designSystem/Toast";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  archiveElement as archiveElementAction,
  selectDraftElementId,
  selectDraftElementProjectId,
  selectElementsMapping,
  setEditorMode,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "replo-utils/lib/string";

import useSetDraftElement from "./useSetDraftElement";

type ArchiveOptions = {
  onSuccess?: () => void;
  from: "modal" | "button";
};

export function useArchiveElement() {
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const projectId = useCurrentProjectId();
  const navigate = useNavigate();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const draftElementProjectId = useEditorSelector(selectDraftElementProjectId);
  // TODO (BEN O, 02-01-2025): remove once fully moved to trpc
  const allElements = Object.values(useEditorSelector(selectElementsMapping));
  const setDraftElement = useSetDraftElement();
  const { mutate: archiveMutation } = trpc.element.archive.useMutation();

  const { data: elementsMetadata } =
    trpc.element.listAllElementsMetadata.useQuery(
      isFeatureEnabled("refactor-element-settings") && projectId
        ? { projectId }
        : skipToken,
    );

  const elements = React.useMemo(
    () =>
      isFeatureEnabled("refactor-element-settings")
        ? elementsMetadata ?? []
        : allElements,
    [elementsMetadata, allElements],
  );

  const handleArchive = React.useCallback(
    (
      element: ReploElement | ReploElementMetadata,
      shouldUnpublish: boolean,
      options: ArchiveOptions,
    ) => {
      archiveMutation(
        { elementId: element.id, shouldUnpublish },
        {
          onSuccess: () => {
            toast({
              header: `${capitalizeFirstLetter(
                elementTypeToEditorData[element.type].singularDisplayName,
              )} archived successfully`,
              message: `Your ${capitalizeFirstLetter(
                elementTypeToEditorData[element.type].singularDisplayName,
              )} has been archived successfully`,
              cta: "View archived element",
              ctaOnClick: () => {
                dispatch(setEditorMode(EditorMode.archived));
              },
            });
            dispatch(archiveElementAction(element.id));
            void trpcUtils.element.listArchived.invalidate();
            void trpcUtils.element.listAllElementsMetadata.invalidate();

            logEvent("element.archive", {
              elementId: element.id,
              elementType: element.type,
              projectId: projectId ?? "",
              from: options.from,
            });

            if (
              draftElementId === element.id &&
              draftElementProjectId &&
              elements.length > 1
            ) {
              const currentIndex = elements.findIndex(
                (p) => p.id === element.id,
              );
              const nextIndex =
                currentIndex === 0 ? currentIndex + 1 : currentIndex - 1;
              const newPageId = elements[nextIndex]!.id;
              setDraftElement({ id: newPageId });
              navigate(
                generatePath(routes.editor.element, {
                  projectId: draftElementProjectId,
                  elementId: newPageId,
                } as EditorRoute),
              );
            }

            options.onSuccess?.();
          },
        },
      );
    },
    [
      archiveMutation,
      dispatch,
      logEvent,
      projectId,
      draftElementId,
      draftElementProjectId,
      elements,
      setDraftElement,
      navigate,
    ],
  );

  return handleArchive;
}
