/**
 * Generates the S3 URL for a project's preview image
 */
export function getProjectPreviewImageUrl({
  workspaceId,
  projectId,
  env,
  includeBaseUrl = true,
}: {
  workspaceId: string;
  projectId: string;
  env: string | undefined;
  includeBaseUrl?: boolean;
}): string {
  return `${includeBaseUrl ? "https://andytown-public.s3.us-west-1.amazonaws.com/" : ""}${env ?? "dev"}/workspaces/${workspaceId}/projects/${projectId}/preview-desktop.jpg`;
}
