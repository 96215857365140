import type { ElementRequestType } from "@editor/utils/element";

import * as React from "react";

import ElementNameEditor from "@components/editor/elementEditors/ElementNameEditor";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useElementsMetadataByType } from "@editor/hooks/useElementsMetadataByType";
import { useInitial } from "@editor/hooks/useInitial";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { selectSections } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { removeFolderNameFromElementName } from "replo-utils/element";
import { slugify } from "replo-utils/lib/string";

export const SectionElementEditor: React.FC<{
  requestType: ElementRequestType;
  initialName?: string;
}> = ({ requestType, initialName }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const initialElementIsPublished = useInitial(element.isPublished);
  const modal = useModal();

  // TODO (Ben O., 02-01-2025): clean up once fully moved to trpc
  const sectionsSelector = useEditorSelector(selectSections);
  const sectionsTRPC = useElementsMetadataByType("shopifySection");
  const sections = isFeatureEnabled("refactor-element-settings")
    ? sectionsTRPC
    : sectionsSelector;

  const currentSection = sections.find(
    (section) => section.name === element.name,
  );
  const handleNameChange = (name: string) => {
    // NOTE (Matt 08-14-2023): In this context, the 'shopifyPagePath' is the Shopify Section File name.
    // This is used in the modal to validate whether or not a section already exists in this theme with
    // the same name.
    const shopifyPagePath =
      requestType !== "update" || !element?.shopifyPagePath
        ? slugify(removeFolderNameFromElementName(name))
        : element.shopifyPagePath;
    onChangeElement({
      ...element,
      name,
      shopifyPagePath,
    });
  };

  return (
    <>
      <div className="mb-2 flex flex-col">
        <ElementNameEditor
          type="shopifySection"
          initialName={initialName ?? element.name}
          inputName="section-name"
          onChange={handleNameChange}
        />
        {requestType === "update" && (
          <div className="mt-4 flex flex-col gap-y-2">
            {initialElementIsPublished && (
              <SwitchWithDescription
                label="Is Published"
                description="This section will be available to use in the Shopify Theme Customizer."
                isOn={element.isPublished}
                onChange={(isPublished) => {
                  if (!isPublished && currentSection?.isPublished) {
                    modal.openModal({
                      type: "deleteElementConfirmationModal",
                      props: {
                        type: "unpublish",
                        element: currentSection!,
                        onDelete: () => {
                          onChangeElement({ ...element, isPublished });
                          modal.closeModal({
                            type: "deleteElementConfirmationModal",
                          });
                        },
                      },
                    });
                  } else {
                    onChangeElement({ ...element, isPublished });
                  }
                }}
                size="sm"
              />
            )}
          </div>
        )}
        <div className="mt-4 flex flex-col gap-y-2">
          <SwitchWithDescription
            label="Editable in Shopify Theme Editor"
            description="Text and images in this section will be editable in the Shopify Theme Editor."
            isOn={element.useSectionSettings ?? false}
            onChange={(useSectionSettings) => {
              onChangeElement({ ...element, useSectionSettings });
            }}
            size="sm"
          />
        </div>
      </div>
    </>
  );
};

export default SectionElementEditor;
