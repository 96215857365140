import type { RenderComponentProps } from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { Component } from "schemas/component";
import type { ItemsConfig } from "schemas/dynamicData";
import type { ActionType } from "./config";

import * as React from "react";

import * as RadioGroup from "@radix-ui/react-radio-group";
import { mergeContext } from "replo-runtime/shared/utils/context";
import { useRenderChildren } from "replo-runtime/shared/utils/renderComponents";
import { withLiquidAlternate } from "replo-runtime/shared/utils/withLiquidAlternate";
import { useConsistentLiquidId } from "replo-runtime/store/hooks/useConsistentLiquidId";
import { isItemsDynamic } from "replo-runtime/store/utils/items";
import { getLiquidString } from "replo-runtime/store/utils/liquid";

import { ReploComponent } from "../ReploComponent";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";

type SelectionListContextValue = {
  selectedItem: string | null;
  setSelectedItem?: (selectedItem: string) => void;
};

const SelectionListContext = React.createContext<SelectionListContextValue>({
  selectedItem: null,
  setSelectedItem: undefined,
});

const useSelectionListContext = () => {
  return React.useContext(SelectionListContext);
};

const SelectionList: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const customProps = getCustomProps(component);
  const listItems = useRenderChildren(component, {
    itemsConfig: customProps.items,
    context,
  });
  const areItemsDynamic = isItemsDynamic(customProps.items);
  const [selectedItem, setSelectedItem] = React.useState<string>(
    listItems[0]?.component.id ? `${listItems[0].component.id}.0` : "",
  );

  const isAriaSelectionEnabled = customProps.accessibilitySelection ?? true;

  return (
    <SelectionListContext.Provider value={{ selectedItem, setSelectedItem }}>
      <SelectionListWrapper isAriaSelectionEnabled={isAriaSelectionEnabled}>
        <div {...componentAttributes}>
          {listItems.map((item, index) => {
            const template =
              component.children?.[index] ?? component.children?.[0];

            if (!template) {
              return null;
            }

            return (
              <SelectionListItem
                isAriaSelectionEnabled={isAriaSelectionEnabled}
                index={areItemsDynamic ? index : 0}
                key={item.component.id}
                item={item}
                parentContext={context}
                template={template}
              />
            );
          })}
        </div>
      </SelectionListWrapper>
    </SelectionListContext.Provider>
  );
};

const SelectionListLiquid: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const customProps = getCustomProps(component);
  const listItems = useRenderChildren(component, {
    itemsConfig: customProps.items,
    context,
  });
  const [selectedItem, setSelectedItem] = React.useState<string>(
    listItems[0]?.component.id ? `${listItems[0].component.id}.0` : "",
  );
  const isAriaSelectionEnabled = customProps.accessibilitySelection ?? true;
  const dynamicPath =
    customProps.items?.type === "inline" &&
    customProps.items.valueType === "dynamic" &&
    customProps.items.dynamicPath;

  const liquidString = dynamicPath && getLiquidString(dynamicPath, context);
  const consistentLiquidId = useConsistentLiquidId();

  if (!component.children?.[0]) {
    return null;
  }
  if (!liquidString) {
    return (
      <SelectionList
        component={component}
        componentAttributes={componentAttributes}
        context={context}
      />
    );
  }

  // NOTE (Matt 2025-01-16): `getLiquidString` leaves the {{}} on, and we don't need those
  const liquidItems = liquidString.slice(2, -2);
  return (
    <SelectionListContext.Provider value={{ selectedItem, setSelectedItem }}>
      <SelectionListWrapper isAriaSelectionEnabled={isAriaSelectionEnabled}>
        <div {...componentAttributes}>
          <ReploLiquidChunk>
            {`{%- assign previousCurrentItem${consistentLiquidId} = currentItem -%}`}
            {`{%- for unformattedCurrentItem in ${liquidItems} -%}`}
            {`{%- liquid
              assign currentItem = unformattedCurrentItem
              if currentItem.src
                assign currentItem = unformattedCurrentItem.src
              endif
            -%}`}
            {`{%- case forloop.index0 -%}`}
            {component.children.map((childComponent, childIndex) => {
              return (
                <>
                  {`{% when ${childIndex} %}`}
                  <SelectionListItem
                    // biome-ignore lint/correctness/useJsxKeyInIterable: ignore key
                    isAriaSelectionEnabled={isAriaSelectionEnabled}
                    index="{{-forloop.index0-}}"
                    item={{ component: childComponent, context, item: null }}
                    parentContext={context}
                    template={childComponent}
                  />
                </>
              );
            })}
            {`{%- else -%}`}
            <SelectionListItem
              isAriaSelectionEnabled={isAriaSelectionEnabled}
              index="{{-forloop.index0-}}"
              item={{ component: component.children[0], context, item: null }}
              parentContext={context}
              template={component.children[0]}
            />
            {`{%- endcase -%}`}
            {`{%- endfor -%}`}
            {`{%- assign currentItem = previousCurrentItem${consistentLiquidId} -%}`}
          </ReploLiquidChunk>
        </div>
      </SelectionListWrapper>
    </SelectionListContext.Provider>
  );
};

const SelectionListWrapper: React.FC<
  React.PropsWithChildren<{
    isAriaSelectionEnabled: boolean;
  }>
> = ({ isAriaSelectionEnabled, children }) => {
  const selectionListContext = useSelectionListContext();
  if (isAriaSelectionEnabled) {
    return (
      <RadioGroup.Root value={selectionListContext.selectedItem ?? ""} asChild>
        {children}
      </RadioGroup.Root>
    );
  }
  return children;
};

const SelectionListItem: React.FC<{
  isAriaSelectionEnabled: boolean;
  item: { component: Component; context: Context; item: any };
  index: number | string;
  parentContext: Context;
  template: Component;
}> = ({ isAriaSelectionEnabled, parentContext, item, template, index }) => {
  const selectionListContext = useSelectionListContext();
  const repeatedIndexPath = `${parentContext.repeatedIndexPath}.${index}`;

  if (!isAriaSelectionEnabled) {
    return (
      <ReploComponent
        component={template}
        context={mergeContext(item.context, parentContext)}
        repeatedIndexPath={repeatedIndexPath}
      />
    );
  }

  const actionHooks = {
    setSelectedListItem: () => {
      selectionListContext.setSelectedItem?.(itemValue);
    },
  } satisfies {
    [K in ActionType]: Function;
  };

  const itemValue = `${item.component.id}.${index}`;
  const mergedContext = mergeContext(item.context, {
    ...parentContext,
    attributes: {
      ...parentContext.attributes,
      ...item.context.attributes,
    },
    state: {
      ...parentContext.state,
      selectionList: {
        isItemSelected: selectionListContext.selectedItem === itemValue,
        selectedListItem: item.component.id,
      },
    },
    actionHooks,
  });

  return (
    <RadioGroup.Item value={itemValue} asChild>
      <ReploComponent
        component={template}
        context={mergedContext}
        repeatedIndexPath={repeatedIndexPath}
        defaultActions={{
          actions: {
            onClick: [
              {
                id: "alchemy:setSelectedListItem",
                type: "setSelectedListItem",
                value: null,
              },
            ],
          },
        }}
      />
    </RadioGroup.Item>
  );
};

function getCustomProps(component: Component) {
  return Object.fromEntries(
    Object.entries(component.props).map(([key, value]) => [
      key.startsWith("_") ? key.slice(1) : key,
      value,
    ]),
  ) as {
    items?: ItemsConfig;
    accessibilitySelection?: boolean;
  };
}

export default withLiquidAlternate(SelectionList, SelectionListLiquid);
