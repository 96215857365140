import type { Component } from "replo-runtime";

import * as React from "react";

import Modal from "@editor/components/common/designSystem/Modal";
import { ToggleGroup } from "@editor/components/common/designSystem/ToggleGroup";

import Button from "@replo/design-system/components/button";
import { Textarea } from "@replo/design-system/components/textarea";
import { BsX } from "react-icons/bs";

type Prompts = {
  systemPrompt: string;
  initialPrompt: string;
};

type PromptModalProps = {
  isOpen: boolean;
  onClose: () => void;
  prompts: Prompts | null;
  component: Component | null;
  onPromptsChange?: (prompts: Prompts) => void;
};

type PromptType = "system" | "initial";

type DynamicSection = {
  start: number;
  end: number;
  type: "component" | "dynamicData" | "brandDetails" | "userInstructions";
  content: string;
};

const findDynamicSections = (text: string): DynamicSection[] => {
  const sections: DynamicSection[] = [];

  if (!text) {
    return sections;
  }

  // Find component JSON sections
  const componentMatches = text?.matchAll(/\[JSON Template]\n({[\S\s]*?})/g);
  for (const match of componentMatches) {
    if (match.index !== undefined) {
      sections.push({
        start: match.index,
        end: match.index + match[0].length,
        type: "component",
        content: match[0],
      });
    }
  }

  // Find dynamic data ({{...}})
  const dynamicDataMatches = text?.matchAll(/{{.*?}}/g);
  for (const match of dynamicDataMatches) {
    if (match.index !== undefined) {
      sections.push({
        start: match.index,
        end: match.index + match[0].length,
        type: "dynamicData",
        content: match[0],
      });
    }
  }

  // Find brand details sections
  const brandDetailsMatches = text.matchAll(
    /\[Brand Details][\S\s]*?(?=\[|$)/g,
  );
  for (const match of brandDetailsMatches) {
    if (match.index !== undefined) {
      sections.push({
        start: match.index,
        end: match.index + match[0].length,
        type: "brandDetails",
        content: match[0],
      });
    }
  }

  // Find user instructions sections
  const userInstructionsMatches = text.matchAll(
    /\[User Prompt][\S\s]*?(?=\[|$)/g,
  );
  for (const match of userInstructionsMatches) {
    if (match.index !== undefined) {
      sections.push({
        start: match.index,
        end: match.index + match[0].length,
        type: "userInstructions",
        content: match[0],
      });
    }
  }

  return sections.sort((a, b) => a.start - b.start);
};

const STORAGE_KEY_PREFIX = "replo.ai.editedPrompt";

export const PromptModal: React.FC<PromptModalProps> = ({
  isOpen,
  onClose,
  component,
  prompts,
  onPromptsChange,
}) => {
  const [selectedPrompt, setSelectedPrompt] =
    React.useState<PromptType>("system");
  const [editedSystemPrompt, setEditedSystemPrompt] =
    React.useState<string>("");
  const [editedInitialPrompt, setEditedInitialPrompt] =
    React.useState<string>("");
  const [hasEdited, setHasEdited] = React.useState(false);

  const storageKey = `${STORAGE_KEY_PREFIX}.template`;

  // Load saved edits from localStorage when component or type changes
  React.useEffect(() => {
    if (!storageKey) {
      return;
    }

    const savedEdits = localStorage.getItem(storageKey);
    if (savedEdits) {
      const { systemPrompt, initialPrompt } = JSON.parse(savedEdits);
      setEditedSystemPrompt(systemPrompt);
      setEditedInitialPrompt(initialPrompt);
      setHasEdited(true);
    } else {
      setHasEdited(false);
    }
  }, [storageKey]);

  // Initialize or update prompts when they change
  React.useEffect(() => {
    if (!prompts || !component) {
      return;
    }

    if (!hasEdited) {
      // If we haven't edited yet, use the new prompts directly
      setEditedSystemPrompt(prompts.systemPrompt);
      setEditedInitialPrompt(prompts.initialPrompt);
      return;
    }

    // If we have edited, only update dynamic sections

    const oldSections = findDynamicSections(editedInitialPrompt);
    const newSections = findDynamicSections(prompts.initialPrompt);

    // Create a map of section types to their content from the new prompt
    const newSectionMap = new Map<string, string>();
    newSections.forEach((section) => {
      newSectionMap.set(section.type, section.content);
    });

    // Update only the component JSON sections in the edited prompt
    let updatedPrompt = editedInitialPrompt;
    [...oldSections].reverse().forEach((section) => {
      const newContent = newSectionMap.get(section.type);
      if (
        newContent &&
        (section.type === "component" || section.type === "userInstructions")
      ) {
        updatedPrompt =
          updatedPrompt.slice(0, section.start) +
          newContent +
          updatedPrompt.slice(section.end);
      }
    });

    setEditedInitialPrompt(updatedPrompt);
  }, [prompts, component, hasEdited, editedInitialPrompt]);

  const toggleOptions = [
    { label: "System Prompt", value: "system" },
    { label: "User Prompt", value: "initial" },
  ];

  const currentPrompt = React.useMemo(() => {
    return selectedPrompt === "system"
      ? editedSystemPrompt
      : editedInitialPrompt;
  }, [selectedPrompt, editedSystemPrompt, editedInitialPrompt]);

  const handlePromptChange = (value: string) => {
    setHasEdited(true);

    const updatedSystemPrompt =
      selectedPrompt === "system" ? value : editedSystemPrompt;
    const updatedInitialPrompt =
      selectedPrompt === "initial" ? value : editedInitialPrompt;

    if (selectedPrompt === "system") {
      setEditedSystemPrompt(value);
    } else {
      setEditedInitialPrompt(value);
    }

    // Save to localStorage
    if (storageKey) {
      localStorage.setItem(
        storageKey,
        JSON.stringify({
          systemPrompt: updatedSystemPrompt,
          initialPrompt: updatedInitialPrompt,
        }),
      );
    }

    // Notify parent of changes
    onPromptsChange?.({
      systemPrompt: updatedSystemPrompt,
      initialPrompt: updatedInitialPrompt,
    });
  };

  const handleReset = () => {
    if (!prompts) {
      return;
    }

    if (selectedPrompt === "system") {
      setEditedSystemPrompt(prompts.systemPrompt);
    } else {
      setEditedInitialPrompt(prompts.initialPrompt);
    }

    // Clear from localStorage
    if (storageKey) {
      localStorage.removeItem(storageKey);
      setHasEdited(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="w-[800px] max-w-[calc(100vw-32px)] h-[600px] max-h-[calc(100vh-32px)] p-0"
      includesCloseIcon={false}
    >
      <div className="flex flex-col h-full">
        {/* Header - fixed height */}
        <div className="flex justify-between items-center p-4 border-b shrink-0">
          <h2 className="text-lg font-semibold">AI Prompts</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <BsX size={20} className="text-slate-600" />
          </button>
        </div>

        {/* Content - flexible height */}
        <div className="flex-1 min-h-0 p-6 flex flex-col">
          {/* Toggle Group - fixed height */}
          <div className="flex justify-center mb-4 shrink-0">
            <ToggleGroup
              type="single"
              options={toggleOptions}
              value={selectedPrompt}
              onChange={(value) => setSelectedPrompt(value as PromptType)}
              allowsDeselect={false}
              size="sm"
            />
          </div>

          {/* Prompt Content - flexible height */}
          <div className="flex-1 min-h-0 flex flex-col">
            <div className="flex justify-between items-center mb-2 shrink-0">
              <span className="text-sm font-medium text-gray-700">
                {selectedPrompt === "system" ? "System Prompt" : "User Prompt"}
              </span>
              <div className="flex gap-2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={handleReset}
                  disabled={!hasEdited}
                >
                  Reset
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() =>
                    void navigator.clipboard.writeText(currentPrompt ?? "")
                  }
                >
                  Copy
                </Button>
              </div>
            </div>
            {component ? (
              <div className="flex-1 min-h-0 flex flex-col overflow-hidden">
                <Textarea
                  value={currentPrompt ?? "Type a message to see the prompt..."}
                  onChange={handlePromptChange}
                  UNSAFE_className="bg-gray-50"
                  layoutClassName="flex-1 min-h-0 h-[350px]"
                  font="mono"
                  placeholder="Type a message to see the prompt..."
                  size="base"
                  textLength="long"
                />
              </div>
            ) : (
              <div className="flex-1 min-h-0 text-sm bg-gray-50 p-4 rounded border overflow-y-auto">
                Select a component to see the prompt
              </div>
            )}
          </div>
        </div>

        {/* Footer - fixed height */}
        <div className="p-4 border-t shrink-0 bg-white flex justify-between">
          <Button
            variant="secondary"
            size="sm"
            onClick={() =>
              void navigator.clipboard.writeText(
                `System Prompt:\n${editedSystemPrompt}\n\nUser Prompt:\n${editedInitialPrompt}`,
              )
            }
          >
            Copy All
          </Button>
          <Button variant="primary" size="sm" onClick={onClose}>
            Apply Changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};
