import React from "react";

import { Checkbox } from "@replo/design-system/components/shadcn/core/checkbox";
import twMerge from "@replo/design-system/utils/twMerge";

type MenuItemProps = {
  children: React.ReactNode;
  size?: "sm" | "base";
  disabled?: boolean;
  layoutClassName?: string;
  UNSAFE_className?: string;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  selected?: boolean;
  shortcut?: string;
  variant?: "default" | "checkbox";
  onCheckedChange?: (checked: boolean) => void;
  onHover?: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      children,
      size = "base",
      disabled = false,
      layoutClassName,
      UNSAFE_className,
      startEnhancer,
      endEnhancer,
      selected,
      shortcut,
      variant = "default",
      onCheckedChange,
      onHover,
      ...props
    },
    ref,
  ) => {
    const content = (
      <button
        ref={ref}
        role="menuitem"
        className={twMerge(
          "w-full cursor-pointer flex gap-1.5 items-center justify-between relative shrink rounded hover:bg-menu-item-hover disabled:opacity-50 disabled:hover:bg-transparent disabled:cursor-not-allowed",
          size === "sm" && "px-2 h-6 typ-body-small",
          size === "base" && "px-2 h-7 typ-body-base",
          selected && "bg-blue-100 hover:bg-menu-item-selected-hover",
          layoutClassName,
          UNSAFE_className,
        )}
        disabled={disabled}
        onMouseEnter={onHover}
        {...props}
      >
        <div className="flex w-full items-center justify-items-stretch gap-2 truncate">
          {startEnhancer}
          <div className="flex grow truncate">{children}</div>
        </div>
        <div className="flex items-center gap-2 shrink-0">
          {shortcut && <span className="text-placeholder">{shortcut}</span>}
          {endEnhancer}
          {variant === "checkbox" && (
            <Checkbox checked={selected} onCheckedChange={onCheckedChange} />
          )}
        </div>
      </button>
    );

    return content;
  },
);

MenuItem.displayName = "MenuItem";

export { MenuItem };
