import type { Slug } from "schemas/generated/utils";

import * as React from "react";

import { BackButton } from "@editor/components/common/BackButton";
import InputComponent from "@editor/components/common/designSystem/Input";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button";
import {
  Card,
  CardContent,
} from "@replo/design-system/components/shadcn/core/card";
import { useForm } from "react-hook-form";
import { BsCheck } from "react-icons/bs";
import { BillingTiers } from "schemas/billing";
import { slugSchema } from "schemas/utils";
import { z } from "zod";

import { ExperimentsLayout } from "./Layout";

const EmptyCustomDomainsState: React.FC<{
  openAddCustomDomainModal: () => void;
}> = ({ openAddCustomDomainModal }) => (
  <div className="flex flex-col gap-4">
    <div className="flex flex-col items-start gap-1 max-w-[500px]">
      <div className="text-sm font-semibold text-default">Custom Domains</div>
      <span className="text-sm font-normal text-slate-500">
        To use a custom domain instead of reploedge, just set it up with your
        domain provider.
      </span>
    </div>
    <div className="flex flex-row justify-end gap-2">
      <Button variant="primary" size="sm" onClick={openAddCustomDomainModal}>
        Add Custom Domain
      </Button>
    </div>
  </div>
);

const CustomDomainsList: React.FC<{
  domains: string[];
  openAddCustomDomainModal: () => void;
}> = ({ domains, openAddCustomDomainModal }) => (
  <div className="flex flex-col gap-4 min-w-[500px] max-w-[500px]">
    <div className="flex justify-between items-center">
      <div className="text-sm font-semibold text-default">Custom Domains</div>
      <Button
        variant="tertiary"
        size="base"
        onClick={openAddCustomDomainModal}
        className="text-xs font-medium text-blue-600"
      >
        Add Custom Domain
      </Button>
    </div>
    <div className="flex flex-col gap-2">
      {domains.map((domain, index) => (
        <div
          key={index}
          className="flex items-center justify-between p-3 border border-slate-200 rounded"
        >
          <span className="text-sm text-gray-700">{domain}</span>
          <BsCheck size={16} fill="#94A3B8" />
        </div>
      ))}
    </div>
  </div>
);

const GroupNamesList: React.FC<{
  groupNames: string[];
  workspaceId: string;
}> = ({ groupNames, workspaceId }) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm<{ slug: Slug }>({
    resolver: zodResolver(z.object({ slug: slugSchema })),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { mutateAsync: addShortName, isPending: isAddingShortName } =
    trpc.workspace.createShortName.useMutation({
      onSuccess: (response) => {
        successToast(
          "Group Name Added",
          `${response.value} has been added successfully.`,
        );
        void trpcUtils.workspace.getShortNames.invalidate();
      },
      onError: () => {
        setIsAdding(false);
        reset();
      },
    });

  const [isAdding, setIsAdding] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;
  const newGroupName = watch("slug");

  const handleSave = handleSubmit(async ({ slug }) => {
    await addShortName({
      workspaceId: workspaceId ?? "",
      slug,
    });
    logEvent("experiment.groupName.add", {
      billingPlanTier: subscriptionTier,
    });
    reset();
    setIsAdding(false);
  });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape" && isAdding) {
      setIsAdding(false);
      reset();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("slug", e.target.value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <div className="flex flex-col gap-4 min-w-[500px] max-w-[500px]">
      <div className="flex justify-between items-center">
        <div className="text-sm font-semibold text-default flex items-center h-8">
          Group Names
        </div>
        {!isAdding && groupNames.length !== 0 && (
          <Button
            variant="tertiary"
            size="base"
            onClick={() => setIsAdding(true)}
            className="text-xs font-medium text-blue-600"
          >
            Add Group Name
          </Button>
        )}
      </div>
      {groupNames.length === 0 && (
        <>
          <span className="text-sm font-normal text-slate-500">
            If you&apos;re using the reploedge.com domain, your Group Name will
            be added to links as: reploedge.com/GroupName.
          </span>
          <div className="flex flex-col items-end gap-1">
            <Button
              variant="primary"
              size="sm"
              onClick={() => setIsAdding(true)}
            >
              Add Group Name
            </Button>
          </div>
        </>
      )}
      {isAdding && (
        <div className="flex flex-col gap-2">
          <InputComponent
            ref={inputRef}
            size="base"
            type="text"
            placeholder="Enter new group name"
            maxLength={256}
            onChange={handleInputChange}
            value={newGroupName}
            onKeyDown={handleKeyDown}
            validityState={errors.slug ? "invalid" : "valid"}
            unsafe_className="h-[46px]"
          />
          {!isAddingShortName && (
            <span className="text-xs font-normal text-red-600">
              {errors?.slug?.message}
            </span>
          )}
          <div className="flex flex-row justify-end">
            <Button
              variant="primary"
              size="sm"
              onClick={() => void handleSave()}
              disabled={!isValid}
            >
              Save Group Name
            </Button>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2">
        {groupNames.map((name) => (
          <div
            key={name}
            className="flex items-center justify-between p-3 border border-slate-200 rounded"
          >
            <span className="text-sm text-gray-700">{name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ExperimentSettingsTab = () => {
  const workspaceId = useCurrentWorkspaceId();

  const { data: customDomains } = trpc.workspace.getCustomDomains.useQuery(
    workspaceId ?? "",
  );
  const { data: shortNames } = trpc.workspace.getShortNames.useQuery(
    workspaceId ?? "",
  );

  const modal = useModal();
  const openAddCustomDomainModal = () =>
    modal.openModal({
      type: "customDomainModal",
    });

  return (
    <ExperimentsLayout
      headerTitle="A/B Test Settings"
      backButton={<BackButton />}
    >
      <div className="flex flex-col items-start gap-4">
        <Card className="inline-block w-auto">
          <CardContent className="gap-2.5">
            {customDomains && customDomains.length > 0 ? (
              <CustomDomainsList
                domains={customDomains.map((domain) => domain.value)}
                openAddCustomDomainModal={openAddCustomDomainModal}
              />
            ) : (
              <EmptyCustomDomainsState
                openAddCustomDomainModal={openAddCustomDomainModal}
              />
            )}
          </CardContent>
        </Card>
        <Card className="inline-block w-auto">
          <CardContent className="gap-2.5">
            <GroupNamesList
              groupNames={shortNames?.map((shortName) => shortName.value) ?? []}
              workspaceId={workspaceId ?? ""}
            />
          </CardContent>
        </Card>
      </div>
    </ExperimentsLayout>
  );
};
