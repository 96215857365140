export const featureFlags = [
  "ai-chat",
  "carousel-v4",
  "announcement_figma_to_replo",
  "carousel-debug",
  "turbo",
  "no-theme-in-editor",
  "prevent-theme-header-footer",
  "component-template-screenshot-v2",
  "integration-hub",
  "analytics-dashboard",
  "experiments-refresh",
  "force-no-mirror",
  "ai-claude",
  "enable-posthog-session-recording",
  "no-mirror",
  "ai-menu-styles",
  "data-em-disable-on-images",
  "multi-selection",
  "archived-pages",
  "2024-12-21-partner-offers-milestone-1",
  "assets-refresh",
  "analytics-v2-data-model",
  "apply-ai-styles-to-templates",
  "new-project-page-ui",
  "refactor-element-settings",
] as const;

export type FeatureName =
  | (typeof featureFlags)[number]
  | `announcement_${string}`;
