// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import * as React from "react";

import {
  Anchor,
  Close,
  Content,
  Portal,
  Root,
  Trigger,
} from "@radix-ui/react-popover";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsX } from "react-icons/bs";
import { animated, config, useTransition } from "react-spring";
import { useControllableState } from "replo-utils/react/use-controllable-state";

export type PopoverProps = {
  children: React.ReactNode;
  isDefaultOpen?: boolean;
  isOpen?: boolean;
  onOpenChange?(isOpen: boolean): void;
  shouldIgnoreOutsideInteractions?: boolean;
  /**
   * Sebas, (2023-07-14):
   * If true, the popover will close automatically, this is useful when you want to
   * close the popover when the user clicks on a button inside the popover or selects
   * an item from a list inside the popover.
   */
  shouldClose?: boolean | null;
};

export type PopoverContentProps = {
  /**
   * Children of the popover content (the stuff that displays inside the popover)
   */
  children: React.ReactNode;

  // Note (Noah, 2022-04-15): This prop is specifically NOT optional because we've
  // had a ton of problems with popovers being dismissed when a modal is triggered
  // from inside them, and we want to force any caller of this popover to really
  // make sure that they want to tell the popover that it should ignore being dismissed
  // on interact outside ALWAYS.
  // Note (Ben, 2023-11-14): ^^^^^^ FOR REAL.
  /**
   * If true, the popover will not dismiss when the user interacts outside of it,
   * even though that's the default behavior. A common flow is to pass whether modals
   * are open as the value of this prop, so that the popover doesn't dismiss as long
   * as any modal is open.
   */
  shouldPreventDefaultOnInteractOutside: boolean;

  /**
   * Title of the popover (displayed in upper left)
   */
  title?: React.ReactNode;

  /**
   * Default is to have a close icon which dismisses the popover. If this prop is
   * true, that close button does not appear.
   */
  hideCloseButton?: boolean;

  /**
   * Which side the popover should be positioned on
   */
  side?: "top" | "bottom" | "left" | "right";

  /**
   * Offset in pixels for how far away from the side the popover should be positioned
   */
  sideOffset?: number;

  /**
   * Called when the user interacts outside the modal (note: this is called even if
   * shouldPreventDefaultOnInteractOutside is true). By default, interacting outside
   * the modal will close it and call this callback.
   */
  onInteractOutside?: (event: CustomEvent) => void;

  className?: string;
  style?: React.CSSProperties;
  /**
   * This is helpful for when you want to modify the animation of the popover
   */
  fromYTransform?: number;
  titleClassnames?: string;
  align?: "center" | "end" | "start" | undefined;
  alignOffset?: number;
  stayInPosition?: boolean;
  /**
   * When set, the trigger will not be focused when the popover closes
   */
  disableTriggerFocusOnClose?: boolean;
  onRequestClose?: () => void;
  avoidCollisions?: boolean;
};

type ContextProps = {
  isOpen: PopoverProps["isOpen"];
  onOpenChange: PopoverProps["onOpenChange"];
};

const PopoverContext = React.createContext<ContextProps | null>(null);
PopoverContext.displayName = "PopoverContext";
function useRequiredPopoverContext(comp: string) {
  const context = React.useContext(PopoverContext);
  if (!context) {
    throw new Error(`${comp} must be used within a Popover`);
  }
  return context;
}

// NOTE (Chance 2023-11-10): This hook is for external components to read to
// know whether or not they are rendered inside of a popover
export function usePopoverContext() {
  return React.useContext(PopoverContext);
}

const PopoverComponent = React.forwardRef(
  (
    {
      isDefaultOpen = false,
      isOpen: isControlledOpen,
      onOpenChange: onControllableOpenChange,
      children,
      shouldIgnoreOutsideInteractions = false,
      shouldClose,
    }: PopoverProps,
    ref,
  ) => {
    const [isOpen, setIsOpen] = useControllableState(
      isControlledOpen,
      isDefaultOpen,
      onControllableOpenChange,
    );

    // Expose a `close` method via the ref
    React.useImperativeHandle(ref, () => ({
      close: () => setIsOpen(false),
    }));

    // Note (Sebas, 2022-09-25): If the popover is inside a ModifierGroup we need
    // to close the popover. This prevents the popover from jumping when for
    // example we undo something and the ModifierGroup automatically closes.
    //
    // NOTE (Chance 2023-11-10): This was previously tracked in redux but moved to
    // utilize context so we can read the modifier group's state directly from its
    // local state.
    // NOTE (patrick 2024-12-04): Removed the useModifierGroupContext as we move this Popover to the design system

    React.useEffect(() => {
      if (shouldClose) {
        setIsOpen(false);
      }
    }, [setIsOpen, shouldClose]);

    return (
      <PopoverContext.Provider value={{ isOpen, onOpenChange: setIsOpen }}>
        <Root
          open={isOpen}
          onOpenChange={setIsOpen}
          modal={shouldIgnoreOutsideInteractions}
        >
          {children}
        </Root>
      </PopoverContext.Provider>
    );
  },
);

function PopoverContent({
  children,
  title,
  hideCloseButton = false,
  side = "left",
  sideOffset = 10,
  className,
  style,
  onInteractOutside,
  shouldPreventDefaultOnInteractOutside = false,
  fromYTransform,
  titleClassnames,
  align,
  alignOffset,
  stayInPosition,
  disableTriggerFocusOnClose,
  onRequestClose,
  avoidCollisions,
  ...props
}: PopoverContentProps) {
  const { isOpen } = useRequiredPopoverContext("Popover.Content");
  const popoverTransitions = {
    from: {
      opacity: 0,
      translateY: fromYTransform ?? 50,
    },
    enter: {
      opacity: 1,
      translateY: 1,
    },
    config: config.stiff,
  };

  const transitions = useTransition(isOpen, popoverTransitions);

  return transitions(
    (styles, item) =>
      item && (
        <Portal>
          <Content
            side={side}
            forceMount
            sideOffset={sideOffset}
            align={align}
            alignOffset={alignOffset}
            asChild
            onInteractOutside={(e) => {
              if (shouldPreventDefaultOnInteractOutside) {
                e.preventDefault();
              }
              onInteractOutside?.(e);
            }}
            updatePositionStrategy={stayInPosition ? "always" : undefined}
            onCloseAutoFocus={
              disableTriggerFocusOnClose ? (e) => e.preventDefault() : undefined
            }
            avoidCollisions={avoidCollisions}
          >
            <animated.div
              style={{
                ...styles,
                transformOrigin:
                  "var(--radix-popover-content-transform-origin)",
              }}
            >
              <div
                className={twMerge(
                  "rounded bg-white py-3 px-3 font-medium shadow",
                  !className && !style?.width && "w-[250px]",
                  className,
                )}
                style={style}
                {...props}
              >
                {(title || !hideCloseButton) && (
                  <div className="mb-2 flex items-start justify-center">
                    <span
                      className={twMerge(
                        "flex-1 text-xs text-default",
                        titleClassnames,
                      )}
                    >
                      {/* Note (Noah, 2023-12-30, REPL-9861): if there's no
                       title but we want to show the close button, we still want
                       to render this to ensure it's still pushed over to the
                       right side */}
                      {title}
                    </span>
                    {!hideCloseButton && (
                      <Close
                        aria-label="Close popover"
                        className="flex h-4 w-4 items-center justify-center justify-self-end rounded-full"
                        onClick={
                          onRequestClose ? () => onRequestClose() : undefined
                        }
                      >
                        <BsX size="16px" className="text-slate-400" />
                      </Close>
                    )}
                  </div>
                )}
                {children}
              </div>
            </animated.div>
          </Content>
        </Portal>
      ),
  );
}

PopoverComponent.displayName = "PopoverComponent";

// Note (Patrick 2025-01-14): Extend the PopoverComponent type to include static properties because of forwardRef
const Popover = PopoverComponent as typeof PopoverComponent & {
  Content: typeof PopoverContent;
  Trigger: typeof Trigger;
  Anchor: typeof Anchor;
};

Popover.Content = PopoverContent;
Popover.Trigger = Trigger;
Popover.Anchor = Anchor;

export default Popover;
