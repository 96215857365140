import type { PlanInfo } from "schemas/billing";
import type { BillingPlanChangeSurveyModalProps } from "../AppModalTypes";

import * as React from "react";

import SelectableButtons from "@common/designSystem/SelectableButtons";
import { ModalLayout } from "@common/ModalLayout";
import Modal from "@editor/components/common/designSystem/Modal";
import useInitializeSubscriptionUpdateMutation from "@editor/hooks/trpc/useInitializeSubscriptionUpdateMutation";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useModal } from "@editor/hooks/useModal";
import { analytics } from "@editor/infra/analytics";
import { useGetProjectsByWorkspaceIdQuery } from "@editor/reducers/api-reducer";
import { useGetModalDataQuery } from "@editor/reducers/sanity-reducer";
import { trpc } from "@editor/utils/trpc";

import { skipToken as rtkSkipToken } from "@reduxjs/toolkit/dist/query";
import Button from "@replo/design-system/components/button";
import { Textarea } from "@replo/design-system/components/textarea";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { BsDashCircleFill, BsXCircleFill } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import { filterNulls } from "replo-utils/lib/array";
import {
  BILLING_PLAN_CONFIRMATION_PARAM,
  BILLING_TIER_INFO_MAP,
} from "schemas/billing";

import { Skeleton } from "../common/designSystem/SkeletonLoader";

type ModalData = ReturnType<typeof useGetModalDataQuery>["data"];

type ReasonsStepProps = {
  user: { firstName: string } | null;
  isLoadingModalData: boolean;
  modalData: ModalData;
  selectedButtons: string[];
  setSelectedButtons: (buttons: string[]) => void;
  hasEverTriedToSubmit: boolean;
  planInfo: PlanInfo | undefined;
  workspaceName: string | undefined;
  workspaceProjectLength: number;
  pagesCount: number;
};

type FeedbackStepProps = {
  selectedButtonData: ModalData extends readonly any[]
    ? ModalData[number]
    : null;
  textFeedback: string;
  setTextFeedback: (value: string) => void;
};

type WorkspaceInfoProps = {
  planInfo: PlanInfo | undefined;
  workspaceName: string | undefined;
  projectsCount: number;
  pagesCount: number;
};

const ReasonsStep: React.FC<ReasonsStepProps> = ({
  user,
  isLoadingModalData,
  modalData,
  selectedButtons,
  setSelectedButtons,
  hasEverTriedToSubmit,
  planInfo,
  workspaceName,
  workspaceProjectLength,
  pagesCount,
}) => (
  <>
    <p className="my-2 gap-2 self-center typ-header-h2">
      {user?.firstName}, before You Go...
    </p>
    <p className="typ-body-base">
      Could you please share your top reason why Replo isn&apos;t a good fit?
    </p>
    {hasEverTriedToSubmit && !selectedButtons.length && (
      <p className="text-sm text-danger mt-2 mb-1">
        * Please select at least one reason
      </p>
    )}
    {isLoadingModalData ? (
      <Skeleton className="h-28 my-2" />
    ) : (
      <SelectableButtons
        multiSelect={false}
        options={[...(modalData ?? [])]
          .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          .map((item) => ({
            label: item.buttonText,
            value: item.buttonText,
          }))}
        value={selectedButtons}
        onChange={setSelectedButtons}
        selectedClassName="p-2 bg-transparent hover:bg-selected border border-primary text-primary"
        unselectedClassName="bg-transparent p-2 border border-subtle hover:bg-selected text-muted"
        className="my-4"
      />
    )}
    <WorkspaceInfo
      planInfo={planInfo}
      workspaceName={workspaceName}
      projectsCount={workspaceProjectLength}
      pagesCount={pagesCount}
    />
  </>
);

const FeedbackStep: React.FC<FeedbackStepProps> = ({
  selectedButtonData,
  textFeedback,
  setTextFeedback,
}) => (
  <div className="flex flex-col gap-1 w-full">
    <div>
      <p className="my-2 gap-2 text-xl font-semibold">
        We&apos;re sorry to see you go! 😢
      </p>
      <p className="text-sm text-muted mb-4">
        We&apos;ve gone ahead and refunded the remaining month&apos;s
        subscription as a token of appreciation for giving Replo a try.
      </p>
    </div>
    <p className="text-sm font-semibold mb-2">
      {selectedButtonData?.customQuestion ??
        "Anything you want to share with the team?"}
    </p>
    <Textarea
      debounce
      size="base"
      layoutClassName="h-24 w-full mb-3"
      value={textFeedback}
      onChange={setTextFeedback}
      placeholder="Anything you share will be helpful for our small team."
      autoFocus
    />
  </div>
);

const WorkspaceInfo: React.FC<WorkspaceInfoProps> = ({
  planInfo,
  workspaceName,
  projectsCount,
  pagesCount,
}) => (
  <div className="bg-danger-soft p-2 mb-4 py-1 rounded-lg">
    <p className="text-sm text-muted">
      You&apos;re cancelling your{" "}
      <span className="font-semibold text-default">
        Replo {planInfo?.displayName}
      </span>{" "}
      plan for{" "}
      <span className="font-semibold text-default">{workspaceName}</span>, which
      has{" "}
      <span className="font-semibold text-default">
        {projectsCount} projects
      </span>{" "}
      and{" "}
      <span className="font-semibold text-default">
        {pagesCount} published pages
      </span>
      .
    </p>
  </div>
);

export const BillingPlanChangeSurveyModal: React.FC<
  BillingPlanChangeSurveyModalProps
> = ({ tier, downgradeTo, source, workspaceId }) => {
  const modal = useModal();
  const { data: modalData, isLoading: isLoadingModalData } =
    useGetModalDataQuery({});
  const [selectedButtons, setSelectedButtons] = React.useState<string[]>([]);
  const { user } = useCurrentUser();

  const [textFeedback, setTextFeedback] = React.useState("");

  const logAnalyticsData = () => {
    analytics.logEvent("billing.downgrade.survey", {
      previousPlan: tier,
      currentPlan: downgradeTo,
      reasons: filterNulls(selectedButtons),
      feedback: textFeedback,
    });
  };

  const hasFilledOutSurvey = selectedButtons.length > 0;
  const [hasEverTriedToSubmit, setHasEverTriedToSubmit] = React.useState(false);

  const [, setSearchParams] = useSearchParams();

  const { initializeSubscriptionUpdate } =
    useInitializeSubscriptionUpdateMutation();

  // Note (Evan, 2024-04-24): Handle loading state ourselves since we still want to show a
  // loading state if the mutation has succeeded and we are waiting for a redirect.
  const [isLoading, setIsLoading] = React.useState(false);

  const [currentStep, setCurrentStep] = React.useState<"reasons" | "feedback">(
    "reasons",
  );

  const { data: workspaceData } = trpc.workspace.getById.useQuery(
    workspaceId ? { id: workspaceId } : skipToken,
  );

  const { data: responseData } =
    trpc.workspace.getPublishedElementsCountByWorkspaceId.useQuery(
      workspaceId ?? skipToken,
    );

  const { page: pagesCount } = {
    page: responseData?.page ?? 0,
  };

  const { data: workspaceProjectData } = useGetProjectsByWorkspaceIdQuery(
    workspaceId ?? rtkSkipToken,
  );
  const workspaceProjectLength = workspaceProjectData?.projects?.length ?? 0;
  const workspaceName = workspaceData?.workspace.name;

  const planInfo = BILLING_TIER_INFO_MAP[tier];

  const selectedButtonData = React.useMemo(() => {
    if (!modalData || selectedButtons.length === 0) {
      return null;
    }
    return modalData.find((item) => item.buttonText === selectedButtons[0]);
  }, [modalData, selectedButtons]);

  const handleSubmit = async () => {
    logAnalyticsData();
    setIsLoading(true);
    try {
      const result = await initializeSubscriptionUpdate({
        workspaceId,
        tier: downgradeTo,
        source,
      });

      if (!result.success) {
        setIsLoading(false);
      }
      // Note (Evan, 2024-04-24): The case where confirmationRequired is true
      // is handled in the RTK API slice
      else if (!result.confirmationRequired) {
        modal.closeModal({ type: "billingPlanChangeSurvey" });
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const getButtonText = () => {
    if (currentStep === "feedback") {
      return "Finish";
    }
    return "Cancel Plan";
  };

  return (
    <Modal
      isOpen
      onRequestClose={() => {
        if (!isLoading) {
          setSearchParams(
            (params) => {
              if (!params.has(BILLING_PLAN_CONFIRMATION_PARAM)) {
                return params;
              }
              params.delete(BILLING_PLAN_CONFIRMATION_PARAM);
              return new URLSearchParams(params);
            },
            { replace: true },
          );
          modal.closeModal({ type: "billingPlanChangeSurvey" });
        }
      }}
      className="h-auto w-auto overflow-scroll no-scrollbar"
      style={{ maxHeight: "100vh" }}
      includesCloseIcon
      data-testid="billing-plan-change-survey"
    >
      <ModalLayout
        width={576}
        height="auto"
        mainContent={() => {
          return (
            <div className="flex h-full flex-col justify-between gap-2 w-full">
              <div className="flex flex-row justify-between gap-8 w-full">
                <div className="text-lg font-light text-default w-full">
                  {currentStep === "reasons" ? (
                    <ReasonsStep
                      user={{ firstName: user?.firstName ?? "" }}
                      isLoadingModalData={isLoadingModalData}
                      modalData={modalData}
                      selectedButtons={selectedButtons}
                      setSelectedButtons={setSelectedButtons}
                      hasEverTriedToSubmit={hasEverTriedToSubmit}
                      planInfo={planInfo}
                      workspaceName={workspaceName}
                      workspaceProjectLength={workspaceProjectLength}
                      pagesCount={pagesCount}
                    />
                  ) : (
                    <FeedbackStep
                      selectedButtonData={selectedButtonData}
                      textFeedback={textFeedback}
                      setTextFeedback={setTextFeedback}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-end gap-4">
                {currentStep === "reasons" && (
                  <Button
                    variant="secondary"
                    size="lg"
                    onClick={() => {
                      if (!isLoading) {
                        modal.closeModal({ type: "billingPlanChangeSurvey" });
                        modal.openModal({
                          type: "billingModal",
                          props: { source },
                        });
                      }
                    }}
                  >
                    Nevermind
                  </Button>
                )}
                <Button
                  variant={currentStep === "reasons" ? "danger" : "primary"}
                  size="lg"
                  onClick={() => {
                    if (isLoading) {
                      return;
                    }
                    if (currentStep === "reasons") {
                      setHasEverTriedToSubmit(true);
                      if (hasFilledOutSurvey) {
                        setCurrentStep("feedback");
                      }
                    } else {
                      void handleSubmit();
                    }
                  }}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {getButtonText()}
                </Button>
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
};

// TODO (Evan, 2024-03-28): move this to /shared
export const DowngradeFeatureComparison: React.FC<{
  currentPlan: PlanInfo | undefined;
  lowerTier: PlanInfo | undefined;
}> = ({ currentPlan, lowerTier }) => {
  // Note (Juan, 2023-03-28): This function removes the Everything
  // in the lowerPlan feature, this feature should not be rendered
  // on the plan change modal"
  function removeLowerTierFeatures(featureStrings: string[]) {
    if (featureStrings) {
      return featureStrings.filter(
        (str) => filterNulls([str]) && !str.includes("Everything"),
      );
    }
  }
  return (
    <>
      <p className="text-base font-extralight text-slate-400">
        You&apos;ll Lose Access To
      </p>
      <div className="pl-3 pt-2">
        <h1 className="flex flex-row text-xl font-medium">
          <div
            className={classNames(
              twMerge(
                "bg-gradient-to-r bg-clip-text text-transparent",
                currentPlan?.titleColor,
              ),
            )}
          >
            Replo&nbsp;
          </div>
          {currentPlan?.displayName}
        </h1>
        {currentPlan?.features &&
          removeLowerTierFeatures(currentPlan.features)?.map((feature) => {
            return (
              <div
                key={feature}
                className="flex items-center gap-2 py-2 text-sm"
              >
                <div className="w-6">
                  <BsXCircleFill size={20} className="text-red-600" />
                </div>
                <p className="w-full text-sm font-light">{feature}</p>
              </div>
            );
          })}
        <p className="py-2 text-sm font-extralight text-slate-400">
          You&apos;ll Keep Access To
        </p>
        {currentPlan?.tier === "basic" ? (
          <div className="flex items-center gap-2 py-2 text-sm">
            <div className="w-6">
              <BsDashCircleFill size={20} className="text-gray-200" />
            </div>
            <p className="w-full text-sm font-light">
              Your Existing Pages Stay Live
            </p>
          </div>
        ) : (
          lowerTier?.features &&
          removeLowerTierFeatures(lowerTier?.features)?.map((feature, i) => {
            return (
              <div
                key={i.toString()}
                className="flex items-center gap-2 py-2 text-sm"
              >
                <div className="w-6">
                  <BsDashCircleFill size={20} className="text-gray-200" />
                </div>
                <p className="w-full text-sm font-light">{feature}</p>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
