import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip";

type ProjectTitleProps = {
  title: string;
  uniqueId: string;
  onSelectProject: (e: React.MouseEvent) => void;
};

const ProjectTitle: React.FC<ProjectTitleProps> = ({
  title,
  uniqueId,
  onSelectProject,
}) => {
  const [isOverflow, setIsOverflow] = React.useState(false);

  const titleElement = (
    <h3
      ref={(node) => {
        if (node) {
          setIsOverflow(node.scrollWidth > node.clientWidth);
        }
      }}
      className="text-base font-medium text-slate-800 truncate cursor-pointer"
      aria-describedby={uniqueId}
    >
      {title}
    </h3>
  );

  const wrappedTitle = (
    <div onClick={onSelectProject}>
      {isOverflow ? (
        <Tooltip content={title} triggerAsChild>
          {titleElement}
        </Tooltip>
      ) : (
        titleElement
      )}
    </div>
  );

  return wrappedTitle;
};

export default ProjectTitle;
