import type { Context } from "replo-runtime/store/AlchemyVariable";

import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { LIQUID_CHUNK_BEGIN } from "replo-runtime/store/components/ReploLiquid/ReploLiquidChunk";

// NOTE (Gabe 2023-07-20): We'll add width to these filters based on srcset
const SHOPIFY_IMAGE_FILTER = "image_url";

// TODO (Gabe 2023-07-06): more dynamic data strings will be added here as we
// add support for more attributes. (e.g. displayPriceFormatted & more)
const dynamicDataToLiquidMap: Record<string, string> = {
  "attributes._product.title": "product.title",
  "attributes._product.featured_image": `product.featured_image | ${SHOPIFY_IMAGE_FILTER}`,
  "attributes._product.description": "product.description",
  "attributes._product.handle": "product.handle",

  // Selected Variant mappings
  "attributes._variant.title": "reploSelectedVariant.title",
  "attributes._variant.featuredImage": `reploSelectedVariant.featured_image | ${SHOPIFY_IMAGE_FILTER}`,
  "attributes._variant.sku": "reploSelectedVariant.sku",

  // Price Mappings
  // NOTE (Gabe 2024-07-23): You must use a float operand on the divided_by
  // filter to get a float return value.
  "attributes._variant.price": "reploSelectedVariant.price | divided_by: 100.0",
  "attributes._variant.priceRounded":
    "reploSelectedVariant.price | divided_by: 100.0 | round",
  "attributes._variant.priceWithoutSellingPlanDiscount":
    "reploSelectedVariant.price | divided_by: 100.0",
  "attributes._variant.priceWithoutSellingPlanDiscountRounded":
    "reploSelectedVariant.price | divided_by: 100.0 | round",
  "attributes._variant.displayPriceWithoutSellingPlanDiscount":
    "reploSelectedVariant.price | money",
  "attributes._variant.displayPrice": "reploSelectedVariant.price | money",
  "attributes._variant.displayPriceWithoutQuantity":
    "reploSelectedVariant.price | money",
  "attributes._variant.displayPriceRounded":
    "reploSelectedVariant.price | divided_by: 100.0 | round | times: 100 | money_without_trailing_zeros",
  "attributes._variant.compareAtPrice":
    "reploSelectedVariant.compare_at_price | money_without_currency",
  "attributes._variant.compareAtPriceRounded":
    "reploSelectedVariant.compare_at_price | money_without_currency | round",
  "attributes._variant.compareAtDisplayPrice":
    "reploSelectedVariant.compare_at_price | money",
  "attributes._variant.compareAtDisplayPriceRounded":
    "reploSelectedVariant.compare_at_price | money_without_currency | round | times: 100 | money_without_trailing_zeros",
  "attributes._variant.compareAtPriceDifference":
    "reploSelectedVariant.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | money_without_currency",
  "attributes._variant.compareAtPriceDifferencePercentage":
    "reploCompareAtPriceDifferencePercentage | append: '%'",
  "attributes._variant.compareAtPriceDifferenceRounded":
    "reploSelectedVariant.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | money_without_currency | round ",
  "attributes._variant.compareAtDisplayPriceDifference":
    "reploSelectedVariant.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | money",
  "attributes._variant.compareAtDisplayPriceDifferenceRounded":
    "reploSelectedVariant.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | money_without_currency | round | times: 100 | money_without_trailing_zeros",

  // NOTE (Gabe 2023-07-20): The value, reploRepeatedVariant is set in
  // VariantSelect.
  "attributes._currentVariant.title": "reploRepeatedVariant.title",
  "attributes._currentVariant.featuredImage": `reploRepeatedVariant.featured_image | ${SHOPIFY_IMAGE_FILTER}`,

  // NOTE (Gabe 2023-07-20): The value, reploRepeatedOptionValue is set in
  // OptionSelect.
  "attributes._currentOptionValue": "reploRepeatedOptionValue",
  // NOTE (Gabe 2023-07-20): The value, reploRepeatedOptionValue is set in
  // OptionSelect.
  "attributes._currentOption.title": "reploRepeatedOptionValue",
  // NOTE (Gabe 2023-07-20): The value, reploRepeatedSellingPlanName is set in
  // SellingPlanSelect.
  "attributes._currentSellingPlan.name": "reploRepeatedSellingPlanName",

  // Selection List Component
  "attributes._product.images": "product.images",
  "attributes._product.variants": "product.variants",
  "attributes._product.options": "product.options_with_values",
  "attributes._product.options_with_valuesValues":
    "product.options_with_values",
  "attributes._product.sellingPlanGroups": "product.selling_plan_groups",
  "attributes._variants": "product.variants",
  "attributes._options": "product.options_with_values",
  "attributes._optionsValues": "product.options_with_values",
  "attributes._selectedSellingPlan.options": "reploSelectedSellingPlan.options",
  "attributes._selectedSellingPlan.priceAdjustments":
    "reploSelectedSellingPlan.price_adjustments",
  "attributes._sellingPlans": "reploSortedSellingPlans",

  "attributes._currentItem.title":
    "currentItem.title | default: currentItem.name",
  "attributes._currentItem.price": "currentItem.price | divided_by: 100.0",
  "attributes._currentItem.priceRounded":
    "currentItem.price | divided_by: 100.0 | round",
  "attributes._currentItem.priceWithoutSellingPlanDiscount":
    "currentItem.price | divided_by: 100.0",
  "attributes._currentItem.priceWithoutSellingPlanDiscountRounded":
    "currentItem.price | divided_by: 100.0 | round",
  "attributes._currentItem.displayPriceWithoutSellingPlanDiscount":
    "currentItem.price | money",
  "attributes._currentItem.displayPrice": "currentItem.price | money",
  "attributes._currentItem.displayPriceWithoutQuantity":
    "currentItem.price | money",
  "attributes._currentItem.displayPriceRounded":
    "currentItem.price | divided_by: 100.0 | round | times: 100 | money_without_trailing_zeros",
  "attributes._currentItem.compareAtPrice":
    "currentItem.compare_at_price | money_without_currency",
  "attributes._currentItem.compareAtPriceRounded":
    "currentItem.compare_at_price | money_without_currency | round",
  "attributes._currentItem.compareAtDisplayPrice":
    "currentItem.compare_at_price | money",
  "attributes._currentItem.compareAtDisplayPriceRounded":
    "currentItem.compare_at_price | money_without_currency | round | times: 100 | money_without_trailing_zeros",
  "attributes._currentItem.compareAtPriceDifference":
    "currentItem.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | money_without_currency",
  "attributes._currentItem.compareAtPriceDifferencePercentage":
    "reploCompareAtPriceDifferencePercentage | append: '%'",
  "attributes._currentItem.compareAtPriceDifferenceRounded":
    "currentItem.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | money_without_currency | round ",
  "attributes._currentItem.compareAtDisplayPriceDifference":
    "currentItem.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | money",
  "attributes._currentItem.compareAtDisplayPriceDifferenceRounded":
    "currentItem.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | money_without_currency | round | times: 100 | money_without_trailing_zeros",

  "attributes._currentItem.featuredImage": `currentItem.featured_image | ${SHOPIFY_IMAGE_FILTER}`,
};

const INDEXED_IMAGE_REGEX = /attributes\._product\.images\.(?<index>\d+)/;

/**
 * Returns the Liquid representation of a dynamic data string if it exists.
 */
export const getLiquidString = (
  dynamicDataString: string,
  context: Context,
) => {
  // NOTE (Gabe 2023-07-06): We use a regex to find the dynamic data string
  // between the brackets. We then use that string to look up the liquid
  // representation of that dynamic data string in the dynamicDataToLiquidMap.
  const betweenTheBrackets = dynamicDataString.match(/{{(.*)}}/)?.[1];

  if (!betweenTheBrackets) {
    return dynamicDataString;
  }

  let liquidRepresentation;

  if (dynamicDataToLiquidMap[betweenTheBrackets]) {
    liquidRepresentation = dynamicDataToLiquidMap[betweenTheBrackets];
  } else if (
    context.useSectionSettings &&
    betweenTheBrackets.includes("sectionSettings")
  ) {
    // NOTE (Matt 2024-03-29): At the top of the section file we have defined
    // each section settingId as a variable, assigned to its corresponding value.
    // This makes it so we can simply set betweenTheBrakcets to the sectionSettingId.
    const sectionSettingId = betweenTheBrackets.replace(
      "store.sectionSettings.",
      "",
    );
    liquidRepresentation = sectionSettingId;
  } else if (betweenTheBrackets === "attributes._currentItem") {
    if (context.isInsideProductImageCarousel) {
      liquidRepresentation = `reploProductImage.src | ${SHOPIFY_IMAGE_FILTER}`;
    } else if (!context.attributes?._currentItem) {
      // NOTE (Matt 2025-02-12): if `attributes._currentItem` exists in the context,
      // then we know we should be access an exact value at publish time
      // rather than a liquid variable.
      liquidRepresentation = `currentItem | ${SHOPIFY_IMAGE_FILTER}`;
    }
  } else if (betweenTheBrackets.startsWith("attributes._currentItem.")) {
    liquidRepresentation = `${betweenTheBrackets.replace("attributes._", "")}`;
  } else if (
    betweenTheBrackets.startsWith("attributes._product.productMetafields.") ||
    betweenTheBrackets.startsWith("attributes._variant.variantMetafields.")
  ) {
    const productOrVariant = betweenTheBrackets.startsWith(
      "attributes._product.productMetafields.",
    )
      ? "product"
      : "reploSelectedVariant";
    const metafieldPath = betweenTheBrackets.split("Metafields.")[1];
    if (metafieldPath) {
      // NOTE (Matt 2024-01-15): We need to change our metafield declarations to
      // bracket notation in order to avoid a strange liquid syntax bug in
      // sections that breaks when a namespace or key begins with a non-letter.
      const [namespace, key] = metafieldPath.split(".");

      const fullMetafieldPath = `${productOrVariant}.metafields`;
      // NOTE (Gabe 2023-08-31): This is some serious liquid foo to enable
      // resolving file reference urls incase they're used for as an image src. We
      // return this one directly because this is control liquid and should not be
      // wrapped in double brackets. We could add additional resolvers here if we
      // want with a switch statement + more captures, but I'll leave it as is for
      // now.
      // NOTE (Matt 2024-02-14): All of these capture statements are necessary because
      // this liquid code will be run through ReactDOMServer.renderToString, which
      // will escape all <, >, ", and ' characters. This makes it very difficult
      // to write liquid code and should be tackled more holistically and safely.
      // Additionally, we use the `liquid` tag here because it's safer to use with
      // our chunkify logic, as the regex parser can more reliably find the opening/closing
      // liquid tags rather than all of the individual if/endifs, etc.
      // NOTE (Max 2024-05-13): For rich text metafields we add the | metafield_tag to
      // prevent a pre-hydration flicker which would have briefly displayed the metafield
      // as a JSON instead of HTML.
      return `{%- capture fileReference -%}file_reference{%- endcapture -%}
      {%- capture multiLineTextField -%}multi_line_text_field{%- endcapture -%}
      {%- capture richTextField -%}rich_text_field{%- endcapture -%}
      {%- capture reploMetafieldNamespace -%}${namespace}{%- endcapture -%}
      {%- capture reploMetafieldKey -%}${key}{%- endcapture -%}
      {%- capture htmlOpenTag -%}<{%- endcapture -%}
      {%- capture htmlCloseTag -%}>{%- endcapture -%}
      {%- liquid
         assign reploMetafield = ${fullMetafieldPath}[reploMetafieldNamespace][reploMetafieldKey]
         if reploMetafield.type == fileReference
          echo reploMetafield | file_url
         elsif reploMetafield.type == multiLineTextField
           if reploMetafield.value contains htmlOpenTag and reploMetafield.value contains htmlCloseTag
            echo reploMetafield.value
           else
            echo reploMetafield.value | newline_to_br
           endif
         elsif reploMetafield.type == richTextField
          echo reploMetafield | metafield_tag
         else
          echo reploMetafield.value
         endif
      -%}`;
    }
  } else if (INDEXED_IMAGE_REGEX.test(betweenTheBrackets)) {
    const match = betweenTheBrackets.match(
      /attributes\._product\.images\.(?<index>\d+)/,
    );
    if (match && match.groups && match.groups.index !== undefined) {
      liquidRepresentation = `product.images[${match.groups.index}] | default: product.featured_image | ${SHOPIFY_IMAGE_FILTER}`;
    }
  }

  if (liquidRepresentation) {
    return dynamicDataString.replace(betweenTheBrackets, liquidRepresentation);
  }
};

export const isLiquidDynamicData = (value: string | undefined) => {
  return (
    (value?.startsWith(LIQUID_CHUNK_BEGIN) ||
      value?.startsWith(`url("${LIQUID_CHUNK_BEGIN}`) ||
      value?.startsWith(`<p>${LIQUID_CHUNK_BEGIN}`)) ??
    false
  );
};

/**
 * This Regex matches on Shopify Liquid image URL tags in the format:
 *
 * product.featured_image | image_url
 *
 * The regex captures everything up through the image_url filter.
 * This allows us to append image width parameters or any other desired modifications
 * without removing the rest of the liquid code.
 */
const SHOPIFY_LIQUID_IMAGE_SOURCE_REGEX = /(\s*[^|]*?\s*\|\s*image_url)/;

export const isShopifyLiquidImageSource = (imageSource: string | undefined) => {
  // NOTE (Chance 2024-05-24): The regexp test is potentially very expensive, so
  // bail out early if we don't have a liquid expression.
  if (!imageSource || !isDynamicDataValue(imageSource)) {
    return false;
  }
  return SHOPIFY_LIQUID_IMAGE_SOURCE_REGEX.test(imageSource);
};

/**
 * NOTE (Chance 2024-05-24): There is no need to call
 * `isShopifyLiquidImageSource` before calling this function, since both
 * functions use the same regexp to match liquid. Doing so adds unnecessary
 * overhead as the regexp check can be expensive.
 *
 * @returns The image source with the new width parameter appended. If the image
 * source is not a liquid expression or does not include the image_url filter,
 * the original image source is returned as-is.
 */
export function swapShopifyLiquidImageWidth(
  imageSource: string,
  newWidth: number,
) {
  // NOTE (Chance 2024-05-24): The regexp test is potentially very expensive, so
  // bail out early if we don't have a liquid expression.
  if (!imageSource?.startsWith("{{") && !isLiquidDynamicData(imageSource)) {
    return imageSource;
  }
  return imageSource.replace(
    SHOPIFY_LIQUID_IMAGE_SOURCE_REGEX,
    `$1: width: ${newWidth} `,
  );
}

/**
 * This regex checks if the code input includes any valid liquid code.
 *
 * The associated function also checks if the code starts with `{{attributes.`,
 * as this is not liquid code but instead is dynamic data.
 */
const IS_VALID_LIQUID_REGEX = /({{-?.*?-?}}|{%-?.*?-?%})/;
export const isValidLiquid = (code: string) => {
  return IS_VALID_LIQUID_REGEX.test(code);
};
