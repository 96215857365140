import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

export type EditorMediaState = {
  uploadingComponentIds: string[];
  brokenMediaComponentIds: string[];
};

const initialState: EditorMediaState = {
  uploadingComponentIds: [],
  brokenMediaComponentIds: [],
};

const editorMediaSlice = createSlice({
  name: "editorMedia",
  initialState,
  reducers: {
    addEditorMediaUploadingComponentId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.uploadingComponentIds.push(action.payload);
    },
    resetEditorMediaUploadingComponentId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.uploadingComponentIds = state.uploadingComponentIds.filter(
        (id) => id !== action.payload,
      );
    },
    addEditorBrokenMediaComponentId: (state, action: PayloadAction<string>) => {
      state.brokenMediaComponentIds.push(action.payload);
    },
    resetEditorBrokenMediaComponentId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.brokenMediaComponentIds = state.brokenMediaComponentIds.filter(
        (id) => id !== action.payload,
      );
    },
  },
});

export const selectEditorMediaUploadingComponentIds = (state: {
  editorMedia: EditorMediaState;
}) => state.editorMedia.uploadingComponentIds;

export const selectEditorBrokenMediaComponentIds = (state: {
  editorMedia: EditorMediaState;
}) => state.editorMedia.brokenMediaComponentIds;

const { actions, reducer } = editorMediaSlice;

export const {
  addEditorMediaUploadingComponentId,
  resetEditorMediaUploadingComponentId,
  addEditorBrokenMediaComponentId,
  resetEditorBrokenMediaComponentId,
} = actions;
export default reducer;
