import type { ReploSimpleProject } from "schemas/generated/project";

import * as React from "react";

import Modal from "@common/designSystem/Modal";
import { ConnectShopifyIntegrationCta } from "@editor/components/editor/page/ConnectShopifyCallout";

import Tooltip from "@replo/design-system/components/tooltip";
import { Link } from "react-router-dom";

type ConnectShopifyModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  project: ReploSimpleProject;
};

const ConnectShopifyModal: React.FC<ConnectShopifyModalProps> = ({
  isOpen,
  onRequestClose,
  project,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="w-[400px]"
    >
      <div className="flex flex-col gap-4">
        <h2 className="text-base font-medium">Connect Shopify Store</h2>
        <p className="text-sm text-slate-500">
          Connect your Shopify store to enable product data, image uploads, and
          publishing capabilities.
        </p>
        <ConnectShopifyIntegrationCta
          type="projectSettings"
          selectedProject={project}
        />
      </div>
    </Modal>
  );
};

type ShopifyButtonProps = {
  isConnected: boolean;
  shopifyUrl?: string;
  project: ReploSimpleProject;
};

const ShopifyButton: React.FC<ShopifyButtonProps> = ({
  isConnected,
  shopifyUrl,
  project,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  if (isConnected && shopifyUrl) {
    return (
      <Tooltip content={shopifyUrl} triggerAsChild>
        <Link
          to={`https://${shopifyUrl}`}
          target="_blank"
          className="flex flex-row items-center gap-1 rounded px-2 py-1 bg-green-100 min-w-fit"
        >
          <img
            src="/images/shopify-logo.svg"
            alt="Shopify logo"
            className="w-4 h-4 text-slate-600"
            aria-hidden
          />
          <span className="text-xs text-slate-600 whitespace-nowrap">
            Connected
          </span>
        </Link>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip content="Connect to Shopify" triggerAsChild>
        <button
          className="flex flex-row items-center gap-1 rounded px-2 py-1 bg-slate-100 min-w-fit"
          onClick={() => setIsModalOpen(true)}
        >
          <img
            src="/images/shopify-logo.svg"
            alt="Shopify logo"
            className="w-4 h-4 text-slate-600"
            aria-hidden
          />
          <span className="text-xs text-slate-600 whitespace-nowrap">
            Connect store
          </span>
        </button>
      </Tooltip>
      <ConnectShopifyModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        project={project}
      />
    </>
  );
};

export default ShopifyButton;
