import type { ContextWithSlides } from "replo-runtime/store/components/CarouselV3";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { ReploElement } from "schemas/generated/element";

import {
  findAncestorComponent,
  findAncestorComponentOrSelf,
  getCurrentRepeatedIndex,
} from "@utils/component";

import { getSharedStateKey } from "replo-runtime/shared/state";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { isNotNullish, isNullish } from "replo-utils/lib/misc";

/**
 * Return the index of the nearest ancestor carouselV3 slide to the given
 * component id, or null if the component is not inside a carouselV3 slide.
 */
export const nearestCarouselSlideIndex = (
  targetDocument: Document | null,
  canvas: EditorCanvas,
  componentId: string | null,
  element: ReploElement | null,
  currentIndex: number,
) => {
  const slidesComponent = findAncestorComponent(
    element,
    componentId,
    (component) => component.type === "carouselV3Slides",
  );
  if (
    !componentId ||
    !slidesComponent ||
    !element ||
    !slidesComponent?.children?.[0]?.id
  ) {
    return { index: null, repeatedIndex: null };
  }
  const carouselV3Context = getCurrentComponentContext(
    slidesComponent?.id,
    0,
  ) as ContextWithSlides;

  const hasDynamicData = Boolean(carouselV3Context?.carousel?.dynamicItems);

  const slideComponent = findAncestorComponentOrSelf(
    element,
    componentId,
    (_, parent) => parent?.type === "carouselV3Slides",
  );

  const slideComponentRepeatedIndex = getCurrentRepeatedIndex(
    targetDocument,
    canvas,
    element?.id,
    slidesComponent?.children?.[0]?.id,
    currentIndex,
  );

  const firstComponentRepeatedIndex = getCurrentRepeatedIndex(
    targetDocument,
    canvas,
    element?.id,
    componentId,
    null,
  );

  const index = slidesComponent?.children?.findIndex(
    (child) => child.id === slideComponent?.id,
  );

  if (!slideComponentRepeatedIndex) {
    return { index, repeatedIndex: null };
  }

  const repeatedIndex =
    slideComponentRepeatedIndex +
    firstComponentRepeatedIndex?.slice(slideComponentRepeatedIndex.length);

  if (hasDynamicData) {
    return { index: null, repeatedIndex };
  }
  if (index !== -1) {
    return { index, repeatedIndex };
  }
  return { index: null, repeatedIndex };
};

/**
 * Return whether selecting the given component would constitute a transition from
 * one slide to another of the component's nearest carousel. This is useful for when
 * we want to automatically transition a carousel to the slide that contains the
 * component the user has selected.
 */
export const isSlideTransition = (
  newSelectedComponentId: string | null,
  element: ReploElement | null,
  sharedState: Record<string, any>,
) => {
  // Note: Fran (2022-05-11) if the selected id is a child of a carousel we
  // need to set the new active slide to show it in the editor
  const parentCarouselComponent = findAncestorComponent(
    element,
    newSelectedComponentId,
    (component) => component.type === "carouselV3",
  );
  const slidesComponent = findAncestorComponentOrSelf(
    element,
    newSelectedComponentId,
    (component) => component.type === "carouselV3Slides",
  );
  const slideComponent = findAncestorComponentOrSelf(
    element,
    newSelectedComponentId,
    (_, parent) => parent?.type === "carouselV3Slides",
  );
  const slideIndex = slidesComponent?.children?.findIndex(
    (child) => child.id === slideComponent?.id,
  );
  if (
    parentCarouselComponent &&
    isNotNullish(slideIndex) &&
    slideIndex !== -1
  ) {
    const activeSlideStateKey = getSharedStateKey(
      { id: parentCarouselComponent.id, type: "carouselV3" },
      "activeSlide",
    );
    const activeSlideFromState = sharedState[activeSlideStateKey];
    if (isNullish(activeSlideFromState)) {
      return slideIndex !== 0;
    }
    if (slideIndex !== sharedState[activeSlideStateKey]) {
      return true;
    }
  }
  return false;
};
