import { selectSelectedArchivedElementId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

import useCurrentProjectId from "./useCurrentProjectId";

export function useSelectedArchivedElement() {
  const projectId = useCurrentProjectId();
  const { data: archivedElements } = trpc.element.listArchived.useQuery(
    projectId ? { projectId } : skipToken,
  );
  const selectedArchivedElementId = useEditorSelector(
    selectSelectedArchivedElementId,
  );

  if (!archivedElements) {
    return null;
  }

  return archivedElements.find(
    (element) => element.id === selectedArchivedElementId,
  );
}
