import type { ComboboxContentProps } from "../types";

import * as React from "react";

import {
  PopoverContent,
  PopoverPortal,
} from "@replo/design-system/components/shadcn/core/popover";
import twMerge from "@replo/design-system/utils/twMerge";

import { DEFAULT_ITEMS_ON_VIEW_COUNT } from "../ComboboxConstants";
import { useCombobox } from "../hooks/useCombobox";
import { OptionsList } from "./OptionsList";

export const ComboboxContent: React.FC<ComboboxContentProps> = ({
  contentClassName,
  itemsOnViewCount,
  side,
  align,
  sideOffset,
  alignOffset,
  title,
  areOptionsSearchable,
  inputPlaceholder,
  emptySearchMessage,
  isLoading,
  children,
  // NOTE (Jackson, 2024-12-20): Only use if passing non-standard option labels, for ex.
  // in the font family selector
  itemSize,
}) => {
  const { options, setValue, setOpen, value, input, setInput, size } =
    useCombobox();

  // NOTE (Jackson, 2025-02-01): If options.length < default items on view count, collapse the height of the popover
  // to fit that number of items. If it's > default items on view count, we want to start scrolling. If it's specified
  // from props, use that
  const calculatedItemsOnViewCount =
    itemsOnViewCount ?? Math.min(options.length, DEFAULT_ITEMS_ON_VIEW_COUNT);

  return (
    <PopoverPortal>
      <PopoverContent
        className={twMerge(
          "flex flex-col border border-slate-200 p-0 w-full",
          contentClassName,
        )}
        style={{
          minWidth: "var(--radix-popper-anchor-width)",
        }}
        side={side}
        align={align}
        sideOffset={sideOffset}
        alignOffset={alignOffset}
      >
        <OptionsList
          options={options}
          itemsOnViewCount={calculatedItemsOnViewCount}
          title={title}
          setValue={setValue}
          setOpen={setOpen}
          value={value}
          input={input}
          inputPlaceholder={inputPlaceholder}
          emptySearchMessage={emptySearchMessage}
          setInput={setInput}
          areOptionsSearchable={areOptionsSearchable}
          isLoading={isLoading}
          size={size}
          itemSize={itemSize}
        />
        {children}
      </PopoverContent>
    </PopoverPortal>
  );
};
