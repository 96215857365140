import * as React from "react";

import Banner from "@common/designSystem/Banner";
import { toast } from "@editor/components/common/designSystem/Toast";
import DraftComponentPaneHeader from "@editor/components/DraftComponentPaneHeader";
import {
  COMPANION_RIGHT_BAR_WIDTH,
  RIGHT_BAR_WIDTH,
} from "@editor/components/editor/constants";
import DraftComponentPane from "@editor/components/editor/page/element-editor/components/DraftComponentPane";
import TabOptions from "@editor/components/editor/page/element-editor/components/TabOptions";
import TourStepTrigger from "@editor/components/flows/TourStepTrigger";
import {
  RightBarBody,
  RightBarLayout,
} from "@editor/components/layouts/RightBarLayout";
import { useEditorPerformanceContext } from "@editor/contexts/editor-performance.context";
import useRightBarVisibility from "@editor/hooks/useRightBarVisibility";
import { analytics, trackError } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectEditorMode,
  selectUpdatesSinceLastRequestFinished,
} from "@editor/reducers/core-reducer";
import {
  selectIsRightBarAnalyticsOpen,
  selectRightBarActiveTab,
} from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { isDevelopment } from "@editor/utils/env";

import { AIChat } from "@/features/aichat/AIChat";
import { JSONActionPlayer } from "@/features/aichat/JSONActionPlayer";
import { AnalyticsMenuPane } from "@/features/analytics/AnalyticsMenuPane";
import {
  ErrorBoundary,
  ErrorBoundaryDefaultFallback,
} from "replo-runtime/shared/ErrorBoundary";

import ToggleGroup from "./common/designSystem/ToggleGroup";

const ANALYTICS_RIGHT_BAR_WIDTH = 300;

const RightBar = React.memo(function RightBar() {
  const isAiChatEnabled = isFeatureEnabled("ai-chat");
  const [activeTab, setActiveTab] = React.useState(
    isAiChatEnabled ? "companion" : "design",
  );
  const editorMode = useEditorSelector(selectEditorMode);

  const currentTab = useEditorSelector(selectRightBarActiveTab);
  const isRightBarVisible = useRightBarVisibility();
  const isRightBarAnalyticsOpen = useEditorSelector(
    selectIsRightBarAnalyticsOpen,
  );
  const { rightBarElementRef } = useEditorPerformanceContext();

  const handleRenderError = React.useCallback(
    (error: unknown, info: React.ErrorInfo) => {
      if (isDevelopment) {
        console.error("[REPLO] Component rendering error in RightBar", {
          error,
          reactErrorInfo: info,
        });
      }
      toast({
        type: "error",
        header: "Something went wrong",
        message:
          "Please try refreshing the page or reach out to support if the error persists.",
        cta: "Contact Support",
        ctaHref: "mailto:support@replo.app",
      });
      trackError(error);
    },
    [],
  );

  if (!isRightBarVisible && !isAiChatEnabled) {
    return null;
  }

  const designTabRightBarWidth =
    activeTab === "design" ? RIGHT_BAR_WIDTH : COMPANION_RIGHT_BAR_WIDTH;
  const isEditMode = editorMode === EditorMode.edit;
  const rightBarWidth = isRightBarAnalyticsOpen
    ? ANALYTICS_RIGHT_BAR_WIDTH
    : designTabRightBarWidth;

  if (isRightBarAnalyticsOpen) {
    return (
      <AnalyticsMenuPane
        width={rightBarWidth}
        elementRef={rightBarElementRef}
      />
    );
  }

  const TAB_OPTIONS = [
    {
      label: "Companion",
      value: "companion",
      tooltipContent: "Editor Companion",
    },
    {
      label: "Design",
      value: "design",
      tooltipContent: "Fine grained design control",
    },
    {
      label: "JSON Input",
      value: "json-input",
      tooltipContent: "Add JSON to change things",
    },
  ];

  // https://stackoverflow.com/questions/14962468/how-can-i-combine-flexbox-and-vertical-scroll-in-a-full-height-app
  return (
    <RightBarLayout
      rightBarWidth={rightBarWidth}
      rightBarElementRef={rightBarElementRef}
    >
      {isEditMode && isAiChatEnabled && (
        <ToggleGroup
          allowsDeselect={false}
          type="single"
          options={TAB_OPTIONS}
          value={activeTab}
          onChange={(tab) => {
            setActiveTab(tab);
          }}
          style={{ width: "100%" }}
        />
      )}
      {activeTab === "json-input" && <JSONActionPlayer />}
      {activeTab === "companion" && <AIChat />}
      {activeTab === "design" && (
        <>
          {isEditMode && (
            // NOTE (Sebas, 2024-10-24): This is now on the RightBar and not in the
            // DraftComponentPane because it was interfering with the scrollable
            // component and causing weird horizontal and vertical scroll behavior.
            <div className="bg-white px-2 pt-2 z-10 sticky top-0 flex flex-col gap-1">
              <DraftComponentPaneHeader />
              <TourStepTrigger step="step-3">
                <TabOptions
                  currentTab={currentTab}
                  containerClassName="gap-2"
                />
              </TourStepTrigger>
            </div>
          )}
          <RightBarBody isEditMode={isEditMode}>
            {/* Note (Noah, 2022-08-16, REPL-3607): This banner is here because we're
            suspicious of a bug where we think that user updates which are made under
            slow or volatile network conditions may sometimes result in lost work. This
            could possibly happen if the element update request was VERY slow to respond,
            and a lot of updates to the local element don't actually get sent to the
            backend because there's already a request in progress, then the user refreshes
            the page or something and loses the updates (since they've never been sent to
            the backend). This might not happen, but we're putting this banner here if they've
            made some arbitrary number of updates which would currently be lost if the page
            were to refresh, so that we can see it in full stories and debug. */}
            <UpdatesWarningBanner />
            <ErrorBoundary
              fallback={
                <div className="p-2 text-xs">
                  <ErrorBoundaryDefaultFallback />
                </div>
              }
              onError={handleRenderError}
            >
              {isEditMode && <DraftComponentPane />}
            </ErrorBoundary>
          </RightBarBody>
        </>
      )}
    </RightBarLayout>
  );
});

const UpdatesWarningBanner: React.FC<{}> = () => {
  const updatesSinceLastRequestFinished = useEditorSelector(
    selectUpdatesSinceLastRequestFinished,
  );

  const showBanner = updatesSinceLastRequestFinished >= 30;

  React.useEffect(() => {
    if (showBanner) {
      analytics.logEvent("editor.pendingUpdatesWarningBannerShown", {});
    }
  }, [showBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <Banner backgroundColor="bg-red-200" className="color-white p-4 text-xs">
      More than {updatesSinceLastRequestFinished} pending unsaved updates. This
      may be a bug - please reach out to support@replo.app
    </Banner>
  );
};

export default RightBar;
