import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { ComponentMapping } from "replo-runtime/shared/Component";

import { scalingFactor } from "./canvas-utils";

export const CONTROL_BOX_HEIGHT = 28;
export const FIXED_TO_HEADER_PADDING = 8;

export function isEqualBoundingBox(
  box1: { top: number; left: number; width: number; height: number },
  box2: { top: number; left: number; width: number; height: number },
) {
  return (
    box1.top === box2.top &&
    box1.left === box2.left &&
    box1.width === box2.width &&
    box1.height === box2.height
  );
}

// Note (Evan, 2024-06-17): The desired behavior here is to have the control box's vertical position change
// when scrolling / zooming, such that
// 1) if the top of draft component is in view, the control box should move with it (the default behavior prior to this change)
// 2) otherwise, the control box should be either fixed to the header or the lower edge of the draft component,
// whichever is highest. This allows the control box to smoothly scroll out of view with the draft component.
export function calculateControlBoxTop({
  scale,
  boundsTop,
  boundsHeight,
  canvasDeltaY,
}: {
  scale: number;
  boundsTop: number;
  boundsHeight: number;
  canvasDeltaY: number;
}) {
  // i.e., the top position which causes the control box to move with the lower edge of the bounding box
  const fixedToControlBoxBottomPosition = `calc(${boundsTop}px + ${boundsHeight}px - 28px)`;

  // i.e., the top position which causes the control box to move with the upper edge of the bounding box
  // (the default behavior prior to this change)
  const fixedToControlBoxTopPosition = `calc(${boundsTop}px - 36px)`;

  // Note (Evan, 2024-06-17): This is some voodoo magic. The goal here is to
  // calculate a top position for the control box that will result in it rendering
  // immediately below the header (i.e., sticky to the top of the canvas area). The intuitive
  // way of doing this is to simply set a top position of -1 * deltaY (the canvas scroll Y value),
  // divided by the scale. (`scale` itself, rather than the scaling factor, since it's the scale
  // applied to the canvas)
  //
  // The issue with this is that control boxes are scaled differently (they use the scaling factor),
  // so without some additional correction, they will be too low for scales greater than 1. The correction
  // can be calculated from the scaling factor and the height of the control box.
  const correction =
    Math.max(scale - 1, 0) * CONTROL_BOX_HEIGHT * scalingFactor(scale);

  // i.e., the top position which causes the control box to be fixed to the header
  const fixedToHeaderTopPosition = `${(-1 * canvasDeltaY + FIXED_TO_HEADER_PADDING) / scale - correction}px`;

  return `max(${fixedToControlBoxTopPosition}, min(${fixedToHeaderTopPosition}, ${fixedToControlBoxBottomPosition}))`;
}

export function getChildBoxRenderComponentIds(
  componentMapping: ComponentMapping,
  draftComponentId: string | null,
  getAttribute: GetAttributeFunction,
) {
  if (!draftComponentId) {
    return [];
  }

  const childBoxRenderComponents: string[] = [draftComponentId];

  let currentComponentId = draftComponentId;
  while (componentMapping?.[currentComponentId]?.parentComponent?.id) {
    const parentComponent =
      componentMapping?.[currentComponentId]?.parentComponent;
    currentComponentId = parentComponent!.id;

    // Note (Ovishek, 2022-11-4): For ancestor who has a display grid
    // we are rendering childBoxes for all of them. B/c for grid the layout
    // can be complicated and if any of the children is selected there remains no clue to the layout itself.
    // In this way we highlight all the ancestors grid layout by pink and let the user a better user experience.
    if (getAttribute(parentComponent!, "style.display").value === "grid") {
      childBoxRenderComponents.push(currentComponentId);
    }
  }

  return childBoxRenderComponents;
}
