import * as React from "react";

import Label from "@replo/design-system/components/label";
import Description from "@replo/design-system/components/label/Description";
import twMerge from "@replo/design-system/utils/twMerge";
import debounce from "lodash-es/debounce";

type TextareaProps = {
  id?: string;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  isDisabled?: boolean;
  debounce?: boolean;
  label?: React.ReactNode;
  size?: "sm" | "base";
  textLength?: "long" | "short";
  description?: React.ReactNode;
  onChange(value: string): void;
  onBlur?(): void;
  onFocus?(): void;
  onEnter?(): void;
  onEscape?(): void;
  onKeyDown?(e: React.KeyboardEvent): void;
  onKeyUp?(e: React.KeyboardEvent): void;
  onKeyPress?(e: React.KeyboardEvent): void;
  onMouseDown?(): void;
  onMouseUp?(): void;
  onMouseMove?(): void;
  UNSAFE_className?: string;
  layoutClassName?: string;
  font?: "normal" | "mono";
  style?: React.CSSProperties;
  ref?: React.RefObject<HTMLTextAreaElement>;
  maxLength?: number;
  aiStyles?: boolean;
};

const Textarea = ({
  id,
  value = "",
  placeholder = "",
  autoFocus = false,
  isDisabled = false,
  debounce: hasDebounce = false,
  label,
  size = "base",
  textLength = "long",
  description,
  onChange,
  onBlur,
  onFocus,
  onEnter,
  onEscape,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  UNSAFE_className,
  layoutClassName,
  font = "normal",
  style,
  ref,
  maxLength,
  aiStyles,
}: TextareaProps) => {
  const [debouncedValue, setDebouncedValue] = React.useState<string>(value);
  const debouncedOnChange = React.useMemo(() => {
    return debounce((value: string) => onChange?.(value), 300);
  }, [onChange]);

  function _onFocusInput() {
    return onFocus?.();
  }

  function _onBlurInput() {
    return onBlur?.();
  }

  function _onChangeInput(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const trimmedValue =
      maxLength !== undefined
        ? e.target.value.slice(0, Math.max(0, maxLength))
        : e.target.value;
    if (hasDebounce) {
      setDebouncedValue(trimmedValue);
      debouncedOnChange(trimmedValue);
    } else {
      onChange?.(trimmedValue);
    }
  }

  function _onKeyPressInput(e: React.KeyboardEvent) {
    if (e.key === "Enter" && onEnter && !e.shiftKey) {
      onEnter();
    }
    if (e.key === "Escape" && onEscape) {
      onEscape?.();
    }
    return onKeyPress?.(e);
  }

  const internalClassNames = twMerge(
    "resize-none rounded bg-light-surface p-[12px] text-default outline-none transition-all duration-300 placeholder-placeholder focus:ring-1 focus:ring-primary disabled:cursor-not-allowed disabled:opacity-50",
    "disabled:opacity-50 disabled:cursor-not-allowed",
    size === "sm" && "typ-body-small p-[8px]",
    size === "base" && "typ-body-base",
    size === "sm" && textLength === "short" && "h-[48px]",
    size === "sm" && textLength === "long" && "h-[80px]",
    size === "base" && textLength === "short" && "h-[64px]",
    size === "base" && textLength === "long" && "h-[104px]",
    aiStyles && "bg-ai-emphasis",
    font === "mono" && "font-mono",
  );

  return (
    // NOTE (Patrick 2025-01-06): This container needs leading-zero otherwise the textarea will have empty space below it, causing extra gap between the Description
    <div className="w-full leading-zero">
      {label && <Label size={size} label={label} />}
      <textarea
        id={id}
        className={twMerge(
          internalClassNames,
          layoutClassName,
          UNSAFE_className,
        )}
        style={style}
        value={hasDebounce ? debouncedValue : value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        disabled={isDisabled}
        onChange={_onChangeInput}
        onBlur={_onBlurInput}
        onFocus={_onFocusInput}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={_onKeyPressInput}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        ref={ref}
      />
      {description && <Description description={description} size={size} />}
    </div>
  );
};

export { Textarea, type TextareaProps };
