import type { PluginImageData } from "@editor/utils/copyPaste";

import * as React from "react";

import { trackError } from "@editor/infra/analytics";

import { useCloudflareUpload } from "@/features/assets/useCloudflareUpload";
import { slugify } from "replo-utils/lib/string";
import { ReploError } from "schemas/errors";

import useCurrentWorkspaceId from "../useCurrentWorkspaceId";

class ImportFromFigmaError extends ReploError {}

const convertImageDataURL = (image: PluginImageData) => {
  const parts = image.dataURL.split(",");
  const mimetype =
    parts[0]?.match(/:(.*?);/)?.[1] || "application/octet-stream";
  if (parts[1]) {
    const byteString = atob(parts[1]);
    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }
    return { mimetype, byteArray: new Uint8Array(byteNumbers) };
  }
  return null;
};

export const useUploadFigmaImage = () => {
  const uploadToCloudflare = useCloudflareUpload();
  const workspaceId = useCurrentWorkspaceId();

  const uploadFigmaImage = React.useCallback(
    async (
      image: PluginImageData,
    ): Promise<{ url: string; nodeId: string } | null> => {
      const convertedImage = convertImageDataURL(image);
      if (!convertedImage || !workspaceId) {
        return null;
      }
      const filename = slugify(`${image.name}-${image.id}`);
      const response = await uploadToCloudflare(
        new Blob([convertedImage.byteArray], {
          type: convertedImage.mimetype,
        }),
        filename,
        workspaceId,
      );
      if (response && "data" in response) {
        return { url: response.data.url, nodeId: image.id };
      }
      trackError(
        new ImportFromFigmaError({
          message: `Failed to upload image ${filename} from Figma`,
          additionalData: {
            error: response?.error,
          },
        }),
      );
      return null;
    },
    [uploadToCloudflare, workspaceId],
  );

  return { uploadFigmaImage };
};
