import type { ExperimentCreateLocation } from "@editor/infra/analytics";
import type { AppRouterQueryArgs } from "@editor/utils/trpc";
import type { Experiment } from "schemas/generated/experiment";

import { useSubscriptionTier } from "@editor/hooks/subscription";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";

import {
  generateExperimentVariation,
  useExperimentApi,
} from "@/features/experiments/utils";
import { generatePath, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

type CreateExperimentProps = {
  location: ExperimentCreateLocation;
  partialExperiment?: Partial<Experiment>;
  onExperimentCreateSuccess?: () => void;
};

function generateDefaultCreateExperimentArgs({
  workspaceId,
  experiments,
}: {
  workspaceId: string;
  experiments: Experiment[];
}): AppRouterQueryArgs["experiment"]["create"] {
  const newExperimentId = uuid();

  let slug = `test-${experiments?.length ?? 0 + 1}`;
  if (experiments?.find((v) => v.slug === slug)) {
    slug = `test-${uuid().slice(0, 8)}`;
  }

  const defaultExperiment = {
    id: newExperimentId,
    workspaceId,
    name: slug,
    description: "",
    slug,
    variations: [
      generateExperimentVariation({
        slug: "Variant-A",
        target: null,
      }),
      generateExperimentVariation({
        slug: "Variant-B",
        target: null,
      }),
    ],
  };

  return {
    ...defaultExperiment,
    id: newExperimentId,
    workspaceId,
  };
}

export const useExperimentCreate = (workspaceId: string | undefined) => {
  const {
    list: { data },
    create: { mutateAsync: create, isPending: isCreatingExperiment },
  } = useExperimentApi({ workspaceId });

  const logEvent = useLogAnalytics();
  const subscriptionTier = useSubscriptionTier();
  const navigate = useNavigate();
  const errorToast = useErrorToast();
  const createExperiment = async ({
    location,
    partialExperiment,
    onExperimentCreateSuccess,
  }: CreateExperimentProps) => {
    const experiments = data?.experiments;

    if (experiments && workspaceId) {
      const defaultExperimentArgs = generateDefaultCreateExperimentArgs({
        workspaceId,
        experiments,
      });

      await create({ ...defaultExperimentArgs, ...partialExperiment });
      logEvent("experiment.create", {
        billingPlanTier: subscriptionTier,
        location,
      });
      const path = generatePath(routes.workspace.experiments.details, {
        workspaceId,
        experimentId: defaultExperimentArgs.id,
      });
      navigate(path, {
        state: { newExperiment: true },
      });
      onExperimentCreateSuccess?.();
    } else {
      errorToast(
        "Error Creating Test",
        "Please try again, or contact support@replo.app for help.",
        "error.experiment.create",
        {
          error: "No workspace id or experiments",
          workspaceId,
          experiments,
        },
      );
    }
  };

  return {
    createExperiment,
    isCreatingExperiment,
  };
};
