import * as React from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as Label from "@radix-ui/react-label";
import { MenuItem } from "@replo/design-system/components/menu-item";
import { Spinner } from "@replo/design-system/components/spinner";
import Tooltip from "@replo/design-system/components/tooltip";
import { BsCheck } from "react-icons/bs";
import { animated, config, useTransition } from "react-spring";
import { areEqual, FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

export type Option = {
  label: React.ReactNode;
  searchValue?: string;
  value?: string | number | null;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode | string;
  isDefaultActive?: boolean;
  isSelectable?: boolean;
  isDisabled?: boolean;
  disabledMessage?: string | null;
  toolTip?: string | null;
};

export type SelectedValue = string | number | null;

export type InfiniteLoadingSettings = {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  loadNextPage: () => Promise<void>;
};

const ListItem = React.forwardRef<
  HTMLButtonElement,
  {
    item: Option;
    isActive: boolean;
    isMultiselect?: boolean;
    labelClassName?: string;
    onHover?: (option: Option | null) => void;
  }
>(({ item, isActive, isMultiselect, labelClassName, onHover }, ref) => {
  if (item.isDisabled) {
    const menuItem = (
      <MenuItem
        ref={ref}
        disabled
        layoutClassName="h-full"
        UNSAFE_className="p-2 text-xs font-normal text-subtle"
        size="base"
      >
        {item.label}
      </MenuItem>
    );

    return item.disabledMessage ? (
      <Tooltip content={item.disabledMessage} side="top" triggerAsChild={false}>
        {menuItem}
      </Tooltip>
    ) : (
      menuItem
    );
  }

  const { label, endEnhancer, startEnhancer, isSelectable = true } = item;

  const menuItem = (
    <MenuItem
      ref={ref}
      variant={isMultiselect ? "checkbox" : "default"}
      selected={isActive}
      disabled={!isSelectable}
      UNSAFE_className={labelClassName}
      onHover={() => onHover?.(item)}
      startEnhancer={startEnhancer}
      endEnhancer={endEnhancer}
      size="base"
    >
      {label}
    </MenuItem>
  );

  return item.toolTip ? (
    <Tooltip content={item.toolTip} side="top" triggerAsChild={false}>
      {menuItem}
    </Tooltip>
  ) : (
    menuItem
  );
});
ListItem.displayName = "ListItem";

const Row: React.FC<{
  index: number;
  style: React.CSSProperties;
  filteredFormattedOptions: Option[];
  isActive: boolean;
  onSelect?(value: string | number | null): void;
  isMultiselect?: boolean;
  labelClassName?: string;
  onHover?: (option: Option | null) => void;
}> = ({
  index,
  style,
  filteredFormattedOptions,
  onSelect,
  isActive,
  isMultiselect,
  labelClassName,
  onHover,
}) => {
  const item = filteredFormattedOptions[index]!;
  const { isSelectable = true, value } = item;
  const onElementSelect = () => {
    if (isSelectable) {
      onSelect?.(value ?? null);
    }
  };

  // NOTE (Chance 2023-11-07): Radix will conditionally render an `input`
  // element based on whether or not the `Checkbox` is inside of a form element.
  // In those cases it also needs to prevent event propagation on the input,
  // which will stop click events from firing on the container elements. To get
  // around this, we observe the element to see if there is an input nested in
  // our `ListItem` and, if so, wrap it in a label which will trigger the
  // input's change event when clicked.
  //
  // Related issues:
  // - REPL-8296
  // - REPL-9189
  const [isFormControl, setIsFormControl] = React.useState(false);
  const itemRef = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    const itemElement = itemRef.current;
    if (!itemElement) {
      return;
    }

    const detectInput = () => {
      const checkboxElement = itemElement.querySelector(
        "input[type='checkbox']",
      );
      setIsFormControl(Boolean(checkboxElement));
    };

    detectInput();
    const observer = new MutationObserver(detectInput);
    observer.observe(itemElement, {
      childList: true,
      subtree: true,
    });
    return () => observer.disconnect();
  }, []);

  const itemElement = (
    <ListItem
      ref={itemRef}
      item={item}
      isActive={isActive}
      isMultiselect={isMultiselect}
      labelClassName={labelClassName}
      onHover={onHover}
    />
  );

  return isFormControl ? (
    <Label.Root style={style}>{itemElement}</Label.Root>
  ) : (
    <div style={style} onClick={onElementSelect}>
      {itemElement}
    </div>
  );
};

const getListHeight = (
  itemSize: number,
  totalItems: number,
  itemsOnViewCount?: number,
): number => {
  if (!itemsOnViewCount) {
    // Note (Mariano, 08-05-22): This is the default height for the list.
    // This magic number comes from the designs and has been in use before adding this logic
    return 260;
  }
  return Math.ceil(itemSize * Math.min(totalItems, itemsOnViewCount));
};

const Placeholder: React.FC<
  React.PropsWithChildren<{ style: React.CSSProperties }>
> = ({ children, ...props }) => {
  return (
    <div
      className="my-3 flex w-full items-center justify-center text-xs font-normal	text-muted"
      {...props}
    >
      {children}
    </div>
  );
};

export const RegularList: React.FC<{
  options: Option[];
  itemSize: number;
  onSelect?: (value: string | number | null) => void;
  itemsOnViewCount?: number;
  selectedItems: SelectedValue[];
  isMultiselect?: boolean;
  labelClassName?: string;
  noItemsPlaceholder?: React.ReactNode;
  totalItems?: number;
  onHover?: (option: Option | null) => void;
}> = ({
  options,
  onSelect,
  itemSize,
  itemsOnViewCount,
  selectedItems,
  isMultiselect,
  labelClassName,
  noItemsPlaceholder,
  onHover,
}) => {
  const shouldRenderPlaceholder = Boolean(
    options.length === 0 && noItemsPlaceholder,
  );

  const itemData = React.useMemo(
    () => ({
      options,
      onSelect,
      shouldRenderPlaceholder,
      noItemsPlaceholder,
      isMultiselect,
      selectedItems,
      labelClassName,
      onHover,
      isItemLoaded: () => true,
      isLoadingFirstPage: false,
    }),
    [
      options,
      onSelect,
      shouldRenderPlaceholder,
      noItemsPlaceholder,
      isMultiselect,
      selectedItems,
      labelClassName,
      onHover,
    ],
  );

  return (
    // NOTE (Jackson, 12-10-24): Reset hovered item on leaving the list rather than
    // on each row.
    <div onMouseLeave={() => onHover?.(null)}>
      <List
        height={getListHeight(
          itemSize,
          shouldRenderPlaceholder ? 1 : options.length,
          itemsOnViewCount,
        )}
        itemCount={shouldRenderPlaceholder ? 1 : options.length}
        itemSize={shouldRenderPlaceholder ? 200 : itemSize}
        width="100%"
        className="no-scrollbar"
        itemData={itemData}
      >
        {MemoizedRow}
      </List>
    </div>
  );
};

// NOTE (Evan, 8/25/23) This is pretty annoying – in order to prevent each Row from re-mounting on change,
// we need a memoized version of the row so that it isn't recreated. This prevents things like REPL-8332,
// where every checkbox was resetting when any one of them was clicked.

type ItemData = {
  options: Option[];
  onSelect: ((value: string | number | null) => void) | undefined;
  shouldRenderPlaceholder: boolean;
  noItemsPlaceholder: React.ReactNode;
  isItemLoaded: (index: number) => boolean;
  isLoadingFirstPage: boolean;
  isMultiselect: boolean | undefined;
  selectedItems: SelectedValue[];
  labelClassName: string | undefined;
  onHover?: (option: Option | null) => void;
};

const MemoizedRow = React.memo(
  ({
    data,
    index,
    style,
  }: {
    data: ItemData;
    index: number;
    style: React.CSSProperties;
  }) => {
    const {
      options,
      onSelect,
      shouldRenderPlaceholder,
      noItemsPlaceholder,
      isItemLoaded,
      isLoadingFirstPage,
      isMultiselect,
      selectedItems,
      labelClassName,
      onHover,
    } = data;

    if (shouldRenderPlaceholder) {
      return <Placeholder style={style}>{noItemsPlaceholder}</Placeholder>;
    }
    return !isItemLoaded(index) || isLoadingFirstPage ? (
      <div className="my-3 flex w-full justify-center" style={style}>
        <Spinner size={20} variant="primary" />
      </div>
    ) : (
      <Row
        index={index}
        style={style}
        isMultiselect={isMultiselect}
        isActive={selectedItems.includes(
          options[index]?.value as SelectedValue,
        )}
        filteredFormattedOptions={options}
        onSelect={onSelect}
        labelClassName={labelClassName}
        onHover={onHover}
      />
    );
  },
  areEqual,
);
MemoizedRow.displayName = "MemoizedRow";

export const InfiniteList: React.FC<{
  options: Option[];
  itemSize: number;
  onSelect?: (value: string | number | null) => void;
  itemsOnViewCount?: number;
  settings: InfiniteLoadingSettings;
  selectedItems: SelectedValue[];
  isMultiselect?: boolean;
  labelClassName?: string;
  noItemsPlaceholder?: React.ReactNode;
  onHover?: (option: Option | null) => void;
}> = ({
  options,
  onSelect,
  settings,
  itemSize,
  itemsOnViewCount,
  selectedItems,
  isMultiselect,
  labelClassName,
  noItemsPlaceholder,
  onHover,
}) => {
  const { hasNextPage, isNextPageLoading, loadNextPage } = settings;
  const itemCount = hasNextPage ? options.length + 1 : options.length;
  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < options.length;
  };
  const isLoadingFirstPage = isNextPageLoading && options.length === 0;
  const shouldRenderPlaceholder = Boolean(
    !isNextPageLoading && options.length === 0 && noItemsPlaceholder,
  );

  const totalItemCount =
    isLoadingFirstPage || shouldRenderPlaceholder ? 1 : itemCount;

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadNextPage}
    >
      {({ onItemsRendered, ref }) => (
        <List
          height={getListHeight(itemSize, totalItemCount, itemsOnViewCount)}
          itemCount={totalItemCount}
          itemSize={shouldRenderPlaceholder ? 200 : itemSize}
          onItemsRendered={onItemsRendered}
          width="100%"
          ref={ref}
          className="no-scrollbar"
          itemData={{
            options,
            onSelect,
            shouldRenderPlaceholder,
            noItemsPlaceholder,
            isItemLoaded,
            isLoadingFirstPage,
            isMultiselect,
            selectedItems,
            labelClassName,
            onHover,
          }}
        >
          {MemoizedRow}
        </List>
      )}
    </InfiniteLoader>
  );
};

type CheckboxSizes = "sm" | "lg";

type CheckboxProps = {
  isChecked: boolean;
  size?: CheckboxSizes;
  onCheckedChange?: (isChecked: boolean) => void;
};

// NOTE (Chance 2023-11-07): I moved this out of our `designSystem` directory
// because we actually don't use it anywhere else, and we have another Checkbox
// component in `common` with a completely different implementation that we use
// in a bunch of other places. We should figure out which one belongs in the DS
// and update accordingly.
const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ isChecked, size = "lg", onCheckedChange }, ref) => {
    const transitions = useTransition(isChecked, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.stiff,
    });
    const sizes: Record<CheckboxSizes, { box: string; icon: number }> = {
      sm: {
        box: "12px",
        icon: 12,
      },
      lg: {
        box: "20px",
        icon: 16,
      },
    };

    return (
      <CheckboxPrimitive.Root
        ref={ref}
        checked={isChecked}
        onCheckedChange={onCheckedChange}
        style={{
          all: "unset",
          width: sizes[size].box,
          height: sizes[size].box,
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: isChecked ? "#2563EB" : "white",
          border: "1px solid #2563EB",
          flexShrink: 0,
        }}
      >
        <CheckboxPrimitive.Indicator asChild forceMount>
          {transitions((styles, item) => {
            if (!item) {
              return null;
            }

            return (
              <animated.div
                style={{
                  opacity: styles.opacity,
                }}
              >
                <BsCheck color="white" size={sizes[size].icon} />
              </animated.div>
            );
          })}
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    );
  },
);
Checkbox.displayName = "Checkbox";
