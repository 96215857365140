import * as React from "react";

import classNames from "classnames";

/**
 * Helper component that renders a button for choosing a layout.
 */
export const ElementEditorLayoutButton: React.FC<
  React.PropsWithChildren<{
    label: string;
    isActive: boolean;
    onClick: () => void;
    className?: string;
  }>
> = ({ label, isActive, onClick, children, className }) => {
  return (
    <button
      type="button"
      disabled={isActive}
      className={classNames(
        "border-0.5 rounded-lg p-2 flex flex-col gap-2 w-full cursor-pointer h-full justify-between",
        isActive ? "border-blue-600 bg-blue-50" : "border-border",
      )}
      onClick={onClick}
    >
      <div className={classNames("text-xs font-medium text-slate-800")}>
        {label}
      </div>
      <div
        className={classNames(
          "flex aspect-[6/4] w-full flex-col justify-between rounded  p-1",
          isActive ? "bg-white" : "bg-slate-100",
          className,
        )}
      >
        {children}
      </div>
    </button>
  );
};
