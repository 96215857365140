/* Copied from https://github.com/sindresorhus/array-move/blob/main/index.js */

export const moveMutate = (array: any, from: number, to: number): any => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const move = (array: any, from: number, to: number): any => {
  array = [...array];
  moveMutate(array, from, to);
  return array;
};

export function emptyIfNullish<T>(array: T[] | null | undefined): T[] {
  return array ?? [];
}

export function sum(array: number[]): number {
  return array.reduce((a, b) => a + b, 0);
}

export function lastItem<T>(array: T[]): T | undefined {
  return array[array.length - 1];
}
