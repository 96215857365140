import * as React from "react";

import { useDraftElementMetadata } from "@editor/hooks/useDraftElementMetadata";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftElementIsPublished,
  selectDraftElementPublishedAt,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { formatDistanceToNowStrict } from "date-fns";
import { BsFillCircleFill } from "react-icons/bs";

const PublishedStatus = () => {
  // TODO (Ben O., 02-01-2025): clean up once fully moved to trpc
  const isPublishedSelector = useEditorSelector(selectDraftElementIsPublished);
  const publishedAtSelector = useEditorSelector(selectDraftElementPublishedAt);
  const elementMetadata = useDraftElementMetadata();
  const isPublished = isFeatureEnabled("refactor-element-settings")
    ? elementMetadata?.isPublished
    : isPublishedSelector;
  const publishedAt = isFeatureEnabled("refactor-element-settings")
    ? elementMetadata?.publishedAt
    : publishedAtSelector;

  const difference = publishedAt
    ? formatDistanceToNowStrict(new Date(publishedAt))
    : null;

  const label =
    isPublished && difference
      ? `Last Published ${difference} ago`
      : "Not Published";

  return (
    <div
      className={twMerge(
        classNames(
          "flex items-center gap-2 text-sm font-medium text-slate-400",
          {
            "text-green-400": isPublished,
          },
        ),
      )}
    >
      <Tooltip content={label} triggerAsChild>
        <span tabIndex={0}>
          <BsFillCircleFill size={8} />
        </span>
      </Tooltip>
    </div>
  );
};

export default PublishedStatus;
