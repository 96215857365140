// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */

import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { DesignLibrary } from "schemas/generated/designLibrary";
import type { DataTable, ReploElementType } from "schemas/generated/element";
import type { UploadedFont } from "schemas/generated/project";
import type { ReploSymbol } from "schemas/generated/symbol";
import type { ProductRef } from "schemas/product";
import type { ComponentInventory } from "../store/componentInventory";
import type {
  RENDER_ENV_EDITOR,
  RENDER_ENV_PAGE_PREVIEW,
  RENDER_ENV_PAGE_PUBLISHED,
  RuntimeRenderEnvironment,
} from "./render-environment";
import type {
  OkendoNamespace,
  ProductMetafieldMapping,
  RuntimeFeatureFlags,
  RenderPreviewEnvironment as RuntimePreviewEnvironment,
  SharedStatePayload,
  StoreProduct,
  Swatch,
  SyncRuntimeStatePayload,
  VariantMetafieldMapping,
} from "./types";
import type { GlobalWindow } from "./Window";

import * as React from "react";

import { noop } from "replo-utils/lib/misc";

// NOTE (Chance 2024-03-13): Context objects that are not relevant outside of
// the editor (like editor history) still need to be accessible in other
// environments. Those can get a default value so we don't necessarily need to
// include a provider in those environments.

// #region --- Canvas-level context objects ---
//
// NOTE (Chance 2024-03-13): This context is provided at the top level of the
// canvas (or in the case of publishing, the top level of the page). They are
// split up because runtime components shouldn't need to subscribe to every
// potential value change. This will essentially replace most things that are
// being passed via render extras and used as render dependencies.

// #region ReploEditorCanvasContext
export interface ReploEditorCanvasContextValue {
  editorCanvasYOffset: number;
  editorCanvasScale: number;
}
export const ReploEditorCanvasContext =
  createContext<ReploEditorCanvasContextValue>("ReploEditorCanvas", {
    editorCanvasYOffset: 0,
    editorCanvasScale: 1,
  });
// #endregion

// #region ReploEditorActiveCanvasContext
export interface ReploEditorActiveCanvasContextValue {
  activeCanvas: EditorCanvas;
}
export const ReploEditorActiveCanvasContext =
  createContext<ReploEditorActiveCanvasContextValue>(
    "ReploEditorActiveCanvas",
    { activeCanvas: "desktop" },
  );
// #endregion

// #region ReploElementContext
export interface ReploElementContextValue {
  elementType: ReploElementType;
  useSectionSettings: boolean | undefined;
}
export const ReploElementContext =
  createContext<ReploElementContextValue>("ReploElement");
// #endregion

// #region RenderEnvironmentContext
interface _RenderEnvironmentContextValue {
  renderEnvironment: RuntimeRenderEnvironment;
  previewEnvironment: RuntimePreviewEnvironment | null;
  isEditorApp: boolean;
  isPublishing: boolean;
  isPublishedPage: boolean;
  isElementPreview: boolean;
}

interface RenderEnvironmentContextPagePreview
  extends _RenderEnvironmentContextValue {
  renderEnvironment: typeof RENDER_ENV_PAGE_PREVIEW;
  isEditorApp: false;
  isPublishedPage: false;
  isElementPreview: true;
  isPublishing: boolean;
}

interface RenderEnvironmentPagePublished
  extends _RenderEnvironmentContextValue {
  renderEnvironment: typeof RENDER_ENV_PAGE_PUBLISHED;
  isEditorApp: false;
  isPublishedPage: true;
  isElementPreview: false;
  isPublishing: boolean;
}

export interface RenderEnvironmentContextEditor
  extends _RenderEnvironmentContextValue {
  renderEnvironment: typeof RENDER_ENV_EDITOR;
  isEditorApp: true;
  isPublishedPage: false;
  isElementPreview: false;
  isPublishing: false;
}

export type RenderEnvironmentContextValue =
  | RenderEnvironmentContextPagePreview
  | RenderEnvironmentPagePublished
  | RenderEnvironmentContextEditor;

export const RenderEnvironmentContext =
  createContext<RenderEnvironmentContextValue>("RenderEnvironment");
// #endregion

// #region FeatureFlagsContext
export interface FeatureFlagsContextValue {
  featureFlags: RuntimeFeatureFlags;
}
export const FeatureFlagsContext =
  createContext<FeatureFlagsContextValue>("FeatureFlags");
// #endregion

// #region DraftElementContext
export interface DraftElementContextValue {
  draftElementId: string | null;
  draftComponentId: string | null;
  draftRepeatedIndex: string | null;
  draftSymbolInstanceId: string | null;
  draftSymbolId: string | null;
}
export const DraftElementContext = createContext<DraftElementContextValue>(
  "DraftElement",
  {
    draftElementId: null,
    draftComponentId: null,
    draftRepeatedIndex: null,
    draftSymbolInstanceId: null,
    draftSymbolId: null,
  },
);
// #endregion

// NOTE (Matt 2024-04-18): While this is called the DynamicDataStore,
// this is more of a placeholder for now. We need a way to access
// sectionSettings via context, and it makes sense for this to exist
// in the store. However, because of the current GoPuff integration
// we need to keep the rest of what we consider to be "dynamic data"
// (ie products, productMetafields, variantMetafields) on the window
// object. This store will later be built out to include those attributes.
// #region DynamicDataStoreContext
export interface DynamicDataStore {
  sectionSettings?: {
    [key: string]: string;
  };
}
export interface DynamicDataStoreContextValue {
  store: DynamicDataStore;
  setStore?:
    | React.Dispatch<React.SetStateAction<DynamicDataStore>>
    | ((newStore: DynamicDataStore) => void);
}
export const DynamicDataStoreContext =
  createContext<DynamicDataStoreContextValue>("DynamicDataStore");
// #endregion

// #region ShopifyStoreContextValue
export interface ShopifyStoreContextValue {
  storeId: string | undefined;
  storeUrl: string | undefined;
  templateProduct: StoreProduct | null;
  fakeProducts: StoreProduct[] | null;
  activeLanguage: string;
  activeCurrency: string;
  activeShopifyUrlRoot: string;
  moneyFormat: string | null;
}
export const ShopifyStoreContext =
  createContext<ShopifyStoreContextValue>("ShopifyStore");
// #endregion

// #region ReploSymbolsContext
export interface ReploSymbolsContextValue {
  symbols: ReploSymbol[];
}
export const ReploSymbolsContext =
  createContext<ReploSymbolsContextValue>("ReploSymbols");
// #endregion

// #region ComponentErrorContext
export interface ComponentErrorContextValue {
  onComponentError: (
    componentId: string,
    error: unknown,
    info: React.ErrorInfo,
  ) => void;
}
export const ComponentErrorContext = createContext<ComponentErrorContextValue>(
  "ComponentError",
  { onComponentError: noop },
);
// #endregion

// #region ComponentInventoryContext
export interface ComponentInventoryContextValue {
  componentInventory: ComponentInventory;
}
export const ComponentInventoryContext =
  createContext<ComponentInventoryContextValue>("ComponentInventory");
// #endregion

// #region ComponentUpdateContext
export interface ComponentUpdateContextValue {
  onSubmitContentEditableTextUpdate: (
    componentId: string,
    htmlContent: string,
  ) => void;
}
export const ComponentUpdateContext =
  createContext<ComponentUpdateContextValue>("ComponentUpdate", {
    onSubmitContentEditableTextUpdate: noop,
  });
// #endregion

// #region CustomFontsContext
export interface CustomFontsContextValue {
  uploadedFonts: UploadedFont[];
}
export const CustomFontsContext = createContext<CustomFontsContextValue>(
  "CustomFonts",
  { uploadedFonts: [] },
);
// #endregion

// #region DesignLibraryContext
export interface DesignLibraryContextValue {
  designLibrary: DesignLibrary | null;
}
export const DesignLibraryContext = createContext<DesignLibraryContextValue>(
  "DesignLibrary",
  { designLibrary: null },
);
// #endregion

// #region SyncRuntimeStateContext
export interface SyncRuntimeStateContextValue {
  setSyncRuntimeState: (payload: SyncRuntimeStatePayload) => void;
}
export const SyncRuntimeStateContext =
  createContext<SyncRuntimeStateContextValue>("SyncRuntimeState", {
    setSyncRuntimeState: noop,
  });
// #endregion

// #region ExtraContext
export interface ExtraContextValue {
  disableLiquid: boolean;
  okendoNamespace: OkendoNamespace | null;
  runtimeVersion: string | null;
}
export const ExtraContext = createContext<ExtraContextValue>("Extra");
// #endregion

// #region GlobalWindowContext
export type GlobalWindowContextValue = GlobalWindow;
export const GlobalWindowContext =
  createContext<GlobalWindowContextValue | null>("GlobalWindow");
// #endregion

// #region EditorCanvasContext
export type EditorCanvasContextValue = EditorCanvas;
export const EditorCanvasContext =
  createContext<EditorCanvasContextValue | null>("EditorCanvas");
// #endregion

// #region RuntimeHooksContext
export type RuntimeHooksContextValue = {
  usePreviewWidth: () => number;
  useEditorOverrideActiveVariantId: (componentId: string) => string | null;
  useEditorOverrideTextValue: (componentId: string) => string | null;
  useIsEditorEditModeRenderEnvironment: () => boolean;
  useIsLabelledByOtherComponent: (componentId: string) => boolean;
  useSwatches: () => Swatch[];
  useSharedState: (key: string) => any;
  useSetSharedState: () => (payload: SharedStatePayload) => void;
  useDataTableMapping: () => Record<string, DataTable>;
  useTemplateEditorProduct: () => ProductRef | null;
  useRenderedLiquid: (key: string | null) => string | null;
  useIsRenderLiquidLoading: (key: string | null) => boolean;
  useRequestRenderLiquid: () => (liquidSource: string) => void;
  useRenderedLiquidCacheLength: () => number;
  useShopifyProducts: () => StoreProduct[];
  useIsShopifyProductsLoading: () => boolean;
  useShopifyProductMetafieldValues: () => ProductMetafieldMapping;
  useShopifyVariantMetafieldValues: () => VariantMetafieldMapping;
  useHideShopifyHeader: () => boolean;
  useHideShopifyFooter: () => boolean;
  useHideShopifyAnnouncementBar: () => boolean;
  useIsContentEditing: ({
    componentId,
    repeatedIndex,
    canvas,
  }: {
    componentId: string;
    repeatedIndex: string;
    canvas: EditorCanvas;
  }) => boolean;
  useIsUploadingEditorMediaId: (componentId: string) => boolean;
  useIsBrokenEditorMediaId: (componentId: string) => boolean;
  useSetEditorBrokenMediaComponentId: () => (componentId: string) => void;
  useEditorOverrideOpenModalId: () => string | null;
  useDesignLibrary: () => DesignLibrary | null;
};
export const RuntimeHooksContext =
  createContext<RuntimeHooksContextValue | null>("RuntimeHooks");
// #endregion

export type RuntimeContext =
  | typeof ComponentErrorContext
  | typeof ComponentInventoryContext
  | typeof ComponentUpdateContext
  | typeof CustomFontsContext
  | typeof DesignLibraryContext
  | typeof DraftElementContext
  | typeof DynamicDataStoreContext
  | typeof ExtraContext
  | typeof FeatureFlagsContext
  | typeof RenderEnvironmentContext
  | typeof ReploEditorCanvasContext
  | typeof ReploEditorActiveCanvasContext
  | typeof ReploElementContext
  | typeof ReploSymbolsContext
  | typeof SyncRuntimeStateContext
  | typeof ShopifyStoreContext
  | typeof GlobalWindowContext
  | typeof EditorCanvasContext
  | typeof RuntimeHooksContext;

export type RuntimeContextValue =
  | ComponentErrorContextValue
  | ComponentInventoryContextValue
  | ComponentUpdateContextValue
  | CustomFontsContextValue
  | DraftElementContextValue
  | DynamicDataStoreContextValue
  | DesignLibraryContextValue
  | ExtraContextValue
  | FeatureFlagsContextValue
  | RenderEnvironmentContextValue
  | ReploEditorCanvasContextValue
  | ReploEditorActiveCanvasContextValue
  | ReploElementContextValue
  | ReploSymbolsContextValue
  | ShopifyStoreContextValue
  | SyncRuntimeStateContextValue
  | GlobalWindowContextValue
  | EditorCanvasContextValue
  | RuntimeHooksContextValue;

// #endregion

export function useRuntimeContext<T extends RuntimeContextValue>(
  Context: React.Context<T | null>,
): T;
export function useRuntimeContext<T extends RuntimeContextValue>(
  Context: React.Context<T>,
): T;
export function useRuntimeContext<T extends RuntimeContextValue>(
  Context: React.Context<T | null> | React.Context<T>,
): T {
  const ctx = React.useContext(Context as any) as unknown as T;
  if (ctx === undefined) {
    const displayName = Context.displayName || "RuntimeContext";
    // TODO (Chance 2024-03-13): Switch between dev and prod for customer-facing
    // errors
    throw new Error(
      `[REPLO] use${displayName} must only be called from inside a Replo runtime component. If this error is unexpected, you may have forgotten to wrap the component tree in the context's provider.`,
    );
  }
  return ctx;
}

export function RuntimeContextProvider<T extends RuntimeContext>(props: {
  context: T;
  value?: T extends React.Context<infer V> ? V | null : never;
  allowNull?: boolean;
  children: React.ReactNode;
}) {
  let { context: Context, value, allowNull = false } = props;
  const _value = React.useContext(Context as React.Context<any>);
  value = value ?? _value;
  if (!value && !allowNull) {
    const displayName = Context.displayName || "RuntimeContext";
    // TODO (Chance 2024-03-13): Switch between dev and prod for customer-facing
    // errors
    throw new Error(
      `[REPLO] ${displayName}Provider must have access to a value. A value prop can only be omitted if the provider is rendered below the same provider deeper in the render tree.`,
    );
  }
  return (
    <Context.Provider value={value as any}>{props.children}</Context.Provider>
  );
}

export interface RuntimeContextProvidersProps
  extends RuntimeContextNullableValueMap {
  children: React.ReactNode;
}

export function RuntimeContextProviders({
  children,
  componentError,
  componentInventory,
  componentUpdate,
  customFonts,
  draftElement,
  dynamicDataStore,
  syncRuntimeState,
  extraContext,
  featureFlags,
  renderEnvironment,
  reploEditorCanvas,
  reploEditorActiveCanvas,
  reploElement,
  reploSymbols,
  shopifyStore,
  globalWindow,
  editorCanvas,
  runtimeHooks,
  designLibrary,
}: RuntimeContextProvidersProps) {
  return (
    <RuntimeContextProvider
      context={ComponentErrorContext}
      value={componentError}
    >
      <RuntimeContextProvider
        context={ComponentUpdateContext}
        value={componentUpdate}
      >
        <RuntimeContextProvider
          context={ComponentInventoryContext}
          value={componentInventory}
        >
          <RuntimeContextProvider
            context={CustomFontsContext}
            value={customFonts}
          >
            <RuntimeContextProvider
              context={DraftElementContext}
              value={draftElement}
            >
              <RuntimeContextProvider
                context={DynamicDataStoreContext}
                value={dynamicDataStore}
              >
                <RuntimeContextProvider
                  context={ExtraContext}
                  value={extraContext}
                >
                  <RuntimeContextProvider
                    context={FeatureFlagsContext}
                    value={featureFlags}
                  >
                    <RuntimeContextProvider
                      context={RenderEnvironmentContext}
                      value={renderEnvironment}
                    >
                      <RuntimeContextProvider
                        context={ReploEditorCanvasContext}
                        value={reploEditorCanvas}
                      >
                        <RuntimeContextProvider
                          context={ReploEditorActiveCanvasContext}
                          value={reploEditorActiveCanvas}
                        >
                          <RuntimeContextProvider
                            context={ReploElementContext}
                            value={reploElement}
                          >
                            <RuntimeContextProvider
                              context={ReploSymbolsContext}
                              value={reploSymbols}
                            >
                              <RuntimeContextProvider
                                context={SyncRuntimeStateContext}
                                value={syncRuntimeState}
                              >
                                <RuntimeContextProvider
                                  context={ShopifyStoreContext}
                                  value={shopifyStore}
                                >
                                  <RuntimeContextProvider
                                    context={GlobalWindowContext}
                                    allowNull
                                    value={globalWindow}
                                  >
                                    <RuntimeContextProvider
                                      context={EditorCanvasContext}
                                      allowNull
                                      value={editorCanvas}
                                    >
                                      <RuntimeContextProvider
                                        context={RuntimeHooksContext}
                                        value={runtimeHooks}
                                      >
                                        <RuntimeContextProvider
                                          context={DesignLibraryContext}
                                          value={designLibrary}
                                        >
                                          {children}
                                        </RuntimeContextProvider>
                                      </RuntimeContextProvider>
                                    </RuntimeContextProvider>
                                  </RuntimeContextProvider>
                                </RuntimeContextProvider>
                              </RuntimeContextProvider>
                            </RuntimeContextProvider>
                          </RuntimeContextProvider>
                        </RuntimeContextProvider>
                      </RuntimeContextProvider>
                    </RuntimeContextProvider>
                  </RuntimeContextProvider>
                </RuntimeContextProvider>
              </RuntimeContextProvider>
            </RuntimeContextProvider>
          </RuntimeContextProvider>
        </RuntimeContextProvider>
      </RuntimeContextProvider>
    </RuntimeContextProvider>
  );
}

// #endregion

function createContext<T>(displayName: string, value: T): React.Context<T>;
function createContext<T>(
  displayName: string,
  value?: T | null,
): React.Context<T | null>;

function createContext<T>(displayName: string, value?: T | null) {
  const Context = React.createContext(value ?? null);
  Context.displayName = `Runtime${displayName}Context`;
  return Context;
}

interface RuntimeContextMap {
  componentError: typeof ComponentErrorContext;
  componentInventory: typeof ComponentInventoryContext;
  componentUpdate: typeof ComponentUpdateContext;
  customFonts: typeof CustomFontsContext;
  draftElement: typeof DraftElementContext;
  dynamicDataStore: typeof DynamicDataStoreContext;
  syncRuntimeState: typeof SyncRuntimeStateContext;
  extraContext: typeof ExtraContext;
  featureFlags: typeof FeatureFlagsContext;
  renderEnvironment: typeof RenderEnvironmentContext;
  reploEditorCanvas: typeof ReploEditorCanvasContext;
  reploEditorActiveCanvas: typeof ReploEditorActiveCanvasContext;
  reploElement: typeof ReploElementContext;
  reploSymbols: typeof ReploSymbolsContext;
  shopifyStore: typeof ShopifyStoreContext;
  globalWindow: typeof GlobalWindowContext;
  editorCanvas: typeof EditorCanvasContext;
  runtimeHooks: typeof RuntimeHooksContext;
  designLibrary: typeof DesignLibraryContext;
}

export type RuntimeContextValueMap = {
  [Key in keyof RuntimeContextMap]: RuntimeContextMap[Key] extends React.Context<
    infer V
  >
    ? // TODO (Noah, 2024-07-03, REPL-12467): Simplify the types to make null make sense here
      Key extends "globalWindow" | "editorCanvas"
      ? V
      : NonNullable<V>
    : never;
};

// NOTE (Chance 2024-03-29): This type maps contexts that have a default value
// to a nullable version of the value. If the context value itself might be
// null, it's non-nullable here. This is useful because in some environments,
// certain context objects aren't necessary and we can omit them from the
// provider tree, or render a provider without a value. In those cases the
// default value means that using said context will never result in a runtime
// error, so we can use this type to allow passing `null` in cases where the
// context is not strictly needed.
export type RuntimeContextNullableValueMap = {
  [Key in keyof RuntimeContextMap]: RuntimeContextMap[Key] extends React.Context<
    infer V | null
  >
    ? V
    : RuntimeContextMap[Key] extends React.Context<infer V>
      ? V | null
      : never;
};
