import { analyticsLinkWithValuesSchema } from "schemas/analyticsLink";
import { experimentDetailSchema } from "schemas/experiment";
import { z } from "zod";

export const EDGESERVER_DOMAIN_PROD = "reploedge.com";
export const EDGESERVER_DOMAIN_DEV = "localhost:8788";

export const edgeserverLinkConfigTypeSchema = z
  .enum(["experiment"])
  .describe("EdgeserverLinkConfigType");

const edgeserverLinkConfigExperimentSchema = z.object({
  type: z.literal(edgeserverLinkConfigTypeSchema.enum.experiment),
  data: experimentDetailSchema,
});

export const edgeserverLinkConfigSchema = z
  .discriminatedUnion("type", [edgeserverLinkConfigExperimentSchema])
  .describe("EdgeserverLinkConfig");

export const edgeserverLinkRequestSchema = z
  .object({
    link: analyticsLinkWithValuesSchema,
    linkConfig: edgeserverLinkConfigSchema,
  })
  .describe("EdgeserverLinkRequest");
