import type { DataTableColumnType } from "replo-runtime/shared/DataTable";

import * as React from "react";

import Input from "@common/designSystem/Input";
import { ModalLayout } from "@common/ModalLayout";
import { dataCollectionColumnTypeToEditorData } from "@components/editor/page/data-tables-page/EditorData";
import Selectable from "@editor/components/common/designSystem/Selectable";
import {
  selectDraftDataTable,
  updateDraftDataTable,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { Badge } from "@replo/design-system/components/badge";
import Button from "@replo/design-system/components/button";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { DataTableColumnTypes } from "replo-runtime/shared/DataTable";
import { v4 as uuidv4 } from "uuid";

type Props = {
  onClose: () => void;
};

const NewFieldModal = (props: Props) => {
  const [selectedColumnType, setSelectedColumnType] =
    React.useState<DataTableColumnType | null>(null);
  const [fieldName, setFieldName] = React.useState("New Column");
  const dispatch = useDispatch();
  const dataTable = useEditorSelector(selectDraftDataTable);
  const _renderBackToDataTables = () => (
    <button
      onClick={(e) => {
        e.stopPropagation();
        props.onClose();
      }}
      className="mb-8 self-start text-lg"
    >
      <BiArrowBack className="inline" /> New Field
    </button>
  );

  const _renderFieldName = () => (
    <div className="mb-8 flex flex-col">
      <div className="pb-0.5 text-xs text-slate-400">Field Name</div>
      <div className="flex flex-row" style={{ width: "50%" }}>
        <Input
          value={fieldName}
          placeholder="Field Name"
          onChange={(e) => {
            setFieldName(e.target.value);
          }}
        />
      </div>
    </div>
  );

  const _renderAddFieldButton = () => {
    if (!selectedColumnType || !dataTable) {
      return null;
    }
    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              const newColId = uuidv4();
              dispatch(
                updateDraftDataTable({
                  ...dataTable,
                  data: {
                    ...dataTable.data,
                    schema: [
                      ...dataTable.data.schema,
                      {
                        id: newColId,
                        type: selectedColumnType,
                        name: fieldName,
                      },
                    ],
                    rows: dataTable.data.rows.map((row) => ({
                      ...row,
                      [newColId]:
                        dataCollectionColumnTypeToEditorData[selectedColumnType]
                          .defaultValue,
                    })),
                  },
                }),
              );
              props.onClose();
            }}
            className="self-start rounded-md border-black"
            disabled={!Boolean(selectedColumnType)}
            variant="primary"
          >{`Add ${dataCollectionColumnTypeToEditorData[selectedColumnType].displayName} Field`}</Button>
        </div>
      </div>
    );
  };

  const _renderDataTableList = () => (
    <div
      className="grid-cols-1 overflow-y-auto border-t-2 border-gray-900"
      style={{
        height: 450,
        maxHeight: "40vh",
      }}
    >
      <Selectable
        className="my-2"
        onSelect={(value: DataTableColumnType) => {
          setSelectedColumnType(value);
        }}
        placeholder="Select an option"
        options={DataTableColumnTypes.map((columnType) => {
          return {
            label: (
              <div className="flex items-center px-4">
                <Badge
                  type="icon"
                  icon={dataCollectionColumnTypeToEditorData[columnType].icon}
                  UNSAFE_className="p-1 bg-accent text-slate-50"
                />
                <span className="pl-2">
                  {dataCollectionColumnTypeToEditorData[columnType].displayName}
                </span>
              </div>
            ),
            value: columnType,
            isDisabled: false,
          };
        })}
      />
    </div>
  );

  return (
    <ModalLayout
      height={600}
      mainContent={() => (
        <div className="relative flex flex-col justify-between bg-white w-full">
          <div className="flex flex-col">
            <div className="grid grid-cols-12 gap-4 pt-2">
              <div className="col-span-12">
                {_renderBackToDataTables()}
                {_renderFieldName()}
                {_renderDataTableList()}
              </div>
            </div>
          </div>
        </div>
      )}
      footerContent={() => selectedColumnType && _renderAddFieldButton()}
    />
  );
};

export default NewFieldModal;
