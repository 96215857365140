import type { RightBarTab } from "@editor/reducers/ui-reducer";

import * as React from "react";

import { TextTab } from "@editor/components/common/TextTab";
import {
  selectDraftComponentId,
  selectDraftComponentRightBarTabs,
  selectDraftComponentTypeIsModal,
} from "@editor/reducers/core-reducer";
import {
  selectRightBarActiveTab,
  setRightBarActiveTab,
} from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";

const TabOptions: React.FC<
  React.PropsWithChildren<{
    currentTab: RightBarTab;
    containerClassName?: string;
  }>
> = ({ currentTab, containerClassName }) => {
  const dispatch = useEditorDispatch();
  useResetActiveTab();

  const tabOptions = useEditorSelector(selectDraftComponentRightBarTabs);
  const isCurrentTabVisible = tabOptions?.find(
    (tab) => tab.value === currentTab,
  )?.isVisible;

  // NOTE (Sebas, 2025-01-14): When selecting multiple components we need to
  // check if the current tab is visible, if not, reset the tab to design to
  // avoid showing the wrong tab.
  React.useEffect(() => {
    if (!isCurrentTabVisible) {
      dispatch(setRightBarActiveTab("design"));
    }
  }, [dispatch, isCurrentTabVisible]);

  // NOTE (Martin 2025-02-05): If there's only one tab, don't show the tab
  // options because it's not needed.
  if (!tabOptions || tabOptions.filter((tab) => tab.isVisible).length <= 1) {
    return null;
  }

  return (
    <TextTab
      options={tabOptions}
      selectedValue={currentTab}
      className="py-2"
      containerClassName={containerClassName}
      onChange={(value: RightBarTab) => {
        dispatch(setRightBarActiveTab(value));
      }}
    />
  );
};

/**
 * Hook to reset active tab when the draft component changes
 */
function useResetActiveTab() {
  const isModal = useEditorSelector(selectDraftComponentTypeIsModal);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const dispatch = useEditorDispatch();
  const store = useEditorStore();

  // biome-ignore lint/correctness/useExhaustiveDependencies(draftComponentId): I think we want this? Maybe not
  React.useEffect(() => {
    const state = store.getState();
    const currentTab = selectRightBarActiveTab(state);
    if (
      !(selectDraftComponentRightBarTabs(state) ?? [])
        .filter((tab) => tab.isVisible ?? false)
        .map((tab) => tab.value)
        .includes(currentTab)
    ) {
      // Always set the tab to the design tab when you select a new component
      // unless it's a modal, because it doesn't have a design tab
      dispatch(setRightBarActiveTab(isModal ? "custom" : "design"));
    }
  }, [isModal, dispatch, draftComponentId, store]);
}

export default TabOptions;
