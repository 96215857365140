import type { EditorRootState } from "@editor/store";
import type { ComponentTemplate } from "@editor/types/component-template";
import type {
  ComponentTemplateBadgeId,
  ComponentTemplateIndustryId,
} from "schemas/componentTemplates";
import type { componentTemplatePreviewPropsSchema } from "schemas/element";
import type { ComponentTemplateType } from "schemas/generated/componentTemplates";
import type { SaveTemplateModalProps } from "./AppModalTypes";

import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import Modal from "@common/designSystem/Modal";
import ToggleGroup from "@common/designSystem/ToggleGroup";
import { getDependencyData } from "@editor/actions/core-actions";
import toast from "@editor/components/common/designSystem/Toast";
import useGetDesignLibraryComponentReferences from "@editor/hooks/designLibrary/useGetDesignLibraryComponentReferences";
import { useComponentTemplateCollectionsOptions } from "@editor/hooks/useComponentTemplateCollectionsOptions";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftComponent,
  selectDraftComponentNodeFromActiveCanvas,
  selectDraftParentComponent,
  selectGetAttribute,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { isFullWidthComponent } from "@editor/utils/component-attribute";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import SelectablePopover from "@editorComponents/SelectablePopover";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { BsCaretDownFill } from "react-icons/bs";
import {
  COMPONENT_TEMPLATE_CATEGORIES,
  componentTemplateBadges,
  componentTemplateIndustries,
} from "replo-runtime/shared/componentTemplates";
import { refreshComponentIds } from "replo-shared/refreshComponentIds";
import {
  badgeIdSchema,
  CATEGORIES_IDS,
  componentTemplateTypeSchema,
  environmentSchema,
  industryIdSchema,
} from "schemas/componentTemplates";
import z from "zod";

type SetStateAction<T> = React.Dispatch<React.SetStateAction<T>>;

type StoreComponentFormProps = {
  name: string;
  setName: (value: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isLoading: boolean;
};

type GlobalComponentFormProps = {
  type: ComponentTemplateType;
  name: string;
  setType: SetStateAction<ComponentTemplateType | null>;
  setCategoryId: SetStateAction<string | null>;
  setName: SetStateAction<string>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  categoryId: string;
  collectionId: string | null;
  setCollectionId: SetStateAction<string | null>;
  industryId: ComponentTemplateIndustryId | null;
  setIndustryId: SetStateAction<ComponentTemplateIndustryId | null>;
  badgeId: ComponentTemplateBadgeId | null;
  setBadgeId: SetStateAction<ComponentTemplateBadgeId | null>;
  isLoading: boolean;
  brandSlug: string;
  setBrandSlug: SetStateAction<string>;
};

type SavedPageProps = {
  type: ComponentTemplateType | null;
  setType: SetStateAction<ComponentTemplateType | null>;
  setCategoryId: SetStateAction<string | null>;
};

const createTemplatePreviewProps = (
  state: EditorRootState,
  storeShopifyUrl: string,
): z.infer<typeof componentTemplatePreviewPropsSchema> | null => {
  const publishData = getDependencyData(state);
  const projectId = selectProjectId(state);

  if (publishData.elementId === null || !projectId) {
    return null;
  }

  return {
    ...publishData,
    projectId,
    storeUrl: storeShopifyUrl,
    elementProducts: publishData.products,
    currencyCode: state.commerce.activeCurrency,
    activeLanguage: state.commerce.activeLanguage,
    activeShopifyUrlRoot: state.commerce.shopifyUrlRoot,
    moneyFormat: state.commerce.moneyFormat,
  };
};

export const SaveTemplateModal = (props: SaveTemplateModalProps) => {
  const modal = useModal();
  const [name, setName] = React.useState(props.initialName ?? "");
  const [brandSlug, setBrandSlug] = React.useState("");
  const [scope, setScope] = useLocalStorageState(
    "replo.admin.componentTemplateScope",
    "store",
    { schema: z.enum(["global", "store"]) },
  );
  const [type, setType] = useLocalStorageState(
    "replo.admin.componentTemplateType",
    "section",
    { schema: componentTemplateTypeSchema },
  );
  const [collectionId, setCollectionId] = useLocalStorageState(
    "replo.admin.componentTemplateCollectionId",
    null,
    { schema: z.string().uuid() },
  );
  const [industryId, setIndustryId] = useLocalStorageState(
    "replo.admin.industryId",
    null,
    { schema: industryIdSchema },
  );
  const [badgeId, setBadgeId] = useLocalStorageState(
    "replo.admin.badgeId",
    null,
    { schema: badgeIdSchema },
  );

  const [categoryId, setCategoryId] = useLocalStorageState(
    "replo.admin.componentTemplateCategoryId",
    CATEGORIES_IDS.sections,
    { schema: z.string().uuid() },
  );
  const projectId = useCurrentProjectId();
  const store = useEditorStore();
  const { storeUrl } = useGetStoreNameAndUrl();
  const {
    component: draftComponent,
    node: draftComponentNode,
    isFullWidth,
  } = useDraftComponentWithData();
  const analytics = useLogAnalytics();

  function handleComponentTemplateSuccess(
    componentTemplate: Omit<ComponentTemplate, "id">,
  ) {
    const scopeForAnalytics =
      componentTemplate.scope === "global" && !componentTemplate.collectionId
        ? "unlisted"
        : componentTemplate.scope;

    analytics("editor.componentTemplate.save", {
      scope: scopeForAnalytics,
      type: componentTemplate.type,
      name: componentTemplate.name,
    });

    modal.closeModal({});

    toast({
      header: "Component saved",
      message:
        "Your component has been saved, and you can now reuse it as many times as you need.",
    });
  }

  const { mutateAsync: createComponentTemplate, isPending: isLoadingV1 } =
    trpc.componentTemplates.createComponentTemplate.useMutation({
      onSuccess: (_, variables) => {
        handleComponentTemplateSuccess(variables.componentTemplate);
        void trpcUtils.componentTemplates.find.invalidate();
      },
    });

  const { mutateAsync: createComponentTemplateV2, isPending: isLoadingV2 } =
    trpc.componentTemplates.createComponentTemplateV2.useMutation({
      onSuccess: (_, variables) => {
        handleComponentTemplateSuccess(variables.componentTemplate);
        void trpcUtils.componentTemplates.find.invalidate();
      },
    });

  const isLoading = isLoadingV1 || isLoadingV2;

  const { getDesignLibraryComponentReferences } =
    useGetDesignLibraryComponentReferences();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (draftComponent) {
      const bounds = draftComponentNode?.getBoundingClientRect();
      const generateCreateComponentTemplatePayload = () => {
        const component = refreshComponentIds(draftComponent).component;
        const designLibraryMetadata = getDesignLibraryComponentReferences([
          component.id,
        ]);
        return {
          componentTemplate: {
            name,
            // Note (Evan, 2024-07-02): When creating a saved component
            // (scope is "store"), we want to pass "component", since the
            // type in localStorage only applies to global components.
            type: scope === "store" ? "component" : type,
            scope,
            env: environmentSchema.parse("production"),
            storeId: projectId ?? "",
            template: component,
            metadata:
              !isFullWidth && bounds?.width
                ? { originalWidth: bounds.width }
                : {},
            categoryId,
            collectionId,
            brandSlug,
            industryId,
            badgeId,
            designLibraryMetadata,
          },
          storeId: projectId ?? "",
        };
      };

      if (storeUrl && isFeatureEnabled("component-template-screenshot-v2")) {
        const previewData = createTemplatePreviewProps(
          store.getState(),
          storeUrl ?? "",
        );
        if (previewData) {
          await createComponentTemplateV2({
            ...generateCreateComponentTemplatePayload(),
            previewRenderProps: previewData,
          });
        }
      } else {
        await createComponentTemplate(generateCreateComponentTemplatePayload());
      }
    }
  };

  const TOGGLE_OPTIONS = [
    {
      label: "Save to Project",
      value: "store",
    },
    {
      label: "Share With Others",
      value: "global",
    },
  ] as const;

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => modal.closeModal({ type: "saveTemplateModal" })}
      className="w-96"
    >
      <h1 className="mt-5 text-sm">
        {scope === "store" ? "New Saved Component" : "New Shared Template"}
      </h1>
      <div className="my-2">
        <ToggleGroup
          allowsDeselect={false}
          type="single"
          options={TOGGLE_OPTIONS}
          value={scope}
          onChange={(scope) => {
            setScope(scope);
          }}
          style={{ width: "100%" }}
          size="lg"
        />
      </div>
      {scope === "store" && (
        <StoreComponentForm
          name={name}
          onSubmit={async (e) => await onSubmit(e)}
          setName={setName}
          isLoading={isLoading}
        />
      )}
      {scope === "global" && (
        <GlobalComponentForm
          type={type}
          name={name}
          setType={setType}
          setCategoryId={setCategoryId}
          setName={setName}
          onSubmit={async (e) => await onSubmit(e)}
          categoryId={categoryId}
          collectionId={collectionId}
          setCollectionId={setCollectionId}
          industryId={industryId}
          setIndustryId={setIndustryId}
          badgeId={badgeId}
          setBadgeId={setBadgeId}
          isLoading={isLoading}
          brandSlug={brandSlug}
          setBrandSlug={setBrandSlug}
        />
      )}
    </Modal>
  );
};

const InputLabel: React.FC<{
  label: string;
  selectedValue: string | undefined;
}> = ({ label, selectedValue }) => {
  return (
    <span className="py-1 text-sm font-medium text-default flex justify-between w-full items-center">
      {selectedValue ? selectedValue : label}
    </span>
  );
};

function useDraftComponentWithData() {
  const component = useEditorSelector(selectDraftComponent);
  const parent = useEditorSelector(selectDraftParentComponent);
  const getAttribute = useEditorSelector(selectGetAttribute);
  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );

  return {
    component,
    node: draftComponentNode,
    isFullWidth: isFullWidthComponent(component, getAttribute, parent),
  };
}

const SavedPage = ({ type, setType, setCategoryId }: SavedPageProps) => {
  const handleClick = () => {
    setType("page");
    setCategoryId(CATEGORIES_IDS.landingPage);
  };
  return (
    <div
      className="flex shrink grow basis-0 cursor-pointer flex-col items-center justify-center gap-1"
      onClick={handleClick}
    >
      <div
        className={classNames(
          "flex h-24 items-end justify-center self-stretch rounded bg-slate-50",
          { "border-2 border-blue-600": type === "page" },
        )}
      >
        <div
          className={classNames("h-20 w-24 rounded-tl rounded-tr", {
            "bg-blue-600": type === "page",
            "bg-slate-200": type !== "page",
          })}
        />
      </div>
      <div
        className={classNames(
          "self-stretch text-center text-sm font-medium leading-none",
          {
            "text-blue-600": type === "page",
            "text-slate-400": type !== "page",
          },
        )}
      >
        Saved Page
      </div>
    </div>
  );
};

const SavedComponent = ({ type, setType, setCategoryId }: SavedPageProps) => {
  const handleClick = () => {
    setType("section");
    setCategoryId(CATEGORIES_IDS.sections);
  };

  return (
    <div
      className="flex shrink grow basis-0 cursor-pointer flex-col items-center justify-center gap-1"
      onClick={handleClick}
    >
      <div
        className={classNames(
          "flex h-24 items-center justify-center self-stretch rounded bg-slate-50",
          { "border-2 border-blue-600": type === "section" },
        )}
      >
        <div
          className={classNames("h-12 w-24 rounded", {
            "bg-blue-600": type === "section",
            "bg-slate-200": type !== "section",
          })}
        />
      </div>
      <div
        className={classNames(
          "self-stretch text-center text-sm font-medium leading-none",
          {
            "text-blue-600": type === "section",
            "text-slate-400": type !== "section",
          },
        )}
      >
        Saved Section
      </div>
    </div>
  );
};

const StoreComponentForm: React.FC<StoreComponentFormProps> = ({
  name,
  setName,
  onSubmit,
  isLoading,
}) => (
  <form onSubmit={(e) => void onSubmit(e)} className="flex flex-col gap-4">
    <LabeledControl label="Saved Component Name" id="save-template-name">
      <Input
        autoFocus={true}
        value={name}
        id="save-template-name"
        data-testid="template-name-input"
        onChange={(e) => {
          setName(e.target.value);
        }}
        autoComplete="off"
        size="base"
      />
    </LabeledControl>
    <div className=" flex justify-end">
      <Button
        variant="primary"
        type="submit"
        disabled={name.trim().length === 0}
        data-testid="create-template-button"
        size="base"
        className="w-44 py-2 text-sm"
        isLoading={isLoading}
      >
        Create Component
      </Button>
    </div>
  </form>
);

const GlobalComponentForm: React.FC<GlobalComponentFormProps> = ({
  type,
  setType,
  setCategoryId,
  name,
  setName,
  onSubmit,
  categoryId,
  collectionId,
  setCollectionId,
  industryId,
  setIndustryId,
  badgeId,
  setBadgeId,
  isLoading,
  brandSlug,
  setBrandSlug,
}) => {
  const { user } = useCurrentUser();
  const isSuperuser = user?.isSuperuser;
  const { options: collectionsOptions } =
    useComponentTemplateCollectionsOptions();

  const collectionsOptionsWithNoCollection = [
    {
      label: "No Collection",
      value: null,
    },
    ...collectionsOptions,
  ];
  const collectionSelectedItem = collectionsOptionsWithNoCollection.find(
    (option) => option.value === collectionId,
  );
  const industrySelectedItem = componentTemplateIndustries.find(
    (option) => option.id === industryId,
  );
  const badgesSelectedItem = componentTemplateBadges.find(
    (option) => option.id === badgeId,
  );

  // NOTE (Fran, 2023-04-27): We want to show the category options for the correct type
  // here we don't have a mapping to ElementType so for that we assume that if the type is not
  // page we will show categories for sections, because if we save a template as other than page
  // it will show when the element type is shopifySection
  const categoriesFiltered = COMPONENT_TEMPLATE_CATEGORIES.filter(
    (category) =>
      (type === "page" && category.componentTemplateCategoryType === "page") ||
      (type !== "page" && category.componentTemplateCategoryType === "section"),
  );
  const categoryOptions = categoriesFiltered.map((category) => {
    return {
      label: category.name,
      value: category.id,
      isSelectable: true,
    };
  });
  const categorySelectedItem = categoryOptions.find(
    (option) => option.value === categoryId,
  );

  return (
    <form
      onSubmit={(e) => void onSubmit(e)}
      className="mt-4 flex flex-col gap-4"
    >
      <div className="flex h-28 w-full items-start justify-start gap-4">
        <SavedComponent
          type={type}
          setType={setType}
          setCategoryId={setCategoryId}
        />
        <SavedPage
          type={type}
          setType={setType}
          setCategoryId={setCategoryId}
        />
      </div>
      <LabeledControl label="Saved Component Name" id="save-template-name">
        <Input
          autoFocus={true}
          value={name}
          id="save-template-name"
          unsafe_className="h-9 w-full"
          onChange={(e) => {
            setName(e.target.value);
          }}
          autoComplete="off"
          unsafe_inputClassName="text-sm font-medium text-default"
        />
      </LabeledControl>
      <LabeledControl label="Category" id="save-template-category">
        <SelectablePopover
          title="Category"
          itemSize={36}
          itemsOnViewCount={5}
          options={categoryOptions}
          onSelect={(categoryId: string) => {
            setCategoryId(categoryId as "string");
          }}
          selectedItems={
            categorySelectedItem ? [categorySelectedItem.value] : []
          }
          popoverSide="bottom"
          fromYTransform={0}
          popoverContentStyle={{ width: "352px" }}
          labelClassName="text-sm"
          noItemsPlaceholder="No Categories Found"
          shouldCloseOnSelect
          triggerEndEnhancer={
            <BsCaretDownFill size={10} className="text-slate-400" />
          }
          isRemovable={false}
          titleClassnames="text-sm"
          hideClosePopoverButton
          selectFirstSearchOptionOnInputEnter
        >
          <InputLabel
            label="Select a Category"
            selectedValue={categorySelectedItem?.label}
          />
        </SelectablePopover>
      </LabeledControl>

      {isSuperuser && collectionsOptions.length > 0 && (
        <>
          <LabeledControl
            label={
              <div className="flex flex-row gap-x-2">
                <span>Collection</span>
                <span className="pl-1 font-medium text-red-700">
                  Admin Only
                </span>
              </div>
            }
            id="save-template-collection"
          >
            <SelectablePopover
              title="Collection"
              itemSize={36}
              itemsOnViewCount={5}
              options={collectionsOptionsWithNoCollection}
              onSelect={(collectionId: string) => {
                setCollectionId(collectionId);
              }}
              selectedItems={
                collectionSelectedItem ? [collectionSelectedItem.value] : []
              }
              popoverSide="bottom"
              fromYTransform={0}
              popoverContentStyle={{ width: "352px" }}
              labelClassName="text-sm"
              noItemsPlaceholder="No Collections Found"
              shouldCloseOnSelect
              triggerEndEnhancer={
                <BsCaretDownFill size={10} className="text-slate-400" />
              }
              isRemovable={false}
              titleClassnames="text-sm"
              hideClosePopoverButton
              selectFirstSearchOptionOnInputEnter
            >
              <InputLabel
                label="Select a Collection"
                selectedValue={collectionSelectedItem?.label}
              />
            </SelectablePopover>
          </LabeledControl>
          <LabeledControl
            label={
              <div className="flex flex-row gap-x-2">
                <span>Industry</span>
                <span className="pl-1 font-medium text-red-700">
                  Admin Only
                </span>
              </div>
            }
            id="save-template-industry"
          >
            <SelectablePopover
              title="Industry"
              itemSize={36}
              itemsOnViewCount={5}
              options={componentTemplateIndustries.map(({ id, name }) => ({
                label: name,
                value: id,
                isSelectable: true,
              }))}
              onSelect={(industryId: ComponentTemplateIndustryId | null) => {
                setIndustryId(industryId);
              }}
              selectedItems={
                industrySelectedItem ? [industrySelectedItem?.id] : []
              }
              popoverSide="bottom"
              fromYTransform={0}
              popoverContentStyle={{ width: "352px" }}
              labelClassName="text-sm"
              noItemsPlaceholder="No Industry Found"
              shouldCloseOnSelect
              triggerEndEnhancer={
                <BsCaretDownFill size={10} className="text-slate-400" />
              }
              isRemovable
              titleClassnames="text-sm"
              hideClosePopoverButton
            >
              <InputLabel
                label="Select a Industry"
                selectedValue={industrySelectedItem?.name}
              />
            </SelectablePopover>
          </LabeledControl>
          <LabeledControl
            label={
              <div className="flex flex-row gap-x-2">
                <span>Badge</span>
                <span className="pl-1 font-medium text-red-700">
                  Admin Only
                </span>
              </div>
            }
            id="save-template-badge"
          >
            <SelectablePopover
              title="Badge"
              itemSize={36}
              itemsOnViewCount={5}
              options={componentTemplateBadges.map(({ id, name }) => ({
                label: name,
                value: id,
                isSelectable: true,
              }))}
              onSelect={(badgeId: ComponentTemplateBadgeId | null) => {
                setBadgeId(badgeId);
              }}
              selectedItems={badgesSelectedItem ? [badgesSelectedItem?.id] : []}
              popoverSide="bottom"
              fromYTransform={0}
              popoverContentStyle={{ width: "352px" }}
              labelClassName="text-sm"
              noItemsPlaceholder="No Badge Found"
              shouldCloseOnSelect
              triggerEndEnhancer={
                <BsCaretDownFill size={10} className="text-slate-400" />
              }
              isRemovable
              titleClassnames="text-sm"
              hideClosePopoverButton
            >
              <InputLabel
                label="Select a Badge"
                selectedValue={badgesSelectedItem?.name}
              />
            </SelectablePopover>
          </LabeledControl>
          <LabeledControl
            label={
              <div className="flex flex-row gap-x-2">
                <span>Brand Slug</span>
                <span className="pl-1 font-medium text-red-700">
                  Admin Only
                </span>
              </div>
            }
            id="brand-slug"
          >
            <Input
              value={brandSlug}
              id="brand-slug"
              unsafe_className="h-9 w-full"
              onChange={(e) => {
                setBrandSlug(e.target.value);
              }}
              autoComplete="off"
              unsafe_inputClassName="text-sm font-medium text-default"
            />
          </LabeledControl>
        </>
      )}
      <div className="mt-4 flex justify-end gap-3">
        <Button
          variant="primary"
          type="submit"
          disabled={name.trim().length === 0}
          size="base"
          className="w-44 py-2 text-sm"
          isLoading={isLoading}
        >
          Create Component
        </Button>
      </div>
    </form>
  );
};
