import type { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

import * as React from "react";

import { DebouncedInput } from "@editor/components/common/designSystem/Input";
import Separator from "@editor/components/common/designSystem/Separator";
import { Loader } from "@editor/components/common/Loader";
import { TextTab } from "@editor/components/common/TextTab";
import { InfiniteLoadingGrid } from "@editor/components/InfiniteLoadingGrid";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import useDynamicCSSOverflow from "@editor/hooks/useDynamicCSSOverflow";
import {
  selectProjectHasShopifyIntegration,
  selectProjectId,
  selectStoreDoesNotHaveReadFilesAccess,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import SvgAssetsEmptyState from "@svg/empty-assets";

import IconButton from "@replo/design-system/components/button/IconButton";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken } from "@tanstack/react-query";
import { BsSearch } from "react-icons/bs";
import { LuUpload } from "react-icons/lu";
import { isEmpty } from "replo-utils/lib/misc";

import useFileDropZone from "../useFileDropZone";
import AssetCard from "./AssetCard";
import ShopifyAssetCard from "./ShopifyAssetCard";

type ActiveTab = "replo" | "shopify";

const AssetsPane = () => {
  const hasShopifyIntegration = useEditorSelector(
    selectProjectHasShopifyIntegration,
  );
  const [activeTab, setActiveTab] = React.useState<ActiveTab>("replo");
  // TODO (Sebas, 2025-01-31, REPL-15384): Implement search functionality.
  const [searchTerm, setSearchTerm] = React.useState("");
  const { getRootProps, getInputProps, openFileDialog } = useFileDropZone({
    sourceType: "files",
    onUploadComplete: () => {
      void trpcUtils.asset.get.invalidate();
    },
    acceptDropAssetType: [
      ".png",
      ".jpg",
      ".jpeg",
      ".webp",
      ".svg",
      ".gif",
      ".mp4",
    ],
    allowClickToUpload: false,
    customValidator: sizeValidator,
  });
  const inputProps = getInputProps();

  // const { elementRef, overflowClassname } = useDynamicCSSOverflow();

  const tabOptions: { label: string; value: ActiveTab }[] = [
    {
      label: "Replo",
      value: "replo",
    },
    {
      label: "Shopify",
      value: "shopify",
    },
  ];

  return (
    <AssetsSearchContext.Provider value={{ searchTerm, setSearchTerm }}>
      <div className="flex flex-col h-full pl-3 gap-3">
        {hasShopifyIntegration && (
          <div className="pr-3">
            <TextTab<ActiveTab>
              options={tabOptions}
              selectedValue={activeTab}
              onChange={setActiveTab}
            />
          </div>
        )}
        <div className="flex gap-2 pr-3">
          <DebouncedInput
            autoComplete="off"
            placeholder="Search"
            value={searchTerm}
            onValueChange={setSearchTerm}
            startEnhancer={<BsSearch />}
          />
          {activeTab === "replo" && (
            <IconButton
              className="h-6 w-6"
              icon={<LuUpload />}
              variant="secondary"
              onClick={openFileDialog}
            />
          )}
        </div>
        <div className="pr-3">
          <Separator />
        </div>
        <div className="flex-1 min-h-0">
          {activeTab === "replo" ? (
            <div {...getRootProps({ className: "h-full" })}>
              <input {...inputProps} />
              <ReploAssets
                inputProps={inputProps}
                getRootProps={getRootProps}
              />
            </div>
          ) : (
            <ShopifyAssets />
          )}
        </div>
      </div>
    </AssetsSearchContext.Provider>
  );
};

const ReploAssets: React.FC<{
  inputProps: DropzoneInputProps;
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
}> = ({ inputProps, getRootProps }) => {
  const workspaceId = useCurrentWorkspaceId();
  const { searchTerm } = useAssetsSearch();
  const { data, isLoading, fetchNextPage, hasNextPage } =
    trpc.asset.get.useInfiniteQuery(
      workspaceId
        ? {
            workspaceId,
            types: ["image", "video"],
            limit: 15,
            search: searchTerm,
          }
        : skipToken,
      {
        initialCursor: 0,
        getNextPageParam: ({ nextCursor }) => nextCursor,
      },
    );

  const reploAssets = data?.pages?.flatMap((page) => page.assets) ?? [];

  const { elementRef, overflowClassname } = useDynamicCSSOverflow();
  const { currentDragIdentifier } = useCurrentDragType();
  const isDragging = currentDragIdentifier !== null;

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center flex-1">
        <Loader className="w-10 h-10" />
      </div>
    );
  }

  if (!reploAssets || isEmpty(reploAssets)) {
    return <ReploAssetEmptyState />;
  }

  return (
    <div
      id="assets-pane-grid"
      {...getRootProps({
        className: twMerge(
          "flex pr-1 styled-scrollbar h-full",
          overflowClassname,
        ),
      })}
      ref={elementRef}
    >
      <input {...inputProps} />
      <InfiniteLoadingGrid
        listLength={reploAssets.length}
        hasNextPage={hasNextPage}
        loadMoreItems={() => void fetchNextPage()}
        scrollableTargetId="assets-pane-grid"
        style={{ overflow: isDragging ? "visible" : "hidden" }}
      >
        <div className="grid grid-cols-2 gap-2 pb-3">
          {reploAssets.map((asset) => (
            <AssetCard key={asset.id} asset={asset} />
          ))}
        </div>
      </InfiniteLoadingGrid>
    </div>
  );
};

const ShopifyAssets: React.FC = () => {
  const { searchTerm } = useAssetsSearch();
  // TODO (Fran 2025-01-29 REPL-15762): Implement get all assets videos and images form shopify
  const projectId = useEditorSelector(selectProjectId);
  const doesNotHaveReadFilesAccess = useEditorSelector(
    selectStoreDoesNotHaveReadFilesAccess,
  );

  const {
    data,
    isLoading: findAssetsIsLoading,
    fetchNextPage,
    hasNextPage,
  } = trpc.shopify.libraryAssets.find.useInfiniteQuery(
    !projectId || doesNotHaveReadFilesAccess
      ? skipToken
      : {
          projectId,
          contentType: ["image", "video"],
          sourceType: "files",
          pageSize: 15,
          searchText: searchTerm,
        },
    {
      getNextPageParam: ({ pageInfo }) =>
        pageInfo?.hasNextPage ? pageInfo.nextPage : undefined,
    },
  );

  const { elementRef, overflowClassname } = useDynamicCSSOverflow();
  const { currentDragIdentifier } = useCurrentDragType();
  const isDragging = currentDragIdentifier !== null;

  if (findAssetsIsLoading) {
    return (
      <div className="flex flex-col items-center justify-center flex-1">
        <Loader className="w-10 h-10" />
      </div>
    );
  }

  const shopifyAssets = data?.pages?.flatMap((page) => page.assets) ?? [];

  if (!shopifyAssets || isEmpty(shopifyAssets)) {
    return <ShopifyAssetEmptyState />;
  }

  return (
    <div
      id="assets-pane-grid"
      className={twMerge(
        "flex pr-1 styled-scrollbar h-full",
        overflowClassname,
      )}
      ref={elementRef}
    >
      <InfiniteLoadingGrid
        listLength={shopifyAssets.length}
        hasNextPage={hasNextPage}
        loadMoreItems={() => void fetchNextPage()}
        style={{ overflow: isDragging ? "visible" : "hidden" }}
        scrollableTargetId="assets-pane-grid"
      >
        <div className="grid grid-cols-2 gap-2 pb-3">
          {shopifyAssets.map((asset) => (
            <ShopifyAssetCard key={asset.id} asset={asset} />
          ))}
        </div>
      </InfiniteLoadingGrid>
    </div>
  );
};

const ShopifyAssetEmptyState: React.FC = () => {
  return (
    <div className="flex flex-col items-center gap-2 text-default text-center pr-3 mt-6">
      <SvgAssetsEmptyState />
      <div className="typ-header-small">No Shopify assets found</div>
      <div className="typ-body-small">
        View your Shopify Files here, or upload through Replo for faster load
        times (recommended).
      </div>
    </div>
  );
};

const ReploAssetEmptyState: React.FC = () => {
  const { getRootProps, getInputProps } = useFileDropZone({
    sourceType: "files",
    onUploadComplete: () => {
      void trpcUtils.asset.get.invalidate();
    },
    acceptDropAssetType: [
      ".png",
      ".jpg",
      ".jpeg",
      ".webp",
      ".svg",
      ".gif",
      ".mp4",
    ],
    allowClickToUpload: true,
    customValidator: sizeValidator,
  });
  const inputProps = getInputProps();

  return (
    <div
      {...getRootProps({
        className: "flex h-full pr-3",
      })}
    >
      <input {...inputProps} />
      <div className="flex flex-col gap-3 justify-center items-center bg-hover h-52 rounded border border-dashed border-border p-5">
        <SvgAssetsEmptyState />
        <div className="text-center typ-body-small text-muted">
          <span className="typ-header-small">Click to upload </span>
          or drag and drop PNG, JPG, WEBP, SVG, GIF, or MP4
        </div>
      </div>
    </div>
  );
};

const AssetsSearchContext = React.createContext<{
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}>({
  searchTerm: "",
  setSearchTerm: () => {},
});

const useAssetsSearch = () => {
  return React.useContext(AssetsSearchContext);
};

const MAX_IMAGE_SIZE_BYTES = 20 * 1024 * 1024; // 20 MB in bytes
const MAX_VIDEO_SIZE_BYTES = 250 * 1024 * 1024; // 250 MB in bytes

const sizeValidator = (file: File) => {
  const isImage = file.type.startsWith("image/");
  const isVideo = file.type.startsWith("video/");

  if (isImage && file.size > MAX_IMAGE_SIZE_BYTES) {
    return {
      code: "replo-file-too-large",
      message: `Image files must be less than ${MAX_IMAGE_SIZE_BYTES / (1024 * 1024)}MB.`,
    };
  }

  if (isVideo && file.size > MAX_VIDEO_SIZE_BYTES) {
    return {
      code: "replo-file-too-large",
      message: `Video files must be less than ${MAX_VIDEO_SIZE_BYTES / (1024 * 1024)}MB.`,
    };
  }

  return null;
};

export default AssetsPane;
