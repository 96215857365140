import * as React from "react";

import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trackError } from "@editor/infra/analytics";
import { trpc } from "@editor/utils/trpc";

import { slugify } from "replo-utils/lib/string";
import { ReploError } from "schemas/errors";
import { v4 as uuidv4 } from "uuid";

import { useCloudflareUpload } from "./useCloudflareUpload";

class ImportAssetError extends ReploError {}

export const useUploadAsset = () => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const uploadToCloudflare = useCloudflareUpload();
  const workspaceId = useCurrentWorkspaceId();
  const { mutateAsync: createAsset } = trpc.asset.create.useMutation({
    onSuccess: () => {
      setIsUploading(false);
    },
    onError: () => {
      setIsError(true);
    },
  });

  const uploadAsset = React.useCallback(
    async (asset: File, folderId?: string): Promise<{ url: string } | null> => {
      setIsUploading(true);
      if (!asset || !workspaceId) {
        setIsUploading(false);
        return null;
      }
      const assetId = uuidv4();
      const assetName = slugify(asset.name.split(".")[0] ?? ""); // Remove file extension
      const fileType = asset.type.split("/")[0];

      if (fileType !== "image" && fileType !== "video") {
        setIsUploading(false);
        return null;
      }

      const response = await uploadToCloudflare(
        asset,
        assetId, // Name of the asset in the cloudflare bucket
        workspaceId,
      );

      if (response && "data" in response) {
        const date = new Date();
        try {
          const { width, height } = await getMediaDimensions(asset);
          await createAsset({
            projectId: workspaceId,
            asset: {
              id: assetId,
              url: response.data.url,
              name: assetName,
              type: fileType,
              sizeBytes: asset.size,
              fileExtension: asset.name.split(".").at(-1) ?? "",
              width: width ?? null,
              height: height ?? null,
              folderId: folderId ?? null,
              workspaceId: workspaceId,
              createdAt: date,
              updatedAt: date,
            },
          });
        } catch {
          setIsError(true);
        }

        setIsUploading(false);
        return { url: response.data.url };
      }

      setIsUploading(false);
      trackError(
        new ImportAssetError({
          message: `Failed to upload asset "${assetName}"`,
          additionalData: {
            error: response?.error,
          },
        }),
      );
      return null;
    },
    [createAsset, uploadToCloudflare, workspaceId],
  );

  return { uploadAsset, isUploading, isError };
};

const getMediaDimensions = (
  file: File,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const fileType = file.type.split("/")[0];

    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      if (fileType === "image") {
        const img = new Image();
        img.addEventListener("load", () => {
          resolve({ width: img.width, height: img.height });
        });
        img.addEventListener("error", reject);
        img.src = event.target?.result as string;
      } else if (fileType === "video") {
        const video = document.createElement("video");
        video.addEventListener("loadedmetadata", () => {
          resolve({ width: video.videoWidth, height: video.videoHeight });
        });
        video.addEventListener("error", reject);
        video.src = event.target?.result as string;
      } else {
        reject(new Error("Unsupported file type"));
      }
    });
    reader.addEventListener("error", reject);
    reader.readAsDataURL(file);
  });
};
