import type { Option } from "@replo/design-system/components/combobox/types";

import * as React from "react";

import {
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@replo/design-system/components/shadcn/core/command";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import groupBy from "lodash-es/groupBy";
import { BsCheckLg, BsSearch } from "react-icons/bs";
import { VariableSizeList as List } from "react-window";

import Tooltip from "../../tooltip";
import { DEFAULT_ITEMS_ON_VIEW_COUNT } from "../ComboboxConstants";
import { setHoveredLabel } from "../ComboboxHoverStore";
import { useCombobox } from "../hooks/useCombobox";

type OptionsListProps = {
  options: Option[];
  itemsOnViewCount?: number;
  title?: string;
  setValue: (value: string) => void;
  setOpen?: (value: boolean) => void;
  value: string;
  areOptionsSearchable?: boolean;
  input?: string;
  setInput?: (value: string) => void;
  inputPlaceholder?: string;
  isLoading?: boolean;
  itemSize?: number;
  emptySearchMessage?: string;
  size?: "sm" | "base";
};

// NOTE (Jackson, 2024-12-03): These values are used to calculate the height of the virtualized list
const ITEM_GAP = 2;

const getHeaderHeight = (size: "sm" | "base") => (size === "sm" ? 32 : 36);

const getItemHeight = (size: "sm" | "base" = "base", itemSize?: number) => {
  if (itemSize) {
    return itemSize;
  }
  if (size === "sm") {
    return 24;
  }
  return 28;
};

const getVirtualizedListHeight = (
  virtualizedItemSize: number,
  virtualizedItems: VirtualizedItem[],
  size: "sm" | "base",
  itemsOnViewCount: number,
): number => {
  const headerCount = virtualizedItems.filter(
    (item) => item.type === "header",
  ).length;

  const headerHeight = getHeaderHeight(size);
  const calculatedHeight =
    headerCount * headerHeight + itemsOnViewCount * virtualizedItemSize;

  return calculatedHeight;
};

const useSearchableOptions = (
  options: Option[],
  searchValue: string,
  isControlledSearch: boolean,
) => {
  return React.useMemo(() => {
    if (!searchValue || isControlledSearch) {
      return options;
    }

    return options.filter((option) =>
      String(option.label)
        .toLowerCase()
        .includes(searchValue.toLowerCase().trim()),
    );
  }, [options, searchValue, isControlledSearch]);
};

type CommandItemProps = Pick<
  OptionsListProps,
  "value" | "setValue" | "setOpen" | "size"
>;

type GroupedOptions = {
  options: Option[];
  title?: string;
}[];

const groupOptions = (options: Option[]): GroupedOptions => {
  // NOTE (Jackson, 2024-11-27): Group options by their groupTitle. Ordering is essentially handled by
  // the order in which groupTitles are found - we could make this more explicit if needed.
  const grouped = groupBy(options, "groupTitle");

  return Object.entries(grouped).map(([title, options]) => ({
    title: title === "undefined" ? undefined : title,
    options,
  }));
};

type VirtualizedItem =
  | { type: "header"; title: string }
  | { type: "item"; option: Option };

const flattenOptions = (groupedOptions: GroupedOptions): VirtualizedItem[] => {
  return groupedOptions.flatMap((group) => [
    ...(group.title ? [{ type: "header" as const, title: group.title }] : []),
    ...group.options.map((option) => ({ type: "item" as const, option })),
  ]);
};

const CommandOptionItem: React.FC<CommandItemProps & { option: Option }> = ({
  value,
  setValue,
  setOpen,
  option,
  size,
}) => {
  const { previewOnHover, open } = useCombobox();
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  React.useEffect(() => {
    const checkOverflow = () => {
      if (open) {
        // NOTE (Patrick, 2025-01-07): This setTimeout is to allow the popover to render. Once the popover renders,
        // we can check if a menu item is overflowing or not.
        setTimeout(() => {
          if (contentRef.current) {
            const isOverflowing =
              contentRef.current.scrollWidth > contentRef.current.clientWidth;

            setIsOverflowing(isOverflowing);
          }
        }, 100);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow); // Recheck on window resize
    return () => window.removeEventListener("resize", checkOverflow);
  }, [open]);

  const handleMouseEnter = () => {
    if (previewOnHover) {
      setHoveredLabel(option.label);
    }
  };

  return (
    <CommandItem
      key={option.value}
      value={option.value ?? ""}
      disabled={option.isDisabled}
      variant={size}
      onMouseEnter={handleMouseEnter}
      /**
       * NOTE (Max, 2024-09-17): If a component is provided, it means that clicking on the option should not
       * update the value: instead, the provided component will be responsible for updating the value (e.g. with a
       * date-picker).
       */
      onSelect={(currentValue) => {
        if (option.onClick) {
          option.onClick();
        } else {
          setValue(currentValue);
          if (setOpen) {
            setOpen(false);
          }
        }
      }}
      className={classNames(
        "px-0 py-0 text-default font-normal cursor-pointer rounded",
        {
          "hover:bg-slate-50": value !== option.value,
          "bg-blue-100": value === option.value,
        },
      )}
    >
      <Tooltip
        content={isOverflowing && (option.component ?? option.label)}
        triggerAsChild
        isFullWidth
      >
        <div className="px-1.5 py-1 w-full flex items-center justify-between">
          <div ref={contentRef} className="flex-1 truncate">
            {option.component ?? option.label}
          </div>
          {value === option.value && (
            <BsCheckLg
              className={twMerge(
                "text-default",
                size === "sm" ? "size-4" : "size-5",
              )}
            />
          )}
        </div>
      </Tooltip>
    </CommandItem>
  );
};

const getItemSize = (
  index: number,
  items: VirtualizedItem[],
  size: "sm" | "base",
  itemSize?: number,
): number => {
  const item = items[index];
  if (!item) {
    return 0;
  }
  return item.type === "header"
    ? getHeaderHeight(size)
    : getItemHeight(size, itemSize) + ITEM_GAP;
};

const OptionsList: React.FC<OptionsListProps> = ({
  options,
  itemsOnViewCount = DEFAULT_ITEMS_ON_VIEW_COUNT,
  title,
  setValue,
  setOpen,
  value,
  areOptionsSearchable = false,
  input,
  setInput,
  inputPlaceholder,
  isLoading,
  emptySearchMessage = "No results",
  itemSize,
}) => {
  const { isUsingTriggerInput } = useCombobox();

  const filteredOptions = useSearchableOptions(
    options,
    input ?? "",
    /**
     * NOTE (Max, 2025-01-04): If the trigger is an input, then by definition,
     * the options are already searchable (from the ComboboxTrigger). It wouldn't
     * make sense to add another filter mechanism in the OptionsList.
     */
    !isUsingTriggerInput && !areOptionsSearchable,
  );

  // Calculate effective items count based on filtered options
  const effectiveItemsCount = React.useMemo(() => {
    if (itemsOnViewCount !== DEFAULT_ITEMS_ON_VIEW_COUNT) {
      // If explicitly set, use that value
      return itemsOnViewCount;
    }
    return Math.min(filteredOptions.length, DEFAULT_ITEMS_ON_VIEW_COUNT);
  }, [filteredOptions.length, itemsOnViewCount]);

  const grouped = groupOptions(filteredOptions);
  const [flattenedItems] = [flattenOptions(grouped)];

  const hasHeaders = React.useMemo(
    () => flattenedItems.some((item) => item.type === "header"),
    [flattenedItems],
  );

  const listRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, []);

  const { size } = useCombobox();
  const { previewOnHover } = useCombobox();

  const handleMouseLeave = () => {
    if (previewOnHover) {
      setHoveredLabel(null);
    }
  };

  return (
    <>
      <div
        className={twMerge(
          "flex flex-col",
          areOptionsSearchable && "border-b border-slate-200",
          (areOptionsSearchable || title) && "gap-2 p-3",
        )}
      >
        {title && (
          <div
            className={twMerge(
              "font-semibold text-default",
              size === "sm" ? "text-xs" : "text-sm",
            )}
          >
            {title}
          </div>
        )}
        {areOptionsSearchable && (
          <div>
            <CommandInput
              value={input}
              onValueChange={setInput}
              placeholder={inputPlaceholder ?? "Search..."}
              startEnhancer={
                <BsSearch
                  className={twMerge(
                    "text-slate-400",
                    size === "sm" ? "h-2.5 w-2.5" : "h-3 w-3",
                  )}
                />
              }
              variant={size}
              className="placeholder:font-normal"
            />
          </div>
        )}
      </div>

      <CommandList onMouseLeave={handleMouseLeave}>
        {filteredOptions.length === 0 ? (
          <CommandEmpty>
            {isLoading ? "Loading..." : emptySearchMessage}
          </CommandEmpty>
        ) : (
          <CommandGroup className={hasHeaders ? "p-0" : ""}>
            <List
              ref={listRef}
              height={getVirtualizedListHeight(
                getItemHeight(size) + ITEM_GAP,
                flattenedItems,
                size,
                effectiveItemsCount,
              )}
              width="100%"
              itemCount={flattenedItems.length}
              itemSize={(index) =>
                getItemSize(index, flattenedItems, size, itemSize)
              }
              key={flattenedItems.length}
            >
              {({ index, style }) => {
                const item = flattenedItems[index];
                if (!item) {
                  return null;
                }

                if (item.type === "header") {
                  return (
                    <div
                      style={{ ...style, height: getHeaderHeight(size) }}
                      className="flex flex-col justify-end text-default"
                    >
                      {index > 0 && !areOptionsSearchable && (
                        <div className="border-t border-slate-200 my-0"></div>
                      )}
                      <div
                        className={twMerge(
                          "flex items-center gap-2 px-3 pt-2 pb-1.5 font-semibold",
                          size === "sm" ? "text-xs" : "text-sm",
                        )}
                      >
                        {item.title}
                      </div>
                    </div>
                  );
                }

                return (
                  <div
                    style={{
                      ...style,
                      height: 24,
                      paddingBottom: ITEM_GAP,
                    }}
                    className={classNames(
                      hasHeaders && (size === "sm" ? "px-2.5" : "px-3"),
                    )}
                  >
                    <CommandOptionItem
                      value={value}
                      setValue={setValue}
                      setOpen={setOpen}
                      option={item.option}
                      size={size}
                    />
                  </div>
                );
              }}
            </List>
          </CommandGroup>
        )}
      </CommandList>
    </>
  );
};

export { OptionsList };
